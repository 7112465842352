import {
  CONTACTUS_API_FAIL,
  GET_CONTACTUS,
  GET_CONTACTUS_FAIL,
  GET_CONTACTUS_SUCCESS,
} from "./actionTypes"

export const apiFail = error => ({
  type: CONTACTUS_API_FAIL,
  payload: error,
})

/* Get CONTACTUS */
export const getContactus = data => ({
  type: GET_CONTACTUS,
  payload: data,
})

export const getContactusSuccess = contactus => ({
  type: GET_CONTACTUS_SUCCESS,
  payload: contactus,
})

export const getContactusFail = error => ({
  type: GET_CONTACTUS_FAIL,
  payload: error,
})
/* Get CONTACTUS END */