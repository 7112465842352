import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import CKEditor from "react-ckeditor-component";
import FormButton from "components/Common/FormButtons";
import viewuser from "../../../assets/css/viewuser.module.css";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import { addFaq, getFaq, putFaq } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import toastr from "toastr";
const AddCustomer = ({
  accessLevel,
  history,
  error,
  onAddFaq,
  onGetFaq,
  onPutFaq,
  faq,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);
  const location = useLocation()
  const [submitInfo, setSubmitInfo] = useState(null);
  const [isView, setIsView] = useState(false);
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    type: "",
    question: "",
    answer: "",
    status: "",
  });

  useEffect(() => {
    console.log("location", location);
    const lengthh = location.pathname.length
    console.log("lengthh", lengthh);
    const view = location.pathname.split("/")[3]
    console.log("view", view);
    setIsView(view === "view" ? true : false)
  }, [location]);

  useEffect(() => {
    console.log("isView", isView);

  }, [isView]);


  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };

      return setaccesses(data);
    }
    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetFaq(id);
    }
  }, [id]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!!faq && !!faq.answer) {
        try {
          setFields({
            type: faq?.type || "",
            question: faq?.question || "",
            answer: faq?.answer || "",
            status: faq?.status || "active",
          });
        } catch (err) {
          console.log("parsing error ", err);
        }
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(faq)]);

  const handleChange = (name) => (event) => {
    console.log("name: ", name, "event.target.value", event.target.value)
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (fields.question === "") {
      return toastr.error("Question For FAQ is Required");
    }
    if (fields.answer === "") {
      return toastr.error("Answer For FAQ is Required");
    }
    if (fields.status === "") {
      return toastr.error("Status For FAQ is Required");
    }
  

    if (loading) return;

    if (id) {
      if (!accesses.canEdit) return;
      try {
        onPutFaq({ _id: faq._id, ...fields }, history);
        setSubmitInfo("Edit  successfully!!");
      } catch {
        setSubmitInfo(" Some Error Occurred !!");
      }
    } else {
      try {
        if (!accesses.canAdd) return;
        onAddFaq({ ...fields }, history);
        setSubmitInfo("Added successfully!!");
      } catch {
        setSubmitInfo(" Some Error Occurred !!");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Faqs"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) + " " + props.t("faq")
            }
            breadcrumbItems={[
              { title: props.t("faqs"), link: "/faqs" },
              // { title: id ? props.t("edit") : props.t("add") },
              { title: (id && !isView) ? props.t("edit") : (id && isView) ? props.t("view") : props.t("add") }
            ]}
          />

          <Row>
            <Col lg={12}>
              <div className={"addbox position-relative mt-4 " + viewuser.viewProfile}>
                <Form onSubmit={onSubmit} className="spinner-content">
                  {error && typeof error === "string" ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-firstname-Input">
                          {props.t("question")}
                        </Label>
                        <Input
                          type="text"
                          id="formrow-firstname-Input"
                          value={fields.question}
                          onChange={handleChange("question")}
                       
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-firstname-Input">
                          {props.t("Faq's Type")}
                        </Label>
                        <Input
                          type="select"
                          id="formrow-firstname-Input"
                          value={fields.type}
                          onChange={handleChange("type")}
                    
                          disabled={(id && isView) ? true : false}
                        >
                          <option value="" disabled selected>Select Faq's Type</option>
                          <option value={"artist"}>Artist</option>
                          <option value={"buyer"}>User</option>

                        </Input>


                        {/* } */}
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <CKEditor
                        content={fields.answer}
                        isReadOnly={(id && isView) ? true : false}
                        events={{
                          change: (evt) => {
                            const data = evt?.editor?.getData();
                            if (!(id && isView)) {
                              handleChange("answer")({
                                target: { value: data },
                              });
                            }
                          },
                        }}
                        config={{
                          height: 250,
                          toolbar: [
                            [
                              "Undo",
                              "Redo",
                              "Font",
                              "FontSize",
                              "Styles",
                              "Format",
                              "-",
                              "Maximize",
                              "-",
                              "Source",
                            ],
                            [
                              "Bold",
                              "Italic",
                              "Underline",
                              "Strike",
                              "-",
                              "RemoveFormat",
                              "-",
                              "NumberedList",
                              "BulletedList",
                            ],
                            [
                              "Link",
                              "Unlink",
                              "-",
                              "JustifyLeft",
                              "JustifyCenter",
                              "JustifyRight",
                              "JustifyBlock",
                              "-",
                              "Outdent",
                              "Indent",
                              "-",
                              "TextColor",
                              "BGColor",
                            ],
                            [
                              "Image",
                              "Table",
                              "HorizontalRule",
                              "SpecialChar",
                              "-",
                              "Blockquote",
                            ],
                          ],
                        }}
                        scriptUrl="https://cdn.ckeditor.com/4.16.0/full/ckeditor.js"
                      />
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label>{props.t("status")}</Label>
                        <Input type="select" value={fields?.status} onChange={(e) => { setFields((prevState) => ({ ...prevState, ["status"]: event.target.value })) }}  >

                          <option value={null}>Select Status</option>
                          <option value={"active"}>Active</option>
                          <option value={"inactive"}>Inactive</option>

                        </Input>
                        {/* <div className="status-switch square-switch">
                            <input
                            disabled={isView}
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  {!isView &&
                    <FormButton
                      needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      goBack={() => history.goBack()}
                    />
                  }

                  {loading && (
                    <div className="spinner">
                      <Spinner color="primary" />
                    </div>
                  )}
                </Form>
                <div className="w-64">
                  {submitInfo && <Alert>{submitInfo}</Alert>}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  faq: PropTypes.object,
  onAddFaq: PropTypes.func,
  onGetFaq: PropTypes.func,
  onPutFaq: PropTypes.func,
};

const mapStateToProps = ({ faqs }) => ({
  error: faqs.error,
  loading: faqs.loading,
  faq: faqs.faq,
});

const mapDispatchToProps = (dispatch) => ({
  onAddFaq: (data, history) => dispatch(addFaq(data, history)),
  onGetFaq: (id) => dispatch(getFaq(id)),

  onPutFaq: (data, history) => dispatch(putFaq(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
);