import { call, put, takeEvery } from "redux-saga/effects";

// Item Redux States
import {
  GET_ITEMS,
  ADD_ITEM,
  GET_ITEM,
  PUT_ITEM,
  DELETE_ITEM,
  PUT_ITEMS_STATUS,
  GET_NOTIFICATIONS,
  GET_OTHER_NOTIFICATIONS,
  MARK_GET_OTHER_NOTIFICATIONS,
  GET_PARTICULAR_SELLITEM,
} from "./actionTypes";
import {
  getItemsFail,
  getItemsSuccess,
  addItemFail,
  addItemSuccess,
  getItemFail,
  getItemSuccess,
  putItemFail,
  putItemSuccess,
  deleteItemFail,
  deleteItemSuccess,
  putItemsStatusFail,
  putItemsStatusSuccess,
  getNotificationsFail,
  getNotificationsSuccess,
  getOtherNotificationsFail,
  getOtherNotificationsSuccess,
  markOtherNotificationsSuccess,
  markOtherNotificationsFail,
  getParticularSellItemFail,
  getParticularSellItemSuccess,
} from "./actions";

import {
  getItems,
  postItem,
  getItem,
  putItem,
  deleteItem,
  putItemsStatus,
  deleteBlog,
  getNotifications,
  getOtherNotificationsApi,
  onMarkOtherNotificationsApi,
  onGetParticularSellItemApi,
} from "helpers/backend_helper";

function* fetchItems({ payload }) {
  try {
    const response = yield call(getItems, payload);

    if (response.status == "failure") {
      return yield put(getItemsFail(response.message));
    }

    yield put(getItemsSuccess(response));
  } catch (error) {
    yield put(getItemsFail(error));
  }
}

function* onAddNewItem({ payload: { item, history } }) {
  try {
    const response = yield call(postItem, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(addItemFail(response.message));
    }

    history && history.goBack();

    yield put(addItemSuccess());
  } catch (error) {
    yield put(addItemFail("Internal Error!"));
  }
}

function* fetchItem({ payload }) {
  try {
    const response = yield call(getItem, payload);

    if (response.status == "failure") {
      return yield put(getItemFail(response.message));
    }

    yield put(getItemSuccess(response.data));
  } catch (error) {
    yield put(getItemFail(error));
  }
}

function* onPutItem({ payload: { data, history } }) {
  try {
    const response = yield call(putItem, data);

    if (response.status == "failure") {
      return yield put(putItemFail(response.message));
    }

    history && history.goBack();

    yield put(putItemSuccess());
  } catch (error) {
    yield put(putItemFail("Internal Error!"));
  }
}

function* onDeleteItem({ payload: { data, callback, type } }) {
  try {
    let response;
    if (type) {
      response = yield call(deleteItem, data);
    } else {
      response = yield call(deleteBlog, data);
    }
    if (response.status == "failure") {
      return yield put(deleteItemFail(response.message));
    }

    yield put(deleteItemSuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteItemFail("Internal Error!"));
  }
}

function* onPutItemsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putItemsStatus, data);

    if (response.status == "failure") {
      return yield put(putItemsStatusFail(response.message));
    }

    yield put(putItemsStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putItemsStatusFail("Internal Error!"));
  }
}

function* onGetNotifications({ payload: { data, callback } }) {
  try {
    const response = yield call(getNotifications, data);

    if (response.status == "failure") {
      return yield put(getNotificationsFail(response.message));
    }
    yield put(getNotificationsSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(getNotificationsFail("Internal Error!"));
  }
}
function* onGetOtherNotifications({ payload: { data, callback } }) {
  try {
    const response = yield call(getOtherNotificationsApi, data);
    console.log("response from otherNotification admin", response);
    if (response.status == "failure") {

      return yield put(getOtherNotificationsFail(response.message));
    }
    yield put(getOtherNotificationsSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(getOtherNotificationsFail("Internal Error!"));
  }
}

function* onMarkOtherNotifications({ payload: { data, callback } }) {
  try {
    const response = yield call(onMarkOtherNotificationsApi, data);
    console.log("ronMarkOtherNotifications", response);
    if (response.status == "failure") {

      return yield put(markOtherNotificationsFail(response.message));
    }
    yield put(markOtherNotificationsSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(markOtherNotificationsFail("Internal Error!"));
  }
}

function* onGetParticularSellItem({ payload: { id, callback } }) {
  try {
    const response = yield call(onGetParticularSellItemApi, id);
    console.log("ronMarkOtherNotifications", response);
    if (response.status == "failure") {

      return yield put(getParticularSellItemFail(response.message));
    }
    yield put(getParticularSellItemSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(getParticularSellItemFail("Internal Error!"));
  }
}

function* ItemsSaga() {
  yield takeEvery(GET_ITEMS, fetchItems);
  yield takeEvery(ADD_ITEM, onAddNewItem);
  yield takeEvery(GET_ITEM, fetchItem);
  yield takeEvery(PUT_ITEM, onPutItem);
  yield takeEvery(DELETE_ITEM, onDeleteItem);
  yield takeEvery(PUT_ITEMS_STATUS, onPutItemsStatus);
  yield takeEvery(GET_NOTIFICATIONS, onGetNotifications);
  yield takeEvery(GET_OTHER_NOTIFICATIONS, onGetOtherNotifications);
  yield takeEvery(MARK_GET_OTHER_NOTIFICATIONS, onMarkOtherNotifications);
  yield takeEvery(GET_PARTICULAR_SELLITEM, onGetParticularSellItem);
}

export default ItemsSaga;
