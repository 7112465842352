import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import details from "assets/images/svg/details.svg"

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  // header selection 
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (history, toggleConfirmModal, toggleViewUser, sendVerificationMail, t, accesses) => [

  {
    dataField: "partner_id.shop_name",
    text: t("Partner Name"),
    sort: true,
  },
  {
    dataField: "withdraw_track_id",
    text: t("Request Id"),
    formatter: (_, row) => { return row?.withdraw_track_id ? row?.withdraw_track_id : "N/A" }
  },
  {
    dataField: "withdrawal_amount",
    text: t("Withdrawal Amount"),
    sort: true,
    formatter: (_, row) => { return row?.withdrawal_amount ? ( "$ "+ row?.withdrawal_amount.toFixed(6)) : "N/A" }
  },
  {
    dataField: "payment_method",
    text: t("Payment Method"),
    sort: true,
    formatter: (_, row) =>

      <>{row?.payment_method==="crypto" ? "Crypto" : "Bank"}
      </>,
  },
  {
    text: t("Created Date"),
    dataField: "created_date",
    sort: true,
    formatter: (_, row) =>

      <>{row?.created_date ? moment(row?.created_date).utcOffset(330).format("DD MMM YYYY hh:mm A") : "---"} </>,
  },
  {
    dataField: "Status",
    text: t("Status"),
    sort: true,
    formatter: (_, row) =>
      <div className={row?.admin_status === 'Completed' ? 'verify' : (row?.admin_status === 'Pending' || row?.admin_status === 'In Progress') ? 'pending' : (row?.admin_status === 'Reject' || row?.admin_status === 'Failed') ? 'reject d-flex align-items-center gap-1' : ''}>{row?.admin_status}</div>,

  },

  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row, i) => (
      <>
        <button onClick={() => toggleViewUser(row._id)} className='btn p-0'><img src={details} />
        </button>
      </>
    ),
  },
];

export default CustomersColumns;
