import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import classNames from "classnames";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: false,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});


const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  // {
  //   dataField: "S.No",
  //   text: t("S.No"),
  //   sort: true,
  //   formatter: (_, item, index) => (
  //     // <Link to={`/users/profile/${row.from?._id}`}>{row.from?.name}</Link>
  //     index + 1
  //   ),
  // },

  {
    dataField: "trans_hash",
    text: t("Transaction ID "),
    // sort: true,
    formatter: (_, item, index) => (
      // <a href={`https://polygonscan.com/tx/${item?.trans_hash}`} target="_blank">{item?.trans_hash?.substring(0, 5) + "....." + item?.trans_hash?.substring(item?.trans_hash?.length - 4, item?.trans_hash?.length)}</a>
      <a href={`https://www.oklink.com/amoy/tx/${item?.trans_hash}`} target="_blank">{item?.trans_hash?.substring(0, 5) + "....." + item?.trans_hash?.substring(item?.trans_hash?.length - 4, item?.trans_hash?.length)}</a>

      // https://www.oklink.com/amoy/tx/0x213b3842547bc2c6f291eb5e7da27af3905d97db82ea7c9baa3d54eae1902585
    ),
  },
  {
    text: t("Date"),
    dataField: "created_date",
    sort: true,
    formatter: (_, row) => moment.unix(row?.created_date).format("DD MMM YYYY hh:mm A"),
  },
  {
    dataField: "nft_id.name",
    text: t("NFT Title"),
    sort: true,
    // formatter: (_, row) => moment.unix(row?.created_date).format("DD MMM YYYY hh:mm A"),

  },
  {
    dataField: "nft_id.saleType",
    text: t("Sale Type"),
    sort: true,
    formatter: (_, row) => row?.nft_id?.saleType === "auction" ? "Auction" : "Fixed"
  },
  { className:'text-end',
    dataField: "price",
    text: t("Selling Price (MATIC)"),
    formatter: (_, item, index) => (
      item?.price.toFixed(6)
    ),
    // sort: true,
  },
  {
    dataField: "no_of_copies",
    text: t("Copies"),
    sort: true,
  },
  {
    
    dataField: `nft_id.royalties`,
    text: t("Royalty Percentage (%) "),
    sort: true,
    formatter: (_, item, index) => (
      item?.nft_id?.royalties ? item?.nft_id?.royalties : 0
    ),

  },
  {
    dataField: "seller_discount",
    text: t("Discount by Seller (MATIC)"),
    // sort: true,
    formatter: (_, item, index) => (
      item?.seller_discount ? item?.seller_discount?.toFixed(6) + " MATIC" : 0
    ),
  },
  {
    dataField: "buyer_discount",
    text: t("Discount by Buyer (MATIC)"),
    // sort: true,
    formatter: (_, item, index) => (
      item?.buyer_discount ? item?.buyer_discount?.toFixed(6) + " MATIC" : 0
    ),
  },
  {
    
    dataField: "platformfee",
    text: t("Platform Fee (MATIC)"),
    // sort: true,
    formatter: (_, item, index) => (
      item?.platformfee ? item?.platformfee?.toFixed(6) + " MATIC" : 0
    ),
  },
  {
    dataField: "payableAmount",
    text: t("Amount Payable (MATIC)"),
    // sort: true,
    formatter: (_, item, index) => (
      item?.payableAmount ? item?.payableAmount?.toFixed(6) + " MATIC" : 0
    ),
  },
];

export default ListColumns;