import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
const Notification = (props) => {
  // console.log("deepak", props.isOpen);
  return (
    <React.Fragment>
     
    </React.Fragment>
  );
};
export default withTranslation()(Notification);
