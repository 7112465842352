import { GET_ALL_SELL_ITEMS, GET_ALL_SELL_ITEMS_FAIL, GET_ALL_SELL_ITEMS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    error: "",
    loading: false,
    allSellItems: [],
    totalSellItems: 0,
}

const SellItems = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_SELL_ITEMS:
            return {
                ...state,
                loading: true,
            }

        case GET_ALL_SELL_ITEMS_SUCCESS:
            return {
                ...state,
                allSellItems: action.payload.data || [],
                totalSellItems: action.payload.totalCount || 0,
                loading: false,
                error: "",
            }
        case GET_ALL_SELL_ITEMS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state
    }
}

export default SellItems