import { all, fork } from "redux-saga/effects";

import Alert from "./Alert/saga";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import Register from "./auth/register/saga";
import Settings from "./Settings/saga";
import Customers from "./customers/saga";
import Domain from "./domain/saga";
import Collection from "./collection/saga";
import Items from "./items/saga";
import CategoriesSaga from "./category/saga";
import ArtsSaga from "./art/saga";

// Sub admins
import SubAdmins from "./subAdmins/saga";

// Content Page
import content_page from "./contentpage/saga";

// File
import File from "./File/saga";

// Logs
import Logs from "./logs/saga";

// Email Template
import emailtemplates from "./emailtemplates/saga";
import ActivitiesSaga from "./activity/saga";

// Faqs
import faqs from "./faq/saga";
import contactus from "./contactus/saga";

// SendEmails

import SendEmailSaga from "./SendEmail/saga";

import ImageSaga from "./AddImages/saga";
import sendverificationEmailSaga from "./emailVerification/saga"
import SendOtherNotificationSaga from "./otherNotification/saga"
import TransactionsSaga from "./transaction/sagas";
import SellItemsSaga from "./sellItems/saga";
import Partners from "./printingPartner/saga";
export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    Register(),
    LayoutSaga(),
    Settings(),
    fork(ActivitiesSaga),
    fork(Customers),
    fork(Domain),
    fork(Collection),
    fork(Items),
    content_page(),
    fork(CategoriesSaga),
    fork(SubAdmins),
    fork(File),
    fork(Logs),
    fork(emailtemplates),
    fork(faqs),
    fork(contactus),
    fork(SendEmailSaga),
    fork(ImageSaga),
    fork(ArtsSaga),
    fork(sendverificationEmailSaga),
    fork(SendOtherNotificationSaga),
    fork(TransactionsSaga),
    fork(SellItemsSaga),
    fork(Partners),
  ]);
}
