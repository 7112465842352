import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { ADD_PRINTING_PARTTNER, DELETE_PRINTING_PARTTNER, EDIT_PRINTING_PARTTNER, GET_PRINTING_CSV_DATA, GET_PRINTING_PARTTNER, GET_PRINTING_PAYMENT_REQUEST, GET_PRINTING_REQUEST, GET_PRINTING_WITHDRAWL_REQUEST, LIST_PRINTING_PARTTNER, LIST_PRINTING_REQUEST, UPDATE_PRINTING_WITHDRAWL_REQUEST, VIEW_PRINTING_WITHDRAWL_REQUEST } from "./actionTypes"
import { addPrintingPartnerApi, deletePrintingPartnerApi, editPrintingPartnerApi, getPrinterApi, getPrinterCsvDataApi, getPrintingPartnerApi, getPrintingPaymentListApi, getPrintingWithdrawlListApi, listPrinterApi, listPrintingPartnerApi, updatePrintingWithdrawlListApi, viewPrintingWithdrawlListApi } from "helpers/backend_helper"
import { addPrinterFail, addPrinterSuccess, deletePrinterFail, deletePrinterSuccess, editPrinterFail, editPrinterSuccess, getPrinterCsvDataFail, getPrinterCsvDataSuccess, getPrinterFail, getPrinterSuccess, getPrinterWithdrawlListFail, getPrinterWithdrawlListSuccess, getPrintingPaymentListFail, getPrintingPaymentListSuccess, getPrintingRequestFail, getPrintingRequestSuccess, listPrinterFail, listPrinterSuccess, lsitPrintingRequestFail, lsitPrintingRequestSuccess, updatePrinterWithdrawlListFail, updatePrinterWithdrawlListSuccess, viewPrinterWithdrawlListFail, viewPrinterWithdrawlListSuccess } from "./actions"


function* onAddPartner({ payload: { data, callback } }) {
    try {
      const response = yield call(addPrintingPartnerApi, data)
      if (response.status == "failure") {
       yield put(addPrinterFail(response.message))
      }
      yield put(addPrinterSuccess(response))
      callback && callback(response)
    } catch (error) {
      yield put(addPrinterFail("Internal Error!"))
    }
  }

  function* listPrintingPartner({ payload: { data, callback } }) {
    try {
      const response = yield call(listPrintingPartnerApi, data)
      console.log("reposonefsdfdf", response)
      if (response.status == "failure") {
        return yield put(listPrinterFail(response.message))
      }
      yield put(listPrinterSuccess(response))
      callback && callback(response)
    } catch (error) {
      yield put(listPrinterFail("Internal Error!"))
    }
  }

  function* editPartnerInfo({ payload: { data, callback } }) {
    try {
      const response = yield call(editPrintingPartnerApi, data)
      console.log("editPartnerInfores", response)
      if (response.status == "failure") {
       yield put(editPrinterFail(response.message))
      }
      yield put(editPrinterSuccess(response))
      callback && callback(response)
    } catch (error) {
      yield put(editPrinterFail("Internal Error!"))
    }
  }

  function* getPartnerInfo({ payload: { data, callback } }) {
    try {
      console.log({databody :data})
      const response = yield call(getPrintingPartnerApi, data)
      console.log("deletePartnerInfores", response)
      if (response.status == "failure") {
       yield put(getPrinterFail(response.message))
      }
      yield put(getPrinterSuccess(response))
       callback && callback(response)
     
    } catch (error) {
      yield put(getPrinterFail("Internal Error!"))
    }
  }

  function* deletePartnerInfo({ payload: { data, callback } }) {
    try {
      const response = yield call(deletePrintingPartnerApi, data)
      console.log("deletePartnerInfores", response)
      if (response.status == "failure") {
       yield put(deletePrinterFail(response.message))
      }
      yield put(getPrinterSuccess(response))
      callback && callback(response)
     
    } catch (error) {
      yield put(deletePrinterFail("Internal Error!"))
    }
  }


  function* listPrintingInfo({ payload: { data, callback } }) {
    try {
      const response = yield call(listPrinterApi, data)
      console.log("listPrintingInfores", response)
      if (response.status == "failure") {
       yield put(lsitPrintingRequestFail(response.message))
      }
      yield put(lsitPrintingRequestSuccess(response))
      callback && callback(response)
     
    } catch (error) {
      yield put(lsitPrintingRequestFail("Internal Error!"))
    }
  }



  function* getPrintingInfo({ payload: { data, callback } }) {
    try {
      const response = yield call(getPrinterApi, data)
      console.log("getPrintingInfo", response)
      if (response.status == "failure") {
       yield put(getPrintingRequestFail(response.message))
      }
      yield put(getPrintingRequestSuccess(response))
      callback && callback(response)
     
    } catch (error) {
      yield put(getPrintingRequestFail("Internal Error!"))
    }
  }
  function* getPrintingPaymentList({ payload: { data, callback } }) {
    try {
      const response = yield call(getPrintingPaymentListApi, data)
      console.log("getPrintingPaymentListres", response)
      if (response.status == "failure") {
       yield put(getPrintingPaymentListFail(response.message))
      }
      yield put(getPrintingPaymentListSuccess(response))
      callback && callback(response)
     
    } catch (error) {
      yield put(getPrintingPaymentListFail("Internal Error!"))
    }
  }
  function* getPrinterWithdrawlList({ payload: { data, callback } }) {
    try {
      const response = yield call(getPrintingWithdrawlListApi , data)
      console.log("getPrinterWithdrawlList", response)
      if (response.status == "failure") {
       yield put(getPrinterWithdrawlListFail(response.message))
      }
      yield put(getPrinterWithdrawlListSuccess(response))
      callback && callback(response)
     
    } catch (error) {
      yield put(getPrinterWithdrawlListFail("Internal Error!"))
    }
  }
  function* viewPrinterWithdrawlList({ payload: { data, callback } }) {
    try {
      const response = yield call(viewPrintingWithdrawlListApi , data)
      console.log("viewPrinterWithdrawlList", response)
      if (response.status == "failure") {
       yield put(viewPrinterWithdrawlListFail(response.message))
      }
      yield put(viewPrinterWithdrawlListSuccess(response))
      callback && callback(response)
     
    } catch (error) {
      yield put(viewPrinterWithdrawlListFail("Internal Error!"))
    }
  }

  function* updatePrinterWithdrawlList({ payload: { data, callback } }) {
    try {
      const response = yield call(updatePrintingWithdrawlListApi , data)
      console.log("updatePrinterWithdrawlList", response)
      if (response.status == "failure") {
       yield put(updatePrinterWithdrawlListFail(response.message))
      }
      yield put(updatePrinterWithdrawlListSuccess(response))
      callback && callback(response)
     
    } catch (error) {
      yield put(updatePrinterWithdrawlListFail("Internal Error!"))
    }
  }


  function* getPrinterCsvData({ payload: { data, callback } }) {
    try {
      const response = yield call(getPrinterCsvDataApi , data)
      console.log("getPrinterCsvDataresponse", response)
      if (response.status == "failure") {
       yield put(getPrinterCsvDataFail(response.message))
      }
      yield put(getPrinterCsvDataSuccess(response))
      callback && callback(response)
    } catch (error) {
      yield put(getPrinterCsvDataFail("Internal Error!"))
    }
  }
  
  function* PrintingPartnerSaga() {
    yield takeEvery(ADD_PRINTING_PARTTNER, onAddPartner)
    yield takeEvery(LIST_PRINTING_PARTTNER, listPrintingPartner)
    yield takeEvery(EDIT_PRINTING_PARTTNER, editPartnerInfo)
    yield takeEvery(GET_PRINTING_PARTTNER, getPartnerInfo)
    yield takeEvery(DELETE_PRINTING_PARTTNER, deletePartnerInfo)
    yield takeEvery(LIST_PRINTING_REQUEST, listPrintingInfo)
    yield takeLatest(GET_PRINTING_REQUEST, getPrintingInfo)
    yield takeEvery(GET_PRINTING_PAYMENT_REQUEST, getPrintingPaymentList)
    yield takeEvery(GET_PRINTING_WITHDRAWL_REQUEST, getPrinterWithdrawlList)
    yield takeEvery(VIEW_PRINTING_WITHDRAWL_REQUEST, viewPrinterWithdrawlList)
    yield takeLatest(UPDATE_PRINTING_WITHDRAWL_REQUEST, updatePrinterWithdrawlList)
    yield takeLatest(GET_PRINTING_CSV_DATA, getPrinterCsvData)
    // yield takeEvery(DELETE_ACTIVITY, onDeleteActivity)
    // yield takeEvery(PUT_ACTIVITIES_STATUS, onPutActivitiesStatus)
  }



  export default PrintingPartnerSaga
