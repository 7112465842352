import {
  CATEGORY_API_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_CATEGORY,
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
  PUT_CATEGORY,
  PUT_CATEGORY_FAIL,
  PUT_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  PUT_CATEGORIES_STATUS,
  PUT_CATEGORIES_STATUS_FAIL,
  PUT_CATEGORIES_STATUS_SUCCESS,
  GET_BLOG,
  GET_BLOG_SUCCESS,
  GET_BLOG_FAIL,
  GET_REPORT,
  GET_REPORT_FAIL,
  GET_REPORT_SUCCESS,
  GET_REPORT_VIEW,
  ADD_BLOG,
  ADD_BLOG_FAIL,
  ADD_BLOG_SUCCESS,
  UPDATE_BLOG,
  UPDATE_BLOG_FAIL,
  UPDATE_BLOG_SUCCESS,
} from "./actionTypes";

import * as CONST from "./actionTypes";

export const apiFail = (error) => ({
  type: CATEGORY_API_FAIL,
  payload: error,
});

/* Get items */
export const getCategories = (data) => ({
  type: GET_CATEGORIES,
  payload: data,
});

export const getCategoriesSuccess = (items) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: items,
});

export const getCategoriesFail = (error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
});
/* Get Categories END */

/* Add Category */
export const addCategory = (item, history) => ({
  type: ADD_CATEGORY,
  payload: { item, history },
});

export const addCategoryFail = (error) => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
});

export const addCategorySuccess = () => ({
  type: ADD_CATEGORY_SUCCESS,
});
/* Add Category END */

/* Get Category */
export const getCategory = (id) => ({
  type: GET_CATEGORY,
  payload: id,
});

export const getCategoryFail = (error) => ({
  type: GET_CATEGORY_FAIL,
  payload: error,
});

export const getCategorySuccess = (item) => ({
  type: GET_CATEGORY_SUCCESS,
  payload: item,
});
/* Get Category END */

/* Update Category */
export const putCategory = (data, history) => ({
  type: PUT_CATEGORY,
  payload: { data, history },
});

export const putCategoryFail = (error) => ({
  type: PUT_CATEGORY_FAIL,
  payload: error,
});

export const putCategorySuccess = () => ({
  type: PUT_CATEGORY_SUCCESS,
});
/* Update Category END */

/* Delete Category */
export const deleteCategory = (data, callback) => ({
  type: DELETE_CATEGORY,
  payload: { data, callback },
});

export const deleteCategoryFail = (error) => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
});

export const deleteCategorySuccess = () => ({
  type: DELETE_CATEGORY_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Categories Status */
export const putCategoriesStatus = (data, callback) => ({
  type: PUT_CATEGORIES_STATUS,
  payload: { data, callback },
});

export const putCategoriesStatusFail = (error) => ({
  type: PUT_CATEGORIES_STATUS_FAIL,
  payload: error,
});

export const putCategoriesStatusSuccess = () => ({
  type: PUT_CATEGORIES_STATUS_SUCCESS,
});
/* Update Multi Categories Status END */

//blog
export const getBlogs = (data) => ({
  type: GET_BLOG,
  payload: data,
});

export const getBlogsSuccess = (items) => ({
  type: GET_BLOG_SUCCESS,
  payload: items,
});

export const getBlogsFail = (error) => ({
  type: GET_BLOG_FAIL,
  payload: error,
});

export const deleteBlogs = (data, callback) => ({
  type: CONST.DELETE_BLOG,
  payload: { data, callback },
});

export const deleteBlogsSuccess = () => ({
  type: CONST.DELETE_BLOG_SUCCESS,
});

export const deleteBlogsFail = (error) => ({
  type: CONST.DELETE_BLOG_FAIL,
  payload: error,
});

export const viewBlog = (id) => ({
  type: CONST.VIEW_BLOG,
  payload: id,
});

export const viewBlogFail = (error) => ({
  type: CONST.VIEW_BLOG_FAIL,
  payload: error,
});

export const viewBlogSuccess = (item) => ({
  type: CONST.VIEW_BLOG_SUCCESS,
  payload: item,
});

export const getReport = (data) => ({
  type: GET_REPORT,
  payload: data,
});

export const getReportSuccess = (items) => ({
  type: GET_REPORT_SUCCESS,
  payload: items,
});

export const getReportFail = (error) => ({
  type: GET_REPORT_FAIL,
  payload: error,
});

export const getReportById = (data) => ({
  type: GET_REPORT_VIEW,
  payload: data,
});

//  Add Blog
export const addBlogs = (data, callback) => ({
  type: ADD_BLOG,
  payload: { data, callback },
});

export const addBlogsFail = (error) => ({
  type: ADD_BLOG_FAIL,
  payload: error,
});

export const addBlogsSuccess = () => ({
  type: ADD_BLOG_SUCCESS,
});

export const putBlogs = (data, callback) => ({
  type: UPDATE_BLOG,
  payload: { data, callback },
});

export const putBlogsFail = (error) => ({
  type: UPDATE_BLOG_FAIL,
  payload: error,
});

export const putBlogsSuccess = () => ({
  type: UPDATE_BLOG_SUCCESS,
});


export const getSubCategories = (data, callback) => ({
  type: CONST.GET_SUBCATEGORIES,
  payload: { data, callback },
});
export const getSubCategoriesFail = (error) => ({
  type: CONST.GET_SUBCATEGORIES_FAIL,
  payload: error,
});
export const getSubCategoriesSuccess = (data) => ({
  type: CONST.GET_SUBCATEGORIES_SUCCESS,
  payload: data,
});