import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import toastr from "toastr";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";
import sortSvg from "../../../assets/images/svg/sort.svg";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

import SearchInput from "components/Common/SearchInput";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getFaqs, deleteFaq, putFaqsStatus } from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";

const Faqs = ({
  accessLevel,
  history,
  faqs,
  totalFaqs,
  onGetFaqs,
  onDeleteFaq,
  onPutFaqsStatus,
  loading,
  ...props
}) => {
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    faqId: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 10,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });

  const [searchText, setSearchText] = useState("");
  const [faqsList, setFaqsList] = useState([]);
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalFaqs,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    onGetFaqs(filter);
    setFaqsList(faqs);
  }, [onGetFaqs, JSON.stringify(filter)]);

  useEffect(() => {
    setFaqsList(faqs || []);
  }, [faqs]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (faqId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, faqId }));
  };

  const removeConfirm = () => {
    const { faqId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetFaqs(filter);
      toastr.success("Faq Deleted Successfully");
    };

    setConfirmModal({ isOpen: !isOpen, faqId: null });
    onDeleteFaq({ _id: faqId }, onComplete);
  };

  const onStatusChange = (value) => {
    const fieldName = !!value ? "status" : "";
    const fieldValue = value;

    setFilter((prevState) => ({
      ...prevState,
      fields: { fieldName, fieldValue },
    }));
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText, page: 1 }));
  };

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const handleFaqStatusChange = (status) => {
    const callback = () => {
      setSelected([]);
      onGetFaqs(filter);
    };

    onPutFaqsStatus({ _id: selected, status }, callback);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Faqs"
            breadcrumbItem={props.t("faqs")}
            breadcrumbItems={[{ title: props.t("faqs") }]}
          />

          <Row>
            <Col xs="12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={faqsList || []}
                    columns={ListColumns(
                      history,
                      toggleConfirmModal,
                      accesses,
                      props.t
                    )}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-xl-12 mb-4 filterBox">
                            <div className="row">
                              <div className="col-xl-5">
                                <div className="form-group position-relative">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search")}
                                    searchText={searchText}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/filterIcon.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Filter
                                  </h3>
                                  <div className="form-group position-relative w-100">
                                    <select
                                      className=" form-control form-select"
                                      onChange={(e) =>
                                        onStatusChange(e.target.value)
                                      }
                                      value={filter.fields.fieldValue}
                                    >
                                      <option value="">
                                        {props.t("status")}
                                      </option>
                                      <option value="active">
                                        {props.t("active")}
                                      </option>
                                      <option value="inactive">
                                        {props.t("inactive")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div> */}
                              { /*<div className="col-xl-3">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/shortby.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Sort by
                                  </h3>
                                  <div className="form-group position-relative w-100">
                                    <select
                                      name=""
                                      className="form-control form-select"
                                      id=""
                                      onChange={sortDateWise}
                                    >
                                      <option value="Newest_first">Newest First</option>
                                      <option value="Oldest_first">Oldest First</option>
                                    </select>
                                  </div>
                                </div>
                              </div> */}
                              {!!filter.fields.fieldName ||
                                (!!filter.search && (
                                  <div className='col-xl-2'>
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          fields: {
                                            fieldName: "",
                                            fieldValue: "",
                                          },
                                          search: "",
                                        }));
                                        setSearchText("");
                                      }}

                                      className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}
                                    </button>
                                  </div>
                                ))}

                              <div className="col-xl-3 ms-auto text-end">
                                <Link
                                  className="btn btn-dark py-2 px-4 rounded-pill"
                                  to={'/add/faqs'}
                                >
                                  Add Faq
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>


                        {(accesses.canEdit || accesses.canDelete) && (
                          <AnimateHeight
                            duration={500}
                            height={selected.length > 0 ? "auto" : 0}
                          >
                            <div className="custom-flex-xs d-flex align-items-center mb-3">
                              {accesses.canEdit && (
                                <div className="filter form-group d-flex align-items-center mb-0 mr-3">
                                  <label
                                    className="mb-0"
                                    style={{ minWidth: "48px" }}
                                  >
                                    {props.t("action")}:
                                  </label>

                                  <div className="w-auto pl-3">
                                    <select
                                      className="custom-select w-100"
                                      onChange={(e) =>
                                        handleFaqStatusChange(
                                          e.target.value
                                        )
                                      }
                                      style={{ minWidth: "106px" }}
                                    >
                                      <option value="">
                                        {props.t("status")}
                                      </option>
                                      <option value="active">
                                        {props.t("active")}
                                      </option>
                                      <option value="inactive">
                                        {props.t("inactive")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              )}
                              {accesses.canDelete && (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    handleFaqStatusChange("archived")
                                  }
                                >
                                  {props.t("delete")} {props.t("selected")}
                                </button>
                              )}
                            </div>
                          </AnimateHeight>
                        )}

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tableBox faqtble">
                              <BootstrapTable
                                responsive
                                remote
                                bordered={false}
                                noDataIndication={"No data available"}
                                striped={false}
                                classes={
                                  "table table-centered table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                defaultSorted={[
                                  {
                                    dataField: "createdAt",
                                    order: "desc",
                                  },
                                ]}
                                sort={{
                                  sortCaret: (order) =>
                                    order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) : <span className="caret">
                                      {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                    </span>,
                                }}
                              />

                              {loading && (
                                <div className="spinnerBoxNew">
                                  <Spinner color="primary" />
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Faqs.propTypes = {
  faqs: PropTypes.array,
  totalFaqs: PropTypes.number,
  onGetFaqs: PropTypes.func,
  onDeleteFaq: PropTypes.func,
  onPutFaqsStatus: PropTypes.func,
};

const mapStateToProps = ({ Settings, faqs }) => ({
  settings: Settings.settings,
  loading: faqs.loading,
  faqs: faqs.faqs,
  totalFaqs: faqs.totalFaqs,
});

const mapDispatchToProps = (dispatch) => ({
  onGetFaqs: (data) => dispatch(getFaqs(data)),
  onDeleteFaq: (data, callback) => dispatch(deleteFaq(data, callback)),
  onPutFaqsStatus: (data, callback) => dispatch(putFaqsStatus(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Faqs))
);
