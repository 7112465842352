import React from "react"
import { Link } from "react-router-dom"
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap"
import moment from "moment-timezone"
import { NOTIFICATION_TYPE, BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants"

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
})

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "type",
    text: t("Event"),
    formatter: (_, row) => NOTIFICATION_TYPE[row.type],
  },
  {
    dataField: "content",
    text: t("Notification"),
  },
  {
    dataField: "item.name",
    text: t("Item"),
    formatter: (_, row) => (
      <Link to={`/items/view/${row.item?._id}`}>{row.item?.name}</Link>
    ),
  },
  {
    dataField: "sender.name",
    text: t("From"),
    formatter: (_, row) => (
      <Link to={`/users/profile/${row.sender?._id}`}>{row.sender?.name}</Link>
    ),
  },
  {
    dataField: "receiver.name",
    text: t("To"),
    formatter: (_, row) => (
      <Link to={`/users/profile/${row.receiver?._id}`}>
        {row.receiver?.name}
      </Link>
    ),
  },
  {
    text: t("Created At"),
    dataField: "created_date",
    sort: true,
    formatter: (_, row) => moment(row.created_date).format("DD MMM YYYY LT"),
  },
]

export default ListColumns
