import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import CKEditor from "react-ckeditor-component";
import { connect } from "react-redux";
import FormButton from "components/Common/FormButtons";
import Dropzone from "react-dropzone";
import viewuser from "../../../assets/css/viewuser.module.css";
import toastr from "toastr";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";
import { uploadFile, addSendEmail, getCategory, getEmailByID } from "store/actions";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { withTranslation } from "react-i18next";
import avatar4 from "assets/images/svg/logoPlaceholder.svg";
import logoheader from "assets/images/logo.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const AddSendEmail = ({
  accessLevel,
  history,
  error,
  fileLoading,
  onGetEmailById,
  // onPutCategories,
  Emails,
  onUploadFile,
  loading,
  email,
  ...props
}) => {
  const { id } = useParams();
  const location = useLocation()
  useEffect(() => {
    console.log("location", location);
    console.log("id", id);
  }, [location, id])



  const didMountRef = useRef(null);
  const [selectedFiles, setselectedFiles] = useState({});
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    title: "",
    subject: "",
    description: "",
    image: "",
    logo: logoheader,
  });

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };
      return setaccesses(data);
    }
    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetEmailById(id);
    }
  }, [id]);
  useEffect(() => {
    setFields((prevState) => ({ ...prevState, title: email?.title, subject: email?.subject, description: email?.description, image: email?.image }));
    let images = {};
    if (email?.image) {
      images.image = { preview: email?.image };
    }
    // if (email?.image) {
    //   images.bannerImage = { preview: Emails?.bannerImage?.link };
    // }
    setselectedFiles(images);
  }, [email]);
  // useEffect(() => {
  //   if (didMountRef.current) {
  //     if (!!items) {
  //       try {
  //         setFields({
  //           title: Emails?.title,
  //           subject: Emails?.subject,
  //           description: Emails?.description,
  //           image: Emails?.image?._id,
  //         });

  //         let images = {};
  //         if (Emails?.image?.link) {
  //           images.image = { preview: Emails?.image?.link };
  //         }
  //         if (Emails?.bannerImage?.link) {
  //           images.bannerImage = { preview: Emails?.bannerImage?.link };
  //         }
  //         setselectedFiles(images);
  //       } catch (err) {
  //         console.log("parsing error ", err);
  //       }
  //     }
  //   } else didMountRef.current = true;
  // }, [JSON.stringify(Emails)]);

  const uploadFileSuccess = (name) => (response) => {
    console.log(" imageresponse", response)
    const { link: image } = response.data;
    setFields((prevState) => ({ ...prevState, [name]: image }));
  };

  function handleAcceptedFiles(name, _files) {
    if (id && !accesses.canEdit) return;
    const files = _files?.filter((file) => file.size < 5e6);
    if (files.length < _files.length) {
      return toastr.error(props.t("Max. upload file size: 5MB"));
    }

    onUploadFile({ image: files[0] }, uploadFileSuccess(name));
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setselectedFiles((prevState) => ({ ...prevState, [name]: files[0] }));
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    // event.preventDefault();
    if (loading) return;
    if (id) {
      // if (!accesses.canEdit) return;
      // onPutCategories(
      //   { category_id: items?._id, ...fields },
      //   toastr.success("Updated Successfully"),
      //   history.push("/categories")
      // );
    } else {
      if (!accesses.canAdd) return;
      // onAddSendEmails(
      //   { type: fields.blockchain, ...fields },
      //   toastr.success("Send Email Successfully"),
      //   history.push("/email/add")
      // );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Send Email"
            breadcrumbItem={
              (id ? props.t("view") : props.t("add")) +
              " " +
              props.t("Send Email")
            }
            breadcrumbItems={[
              { title: props.t("Send Email"), link: "/email" },
              { title: id ? props.t("view") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>

              <div className={"addbox position-relative " + viewuser.viewProfile}>
                <Form  className="spinner-content">
                  <Row>
                    <Col className="position-relative" lg={2}>
                      <div className="uploadImageMaine">
                        <div className="uploadImgNew">
                          <Dropzone disabled={id ? true : false}
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("image", acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles.image;

                              return (
                                <div
                                  className="dropzone-single-image  categoryUploadImage avatar-xl"
                                  {...getRootProps()}
                                >
                                  <input
                                    className="category-inp"
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}

                                  />
                                  <img
                                    className="dropzone-single-image categoryUploadImage avatar-xl"
                                    alt={
                                      !!imageFile && imageFile.name
                                        ? imageFile.name
                                        : "hlc"
                                    }
                                    src={
                                      !!imageFile
                                        ? imageFile.preview
                                        : avatar4
                                    }
                                  />
                                  <div className="edit" hidden={id ? true : false}>
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                          <FormText className="fw-bold ">Max. upload file size: 5MB</FormText>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="formrow-name-Input">
                              {props.t("Subject")}
                            </Label>
                            <Input
                              type="text"
                              id="formrow-name-Input"
                              value={fields.subject}
                              onChange={handleChange("subject")}
                              placeholder="Enter subject"
                              required
                              disabled={id ? true : false}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup>
                            <Label for="formrow-name-Input">
                              {props.t("Title")}
                            </Label>
                            <Input
                              type="text"
                              id="formrow-name-Input"
                              value={fields.title}
                              onChange={handleChange("title")}
                              placeholder="Enter title"
                              required
                              disabled={id ? true : false}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="formrow-name-Input">
                              {props.t("Description")}
                            </Label>
                            <Input
                              type="textarea"
                              id="formrow-name-Input"
                              value={fields.description}
                              onChange={handleChange("description")}
                              placeholder="Description....."
                              required
                              disabled={id ? true : false}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    {fileLoading ? (
                      <Spinner />
                    ) : (
                      // <FormButton
                      //   needSubmit={id ? accesses.canEdit : accesses.canAdd}

                      // />


                      <div className="col-xl-12 col-12 col-md-12 col-sm-12 ">
                        <button className="btn verifybtn px-5 text-white me-3" onClick={onSubmit}  hidden={id ? true : false}>
                          Submit
                        </button>

                        {/* <Link to={'/categories'} className="btn btn-outline-danger px-5">
                        Cancel
                      </Link> */}

                      </div>
                    )}
                  </Row>
                </Form>
              </div>

              <Form hidden onSubmit={onSubmit} className="spinner-content">
                {error && typeof error === "string" ? (
                  <Alert color="danger">{error}</Alert>
                ) : null}

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="formrow-name-Input">
                        {props.t("Subject")}
                      </Label>
                      <Input
                        type="text"
                        id="formrow-name-Input"
                        value={fields.subject}
                        onChange={handleChange("subject")}
                        placeholder="Enter subject"
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Label for="formrow-name-Input">
                        {props.t("Title")}
                      </Label>
                      <Input
                        type="text"
                        id="formrow-name-Input"
                        value={fields.title}
                        onChange={handleChange("title")}
                        placeholder="Enter title"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="formrow-name-Input">
                        {props.t("Description")}
                      </Label>
                      <Input
                        type="textarea"
                        id="formrow-name-Input"
                        value={fields.description}
                        onChange={handleChange("description")}
                        placeholder="Description....."
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="categories_div">
                      <FormGroup>
                        <Label for="formrow-profile-image-Input">
                          {props.t("Image")}
                        </Label>

                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles("image", acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => {
                            const imageFile = selectedFiles.image;

                            return (
                              <div
                                className="dropzone-single-image avatar-xl"
                                {...getRootProps()}
                              >
                                <input
                                  className="category-inp"
                                  {...getInputProps()}
                                  id="formrow-profile-image-Input"
                                  multiple={false}
                                />
                                <img
                                  className="rounded avatar-xl categories-image-uploader"
                                  alt={
                                    !!imageFile && imageFile.name
                                      ? imageFile.name
                                      : "hlc"
                                  }
                                  src={
                                    !!imageFile
                                      ? imageFile.preview
                                      : avatar4
                                  }
                                />

                                <div className="edit">
                                  <i className="bx bx-pencil"></i>
                                </div>
                              </div>
                            );
                          }}
                        </Dropzone>

                        <FormText>Max. upload file size: 5MB</FormText>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>

                {fileLoading ? (
                  <Spinner />
                ) : (
                  <FormButton
                    needSubmit={id ? accesses.canEdit : accesses.canAdd}
                  />
                )}

                {loading && (
                  <div className="spinnerBoxNew">
                    <Spinner color="primary" />
                  </div>
                )}
              </Form>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddSendEmail.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  items: PropTypes.object,
  // onAddSendEmails: PropTypes.func,
  onGetEmailById: PropTypes.func,
  onGetCategories: PropTypes.func,
  email: PropTypes.object
};
const mapStateToProps = ({ File, SendEmailReducer }) => ({
  loading: SendEmailReducer.loading,
  Emails: SendEmailReducer.email,
  totalEmails: SendEmailReducer.totalEmails,
  fileLoading: File?.loading,
  email: SendEmailReducer.email,

});

const mapDispatchToProps = (dispatch) => ({
  // onAddSendEmails: (data, history) => dispatch(addSendEmail(data, history)),
  onGetEmailById: (id) => dispatch(getEmailByID(id)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddSendEmail))
);
