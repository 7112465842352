import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
  getCollections,
  deleteCollection,
  putCollectionsStatus,
  getNewCollections,
} from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import ViewCollectionDialog from "./ViewCollectionDialog";
import sortSvg from "../../../assets/images/svg/sort.svg";
const Collections = ({
  accessLevel,
  history,
  collections,
  totalCollections,
  onGetCollections,
  onDeleteCollection,
  onPutCollectionsStatus,
  loading,
  newCollections,
  totalNewCollections,
  ...props
}) => {

  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    collectionId: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "created_at",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [searchText, setSearchText] = useState("");
  const [collectionsList, setCollectionsList] = useState([]);
  const data = [
    {
      _id: '123654',
      name: 'Deepak',
      author: {
        username: 'kumar'
      },
      category: 'art',
      noofnft: '10',
      createdAt: '10/09/2023'

    }
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalNewCollections,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    page:filter?.page
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    const callback = (response) => {
      console.log("new collection response", response);
    }
    onGetCollections(filter, callback);
    // onGetUsers({ ...filter, fields: [...filter.fields, lastFields] });
    setCollectionsList(collections);
  }, [onGetCollections, JSON.stringify(filter)]);

  useEffect(() => {
    setCollectionsList(collections || []);
  }, [collections]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "created_at";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }

  };

  const toggleConfirmModal = (collectionId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      collectionId,
    }));
  };

  const removeConfirm = () => {
    const { collectionId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetCollections(filter);
    };

    setConfirmModal({ isOpen: !isOpen, collectionId: null });
    onDeleteCollection({ _id: collectionId }, onComplete);
  };

  const onStatusChange = (value) => {
    const fieldName = !!value ? "status" : "";
    const fieldValue = value;

    setFilter((prevState) => ({
      ...prevState,
      fields: [prevState.fields[0], { fieldName, fieldValue }],
    }));
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText, page:1 }));
  };

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const handleCollectionStatusChange = (status) => {
    const callback = () => {
      setSelected([]);
      onGetCollections(filter);
    };

    onPutCollectionsStatus({ _id: selected, status }, callback);
  };
  const [viewCollection, setviewCollection] = useState({
    isOpen: false,
    userId: null,
  });
  function toggleviewCollection(userId) {
    console.log(userId);
    setviewCollection((prevState) => ({ isOpen: !prevState.isOpen, userId }));
    console.log(viewCollection);
  };
  return (
    <React.Fragment>
      <ViewCollectionDialog isOpen={viewCollection.isOpen} userId={viewCollection.userId} toggle={toggleviewCollection} />
      {/* <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      /> */}


      <Container fluid>
        <Breadcrumbs
          title="Collections"
          breadcrumbItem={props.t("Collections")}
          breadcrumbItems={[{ title: props.t("Collections") }]}
        />

        <Row>
          <Col xs="12">

            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  data={newCollections || []}
                  columns={ListColumns(
                    history,

                    toggleviewCollection,
                    accesses,
                    props.t
                  )}
                  bootstrap4
                  search
                >
                  {(toolkitProps) => (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-xl-12 mb-4 filterBox">

                          <div className="row">
                            <div className="col-xl-4">
                              <div className="form-group position-relative">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />


                              </div>
                            </div>

                            {!!filter.fields[1]?.fieldName ||
                              (!!filter.search && (
                                <div className='col-xl-2'>
                                  <button onClick={(e) => {
                                    e.preventDefault();
                                    setFilter((prevState) => ({
                                      ...prevState,
                                      fields: [
                                        {
                                          fieldName: "",
                                          fieldValue: "",
                                        },
                                        {
                                          fieldName: "",
                                          fieldValue: "",
                                        },
                                      ],
                                      search: "",
                                    }));
                                    setSearchText("");
                                  }}
                                    className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}</button>
                                </div>))}
                          </div>
                        </div>
                      </div>
                      <Row className="mb-2" hidden>
                        {/* <Col sm="2">
                                <div className="form-group row mb-2 mr-2">
                                  <label className="col-md-5 pr-0 col-form-label">
                                    {props.t("filter_by")}:
                                  </label>

                                  <div className="col-md-7">
                                    <select
                                      className="custom-select"
                                      onChange={(e) =>
                                        onStatusChange(e.target.value)
                                      }
                                      value={filter.fields[1]?.fieldValue}
                                    >
                                      <option value="">
                                        {props.t("status")}
                                      </option>
                                      <option value="active">
                                        {props.t("active")}
                                      </option>
                                      <option value="inactive">
                                        {props.t("inactive")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </Col> */}

                        <Col sm="3">
                          <SearchInput
                            {...toolkitProps.searchProps}
                            triggerSearch={onSearch}
                            placeholder={props.t("Search")}
                            searchText={searchText}
                          />
                        </Col>

                        <Col sm="2">
                          {!!filter.fields[1]?.fieldName ||
                            (!!filter.search && (
                              <div
                                className="mr-4 mb-2"
                                style={{
                                  height: "calc( 1.5em + 0.94rem + 2px)",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFilter((prevState) => ({
                                      ...prevState,
                                      fields: [
                                        {
                                          fieldName: "",
                                          fieldValue: "",
                                        },
                                        {
                                          fieldName: "",
                                          fieldValue: "",
                                        },
                                      ],
                                      search: "",
                                    }));
                                    setSearchText("");
                                  }}
                                  className=""
                                >
                                  {props.t("clear_filters")}
                                </Link>
                              </div>
                            ))}
                        </Col>
                      </Row>

                      {/* {(accesses.canEdit || accesses.canDelete) && (
                              <AnimateHeight
                                duration={500}
                                height={selected.length > 0 ? "auto" : 0}
                              >
                                <Row className="pb-2">
                                  {accesses.canEdit && (
                                    <Col sm="2">
                                      <div className="form-group row">
                                        <label className="col-md-5 pr-0 col-form-label">
                                          {props.t("action")}:
                                        </label>

                                        <div className="col-md-7">
                                          <select
                                            className="custom-select"
                                            onChange={(e) =>
                                              handleCollectionStatusChange(
                                                e.target.value,
                                              )
                                            }
                                          >
                                            <option value="">
                                              {props.t("status")}
                                            </option>
                                            <option value="active">
                                              {props.t("active")}
                                            </option>
                                            <option value="inactive">
                                              {props.t("inactive")}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  {accesses.canDelete && (
                                    <Col sm="2">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          handleCollectionStatusChange(
                                            "archived",
                                          )
                                        }
                                      >
                                        {props.t("delete")}{" "}
                                        {props.t("selected")}
                                      </button>
                                    </Col>
                                  )}

                                  <Col></Col>
                                </Row>
                              </AnimateHeight>
                            )} */}

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive tableBox collectionList">
                            <BootstrapTable
                              responsive
                              noDataIndication={"No data available"}
                              remote
                              bordered={false}
                              striped={false}
                              classes={
                                "table table-centered table-nowrap"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChange}
                              {...paginationTableProps}
                              defaultSorted={[
                                {
                                  dataField: "createdAt",
                                  order: "desc",
                                },
                              ]}
                              sort={{
                                sortCaret: (order) =>
                                  order === "asc" ? (
                                    <span className="caret">
                                      <i className="bx bx-caret-up" />
                                    </span>
                                  ) : order === "desc" ? (
                                    <span className="caret">
                                      <i className="bx bx-caret-down" />
                                    </span>
                                  ) : <span className="caret">
                                    {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                  </span>,
                              }}
                            // selectRow={selectRow({
                            //   selected,
                            //   onSelect,
                            //   onSelectAll,
                            // })}
                            />

                            {loading && (
                              <div className="spinnerBoxNew">
                                <Spinner color="primary" />
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>

          </Col>
        </Row>
      </Container>

    </React.Fragment>
  );
};

Collections.propTypes = {
  collections: PropTypes.array,
  totalCollections: PropTypes.number,
  onGetCollections: PropTypes.func,
  onDeleteCollection: PropTypes.func,
  onPutCollectionsStatus: PropTypes.func,
};

const mapStateToProps = ({ collections }) => ({
  loading: collections.loading,
  collections: collections.collections,
  totalCollections: collections.totalCollections,
  newCollections: collections.newCollections,
  totalNewCollections: collections.totalNewCollections,

});

const mapDispatchToProps = (dispatch) => ({
  onGetCollections: (data, callback) => dispatch(getNewCollections(data, callback)),
  onDeleteCollection: (data, callback) =>
    dispatch(deleteCollection(data, callback)),
  onPutCollectionsStatus: (data, callback) =>
    dispatch(putCollectionsStatus(data, callback)),

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Collections))
);
