import { act } from "react-dom/test-utils";
import {
  COLLECTION_API_FAIL,
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  ADD_COLLECTION,
  ADD_COLLECTION_FAIL,
  ADD_COLLECTION_SUCCESS,
  GET_COLLECTION,
  GET_COLLECTION_FAIL,
  GET_COLLECTION_SUCCESS,
  PUT_COLLECTION,
  PUT_COLLECTION_FAIL,
  PUT_COLLECTION_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_FAIL,
  DELETE_COLLECTION_SUCCESS,
  PUT_COLLECTIONS_STATUS,
  PUT_COLLECTIONS_STATUS_FAIL,
  PUT_COLLECTIONS_STATUS_SUCCESS,
  VERIFY_COLLECTION_REQUEST,
  VERIFY_COLLECTION_REQUEST_SUCCESS,
  VERIFY_COLLECTION_REQUEST_FAIL,
  GET_NEW_COLLECTIONS,
  GET_NEW_COLLECTIONS_SUCCESS,
  GET_NEW_COLLECTIONS_FAIL,
  GET_NEW_COLLECTIONS_BY_ID,
  GET_NEW_COLLECTIONS_SUCCESS_BY_ID,
  GET_NEW_COLLECTIONS_FAIL_BY_ID,
} from "./actionTypes";

const INIT_STATE = {
  billingProducts: [],
  totalBillingProducts: 0,
  error: "",
  loading: false,
  billingProduct: {},
  collections: [],
  totalCollections: 0,
  collection: {},
  date: "",
  newCollections: [],
  newCollection:{},
  totalNewCollections:0
};

const Collections = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Collections */
    case GET_COLLECTIONS:
    case GET_NEW_COLLECTIONS:
    case GET_NEW_COLLECTIONS_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case GET_COLLECTIONS_SUCCESS:
    
      return {
        ...state,
        collections: action.payload.data || [],
        totalCollections: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };
      case GET_NEW_COLLECTIONS_SUCCESS_BY_ID:
        return {
          ...state,
          newCollection: action.payload.data || {},
          // totalCollections: action.payload.totalCount || 0,
          loading: false,
          error: "",
        };

    case GET_NEW_COLLECTIONS_SUCCESS:
      return {
        ...state,
        newCollections: action.payload.data || [],
        totalNewCollections: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case GET_COLLECTIONS_FAIL:
    case GET_NEW_COLLECTIONS_FAIL:
    case GET_NEW_COLLECTIONS_FAIL_BY_ID:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Collections END */

    /* Add Collection */
    case ADD_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case ADD_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Collection END */

    /* Get Collection */
    case GET_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
        collection: {},
      };

    case GET_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        collection: {},
      };

    case GET_COLLECTION_SUCCESS:
      let getDate = new Date(action.payload.date_created_utc);
      getDate = `${getDate.getDate()}-${getDate.getMonth()}-${getDate.getFullYear()}`;
      return {
        ...state,
        error: "",
        loading: false,
        collection: action.payload,
        date: getDate,
      };
    /* Add Collection END */

    /* Update Collection */
    case PUT_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case PUT_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case PUT_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Collection END */

    /* Delete Collection */
    case DELETE_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case DELETE_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Collection END */

    /* Update Collections Status */
    case PUT_COLLECTIONS_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case PUT_COLLECTIONS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case PUT_COLLECTIONS_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Collections Status END */

    case COLLECTION_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case VERIFY_COLLECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case VERIFY_COLLECTION_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case VERIFY_COLLECTION_REQUEST_SUCCESS:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Collections;
