import {
  CUSTOMER_LOADING,
  API_FAIL,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_SUCCESS,
  GET_ALL_PLANS,
  GET_ALL_PLANS_SUCCESS,
  GET_ALL_PLANS_FAIL,
  UPDATE_STORE_STATUS,
  UPDATE_STORE_STATUS_SUCCESS,
  UPDATE_STORE_STATUS_FAIL,
  UPDATE_STORE_PLAN,
  UPDATE_STORE_PLAN_SUCCESS,
  UPDATE_STORE_PLAN_FAIL,
  GET_STORE_TRANSACTIONS,
  GET_STORE_TRANSACTIONS_SUCCESS,
  GET_STORE_TRANSACTIONS_FAIL,
  POST_STORE_REFUND,
  POST_STORE_REFUND_SUCCESS,
  POST_STORE_REFUND_FAIL,
  POST_STORE_CHARGE,
  POST_STORE_CHARGE_SUCCESS,
  POST_STORE_CHARGE_FAIL,
  STORE_USER_LOGIN,
  STORE_USER_LOGIN_SUCCESS,
  STORE_USER_LOGIN_FAIL,
  PUT_STORE_PLAN_DISCOUNT,
  PUT_STORE_PLAN_DISCOUNT_SUCCESS,
  PUT_STORE_PLAN_DISCOUNT_FAIL,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
  PUT_STORE_KEYS,
  PUT_STORE_KEYS_SUCCESS,
  PUT_STORE_KEYS_FAIL,
  POST_CHECK_MAIL,
  POST_CHECK_MAIL_SUCCESS,
  POST_CHECK_MAIL_FAIL,
  POST_HIDE_THINGS,
  POST_HIDE_THINGS_SUCCESS,
  POST_HIDE_THINGS_FAIL,
} from "./actionTypes";

import * as CONST from "./actionTypes";

export const apiFail = (error) => ({
  type: API_FAIL,
  payload: error,
});
export const loading = () => ({
  type: CUSTOMER_LOADING,
});

/* Get Customers */
export const getCustomers = (data) => ({
  type: GET_CUSTOMERS,
  payload: data,
});

export const getCustomersSuccess = (customers) => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const getCustomersFail = (error) => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
});
/* Get Customers END */

/* Get Customer */
export const getCustomer = (id) => ({
  type: GET_CUSTOMER,
  payload: id,
});

export const getCustomerFail = (error) => ({
  type: GET_CUSTOMER_FAIL,
  payload: error,
});

export const getCustomerSuccess = (customer) => ({
  type: GET_CUSTOMER_SUCCESS,
  payload: customer,
});
/* Get Customer END */

/** GET Plan Start */
export const getPlans = (data) => ({
  type: GET_ALL_PLANS,
  payload: data,
});

export const getPlansFail = (error) => ({
  type: GET_ALL_PLANS_FAIL,
  payload: error,
});

export const getPlansSuccess = (data) => ({
  type: GET_ALL_PLANS_SUCCESS,
  payload: data,
});
/** GET Plan End */

/** Update Store Status Start*/

export const updateStoreStatus = (data, callback) => ({
  type: UPDATE_STORE_STATUS,
  payload: { data, callback },
});

export const updateStoreStatusSuccess = () => ({
  type: UPDATE_STORE_STATUS_SUCCESS,
});

export const updateStoreStatusFail = (error) => ({
  type: UPDATE_STORE_STATUS_FAIL,
  payload: error,
});
/** Update Store Status End*/

/** Update Store Plan Start*/
export const updateStorePlan = (data, callback) => ({
  type: UPDATE_STORE_PLAN,
  payload: { data, callback },
});

export const updateStorePlanSuccess = () => ({
  type: UPDATE_STORE_PLAN_SUCCESS,
});

export const updateStorePlanFail = (error) => ({
  type: UPDATE_STORE_PLAN_FAIL,
  payload: error,
});
/** Update Store Plan End*/

/** Get Store Transaction Start*/
export const storeTransactions = (data, callback) => ({
  type: GET_STORE_TRANSACTIONS,
  payload: { data, callback },
});

export const storeTransactionsSuccess = (data) => ({
  type: GET_STORE_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const storeTransactionsFail = (error) => ({
  type: GET_STORE_TRANSACTIONS_FAIL,
  payload: error,
});

/** Get Store Transaction End*/

/** Store Charge Start */
export const storeCharge = (data, callback) => ({
  type: POST_STORE_CHARGE,
  payload: { data, callback },
});

export const storeChargeSuccess = () => ({
  type: POST_STORE_CHARGE_SUCCESS,
});

export const storeChargeFail = (error) => ({
  type: POST_STORE_CHARGE_FAIL,
  payload: error,
});

/** Store Charges End */

/** Refund Store Charges Start */
export const refundCharge = (data, callback) => ({
  type: POST_STORE_REFUND,
  payload: { data, callback },
});

export const refundChargeSuccess = () => ({
  type: POST_STORE_REFUND_SUCCESS,
});

export const refundChargeFail = (error) => ({
  type: POST_STORE_REFUND_FAIL,
  payload: error,
});
/** Refund Store Charges End */

/**Store User Login Start */
export const storeUserLogin = (data, callback) => ({
  type: STORE_USER_LOGIN,
  payload: { data, callback },
});

export const storeUserLoginSuccess = () => ({
  type: STORE_USER_LOGIN_SUCCESS,
});
/** Store User Login End */

/* Give Discount on store plan */
export const putStorePlanDiscount = (data) => ({
  type: PUT_STORE_PLAN_DISCOUNT,
  payload: data,
});

export const putStorePlanDiscountSuccess = () => ({
  type: PUT_STORE_PLAN_DISCOUNT_SUCCESS,
});

export const putStorePlanDiscountFail = (error) => ({
  type: PUT_STORE_PLAN_DISCOUNT_FAIL,
  payload: error,
});
/* Give Discouhnt on store plan END */
/* Delete Store */
export const deleteStore = (data, callback) => ({
  type: DELETE_STORE,
  payload: { data, callback },
});

export const deleteStoreSuccess = () => ({
  type: DELETE_STORE_SUCCESS,
});

export const deleteStoreFail = (error) => ({
  type: DELETE_STORE_FAIL,
  payload: error,
});
/* Delete Store END */

/* PUT STORE KEYS */
export const putStoreKeys = (data) => ({
  type: PUT_STORE_KEYS,
  payload: data,
});
export const putStoreKeysSuccess = (data) => ({
  type: PUT_STORE_KEYS_SUCCESS,
  payload: data,
});
export const putStoreKeysFail = (error) => ({
  type: PUT_STORE_KEYS_FAIL,
  payload: error,
});
/* PUT STORE KEYS END */

/* Store Check mail */
export const postCheckMail = (data, callback) => ({
  type: POST_CHECK_MAIL,
  payload: { data, callback },
});

export const postCheckMailSuccess = () => ({
  type: POST_CHECK_MAIL_SUCCESS,
});

export const postCheckMailFail = (error) => ({
  type: POST_CHECK_MAIL_FAIL,
  payload: error,
});

export const postHideThings = (data, callback) => ({
  type: POST_HIDE_THINGS,
  payload: { data, callback },
});

export const postHideThingsSuccess = () => ({
  type: POST_HIDE_THINGS_SUCCESS,
});

export const postHideThingsFail = (error) => ({
  type: POST_HIDE_THINGS_FAIL,
  payload: error,
});
/* Store check mail END */

/* Get Customers Verification Request */
export const getVerificationRequests = (data) => ({
  type: CONST.GET_REQESTS,
  payload: data,
});

export const getVerificationRequestsSuccess = (customers) => ({
  type: CONST.GET_REQESTS_SUCCESS,
  payload: customers,
});

export const getVerificationRequestsFail = (error) => ({
  type: CONST.GET_REQESTS_FAIL,
  payload: error,
});
export const getVerificationRequest = (data) => ({
  type: CONST.GET_REQEST,
  payload: data,
});

export const getVerificationRequestSuccess = (customers) => ({
  type: CONST.GET_REQEST_SUCCESS,
  payload: customers,
});

export const getVerificationRequestFail = (error) => ({
  type: CONST.GET_REQEST_FAIL,
  payload: error,
});
/* Get Customers Verification Request */
