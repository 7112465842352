
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS_FAIL = "GET_ALL_TRANSACTIONS_FAIL";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";


export const GET_MANAGE_EARNING = "GET_MANAGE_EARNING";
export const GET_MANAGE_EARNING_FAIL = "GET_MANAGE_EARNING_FAIL";
export const GET_MANAGE_EARNING_SUCCESS = "GET_MANAGE_EARNING_SUCCESS";

export const GET_CSV = "GET_CSV";
export const GET_CSV_FAIL = "GET_CSV_FAIL";
export const GET_CSV_SUCCESS = "GET_CSV_SUCCESS";


export const GET_ADMIN_EARNING = "GET_ADMIN_EARNING";
export const GET_ADMIN_EARNING_FAIL = "GET_ADMIN_EARNING_FAIL";
export const GET_ADMIN_EARNING_SUCCESS = "GET_ADMIN_EARNING_SUCCESS";