import { APPROVE_OR_REJECT, APPROVE_OR_REJECT_FAIL, APPROVE_OR_REJECT_SUCCESS, GET_ARTS, GET_ARTS_FAIL, GET_ARTS_SUCCESS, GET_ART_BY_ID, GET_ART_BY_ID_FAIL, GET_ART_BY_ID_SUCCESS } from "./actionTypes"




export const apiFail = (error) => ({
    type: GET_ARTS_FAIL,
    payload: error,
});


export const getArts = (data) => ({
    type: GET_ARTS,
    payload: data,
});

export const getArtsSuccess = (items) => ({
    type: GET_ARTS_SUCCESS,
    payload: items,
});

export const getArtsFail = (error) => ({
    type: GET_ARTS_FAIL,
    payload: error,
});


export const approveOrRejectArt = (data, callback) => ({
    type: APPROVE_OR_REJECT,
    payload: { data, callback },
});

export const approveOrRejectArtSuccess = (items) => ({
    type: APPROVE_OR_REJECT_SUCCESS,
    payload: items,
});
export const approveOrRejectFail = (error) => ({
    type: APPROVE_OR_REJECT_FAIL,
    payload: error,
});


export const getArtById = (userId, callback) => ({
    type: GET_ART_BY_ID,
    payload: { userId, callback },
});

export const getArtByIdSuccess = (item) => ({
    type: GET_ART_BY_ID_SUCCESS,
    payload: item,
});

export const getArtByIdFail = (error) => ({
    type: GET_ART_BY_ID_FAIL,
    payload: error,
});