import {
  CONTACTUS_API_FAIL,
  GET_CONTACTUS,
  GET_CONTACTUS_FAIL,
  GET_CONTACTUS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  contactus: [],
  totalContactUs: "",
  error: "",
  loading: false,
}

const ContactUs = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get CONTACTUS */
    case GET_CONTACTUS:
      return {
        ...state,
        loading: true,
      }

    case GET_CONTACTUS_SUCCESS:
      return {
        ...state,
        contactus: action.payload.data || [],
        totalContactUs: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_CONTACTUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get ContactUs END */
    case CONTACTUS_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default ContactUs
