export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const VERIFY_PROFILE_REQUEST = "VERIFY_PROFILE_REQUEST";
export const VERIFY_PROFILE_REQUEST_SUCCESS = "VERIFY_PROFILE_REQUEST_SUCCESS";
export const VERIFY_PROFILE_REQUEST_FAIL = "VERIFY_PROFILE_REQUEST_FAIL";
