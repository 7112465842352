import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "name",
    text: t("Name"),
  },
  {
    dataField: "reportType",
    text: t("Report Type"),
  },
  // {
  //   dataField: "status",
  //   text: t("Status"),
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         "text-capitalize font-size-13 badge-soft-" +
  //         (row.status == "active"
  //           ? "success"
  //           : row.status == "inactive"
  //           ? "warning"
  //           : "danger")
  //       }
  //       color={row.badgeClass}
  //     >
  //       {t(row.status)}
  //     </Badge>
  //   ),
  // },
  {
    text: t("Created At"),
    dataField: "create_date",
    sort: true,
    formatter: (_, row) => moment(row.create_date).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link to={`/report/view/${row._id}`} className="mr-3 text-secondary">
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default ListColumns;
