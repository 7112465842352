import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Button, Modal, ModalBody, Spinner } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "store/actions";
import CustomersList from ".";
import moment from "moment-timezone";

const ViewUser = props => {
  const dispatch = useDispatch()
  const { customer } = useSelector((s) => s?.customers)
  const { isOpen, toggle, onConfirm, userId } = props
  const [userRole, setuserRole] = useState('MUSEUM')
  console.log(isOpen);
  console.log(userId);

  const [viewDialog, setViewDialog] = useState(false)

  useEffect(() => {
    if (userId && isOpen === true) {
      dispatch(getCustomer(userId))
    }
    console.log(viewuser);
  }, [userId, isOpen, viewuser])
  const closeDialog = () => {
    console.log('Deepak');
    setViewDialog(false)
  }
  return (
    // <Modal

    //   isOpen={isOpen}
    //   autoFocus={true}
    //   centered={true}
    //   toggle={toggle} className="dialogInner"
    // >
    //   <div className="modal-content ">
    //     <ModalBody>
    //       <div className="infoimg">
    //        Deepak
    //       </div>

    //       <h4>Are you sure?</h4>

    //       <div className="delete-btns">
    //         <Button type="button" outline color="secondary" onClick={toggle}>
    //           No, cancel!
    //         </Button>

    //         <Button type="button" color="primary" onClick={onConfirm}>
    //           Yes, delete it!
    //         </Button>
    //       </div>
    //     </ModalBody>
    //   </div>
    // </Modal>
    <>
      {props?.particularCustomerLoading ? (
        <div className="spinnerBoxNew">
          <Spinner color="primary" />
        </div>) :
        (
          <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>
            <div className='dialogInner'>
              <div className='dialogHead'>
                <h1>View {customer.role === 'USER' ? 'User' : customer.role === 'ARTIST' ? 'Artist' : 'Museum'}</h1>
                <button className='closebtn' onClick={toggle}>
                  <img src={'/images/svg/icons/closeIcon.svg'} alt="close" width={40} height={40} />
                </button>
              </div>
              <div className={'dialogbody ' + (viewuser.viewProfile)}>
                <div className='row'>
                  <div className='col-xl-5'>
                    <div className={viewuser.profilephoto}>
                      <img src={customer?.profileImage?.link} alt="Image" width={100} height={100} />
                    </div>
                  </div>
                  <div className='col-xl-7'>
                    <div className='row'>
                      {(customer.role === 'USER' || customer.role === 'ARTIST' || customer?.role === 'ADMIN') &&
                        <>
                          <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                            <div className='form-group'>
                              <label >First Name</label>
                              <input type="text" className='form-control' readOnly value={customer?.name} name="" id="" />
                            </div>
                          </div>
                          <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                            <div className='form-group'>
                              <label >Last Name</label>
                              <input type="text" className='form-control' readOnly value={customer?.lastname} name="" id="" />
                            </div>
                          </div>
                        </>
                      }
                      {(customer?.role === 'MUSEUM' || customer?.role === 'ADMIN') &&
                        <>
                          <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                            <div className='form-group'>
                              <label >Museum Name</label>
                              <input type="text" className='form-control' readOnly value={customer?.museumName} name="" id="" />
                            </div>
                          </div>
                          <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                            <div className='form-group'>
                              <label >Contact Person Name</label>
                              <input type="text" className='form-control' readOnly value={customer?.name} name="" id="" />
                            </div>
                          </div>
                          <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                            <div className='form-group'>
                              <label >Contact Person Title</label>
                              <input type="text" className='form-control' readOnly value={customer?.jobTitle} name="" id="" />
                            </div>
                          </div>
                        </>
                      }
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Phone Number</label>
                          <input type="text" className='form-control' readOnly value={customer?.mobileNumber} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Email</label>
                          <input type="text" className='form-control' readOnly value={customer?.email} name="" id="" />
                        </div>
                      </div>
                      {(customer?.role === 'USER' || customer.role === 'ARTIST') &&
                        <>
                          <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                            <div className='form-group'>
                              <label >Gender</label>
                              <input type="text" className='form-control' readOnly value={customer?.gender} name="" id="" />
                            </div>
                          </div>
                          <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                            <div className='form-group'>
                              <label >Age</label>
                              <input type="text" className='form-control' readOnly value={customer?.age} name="" id="" />
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>

                </div>
                <div className='row'>
                  <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                    <div className='form-group'>
                      <label >Address</label>
                      <input type="text" className='form-control' readOnly value={customer?.address ? customer?.address : "NA"} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                    <div className='form-group'>
                      <label >Country</label>
                      <input type="text" className='form-control' readOnly value={customer?.country ? customer?.country : "NA"} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                    <div className='form-group'>
                      <label >State</label>
                      <input type="text" className='form-control' readOnly value={customer?.state ? customer?.state : " "} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                    <div className='form-group'>
                      <label >City</label>
                      <input type="text" className='form-control' readOnly value={customer?.city ? customer?.city : "NA"} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                    <div className='form-group'>
                      <label >Zip</label>
                      <input type="text" className='form-control' readOnly value={customer?.zipcode ? customer?.zipcode : "NA"} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                    <div className='form-group'>
                      <label >User Role</label>
                      <input type="text" className='form-control' readOnly value={customer?.role ? customer?.role : "NA"} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                    <div className='form-group'>
                      <label >Register At</label>
                      <input type="text" className='form-control' readOnly value={customer.created_at ? moment(customer?.created_at).utcOffset(330).format("DD MMM YYYY hh:mm A") : "---"} name="" id="" />
                    </div>

                  </div>
                  {customer?.role === 'MUSEUM' &&
                    <>
                      <div className='col-xl-12 0col-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label >Document Number</label>
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <input type="text" className='form-control' readOnly value={customer?.docs_number ? customer?.docs_number : "NA"} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className={'form-group position-relative ' + viewuser.documentdetails}>
                          <input type="text" className='form-control' readOnly value={customer?.docs_upload ? customer?.docs_upload.split(".")[0].substring(0, 10) : "NA"} name="" id="" />
                          {/* <span  >View Document</span> */}
                          <a className='position-absolute end-0 top-0 bottom-0' href={customer?.docs_link?.link} target={"_blank"}>View Document</a>
                          {/* <a className='position-absolute end-0 top-0 bottom-0'  href="path_to_file" download={customer?.d}>Download</a> */}
                        </div>
                      </div>
                      <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                        <div className='form-group'>
                          <label >Website</label>
                          <input type="text" className='form-control' readOnly value={customer?.website !== "" ? customer?.website : "NA"} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                        <div className='form-group'>
                          <label >Description</label>
                          <textarea className='form-control' readOnly value={customer?.description ? customer?.description : "NA"} name="" id="" ></textarea>
                        </div>
                      </div>
                    </>
                  }
                  {customer?.role === 'ARTIST' &&
                    <>
                      <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                        <div className='form-group'>
                          <label >Company Name</label>
                          <input type="text" className='form-control' readOnly value={customer?.companyName ? customer?.companyName : "NA"} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                        <div className='form-group'>
                          <label >Website</label>
                          <input type="text" className='form-control' readOnly value={customer?.website ? customer?.website : "NA"} name="" id="" />
                        </div>
                      </div>

                    </>
                  }
                  {(customer?.facebookUrl || customer?.instagramUrl || customer?.telegramUrl || customer?.youtubeUrl || customer?.discordUrl) &&
                    <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                      <div className='form-group'>
                        <label >Social Media</label>
                        <ul className="socialMediaArtist mt-2">
                          {customer?.facebookUrl &&
                            < li >
                              <a href={customer?.facebookUrl} target="_blank" rel="noreferrer"><div className="facebook"></div></a>
                            </li>
                          }
                          {customer?.instagramUrl &&
                            <li>
                              <a href={customer?.instagramUrl} target="_blank" rel="noreferrer"><div className="instagram"></div></a>
                            </li>
                          }
                          {customer?.telegramUrl &&
                            < li >
                              <a href={customer?.telegramUrl} target="_blank" rel="noreferrer"><div className="telegram"></div></a>
                            </li>
                          }
                          {customer?.youtubeUrl &&
                            <li>
                              <a href={customer?.youtubeUrl} target="_blank" rel="noreferrer"><div className="youtube"></div></a>
                            </li>
                          }
                          {customer?.discordUrl &&
                            <li>
                              <a href={customer?.discordUrl} target="_blank" rel="noreferrer"><div className="discord"></div></a>
                            </li>
                          }

                        </ul>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  )

}
ViewUser.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any
}
const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
  particularCustomerLoading: customers.particularCustomerLoading
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getCustomer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);