import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody } from "reactstrap";
import deleteImg from "assets/images/svg/deleteImge.svg"

//i18n
import { withTranslation } from "react-i18next";

const ConfirmModal = (props) => {
  const { isOpen, toggle, onConfirm } = props;

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
    >
      <div className="modal-content text-center py-4 remove-confirm">
        <ModalBody>
          <div className="infoimg">
            <img src={deleteImg}></img>
          </div>

          <h4 className="my-4">{props.t("Are you sure you want to delete this Blog")}</h4>

          <div className="delete-btns">
            <button type="button"  color="secondary" className="btn verifybtn px-5 text-white me-3 py-2"  onClick={toggle}>
              {props.t("Cancel")}
            </button>

            <button type="button" className="btn btn-danger px-5 py-2"  onClick={onConfirm}>
              {props.t("Delete")}
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default withTranslation()(ConfirmModal);
