import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select, { components } from "react-select";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";
import { getSettings, putSettings } from "store/actions";
import viewuser from "../../../assets/css/viewuser.module.css";

import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Button,
  FormText,
  Label,
  Input,
  UncontrolledTooltip,
  Container,
  Card,
  CardBody,
  Spinner,
  Alert,
} from "reactstrap";

// Actions
import { uploadFile } from "store/actions";
import Breadcrumbs from "components/Common/Breadcrumb";

const MailChimpSettings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;

  // mailchimp
  const [fields, setFields] = useState({
    api_key: "",
    audiance_id: "",
  });
  const [submitInfo, setSubmitInfo] = useState(null);

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings && props.settings.mailchimp) {
      setFields((prevState) => ({
        ...prevState,
        api_key: props.settings.mailchimp.api_key,
        audiance_id: props.settings.mailchimp.audiance_id,
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  function submit() {
    try {
      onPutSettings({
        mailchimp: {
          api_key: fields.api_key,
          audiance_id: fields.audiance_id,
        },
        _id: props.settings._id,
      });
      setSubmitInfo("Information updated successfully!!");
    } catch {
      setSubmitInfo("Some Error Occurred!!");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="MailChimp Settings" />
          <Row>
         
                <Col md={12}>
                <div className={"addbox position-relative mt-4 " + viewuser.viewProfile}>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <div className="mt-2">
                          {submitInfo && <Alert>{submitInfo}</Alert>}
                        </div>
                        <FormGroup>
                          <Label> API Key </Label>
                          <Input
                            type="text"
                            value={fields.api_key}
                            onChange={(e) => {
                              handleChange("api_key")(e);
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Audiance id</Label>
                          <Input
                            type="text"
                            value={fields.audiance_id}
                            onChange={(e) => {
                              handleChange("audiance_id")(e);
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                  
                  <Col>
                  <button
                      className="btn verifybtn px-5 text-white"
                      disabled={loading}
                      onClick={submit}
                    >
                      Save Changes
                    </button>
                  </Col>
                  {loading && (
                  <div className="spinnerBoxNew">
                    <Spinner color="primary" />
                  </div>
                )}
                </Row>
                </div>
                </Col>
             
                
          
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

MailChimpSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MailChimpSettings)
);
