import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody } from "reactstrap"
import successful_feedback from "assets/images/svg/successful_feedback.svg"
import feedback from "assets/images/svg/feedback.svg"
import { connect } from "react-redux";
import toastr from "toastr"
import { updatePrinterWithdrawlList } from "store/actions"
const ConfirmModal = props => {
  const { isOpen, toggle, onConfirm, onStatus, onUpdatewithdrawlStatus, toggleViewUser } = props
  const [rejectReason, setRejectReason] = useState('')
  const [loading, setLoading]= useState(false)
  const handleStatusChange = () => {
    setLoading(true)
    const callback = (res) => {
      setLoading(false)
      if (res.status === 'success') {
        toastr.success("Status Updated Successfully")
        toggle()
        toggleViewUser()
      } else {
        toastr.error(res.message);
      }
    }
    let body = {}
    body.status = onStatus.status==="Approve" ? "In Progress" : onStatus.status
    body.id = onStatus.id
    if (onStatus.status === "Reject" || onStatus.status ==="Failed") {
      if (rejectReason === '') return toastr.error("Reason is Required")
      body.reason = rejectReason
    }
    
         onUpdatewithdrawlStatus(body, callback)
   
  }

  return (
    <>
      {(props?.onStatus?.status == 'Approve' || props?.onStatus?.status == 'Completed' ) ? (
        <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>
          <div className='dialogRejectView'>
            <div className='dialogbody acceptbody'>
              <div className=""> <img src={successful_feedback} alt="close" width={340} height={280} /> </div>
              <div className="mainPara">
                <h4>Payment Confirmation</h4>
                <p>Please confirm if you want to proceed with approving <br />this print request.</p>
              </div>

              <div className="confirmbutton">
                <div className="confirmbutton">
                  <button className="btn success-button text-white me-1" onClick={handleStatusChange}>
                    Confirm
                  </button>
                  <button className="btn btn-outline-danger " onClick={toggle}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) :
        (
          <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>
            <div className='dialogRejectView'>
              <div className='dialogbody'>
                <div className=""> <img src={feedback} alt="close" width={340} height={280} /> </div>
                <div className="mainPara">
                  <h4>Reason for {props?.onStatus?.status==="Reject"? "Rejection" : "Fail"}</h4>
                  <textarea placeholder="Describe reason..." onChange={(e) => setRejectReason(e.target.value)} value={rejectReason} />
                </div>

                <div className="confirmbutton">
                  <div className="confirmbutton">
                    <button className="btn success-button text-white me-1" onClick={handleStatusChange}>
                      Submit
                    </button>
                    <button className="btn btn-outline-danger " onClick={toggle}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  )
}
ConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  withdrawl: PropTypes.object,
  onUpdatewithdrawlStatus: PropTypes.func,
}
const mapStateToProps = ({ Partners }) => ({
  error: Partners.error,
  loading: Partners.loading,
  withdrawl: Partners.viewWithdrawlRequest
});
const mapDispatchToProps = (dispatch) => ({
  onUpdatewithdrawlStatus: (data, callback) => dispatch(updatePrinterWithdrawlList(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);