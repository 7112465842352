import React from "react"
import { Link } from "react-router-dom"
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap"
import moment from "moment-timezone"
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants"

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
})

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "nft_id.name",
    text: t("Item"),
    sort: true,
    // formatter: (_, row) => (
    //   <Link to={`/items/view/${row.item?._id}`}>{row.item?.name}</Link>
    // ),
  },
  {
    dataField: "price",
    text: t("Price (MATIC)"),
    sort: true,
    formatter: (_, row) =>
      Number(row.price).toFixed(5)
    // +
    // " " +
    // BLOCKCHAIN_CURRENCY_CODE[row.item?.blockchain],
  },
  {
    dataField: "no_of_copies",
    text: t("NFT Edition "),
    sort: true,
    formatter: (_, row) =>
      Number(row.no_of_copies)
    // +
    // " " +
    // BLOCKCHAIN_CURRENCY_CODE[row.item?.blockchain],
  },
  {
    dataField: "",
    text: t("Total Amount (MATIC)"),
    sort: true,
    formatter: (_, row) =>
     (Number(row.price)*Number(row.no_of_copies)).toFixed(3)
    // +
    // " " +
    // BLOCKCHAIN_CURRENCY_CODE[row.item?.blockchain],
  },
  {
    dataField: "sale_type",
    text: t("Event"),
    sort: true,
    formatter: (_, row) =>row?.sale_type==="buy" ? "Bought" :  row?.sale_type?.charAt(0).toUpperCase() + row?.sale_type?.slice(1)
  },
  {
    dataField: "from",
    text: t("From"),
    sort: true,
    formatter: (_, item) => (
      // <Link to={`/users/profile/${row.from?._id}`}>{row.from?.name}</Link>
      item?.from?.substring(0, 5) + "....." + item?.from?.substring(item?.from?.length - 4, item?.from?.length)
    ),
  },
  {
    dataField: "to",
    text: t("To"),
    sort: true,
    formatter: (_, item) => (
      item?.to?.substring(0, 5) + "....." + item?.to?.substring(item?.to?.length - 4, item?.to?.length)
    ),
  },
  {
    dataField: "trans_hash",
    sort: true,
    text: t("Transaction"),
    formatter: (_, item) => (
      item?.trans_hash?.substring(0, 5) + "....." + item?.trans_hash?.substring(item?.trans_hash?.length - 4, item?.trans_hash?.length)
    ),
  },
  {
    text: t("Date"),
    dataField: "created_date",
    sort: true,
    formatter: (_, row) => moment.unix(row?.created_date).format("DD MMM YYYY hh:mm A"),
  },
]

export default ListColumns
