import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import toastr from "toastr";
// Customer Redux States
import { GET_CUSTOMERS, GET_CUSTOMER } from "./actionTypes";
import {
  getCustomersFail,
  getCustomersSuccess,
  getCustomerFail,
  getCustomerSuccess,
} from "./actions";

import {
  getCustomers,
  getCustomer,
  getVerificationRequests,
  getVerificationRequest,
} from "helpers/backend_helper";

import * as CONST from "./actionTypes";
import * as actType from "./actions";

toastr.options = {
  newestOnTop: true,
  progressBar: true,
};

function* fetchCustomers({ payload }) {
  try {
    const response = yield call(getCustomers, payload);

    if (response.status == "failure") {
      return yield put(getCustomersFail(response.message));
    }

    yield put(getCustomersSuccess(response));
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

function* fetchCustomer({ payload }) {
  try {
    const response = yield call(getCustomer, payload);
   console.log("get uset resposen", response)
    if (response.status == "failure") {
      return yield put(getCustomerFail(response.message));
    }


    
    yield put(getCustomerSuccess(response.data));
  } catch (error) {
    yield put(getCustomerFail(error));
  }
}

function* fetchRequests({ payload }) {
  try {
    const response = yield call(getVerificationRequests, payload);
    if (response.status == "failure") {
      return yield put(actType.getVerificationRequestsFail(response.message));
    }
    yield put(actType?.getVerificationRequestsSuccess(response));
  } catch (error) {
    yield put(actType.getVerificationRequestsFail(error));
  }
}

function* fetchRequest({ payload }) {
  try {
    const response = yield call(getVerificationRequest, payload);

    if (response.status == "failure") {
      return yield put(actType.getVerificationRequestFail(response.message));
    }

    yield put(actType.getVerificationRequestSuccess(response.data));
  } catch (error) {
    yield put(actType.getVerificationRequestFail(error));
  }
}

function* customersSaga() {
  yield takeLatest(GET_CUSTOMERS, fetchCustomers);
  yield takeLatest(GET_CUSTOMER, fetchCustomer);
  yield takeLatest(CONST.GET_REQESTS, fetchRequests);
  yield takeLatest(CONST.GET_REQEST, fetchRequest);
}

export default customersSaga;
