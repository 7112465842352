import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody } from "reactstrap"
import Astronaut from "assets/images/svg/Astronaut.svg"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";
import { deletePrinter } from "store/actions";
import toastr from "toastr";
const DeletePrintPartner = props => {
  const { isOpen, toggle, onConfirm, userId, onDeletePartners } = props
  const handleDeleteButton = (userId)=>{
    if(userId){
      const callback = (res)=>{
        if(res.status==="failure"){
         return toastr.error(res.message);
        }else{
          toggle()
          toastr.success("Print Shop Deleted Successfully")
        }

      }
      onDeletePartners({_id:userId}, callback)
    }
  }
  return (
    <>
      <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>
        <div className='dialogDeleteInner'>
          <div className='dialogbody'>
            <div className="deleteimg"> <img src={Astronaut} alt="close" width={170} /> </div>
            <h4>Delete Print Shop</h4>
            <p>Are you sure you want to delete this Print Shop?</p>
            <div className="deletebtn">
              <Button type="button" color="danger" onClick={(e)=>handleDeleteButton(userId)} >Delete </Button>
              <Button type="button" outline color="secondary" onClick={toggle}> Cancel </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
DeletePrintPartner.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  userId: PropTypes.any,
}
const mapStateToProps = ({ Partners }) => ({
  error: Partners.error,
  loading: Partners.loading,
  partner: Partners.partner,
});
const mapDispatchToProps = (dispatch) => {
  return {
    onDeletePartners: (data, callback) => {dispatch(deletePrinter(data, callback));}
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeletePrintPartner)));
