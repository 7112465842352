import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Button, Modal, ModalBody, Spinner } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { useDispatch, useSelector } from "react-redux";
import { approveOrRejectArt, getArtById, getCustomer, otherNotificationRequest, uploadFile } from "store/actions";
import CustomersList from ".";
import moment from "moment-timezone";
import toastr from "toastr";

const ViewUser = props => {
  const { isOpen, toggle, onGetArt, userId, artByid, user, onUploadFile } = props
  const [fileName, setFileName] = useState("")
  const [viewDialog, setViewDialog] = useState(false)
  const [userArtData, setUserArtData] = useState({})
  const [artLoading, setArtLoading] = useState(false)
  const [image, setimage] = useState()
  const [imageURL, setImageURL] = useState();
  const [note, setNote] = useState()
  const dispatch = useDispatch()
  const [desc, setDesc] = useState('')
  const [notificationState, setNotificationState] = useState({
    sender: "",
    receiver: "",
    type: "artApproval",
  });
  useEffect(() => {
    const callback = (res) => {
      if (res.status === "success") {
        setUserArtData(res.data)
      }
      setArtLoading(false)
    }
    if (userId && isOpen === true) {
      setArtLoading(true)
      onGetArt(userId, callback)
    }
  }, [userId])
  useEffect(() => {
    setNotificationState((prevState) => ({
      ...prevState,
      sender: user?.email,
      receiver: userArtData?.user_id,
    }));
  }, [userArtData])
  useEffect(() => {
    console.log("artByid", artByid)
    setDesc(artByid?.artInfos?.description)
  }, [artByid])

  function deleteFile() {
    setImageURL();
    setimage([])
  }

  useEffect(() => {
    setUserArtData(artByid)
    setFileName(artByid?.artInfos ? artByid?.artInfos?.title : artByid?.image?.file_name);
  }, [artByid])
  function verify(status) {
    setArtLoading(true)
    let content2 = `Your ${fileName} has been ${status}`;
    let obj = {}
    obj.receiver = artByid?.user_id?._id
    obj.type = "artApproval"
    obj.content = content2
    obj.sender=process.env.REACT_APP_ADMIN_ID
    let data = {};
    data.artname = artByid?.artInfos ? artByid?.artInfos?.title : artByid?.image?.file_name;
    data.email = artByid?.user_id?.email;
    data.name = artByid.user_id?.name + " " + artByid.user_id?.lastname;
    data.adminStatus = status;
    data.art_id = userId;
    const callback = (response) => {
      setArtLoading(false)
      if (response.status === "success") {
        setimage()
        setImageURL()
        setNote('')
        toggle()
        if (response.data.adminStatus === "Approved") {
          toastr.success("Art has been approved successfully.");
        } else {
          toastr.success("Art has been rejected successfully.");
        }
        const otherNotificationRequestcallback = (reponse) => {
        };
        if (artByid?.user_id?._id) {
          dispatch(otherNotificationRequest(obj, otherNotificationRequestcallback));
        }
        if (status === "Approved") {
        }
        setViewDialog(false);

      } else {
        toastr.success("Something went wrong");
      }
    };

    if (image?.length > 0) {
      const uploadFileSuccess = (name) => (response) => {
        console.log("resewrwer", response)
        if (response?.data?.link) {
          data.imageLink = response?.data?.link
        }
        if (note) {
          data.note = note
        }
        dispatch(approveOrRejectArt(data, callback));
        // const { _id: image } = response.data;
        // setFields((prevState) => ({ ...prevState, [name]: image }));
      };
      onUploadFile({ image: image[0] }, uploadFileSuccess("name"));
    } else {
      if (note) {
        data.note = note
      }
      dispatch(approveOrRejectArt(data, callback));
    }

  }
  // const onChangeHandlerImage = async (e) => {
  //   console.log("e", e.target.files)
  // }

  const onChangeHandlerImage = async (e) => {
    console.log("images", e.target.files);
    var images = e.target.files
    if (images.length < 1) return
    for (let i = 0; i < images.length; i++) {
      const ext = images[i].name.split(".").pop().toLowerCase();
      if (ext !== "png" && ext !== "jpg" && ext !== "jpeg") {
        toastr.error("Invalid file format. Please upload a JPG, JPEG & PNG image.");
        return;
      }
      // for (let i = 0; i < images.length; i++) {
      //   if (images[i] && images[i].size >= 50 * 1e7) {
      //     toastr.error("File size exceeds the limit. Please choose a smaller image.");
      //     return false;
      //   }
      // }

      // if (ext === "heic") {

      //   setIsHeic(true);
      //   const blobfile = e.target.files[0];
      //   const blobURL = URL.createObjectURL(blobfile);
      //   const name = e.target.files[0].name.replace(/\s/g, "").split(".")[0];

      //   const resultBlob = await heic2any({
      //     blob: await (await fetch(blobURL)).blob(),
      //     toType: 'image/jpg',
      //     quality: 1,
      //   });
      //   const converted_file = new File([resultBlob], name + '.jpg', {
      //     type: 'image/jpeg',
      //     lastModified: new Date().getTime(),
      //   });

      //   const reader = new FileReader();
      //   reader.readAsDataURL(converted_file);

      //   reader.onload = (e) => {
      //     setImageURL(e.target.result);
      //     setimage(converted_file);
      //   };


      // }
      // else if (ext === "tiff") {
      //   const file = e.target.files[0];
      //   const reader = new FileReader();
      //   reader.onload = () => {
      //     const tiff = new Tiff({ buffer: reader.result });
      //     console.log("tiff.buffer", reader.result);
      //     const canvas = tiff.toCanvas();
      //     const ctx = canvas.getContext('2d');
      //     let firstname = e.target.files[0].name.replace(/\s/g, "")
      //     let removed_tiff = firstname.split(".")[0]
      //     let name = removed_tiff
      //     const newCanvas = document.createElement('canvas');
      //     const newCtx = newCanvas.getContext('2d');
      //     newCanvas.width = canvas.width;
      //     newCanvas.height = canvas.height;
      //     newCtx.drawImage(canvas, 0, 0);
      //     const jpgData = newCanvas.toDataURL('image/jpeg');
      //     console.log("jpgData", jpgData);
      //     setImageURL(jpgData);
      //     canvas.toBlob((blob) => {
      //       console.log("blob", blob);
      //       const downloadLink = document.createElement('a');
      //       downloadLink.href = URL.createObjectURL(blob);
      //       downloadLink.download = 'converted.jpg';
      //       let converted_file = new File([blob], name + '.jpeg', {
      //         type: 'image/jpeg',
      //         lastModified: new Date().getTime(),
      //       });
      //       // downloadLink.click();
      //       console.log("converted_file", converted_file);
      //       setimage(converted_file)
      //     }, 'image/jpeg', 1);
      //   };
      //   reader.readAsArrayBuffer(file);
      // }
      // else {
      setimage(images)
      const fileArray = Array.from(images).map((file) => URL.createObjectURL(file))
      setImageURL(fileArray)
      // }
    }
  }
  // const onSubmit = async (evt) => {

  //   evt.preventDefault();


  //   setLoading2(true)
  //   if (images.length < 1) {
  //     toastr.error("No image selected. Please choose an image to upload.")
  //     setLoading2(false)
  //     return
  //   }
  //   closedialogBox()
  //   setDisableSubmitButton(true)
  //   var formData = new FormData();
  //   const art = "art"
  //   formData.append("category", art)
  //   let lastimage = {}
  //   if (setIsHeic) {
  //     for (const key of Object.keys(images)) {
  //       lastimage = images[key]
  //     }
  //   }
  //   else {
  //     for (const x of images) {
  //       for (const key of Object.keys(images)) {
  //         lastimage = x[key]
  //       }
  //     }
  //   }
  //   formData.append("image", lastimage);
  //   dispatch(
  //     uploadArt(formData, uploadMultiFileSuccess("images")))
  // }
  useEffect(() => { console.log("artbyid", artByid) }, [artByid])
  return (

    <div className={"dialogMain " + (isOpen === true ? "show" : "")}>
      <div className="dialogInner">
        <div className="dialogHead">
          <h1>Update Status</h1>
          <button className="closebtn" onClick={toggle}>
            <img
              src={"/images/svg/icons/closeIcon.svg"}
              alt="close"
              width={40}
              height={40}
            />
          </button>
        </div>
        <div className={"dialogbody " + viewuser.viewProfile}>
          <div className="row">
            <div className="col-xl-4 mb-4">
              <div className={'h-auto ' + viewuser.profilephoto}>
                {artLoading ? (
                  <div className="spinnerBoxNew ">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <img
                    src={artByid?.images?.link}
                    alt=""
                    className="img-fluid"
                  />)
                }
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row">
                <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                  <div className="form-group">
                    <label>Art Name</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={artByid?.artInfos ? artByid?.artInfos?.title : artByid?.images?.file_name}
                      name=""
                      id=""
                    />
                  </div>
                  <div className="form-group">
                    <label>Artist Name</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={artByid?.user_id?.name + " " + artByid?.user_id?.lastname}
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={artByid?.user_id?.email}
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                  <div className="form-group">
                    <label>User Role</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={artByid?.user_id?.role}
                      name=""
                      id=""
                    />
                  </div>
                </div>
                {artByid?.artInfos && <>
                  <div className="col-xl-12 col-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <label>Digital Artwork Size</label>
                    </div>
                    <div className="row">
                      <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={artByid?.artInfos?.vertical_dimension}
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={artByid?.artInfos?.horizontal_dimension}
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={artByid?.artInfos?.size_type}
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                    <div className="form-group">
                      <label>Medium Used</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={artByid?.artInfos?.medium}
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                    <div className="form-group">
                      <label>Color Option</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={artByid?.artInfos?.color_options}
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-12 col-md-6 col-sm-6 mb-4">
                    <div className="form-group">
                      <label>Type of Paper Used</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={artByid?.artInfos?.type_of_surface}
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-12 col-md-12 col-sm-12 mb-4">
                    <div className="form-group">
                      <label>Description</label>
                      <textarea className="form-control" value={desc} readOnly>{artByid?.artInfos?.description}</textarea>
                    </div>
                  </div></>
                }
                <div className="col-xl-6 col-12 col-md-6 col-sm-6 mb-4">
                  <div className="form-group">
                    <label>Uploaded At</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={moment(artByid?.created_date)
                        .utcOffset(330)
                        .format("DD MMM YYYY hh:mm A")}
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-12 col-md-6 col-sm-6 mb-4">
                  <div className="form-group">
                    <label> Status</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={artByid?.adminStatus}
                      name=""
                      id=""
                    />
                  </div>
                </div>
                {artByid?.adminStatus === "Pending" && <>
                  <div className="col-xl-12 col-12 col-md-12 col-sm-12 mb-4 ">
                    <div className="form-group">
                      <label>Note</label>
                      <textarea rows={3} className="form-control" value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                    </div>
                  </div>



                  <div className="col-xl-12 col-12 col-md-12 col-sm-12 mb-4 ">
                    <div className="form-group">
                      <label>Attachment</label>
                      <div className="updatefile">
                        {!imageURL && <>
                          <input type="file" id="attachment" onChange={(e) => onChangeHandlerImage(e)} hidden />
                          <label htmlFor="attachment" >Choose File</label></>
                        }
                        {imageURL &&
                          <div className="imguploaded"  >
                            <button onClick={deleteFile}>
                              <i class="fa-solid fa-xmark"></i>
                            </button>
                            <img src={imageURL} />
                          </div>
                        }
                      </div>
                    </div>
                  </div></>
                }



                <div className="col-xl-12 col-12 col-md-12 col-sm-12 ">
                  <div className="row">
                    <div className="col-xl-6">
                      <button hidden={artByid?.adminStatus !== "Pending"}
                        className="btn verifybtn w-100 text-white"
                        onClick={() => verify("Approved")}
                      >
                        Approve
                      </button>
                    </div>
                    <div className="col-xl-6">
                      <button hidden={artByid?.adminStatus !== "Pending"}
                        className="btn btn-outline-danger w-100"
                        onClick={() => verify("Rejected")}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
ViewUser.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any,
  user: PropTypes.any,
}
const mapStateToProps = ({ Arts, Login }) => ({
  error: Arts.error,
  loading: Arts.loading,
  artByid: Arts.artById,
  user: Login.user,
});
const mapDispatchToProps = (dispatch) => ({
  onGetArt: (userId, callback) => dispatch(getArtById(userId, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);