import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { ACTIVITY_TYPE, BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "history_type",
    text: t("Event"),
    formatter: (_, row) => ACTIVITY_TYPE[row?.history_type],
  },
  {
    dataField: "item.name",
    text: t("Item"),
    formatter: (_, row) => (
      <Link to={`/items/view/${row?.item?._id}`}>{row?.item?.name}</Link>
    ),
  },
  {
    dataField: "price",
    text: t("Price"),
    formatter: (_, row) =>
      Number(row.price).toFixed(8) +
      " " +
      BLOCKCHAIN_CURRENCY_CODE[row?.item?.blockchain],
  },
  {
    dataField: "from.name",
    text: t("From"),
    formatter: (_, row) => (
      <>
        {console.log(row?.from, "RowData")}
        {row?.from?._id ? (
          <Link to={`/users/profile/${row?.from?._id}`}>{row?.from?.name}</Link>
        ) : (
          "----"
        )}
      </>
    ),
  },

  {
    dataField: "to.name",
    text: t("To"),
    formatter: (_, row) => (
      <>
        {/* {console.log(row, "RowData")} */}
        <Link to={`/users/profile/${row?.to?._id}`}>{row?.to?.name}</Link>
      </>
    ),
  },
  {
    dataField: "item.transactionHash",
    text: t("Transaction"),
  },
  {
    text: t("Created At"),
    dataField: "created_date",
    sort: true,
    formatter: (_, row) => moment(row.created_date).format("DD MMM YYYY LT"),
  },
];

export default ListColumns;
