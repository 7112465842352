/** Super Admin apis start */

// LOGIN
export const POST_LOGIN = "/admin/login";
export const CHANGE_PASSWORD = "/user/super-admin/changepassword";
export const VERIFY_PROFILE_REQUEST =
  "/user/super-admin/acceptRejectProfileRequest";
export const GET_LANGUAGES = "/setting/configs";
export const DELETE_STORE = "/super-admin/deletestore";

// CUSTOMERS (STORES)
export const UPDATE_STORE_STATUS = "/super-admin/store/updateStatus";
export const PUT_STORE_KEYS = "/super-admin/store/keys";
export const POST_CHECK_MAIL = "/super-admin/checkMailgunEmail";
export const POST_HIDE_THINGS = "/super-admin/store/hidethings";

// Sub-Admins
export const GET_SUB_ADMINS = "/super-admin/getSuperAdminUsersWithFilter";
export const ADD_SUB_ADMIN = "/super-admin/add";
export const GET_SUB_ADMIN = "/super-admin/getSuperAdminById";
export const PUT_SUB_ADMIN = "/super-admin/updateSuperAdminById";
export const DELETE_SUB_ADMIN = "/super-admin/delete";

// Logs
export const GET_LOGS = "/super-admin/getLog";
export const POST_REBUILD_SCRIPT = "/super-admin/rebuild";
export const POST_STORE_REBUILD_SCRIPT =
  "/super-admin/updateRebuildScriptByStoreId";
export const POST_STORE_SETTINGS_SCRIPT = "/super-admin/rebuildSettingScript";
export const POST_CLEAR_LOGS = "/super-admin/clearlogs";

// Email Template
export const GET_EMAIL_TEMPLATES = "/super-admin/templates";
export const POST_EMAIL_TEMPLATE = "/super-admin/template/add";
export const PUT_EMAIL_TEMPLATE = "/super-admin/templates/update";
export const DELETE_EMAIL_TEMPLATE = "/super-admin/templates/remove";
export const GET_EMAIL_TEMPLATE = "/super-admin/template/view";

// Billing Products
export const POST_ITEM = "/super-admin/billingproduct/add";
export const PUT_ITEM = "/super-admin/billingproduct/update";
// export const GET_ITEM = "/super-admin/billingproduct/view"

// Collections
// export const GET_COLLECTIONS = "/super-admin/billingplan/list"
export const POST_COLLECTION = "/super-admin/billingplan/add";
export const PUT_COLLECTION = "/super-admin/billingplan/update";
export const DELETE_COLLECTION = "/super-admin/billingplan/remove";
// export const GET_COLLECTION = "/super-admin/billingplan/view"
export const PUT_COLLECTION_STATUS =
  "/super-admin/billingplan/updatestatus/all";
export const UPDATE_STORE_COLLECTION = "/super-admin/store/updatePlan";
export const PUT_COLLECTION_DISCOUNT = "/super-admin/billingplan/discount";

export const VERIFY_COLLECTION_REQUEST =
  "/user/super-admin/acceptorRejectCollectionRequest";

// Domain
export const UPDATE_DNS = process.env.REACT_APP_UPDATE_DNS;
export const ADD_DOMAIN = "/super-admin/domain/add";
export const SEARCH_DOMAIN = "/super-admin/domain/search";
export const BUY_DOMAIN = "/super-admin/domain/buy";

// Transactions
export const GET_TRANSACTIONS = "/super-admin/store/transaction";
export const POST_STORE_CHARGE = "/super-admin/store/charge";
export const POST_STORE_REFUND = "/super-admin/store/refund";

// Store User Login
export const STORE_LOGIN_BY_ADMIN = "/super-admin/store/generate-token";

/** Super Admin apis end */

export const POST_FORGOT_PASSWORD = "/store/forgotpassword";
export const POST_RESET_PASSWORD = "/store/resetpassword";
export const SOCIAL_LOGIN = "/social-login";

// Profile
export const GET_CURRENT_USER = "/store/me";
export const PUT_PROFILE = "/store/updateprofile";

// SIGNUP
export const POST_REGISTER_EMAIL = "/store/signupemail";
export const POST_STORE_NAME = "/store/check";

export const POST_REGISTER = "/store/signup";

// File
export const POST_FILE = "/file/add";
export const POST_REMOVE_FILE = "/file/remove";

// Settings
export const API_SETTINGS = "/setting";
export const GET_SETTINGS = "/setting/view";
export const PUT_SETTINGS = "/setting/update";
export const GET_STORE_SETTINGS = "storetype";
export const PUT_STORE_SETTINGS = "addsetting";
export const GET_ACCESS_LIST = "/setting/accesslist";
export const GET_STORE_TYPES = "/setting/storetypes";
export const GET_STARTED = "/setting/getStarted";
export const POST_CONTRACT_DEPLOY = "/setting/admin/deploycontract";

// Category
export const ADD_CATEGORY = "/category/admin/add";
export const VIEW_CATEGORIES = "/category/admin/list";
export const DELETE_ITEM = "/category/admin/remove";
export const DELETE_CATEGORY = "/category/admin/remove";
export const PUT_ITEM_STATUS = "/category/admin/updatestatus/all";
export const VIEW_CATEGORY = "/category/admin/view";
export const UPDATE_CATEGORY = "/category/admin/update";
export const GET_SUBCATEGORIES = "/category/list/subcategories";

//arts
export const GET_ARTS = "/user/super-admin/getArtsOfAll";
export const APPROVE_OR_REJECT_ART = "/user/super-admin/acceptOrRejectArt";
export const ArtById = "/art/getArtById";

//Blog
export const ADD_BLOG = "/blog/admin/add";
export const UPDATE_BLOG = "/blog/admin/update";
export const DELETE_BLOG = "/blog/admin/remove";
export const VIEW_BLOG = "/blog/admin/view";
export const VIEW_BLOG_LIST = "/blog/admin/list";
export const PUT_BLOG_STATUS = "/blog/admin/updatestatus/all";

// Verification

export const GET_VERIFICATIONS = "/user/admin/new";
export const GET_VERIFICATION = "/home/view";

// Users
export const GET_USERS = "/user/admin/list";
export const VIEW_USER = "/user/admin/view";

// Collections
export const GET_COLLECTIONS = "/collection/admin/list";
export const VIEW_COLLECTION = "/collection/admin/view";
export const GET_COLLECTION = "/collection/admin/view";

// Items
// export const VIEW_ITEM_ACTIVITY = "/item/activity"
export const GET_ITEMS = "/sellItems/getAllSellItemWithFilter";
export const GET_ITEM = "/item/admin/view";
export const GET_NOTIFICATIONS = "/item/admin/notifications";
export const GET_OTHER_NOTIFICATIONS = "/notification/user/getUserNotification";
export const MARK_OTHER_NOTIFICATIONS = "/notification/user/updateArtNotification";
export const GET_PARTICULAR_SELLITEM_URL = "/sellItems/getParticularSellItem";

// Activity
export const GET_ACTIVITIES = "/item/activity";

//  Send Emails

export const GET_EMAILS = "/sendmail/admin/list";
export const ADD_EMAILS_URL = "/sendmail/admin/send";
export const GET_EMAIL_BY_ID_API = "/sendmail/admin/get";

// FAQS
export const GET_FAQS = "/faq/admin/list";
export const ADD_FAQ = "/faq/admin/add";
export const GET_FAQ = "/faq/admin/view";
export const PUT_FAQ = "/faq/admin/update";
export const DELETE_FAQ = "/faq/admin/remove";
export const PUT_FAQS_STATUS = "/faq/updatestatus/all";

// ContactUs
export const GET_CONTACTUS = "admin/listContactUs";
export const GET_REPROT_USER_LIST = "/item/user/reportList";
export const GET_REPORT_BY_ID = "/item/admin/reportView/";

// CONTENT_PAGES
export const GET_CONTENT_PAGES = "/page/admin/list";
export const ADD_CONTENT_PAGE = "/page/admin/add";
export const GET_CONTENT_PAGE = "/page/admin/view";
export const PUT_CONTENT_PAGE = "/page/admin/update";
export const DELETE_CONTENT_PAGE = "/page/admin/remove";
export const PUT_CONTENT_PAGES_STATUS = "/page/updatestatus/all";

// images
export const POST_IMAGE = "/setting/add/home/image";
export const PUT_IMAGE = "/update/home/image";
export const GET_IMAGES = "/image/list";
export const DELETE_IMAGE = "/remove/home/image";
export const GET_IMAGE = "/view/home/image/";

// send verification email

export const VERIFICATION_EMAIL = '/user/resend-otp';


// send other notification
export const SEND_OTHER_NOTIFICATION = '/notification/admin/postNotification';


// getNew collection

export const GET_NEW_COLLECTION_URL = '/newCollection/getNewCollections';
export const GET_NEW_COLLECTION_BY_ID_URL = '/newCollection/getNewCollectionById/';


// transactions 
export const GET_TRANSACTIONS_WITH_FILTER_URL = '/transaction/getAllTransactionwithFilter/';
export const GET_MANAGE_EARNING_URL = '/transaction/manageEarning/';
export const GET_CSV_URL = '/transaction/getCsv/';
export const GET_ADMIN_EARNING_URL = '/transaction/getAdminEarning/';

// sell items

export const GET_SELL_ITEMS_WITH_FILTER_URL = '/sellItems/getAllSellItemWithFilter/';

// printing partner
export const ADD_PRINTING_PARTNER_URL = '/printing_partner_panel/admin/printeronboard/';
export const LIST_PRINTING_PARTNER_URL = 'printing_partner_panel/admin/getPartnersAdminList/';
export const EDIT_PRINTING_PARTNER_URL = 'printing_partner_panel/editPartner/';
export const GET_PRINTING_PARTNER_URL = 'printing_partner_panel/viewPartner/';
export const DELETE_PRINTING_PARTNER_URL = 'printing_partner_panel/admin/deletePrinter/';
export const LIST_PRINTING_REQUEST_URL = 'printing_partner_panel/admin/getPrintingRequestAdminList';
export const GET_PRINTING_REQUEST_URL = 'printing_partner_panel/getPrintingRequest';
export const GET_PRINTING_PAYMENT_REQUEST_URL = 'printing_partner_panel/printTransAdminList';
export const GET_PRINTER_WITHDRAWL_REQUEST_URL = 'printing_partner_panel/listPrinterWithdrawlRequest';
export const VIEW_PRINTER_WITHDRAWL_REQUEST_URL = 'printing_partner_panel/viewPrinterWithdrawlRequest';
export const UPDATE_PRINTER_WITHDRAWL_REQUEST_URL = 'printing_partner_panel/updatePrinterWithdrawlRequest';
export const GET_PRINTER_CSV_DATA_URL = 'printing_partner_panel/getPrintPaymentCsv';