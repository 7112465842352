import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import AnimateHeight from "react-animate-height"
// import sortSvg from "../../../assets/images/svg/sort.svg";
import sortSvg from "../../assets/images/svg/sort.svg";
import earningicon from "../../assets/images/svg/earningIcon.svg"
import calandericon from "../../assets/images/svg/calanderIcon.svg"
import arrowicon from "../../assets/images/svg/arrowIcon.svg"
import exporticon from "../../assets/images/svg/exportIcon.svg"
import nfticon from "../../assets/images/png/nft.png"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import SearchInput from "components/Common/SearchInput"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2"
import {
  getActivities,
  deleteActivity,
  putActivitiesStatus,
  getTransactionsWithFilter,
  manageEarning,
  getCsv,
  getAdminEarning,
} from "store/actions"
import ListColumns from "./ListColumns"
import { DatePicker } from 'antd'
import moment from 'moment';
import Papa from 'papaparse';
import toastr from "toastr";
import { Fields } from "redux-form"
import { compileString } from "sass"
const { RangePicker } = DatePicker;
const Earning = props => {
  const { accessLevel,
    history,
    items,
    totalItems,
    onGetActivities,
    onDeleteActivity,
    onPutActivitiesStatus,
    totalEarnings,
    earnings,
    onGetEarnings,
    onGetCsvData,
    onGetAdminEarning,
    csvData,
    adminEarning,
    loading,
  } = props
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  })
  const [selected, setSelected] = useState([])
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    itemId: null,
  })
  const [filter, setFilter] = useState({
    orderBy: "created_date",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    fieldName: "",
    fieldValue: "",
    year: '',
    month: 1,
    day: null,
    hours: 0,
    minutes: 0,
    seconds: 0,
    timezone: '',
    starting_day: null,

    fields: [
      // {
      //   fieldName: "",
      //   fieldValue: "",
      // },

    ],
  })
  const [firstAdminEarning, setFirstAdminEarning] = useState(true)
  const [searchText, setSearchText] = useState("")
  const [itemsList, setItemsList] = useState([])
  const [filterCallCount, setFilterCallCount] = useState(0)
  const [filteredAdminEarning, setFilteredAdminEarning] = useState(0)
  const [filteredNftSold, setFilteredNftSold] = useState(0)
  const [dates, setDates] = useState([])
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalEarnings,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    page:filter?.page
  }
  const { SearchBar } = Search
  useEffect(() => {
    let body = {}
    const currentYear = new Date().getFullYear();
  }, [])
  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      }

      return setaccesses(data)
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    }

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value
          break

        case "UPDATE":
          data.canEdit = item.value
          break

        case "DELETE":
          data.canDelete = item.value
          break

        case "BLOCK":
          data.canBlock = item.value
          break
      }
    })

    setaccesses(data)
  }, [JSON.stringify(accessLevel)])

  useEffect(() => {
    setFilterCallCount(filterCallCount + 1)
    const adminEarningCallback = (res) => {
      console.log("dasasfa", res)
      if (res.status === "success") {
        setFirstAdminEarning(false)
      }
    }

    onGetAdminEarning({ firstAdminEarning: firstAdminEarning, starting_day:filter?.starting_day, day:filter?.day }, adminEarningCallback)
    onGetEarnings(filter)
    setItemsList(earnings)
  }, [filter])

  useEffect(() => {
    let filteredEarning = 0
    let filteredNftSold = 0
    for (let i = 0; i < earnings.length; i++) {
      filteredEarning += earnings[i]?.adminEarning
      filteredNftSold += earnings[i]?.nftSold
    }
    setFilteredAdminEarning(filteredEarning)
    setFilteredNftSold(filteredNftSold)

    setItemsList(earnings || [])
  }, [earnings])

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {

    if (type === "search") {
      console.log("type", type)
      setSearchText(searchText)
    }


    if (type === "sort") {
      console.log("type", type)
      if (sortField == "createdAt") {
        sortField = "date_created"
      }

      if (sortOrder == "desc") {
        sortOrder = -1
      } else if (sortOrder == "asc") {
        sortOrder = 1
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }))
    }
  }

  const toggleConfirmModal = (itemId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      itemId,
    }))
  }

  const removeConfirm = () => {
    const { itemId, isOpen } = confirmModal

    const onComplete = () => {
      onGetActivities(filter)
    }

    setConfirmModal({ isOpen: !isOpen, itemId: null })
    onDeleteActivity({ _id: itemId }, onComplete)
  }

  const onStatusChange = (value) => {
    const fieldName = !!value ? "status" : ""
    const fieldValue = value

    setFilter((prevState) => ({
      ...prevState,
      fields: { fieldName, fieldValue },
    }))
  }

  const onSearch = (event) => {
    event.preventDefault()

    setFilter((prevState) => ({ ...prevState, search: searchText }))
  }

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id])
    } else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id))
    }
  }

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id)
    if (isSelect) {
      setSelected(ids)
    } else {
      setSelected([])
    }
  }
  function setSno(page) {

  }

  const handleItemStatusChange = (status) => {
    const callback = () => {
      setSelected([])
      onGetActivities(filter)
    }

    onPutActivitiesStatus({ _id: selected, status }, callback)
  }
  async function arrayToCsv() {

    if (!filter?.starting_day) {
      return toastr.error("Starting Day is required")
    }
    if (!filter?.day) {
      return toastr.error("Ending Day is required")
    }
    let data = {}
    data.starting_day = filter.starting_day
    data.day = filter.day
    const callback = (res) => {
      console.log("ceceer", res)
      if (res.status === "success" && res.data.length > 0) {
        const data = res.data;

        const customColumnNames = [{ 'Transaction ID': 'trans_hash' }, { 'Created At': 'created_date' }, { 'NFT Title': 'title' }, { 'Sale Type': 'sale_event' }, { 'Selling Price (MATIC)': 'price' }, { 'Royality Percentage': 'royalties' }, { 'Discount By Seller': 'sller_discount' }, { 'Discount By Buyer': 'buyer_discount' }, { 'Platform Fee': 'platform_fee' }, { 'Amount Payable': 'amount_payable' }];


        const customData = data.map(item => {
          const customObject = {};
          for (let i = 0; i < customColumnNames.length; i++) {
            console.log("insidefor")
            const currentObject = customColumnNames[i];
            for (const key in currentObject) {
              console.log("insidefor2")
              if (currentObject.hasOwnProperty(key)) {

                const value = currentObject[key];
                console.log("insidefor3", "key", key, "value", value)
                customObject[key] = value !== undefined ? item[value] : "N/A";
              }
            }
          }
          return customObject;
        });

        // Convert customData to CSV
        const csv = Papa.unparse(customData);

        // Rest of your code remains the same...
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (navigator.msSaveBlob) {
          // For IE
          navigator.msSaveBlob(blob, `${filter?.starting_day} to ${filter?.day} Earning History`);
        } else {
          // For other browsers
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.setAttribute('download', `${filter?.starting_day} to ${filter?.day} Earning History`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        return toastr.error("No Data for CSV")
      }

    }

    onGetCsvData(data, callback)

  }

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("cjnsjcnjsdncsjk", userTimeZone)
    setFilter((prevState) => ({ ...prevState, timezone: userTimeZone }))
  }, [])
  // dates 

  useEffect(() => {
    console.log("dates", dates)
    setFilter((prevState) => ({ ...prevState, starting_day: dates[0], day: dates[1], page:1 }))
  }, [dates])
  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }
  const handleClear = () => {
    console.log("handleClear")
  }

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Manage Earning"
            breadcrumbItem={props?.t("Manage Earning")}
            breadcrumbItems={[{ title: props?.t("Manage Earning") }]}
          />

          <Row>
            <Col className="mt40" xl={12}>
              <Row>
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <div className="earningBox earn">
                        <img src={earningicon} width={40} height={40} alt="" />
                        <h2>Total Earning</h2>
                        {/* <h3>{(firstAdminEarning === false && filterCallCount > 2) ? filteredAdminEarning : adminEarning?.totalAdminEarning} MATIC</h3> */}
                        <h3>{adminEarning?.totalAdminEarning ? (adminEarning?.totalAdminEarning)?.toFixed(5) : 0} MATIC</h3>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="earningBox ">
                        <img src={nfticon} width={40} height={40} alt="" />
                        <h2>Total Number of NFT Sold</h2>
                        <h3> {adminEarning?.soldNfts ? adminEarning?.soldNfts : 0}</h3>
                        {/* <h3> {(firstAdminEarning === false && filterCallCount > 2) ? filteredNftSold : adminEarning?.soldNfts}</h3> */}
                      </div>
                    </Col>
                    {/* <Col lg={4}>
                      <div className="earningBox ">
                        <img src={earningicon} width={40} height={40} alt="" />
                        <h2>Amount Settled</h2>
                        <h3>$ 20,000</h3>
                      </div>
                    </Col> */}
                  </Row>
                </Col>
                <Col lg={6}>
                  <div className="exportTab justify-content-end">
                    {/* <div className="year"onClick={()=>{
                       setFilter((prevState) => ({ ...prevState, year: 2024, month:1, day:25, starting_day:22, hours:0, minutes:0, seconds:0 }))
                    }}> */}
                    {/* <img src={calandericon} width={35} height={35} />
                      <h2>2024</h2>
                      <img src={arrowicon} width={35} height={35} />

                    </div>
                    <div className="month">
                      <img src={calandericon} width={35} height={35} />
                      <h2>January</h2>
                      <img src={arrowicon} width={35} height={35} />
                    </div>
                    <div className="days">
                      <img src={calandericon} width={35} height={35} />
                      <h2>1-15 Days</h2>
                      <img src={arrowicon} width={35} height={35} />
                    </div> */}
                    
                    <div style={{ margin: 20, position:"relative" }}>
                    <RangePicker className="custom-date-picker"
                                              onClear={handleClear}
                                              disabledDate={disabledDate}
                                              onChange={(values) => {
                                                if (values) {
                                                  var dateStr1 = values[0].$d;
                                                  var dateStr2 = values[1].$d;

                                                  // Parse the date strings using Moment.js
                                                  var date1 = moment(dateStr1);
                                                  var date2 = moment(dateStr2);

                                                  // Calculate the difference between the two dates
                                                  var dateDifference = date2.diff(date1);

                                                  var daysDifference = moment.duration(dateDifference).asDays();
                                                  // if (daysDifference > 15) {
                                                  //   return toastr.error("Date Range should be less than 15 days")
                                                  // }
                                                  // Display the difference in days
                                                  var daysDifference = moment.duration(dateDifference).asDays();
                                                  setDates(values.map(item => {
                                                    return moment(item.$d).format('YYYY-DD-MM')
                                                  }))
                                                }
                                                else {
                                                  setFilter((prevState) => ({ ...prevState, starting_day: '1970-01-01', day: todaysDate }))
                                                }
                                              }} />
                                            <img src="/images/svg/Vector.svg" width="auto" height="auto" classname="position-absolute" style={{ right: "1.8rem", top: "1.2rem", position: "absolute" }} />

                    </div>
                    <button className="exportbtn" onClick={() => arrayToCsv(itemsList)}>
                      <img src={exporticon} width={30} height={30} />
                      Export CSV
                    </button>

                  </div>
                </Col>

              </Row>
            </Col>
            <Col className="mt-4" xs="12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={itemsList || []}
                    columns={ListColumns(
                      history,
                      toggleConfirmModal,
                      accesses,
                      props.t,
                    )}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>


                        {/* {(accesses.canEdit || accesses.canDelete) && (
                          <AnimateHeight
                            duration={500}
                            height={selected.length > 0 ? "auto" : 0}
                          >
                            <div className="custom-flex-xs d-flex align-items-center mb-3">
                              {accesses.canEdit && (
                                <div className="filter form-group d-flex align-items-center mb-0 mr-3">
                                  <label
                                    className="mb-0"
                                    style={{ minWidth: "48px" }}
                                  >
                                    {props.t("action")}:
                                  </label>

                                  <div className="w-auto pl-3">
                                    <select
                                      className="custom-select w-100"
                                      onChange={(e) =>
                                        handleFaqStatusChange(
                                          e.target.value
                                        )
                                      }
                                      style={{ minWidth: "106px" }}
                                    >
                                      <option value="">
                                        {props.t("status")}
                                      </option>
                                      <option value="active">
                                        {props.t("active")}
                                      </option>
                                      <option value="inactive">
                                        {props.t("inactive")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              )}
                              {accesses.canDelete && (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    handleFaqStatusChange("archived")
                                  }
                                >
                                  {props.t("delete")} {props.t("selected")}
                                </button>
                              )}
                            </div>
                          </AnimateHeight>
                        )} */}

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tableBox faqtble">
                              <BootstrapTable
                                responsive
                                remote
                                noDataIndication={"No data available"}
                                data={itemsList}
                                bordered={false}
                                striped={false}
                                classes={
                                  "table table-centered table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                defaultSorted={[
                                  {
                                    dataField: "created_date",
                                    order: "desc",
                                  },
                                ]}
                                sort={{
                                  sortCaret: (order) =>
                                    order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) : <span className="caret">
                                      {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                    </span>,
                                }}
                              />

                              {loading && (
                                <div className="spinnerBoxNew">
                                  <Spinner color="primary" />
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
Earning.propTypes = {
  t: PropTypes.any,
  onGetEarnings: PropTypes.func,
  onGetCsvData: PropTypes.func,
  onGetAdminEarning: PropTypes.func,
  soldNfts: PropTypes.number
};
const mapStateToProps = ({ Transactions }) => ({
  loading: Transactions.loading,
  adminEarning: Transactions.adminEarning,
  earnings: Transactions.Earnings,
  csvData: Transactions.Earnings,
  totalEarnings: Transactions.totalEarnings,
})

const mapDispatchToProps = (dispatch) => ({
  onGetEarnings: (data) => dispatch(manageEarning(data)),
  onGetCsvData: (data, callback) => dispatch(getCsv(data, callback)),
  onGetAdminEarning: (data, callback) => dispatch(getAdminEarning(data, callback))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Earning)),
)