import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";

// Breadcrumbs
//test
import Breadcrumbs from "components/Common/Breadcrumb2";

import { getCollection, VerifyCollectionRequest } from "store/actions";

import moment from "moment";

const ViewCollection = (props) => {
  const {
    match: { params },
    onGetCollection,
    collection,
    onPutVerifyCollectionRequest,
    loading,
    date,
    error,
  } = props;

  console.log(collection, 446427878);
  useEffect(() => {
    if (params && params.id) {
      onGetCollection(params.id);
    }
  }, [params.id]);

  const collectionVerifyStatus = (name) => {
    var parameter = {
      collection_id: params.id,
      verificationCollectionStatus: name,
    };
    const callback = (response) => {
      if (response.status === "success") {
        toastr.success("Profile verify successfully.");
        onGetCollection(params?.id);
      } else {
        toastr.success("Profile not verify.");
        onGetCollection(params?.id);
      }
    };
    onPutVerifyCollectionRequest(parameter, callback);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="page-content">
            <Spinner color="primary" />
          </div>
        </div>
      )}

      {error && <Redirect to="/dashboard" />}
      {!error && !isEmpty(collection) && !loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Collections"
              breadcrumbItem="Collection Details"
            />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <h3 className="mt-1 mb-3 text-primary">
                          {collection?.name}
                        </h3>
                      </div>
                    </Col>
                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <img
                          className="mt-1 mb-3 h-50 w-50"
                          src={collection?.image.link}
                          alt="Collection"
                        />
                      </div>
                    </Col>
                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <h6 className="mt-1 mb-3 text-muted">
                          Description - {collection?.description}
                        </h6>
                      </div>
                    </Col>
                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <h4 className="mt-1 mb-3 text-primary">
                          Author - {collection?.author?.name}
                        </h4>
                      </div>
                    </Col>
                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <h6 className="mt-1 mb-3 text-muted">
                          Status - {collection?.status}
                        </h6>
                      </div>
                    </Col>
                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <a
                          href={
                            collection?.blockchain === "ethereum"
                              ? `https://etherscan.io/address/` +
                                collection?.contractAddress
                              : collection?.blockchain === "BNB"
                              ? `https://testnet.bscscan.com/address/` +
                                collection?.contractAddress
                              : collection?.blockchain === "polygon"
                              ? `https://mumbai.polygonscan.com/address/` +
                                collection?.contractAddress
                              : ""
                          }
                          target="_blank"
                        >
                          <h6 className="mt-1 mb-3 text-info">
                            Contract Address - {collection?.contractAddress}
                          </h6>
                        </a>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <a
                          href={
                            collection?.blockchain === "ethereum"
                              ? `https://etherscan.io/tx/ ` +
                                collection?.transactionHash
                              : collection?.blockchain === "BNB"
                              ? `https://testnet.bscscan.com/tx/` +
                                collection?.transactionHash
                              : collection?.blockchain === "polygon"
                              ? `https://mumbai.polygonscan.com/tx/` +
                                collection?.transactionHash
                              : ""
                          }
                          target="_blank"
                        >
                          <h6 className="mt-1 mb-3 text-info">
                            Transaction Hash - {collection?.transactionHash}
                          </h6>
                        </a>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <h6 className="mt-1 mb-3 text-success">
                          Created On :-
                          {moment(collection?.date_created_utc).format(
                            "DD/MM/YYYY "
                          )}
                        </h6>
                      </div>

                      {collection?.verificationCollectionStatus ===
                        "requested" && (
                        <div className="mt-5">
                          <h5 className="mb-4">Verify Profile Request:</h5>
                          <button
                            className="btn btn-success mr-2"
                            onClick={() => collectionVerifyStatus("accept")}
                          >
                            Accept Collection
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => collectionVerifyStatus("reject")}
                          >
                            Reject Collection
                          </button>
                        </div>
                      )}

                      {collection?.verificationCollectionStatus ===
                        "accept" && (
                        <div className="mt-5">
                          <b>Collection Verified: </b>
                          <p>Yes</p>
                        </div>
                      )}
                      {collection?.verificationCollectionStatus ===
                        "reject" && (
                        <div className="mt-5">
                          <b>Collection Verified: </b>
                          <p>No</p>
                        </div>
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ViewCollection.propTypes = {
  collection: PropTypes.any,
  date: PropTypes.string,
  onGetCollection: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ collections }) => ({
  loading: collections.loading,
  collection: collections.collection,
  date: collections.date,
  error: collections.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollection: (data) => dispatch(getCollection(data)),
  onPutVerifyCollectionRequest: (data, callback) =>
    dispatch(VerifyCollectionRequest(data, callback)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ViewCollection))
);
