import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSettings, putSettings } from "store/actions";
import viewuser from "../../../assets/css/viewuser.module.css";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  Alert,
  Spinner,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";

const WalletSettings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;
  const [submitInfo, setSubmitInfo] = useState(null);
  const [fields, setFields] = useState({
    apiKey: "",
    secretKey: "",
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings && props.settings.pinataIpfsSetting) {
      setFields((prevState) => ({
        ...prevState,
        apiKey: props.settings.pinataIpfsSetting.apiKey,
        secretKey: props.settings.pinataIpfsSetting.secretKey,
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  function submit() {
    try {
      onPutSettings({
        pinataIpfsSetting: {
          apiKey: fields.apiKey,
          secretKey: fields.secretKey,
        },
        _id: props.settings._id,
      });
      setSubmitInfo("Information updated successfully!!");
    } catch {
      setSubmitInfo("Some Error Occurred!!");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="IPFS Settings" />

          <Row>
              <div className="mt-2 ml-5 mr-5">
                {submitInfo && <Alert>{submitInfo}</Alert>}
              </div>
                <Col md={12}>
                <div className={"addbox position-relative mt-4 " + viewuser.viewProfile}>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label>API Key</Label>

                          <Input
                            type="text"
                            value={fields.apiKey}
                            onChange={handleChange("apiKey")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Secret Key</Label>

                          <Input
                            type="text"
                            value={fields.secretKey}
                            onChange={handleChange("secretKey")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
              
                  <Col className="d-flex justify-content-start">
                  
                    <button
                      className="btn verifybtn px-5 text-white me-3"
                      disabled={loading}
                      onClick={submit}
                    >
                      Save Changes
                    </button>
                  </Col>
                    {loading && (
                  <div className="spinnerBoxNew">
                    <Spinner color="primary" />
                  </div>
                )}
                </Row>
                </div>
                </Col>

               
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

WalletSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WalletSettings)
);
