import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import deletebutton from "assets/images/svg/delete.svg"
import eyebutton from "assets/images/svg/Eye.svg"
import editbutton from "assets/images/svg/edit.svg"
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (history, toggleConfirmModal, toggleViewPrintPartner, toggleEditPrintPartner, t, accesses) => [
  {
    dataField: "shop_name",
    text: t("Shop Name"),
    sort: true,
  },
  // {
  //   dataField: "_id",
  //   text: t("Id")
  // },
  {
    text: t("Email"),
    dataField: "email",
    sort: true,
    formatter: (cellContent, row) => <>{row?.email || "---"}</>,
  },
  {
    dataField: "business_address",
    text: t("Business Address"),
    sort: true,
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row, i) => (
      <>
        <button onClick={() => toggleViewPrintPartner(row._id)} className='btn p-1'><img src={eyebutton} />
        </button>
        {/* <button onClick={() => toggleEditPrintPartner(row._id)} className='btn p-1'><img src={editbutton} />
        </button> */}
        <button onClick={() => toggleConfirmModal(row._id)} className='btn p-1'><img src={deletebutton} />
        </button>
      </>
    ),
  },
];

export default CustomersColumns;
