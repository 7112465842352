import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Button, Modal, ModalBody } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { useDispatch, useSelector } from "react-redux";
import { approveOrRejectArt, getArtById, getCustomer, viewBlog } from "store/actions";
import CustomersList from "./ViewBlog";
import moment from "moment-timezone";
import toastr from "toastr";

const ViewBlog = props => {
  console.log(props);
  const { isOpen, toggle, userId, user, onViewBlog, blogData } = props
  console.log(isOpen);
  console.log(userId);
  const [userArtData, setUserArtData] = useState({})
  const [notificationState, setNotificationState] = useState({
    sender: "",
    receiver: "",
    type: "artApproval",
  });
  useEffect(() => {
    if (userId) {
      onViewBlog(userId)
    }
  }, [userId])
  useEffect(() => {
    setNotificationState((prevState) => ({
      ...prevState,
      sender: user?.email,
      receiver: userArtData?.user_id,
    }));
  }, [userArtData])


  return (

    <div className={"dialogMain viewBlog " + (isOpen === true ? "show" : "")}>
      <div className="dialogInner">
        <div className="dialogHead">
          <h1>View Blog</h1>
          <button className="closebtn" onClick={toggle}>
            <img
              src={"/images/svg/icons/closeIcon.svg"}
              alt="close"
              width={40}
              height={40}
            />
          </button>
        </div>
        <div className={"dialogbody viewBlogBody " + viewuser.viewProfile}>
          <div className="row">
            <div className="col-xl-12 mb-4">
              <div className={viewuser.profilephoto}>
                <img
                  src={blogData?.image?.link}
                  alt=""
                  className="img-fluid aspectration"
                />
                <div className="content" dangerouslySetInnerHTML={{ __html: blogData?.description }}></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )

}
ViewBlog.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any,
  user: PropTypes.any,
  onViewBlog: PropTypes.func,
}
const mapStateToProps = ({ Category }) => ({
  error: Category.error,
  loading: Category.loading,
  blogData: Category.blogview,
  // user: Login.user,
});
const mapDispatchToProps = (dispatch) => ({
  onViewBlog: (id) => dispatch(viewBlog(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBlog);