import { call, put, takeEvery } from "redux-saga/effects";

// Category Redux States
import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  ADD_BLOG,
  GET_CATEGORY,
  PUT_CATEGORY,
  DELETE_CATEGORY,
  PUT_CATEGORIES_STATUS,
  GET_REPORT,
  GET_REPORT_VIEW,
} from "./actionTypes";
import {
  getCategoriesFail,
  getCategoriesSuccess,
  addCategoryFail,
  addCategorySuccess,
  getCategoryFail,
  getCategorySuccess,
  putCategoryFail,
  putCategorySuccess,
  deleteCategoryFail,
  deleteCategorySuccess,
  putCategoriesStatusFail,
  putCategoriesStatusSuccess,
  addBlogsFail,
  addBlogsSuccess,
  putBlogsFail,
  putBlogsSuccess,
  viewBlogFail,
  viewBlogSuccess,
  deleteBlogsFail,
  deleteBlogsSuccess,
} from "./actions";

import * as CONST from "./actionTypes";
import * as ac from "./actions";

import {
  getCategories,
  getBlogs,
  getReport,
  getReportById,
  getCategory,
  deleteCategory,
  updateCategories,
  postCategory,
  putBlog,
  postblog,
  getBlogView,
  delBlogs,
  getSubCategoriesApi,
} from "helpers/backend_helper";
import { getCustomerSuccess, getCustomerFail } from "../customers/actions";

function* fetchCategories({ payload }) {
  try {
    const response = yield call(getCategories, payload);
    if (response.status == "failure") {
      return yield put(getCategoriesFail(response.message));
    }

    yield put(getCategoriesSuccess(response));
  } catch (error) {
    yield put(getCategoriesFail(error));
  }
}

function* onAddNewCategory({ payload: { item, history } }) {
  try {
    const response = yield call(postCategory, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(addCategoryFail(response.message));
    }

    history && history.goBack();

    yield put(addCategorySuccess());
  } catch (error) {
    yield put(addCategoryFail("Internal Error!"));
  }
}

function* onAddNewBlog({ payload: { data, callback } }) {
  try {
    const response = yield call(postblog, data);
    if (response.status == "failure") {
      return yield put(addBlogsFail(response.message));
    }

    callback && callback(response);
    yield put(addBlogsSuccess());
  } catch (error) {
    yield put(addBlogsFail("Internal Error!"));
  }
}

function* fetchCategory({ payload }) {
  try {
    const response = yield call(getCategory, payload);

    if (response.status == "failure") {
      return yield put(getCategoryFail(response.message));
    }

    yield put(getCategorySuccess(response.data));
  } catch (error) {
    yield put(getCategoryFail(error));
  }
}

function* onPutCategory({ payload: { data, history } }) {
  try {
    const response = yield call(updateCategories, data);

    if (response.status == "failure") {
      return yield put(putCategoryFail(response.message));
    }

    history && history.goBack();

    yield put(putCategorySuccess());
  } catch (error) {
    yield put(putCategoryFail("Internal Error!"));
  }
}

function* onDeleteCategory({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCategory, data);

    if (response.status == "failure") {
      return yield put(deleteCategoryFail(response.message));
    }

    yield put(deleteCategorySuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteCategoryFail("Internal Error!"));
  }
}

function* onPutCategoriesStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putCategoriesStatus, data);

    if (response.status == "failure") {
      return yield put(putCategoriesStatusFail(response.message));
    }

    yield put(putCategoriesStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putCategoriesStatusFail("Internal Error!"));
  }
}

//blog section
function* fetchBlog({ payload }) {
  try {
    const response = yield call(getBlogs, payload);
    if (response.status == "failure") {
      return yield put(ac.getBlogsFail(response.message));
    }
    yield put(ac.getBlogsSuccess(response));
  } catch (error) {
    yield put(ac.getBlogsFail(error));
  }
}
function* fetchReport({ payload }) {
  try {
    const response = yield call(getReport, payload);
    if (response.status == "failure") {
      return yield put(ac.getBlogsFail(response.message));
    }
    yield put(ac.getBlogsSuccess(response));
  } catch (error) {
    yield put(ac.getBlogsFail(error));
  }
}
function* onGetReportById({ payload }) {
  try {
    const response = yield call(getReportById, payload);
    if (response.status == "failure") {
      return yield put(getCustomerFail(response.message));
    }
    yield put(getCustomerSuccess(response.data));
  } catch (error) {
    yield put(getCustomerFail(error));
  }
}

function* onputBlogs({ payload: { data, callback } }) {
  try {
    const response = yield call(putBlog, data);
    if (response.status == "failure") {
      return yield put(putBlogsFail(response.message));
    }
    callback && callback(response);

    yield put(putBlogsSuccess());
  } catch (error) {
    yield put(putBlogsFail("Internal Error!"));
  }
}

function* onDeleteBlog({ payload: { data, callback } }) {
  try {
    const response = yield call(delBlogs, data);

    if (response.status == "failure") {
      return yield put(deleteBlogsFail(response.message));
    }

    yield put(deleteBlogsSuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteBlogsFail("Internal Error!"));
  }
}

function* viewBlog({ payload }) {
  try {
    const response = yield call(getBlogView, payload);

    if (response.status == "failure") {
      return yield put(viewBlogFail(response.message));
    }

    yield put(viewBlogSuccess(response.data));
  } catch (error) {
    yield put(viewBlogFail(error));
  }
}

function* fetchSubCategories({ payload }) {
  try {
    const response = yield call(getSubCategoriesApi, payload);
    if (response.status == "failure") {
      return yield put(ac.getSubCategoriesFail(response.message));
    }

    yield put(ac.getSubCategoriesSuccess(response));
  } catch (error) {
    yield put(ac.getSubCategoriesFail(error));
  }
}

function* CategoriesSaga() {
  yield takeEvery(CONST.UPDATE_BLOG, onputBlogs);
  yield takeEvery(CONST.VIEW_BLOG, viewBlog);
  yield takeEvery(CONST.DELETE_BLOG, onDeleteBlog);
  yield takeEvery(ADD_BLOG, onAddNewBlog);
  yield takeEvery(GET_CATEGORIES, fetchCategories);
  yield takeEvery(ADD_CATEGORY, onAddNewCategory);
  yield takeEvery(GET_CATEGORY, fetchCategory);
  yield takeEvery(CONST.GET_BLOG, fetchBlog);
  yield takeEvery(CONST.GET_REPORT, fetchReport);
  yield takeEvery(PUT_CATEGORY, onPutCategory);
  yield takeEvery(CONST.DELETE_CATEGORY, onDeleteCategory);
  yield takeEvery(PUT_CATEGORIES_STATUS, onPutCategoriesStatus);
  yield takeEvery(GET_REPORT_VIEW, onGetReportById);
  yield takeEvery(CONST.GET_SUBCATEGORIES, fetchSubCategories);
}

export default CategoriesSaga;
