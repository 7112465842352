import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Button, Modal, ModalBody, Spinner } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, getPrintingRequest } from "store/actions";
import CustomersList from ".";
import moment from "moment-timezone";
import addprintclose from "assets/images/svg/addprintclose.svg"
import tick from 'assets/images/svg/tick.svg'
const ViewPrint = props => {
  const dispatch = useDispatch()
  const { isOpen, toggle, userId, getPrintingRequest, printingRequest } = props
  const [printableFormat, setPrintableFormat] = useState('')
  const [image,  setImage] = useState('')
  const [orderTrackLength, setOrderTrackLength] = useState(0)
  useEffect(() => {
    if (printingRequest && printingRequest?.order_tracking_status && printingRequest?.order_tracking_status.length > 0) {
      setOrderTrackLength(printingRequest?.order_tracking_status?.length)
    }
    if(printingRequest){
      if(printingRequest?.sell_item?.printable_image_id?.link){
        setPrintableFormat(printingRequest?.sell_item?.printable_image_id?.link)
      }else if (printingRequest?.nft_id?.image_id?.image?.link){
         setImage(printingRequest?.nft_id?.image_id?.image?.link)
      }
    }
  }, [printingRequest])

 
  console.log(isOpen);
  // useEffect(() => {
  //   if (userId) {
  //     let data = {};
  //     data.id = userId;
  //     getPrintingRequest(data)
  //   }
  // }, [userId])

  const [viewDialog, setViewDialog] = useState(false)

  useEffect(() => {
    if (userId && isOpen === true) {
      dispatch(getPrintingRequest({ id: userId }))
    }
    console.log(viewuser);
  }, [userId, isOpen, viewuser])
  const closeDialog = () => {
    console.log('Deepak');
    setViewDialog(false)
  }
  return (
    <>
      {props?.particularCustomerLoading ? (
        <div className="spinnerBoxNew">
          <Spinner color="primary" />
        </div>) :
        (
          <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>

            <div className='dialogViewInner'>
              <div className='addprinthead'>
                <h1>View</h1>
                <button className='closebtn' onClick={toggle}>
                  <img src={addprintclose} alt="close" width={40} height={40} />
                </button>
              </div>
              <div className={'dialogbody ' + (viewuser.viewProfile)}>
                {/* <div className='details'>
                  <h5>Request ID</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Request ID</label>
                        <input className='form-control' placeholder="Request ID" value={printingRequest?.order_track_id} />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className='details'>
                  <h5>User Information</h5>
                  <div className='row'>
                    <div className='col-xl-3 col-md-3 col-sm-3 mb-4'>
                    <div className='form-group'>
                        <label>Request ID</label>
                        <input className='form-control' placeholder="Request ID" value={printingRequest?.order_track_id} />
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-3 mb-4'>
                      <div className='form-group'>
                        <label>User Name</label>
                        <input type="text" className='form-control' placeholder="user name" value={printingRequest?.partner_id?.fullName} />
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>User Contact Number</label>
                        <input type="text" className='form-control' placeholder="contact number" value={printingRequest?.partner_id?.contact_number} />
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-3 mb-4'>
                      <div className='form-group'>
                        <label>User Email Address</label>
                        <input type="text" className='form-control' placeholder="Email Address" value={printingRequest?.partner_id?.email} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='details'>
                  <h5>Print Partner Information</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Assigned Printing Shop Name</label>
                        <input type="text" className='form-control' placeholder="Assigned Printing Shop Name" value={printingRequest?.partner_id?.shop_name} />
                      </div>
                    </div>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Shop Contact Details</label>
                        <input type="text" className='form-control' placeholder="Shop Contact Details" value={printingRequest?.partner_id?.business_address} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='details'>
                  <h5>Request Details</h5>
                  <div className='row'>
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Date of Request</label>
                        <input type="text" className='form-control' placeholder="Date of Request" value={printingRequest?.created_date ? moment(printingRequest?.created_date).utcOffset(330).format("DD MMM YYYY") : "---"} />
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Due Date</label>
                        <input type="text" className='form-control' placeholder="Due Date" value={printingRequest?.due_date ? moment(printingRequest?.due_date).utcOffset(330).format("DD MMM YYYY") : "---"} />
                      </div>
                    </div>

                  </div>
                </div>
                <div className='details'>
                  <h5>Pricing and Payment</h5>
                  <div className='row'>
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Total Cost</label>
                        <input type="text" className='form-control' placeholder="Total Cost" value={printingRequest?.total_cost} />
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Payment Status</label>
                        <input type="text" className='form-control' placeholder="Payment Status" value={printingRequest?.order_status} />
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Payment Method</label>
                        <input type="text" className='form-control' placeholder="Payment Method" value={printingRequest?.payment_method} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='details'>
                  <h5>Order Status and Progress Tracking</h5>
                  <div className='row'>
                    <div className='col-xl-10 col-md-10 col-sm-10 mb-4'>
                      <div className="progress-tracker">
                        <div className="step completed">
                          <div className="circle">
                            <img className="imagetick" src={orderTrackLength >= 1 ? tick : ''} alt="tick" />
                          </div>
                          <div className="label"><strong>Order Received </strong></div>
                          {/* <div className="date">7 Aug 2024</div> */}
                          {orderTrackLength >= 1 &&  <div className="date">{printingRequest?.order_tracking_status[0]?.date}</div>  }
                        </div>

                        <div className={`line ${orderTrackLength >= 1 ? "completed" : ''}`}></div>


                        <div className={`step ${orderTrackLength >= 2 ? 'completed' : 'active'}`}>
                          <div className="circle">
                            {orderTrackLength >= 2 &&
                              <img className="imagetick" src={tick} alt="tick" />
                            }
                          </div>



                          <div className="label"> <strong>Processing</strong></div>
                          {/* <div className="date">9 Aug 2024</div> */}
                            {orderTrackLength >= 2 && <div className="date">{printingRequest?.order_tracking_status[1]?.date}</div>  }
                        </div>

                        <div className={`line ${orderTrackLength >= 3 ? "completed" : ''}`}></div>



                        <div className={`step ${orderTrackLength >= 3 ? 'completed' : (orderTrackLength === 2 ? 'active' : '')}`}>
                          <div className="circle">
                            {orderTrackLength >= 3 &&
                              <img className="imagetick" src={tick} alt="tick" />
                            }
                          </div>
                          <div className="label"> <strong>Packed</strong></div>
                          {orderTrackLength >= 3 && <div className="date">{printingRequest?.order_tracking_status[2]?.date}</div>  }

                        </div>

                        <div className={`line ${orderTrackLength >= 3 ? "completed" : ''}`}></div>

                        <div className={`step ${orderTrackLength >= 4 ? 'completed' : (orderTrackLength >= 3 ? 'active' : '')}`}>
                          <div className="circle">  {orderTrackLength >= 4 &&
                            <img className="imagetick" src={tick} alt="tick" />
                          }</div>
                          <div className="label"> <strong>Shipping</strong></div>
                          {orderTrackLength >= 4 && <div className="date">{printingRequest?.order_tracking_status[3]?.date}</div>  }
                        </div>

                        <div className={`line ${orderTrackLength >= 4 ? "completed" : ''}`}></div>

                        <div className={`step ${orderTrackLength >= 5 ? 'completed' : (orderTrackLength >= 4 ? 'active' : '')}`}>
                          <div className="circle">
                            {orderTrackLength >= 5 &&
                              <img className="imagetick" src={tick} alt="tick" />
                            }
                          </div>
                          <div className="label" > <strong>Delivered</strong></div>
                          {orderTrackLength >= 5 &&  <div className="date">{printingRequest?.order_tracking_status[4]?.date}</div>  }
                        </div>
                      </div>
                    </div>

                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Current Status</label>
                        <input type="text" className='form-control' placeholder="Current Status"
                          value={printingRequest?.order_tracking_status?.length > 0 ? (printingRequest?.order_tracking_status[printingRequest?.order_tracking_status?.length - 1]?.status) : "N/A"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='details'>
                  <h5>File Attachments</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group form-control d-flex justify-content-between align-items-center colorforfield'>
                        <span>File Attachments</span>
                          <a className='position-absolute end-0 top-0 bottom-0' href={printingRequest?.nft_id?.image_id?.image?.link} target={"_blank"}><i className="fa-light fa-eye"></i></a>
                      </div>
                    </div>

                  </div>
                </div> */}
                <div className='details'>
                  <h5>File Attachments</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group form-control d-flex justify-content-between align-items-center colorforfield'>
                        <span>{printingRequest?.nft_id?.image_id?.image?.file_name}</span>
                        {(image|| printableFormat)? (
                          <a
                            className='position-relative'
                            href={printableFormat? printableFormat:image}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="View attachment"
                          >
                            <i className="fa-light fa-eye" aria-hidden="true"></i>
                          </a>
                        ) : (
                          <span>No attachment available</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='details'>
                  <h5>Service Information</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        {/* <label>Canvas Sizes</label> */}

                        {printingRequest?.print_option === "Paper" &&
                          <div className="CanvasSize">
                            <label>Paper</label>
                            <ul className="mt-2">
                              {/* {printingRequest?.partner_id?.papers?.length > 0 ? printingRequest?.partner_id?.papers?.map((item, index) => */}
                              <li>{printingRequest?.canvas_type}</li>
                              {/* } */}

                            </ul>
                          </div>
                        }
                        {printingRequest?.print_option !== "Paper" &&
                          <div className="CanvasSize mt-0">
                            <label>Canvas</label>
                            <ul className="mt-2">
                              {printingRequest?.partner_id?.canvases?.length > 0 ? printingRequest?.partner_id?.canvases?.map((item, index) =>
                                <li>{item?.name}</li>) : "No Canvas Found."
                              }

                            </ul>
                          </div>
                        }
                        {/* <input type="text" className='form-control' placeholder="Overview of Services Offered" value={partner?.services_offered  || "N/A"} /> */}
                      </div>
                    </div>

                    {/* <div className='col-xl-12 col-md-12 col-sm-12 mb-4'>
                      <div className='form-group'>
                        <label>List of Products</label>
                        <ul className="product-list">
                        {partner?.products?.length > 0
                          ? partner.products.map((product, index) => (
                            <li key={index}>
                              {product.name} - ${product.price}
                            </li>
                          ))
                          : "N/A"}
                      </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  )

}
ViewPrint.propTypes = {
  toggle: PropTypes.func,
  getPrintingRequest: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any
}
const mapStateToProps = ({ Partners }) => ({
  error: Partners.error,
  loading: Partners.loading,
  printingRequest: Partners.printingRequest
});

const mapDispatchToProps = (dispatch) => ({
  // onGetCustomer: (id) => dispatch(getCustomer(id)), 
  getPrintingRequest: (data, callback) => dispatch(getPrintingRequest(data, callback))

});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPrint);