import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty, orderBy } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import toastr from "toastr"
//i18n
import { withTranslation } from "react-i18next";
import sortSvg from "../../../assets/images/svg/sort.svg";
// import onboard from "assets/images/svg/onboard.svg"
import printer from "assets/images/svg/print_image.svg"
import print_pending from "assets/images/svg/print_pending.svg"
import complete_print from "assets/images/svg/complete_print.svg"
import print_progress from "assets/images/svg/print_progress.svg"
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker;

import addprint from "assets/images/svg/addprinter.svg"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import { getCustomers, getCustomersSuccess, getOtherNotifications, lsitPrintingRequest } from "store/actions";
import UsersColumns, { selectRow } from "./Columns";

import { STORE_FIELD_NAME, STATUS_VALUE, TRIAL_VALUE } from "helpers/contants";
import ViewPrint from "./ViewPrint";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { sendVerificationMailRequest, sendVerificationMailSuccess } from "store/emailVerification/action";
import moment from 'moment';
const statusFilter = { fieldName: "status", fieldValue: { $ne: "temp" } };

// const data = viewDialog
const print = (props) => {
  const {
    accessLevel,
    history,
    totalRequests,
    requests,
    onGetPrintingRequest,
    loading,
    onSendEmail,
    pendingPrintrequests,
    progressPrintrequests,
    completedPrintrequests,
  } = props;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [clearAllBtnForSortBy, setClearAllBtnForSortBy] = useState(false)
  const [todaysDate, setTodaysDate] = useState('')
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [printingRequest, setPrintingRequest]= useState()

  const [viewPrint, setviewPrint] = useState({
    isOpen: false,
    userId: null,
  });

  const [filter, setFilter] = useState({
    orderBy: "created_date",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    order_status: "",
    starting_day: "1970-01-01",
    day: todaysDate,
    timeZone: timeZone,
    fields: [
      {
        fieldName: "status",
        fieldValue: "",
      },
    ],
  });



  const [lastFields, setLastFields] = useState({
    fieldName: "status",
    fieldValue: "",
  });

  const [searchText, setSearchText] = useState("");
  const [userList, setUsersList] = useState([]);
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalRequests,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    page: filter?.page
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    const getFormattedDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so +1
      const day = String(today.getDate()).padStart(2, '0');

      return `${year}-${day}-${month}`;
    };
    setTodaysDate(getFormattedDate())


  }, [])
  useEffect(()=>{console.log("printingRequest", printingRequest)}, [printingRequest])
  useEffect(() => {
    // getCustomersSuccess({ data: [], totalcount: 0 });
    onGetPrintingRequest({ ...filter, fields: [...filter.fields, lastFields] });
    // setUsersList(requests);
  }, [JSON.stringify(filter), lastFields.fieldValue]);

  useEffect(() => {
    setUsersList(requests || []);
  }, [requests]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {

    if (type === "search") {
      setSearchText(searchText);
    }
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };


  const toggleViewPrint = (userId) => {
    setviewPrint((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const onFieldNameChange = (value) => {
    setLastFields({ fieldName: value, fieldValue: "" });
    /* setFilter((prevState) => ({
      ...prevState,
      fields: [
        prevState?.fields[0],
        statusFilter,
        { fieldName: value, fieldValue: "" },
      ],
    })) */
  };
  useEffect(() => { setFilter((p) => ({ ...p, day: todaysDate, starting_day: '1970-01-01' })) }, [todaysDate])

  const onFieldValueChange = (value) => {
    let fieldName = lastFields.fieldName;
    let fieldValue = value;

    if (value !== "" && fieldName === "isTrial") {
      fieldName = "billingPlan";
    }

    if (value === "isTrial") {
      fieldName = "isTrial";
      fieldValue = true;
    }

    if (value == "isPaid") {
      fieldName = "isTrial";
      fieldValue = false;
    }

    setLastFields((prevState) => ({ ...prevState, fieldName, fieldValue }));

    /* setFilter((prevState) => ({
      ...prevState,
      fields: [
        prevState?.fields[0],
        statusFilter,
        { ...prevState?.fields[2], fieldValue },
      ],
    })) */
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText, page: 1 }));
  };
  const selectRole = (e) => {
    setFilter((prevState) => ({ ...prevState, order_status: e.target.value }));
  }
  const sortDateWise = (e) => {
    setClearAllBtnForSortBy(true)
    if (e.target.value === "Newest_first") {
      setFilter((prevState) => ({
        ...prevState,
        orderBy: "date_created",
        order: -1,
      }));
    }
    else {
      setFilter((prevState) => ({
        ...prevState,
        orderBy: "date_created",
        order: 1,
      }));
    }

  }

  const [dates, setDates] = useState([])
  const handleClear = () => {
  }
  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }
  useEffect(() => {
    setFilter((prevState) => ({ ...prevState, starting_day: dates[0], day: dates[1], page: 1 }))
  }, [dates])

  return (
    <React.Fragment>
      <ViewPrint  isOpen={viewPrint.isOpen} userId={viewPrint.userId} toggle={toggleViewPrint} onConfirm={''} />




      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Print Request"
            breadcrumbItem={props.t("Print Request")}
            breadcrumbItems={[{ title: props.t("Print Request") }]}
          />

          <Row>
            <Col xs="12">
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={userList || []}
                    columns={UsersColumns(
                      history,
                      toggleViewPrint,
                      props.t,
                      accesses,
                      setPrintingRequest
                    )}
                    bootstrap4
                    search
                  >

                    {(toolkitProps) => (
                      <React.Fragment>
                        {/* <div className="row mt-4">

                          <div className="col-xl-3 ">
                            <div className="printbox1">
                              <div className="imageicon"><img src={printer} /></div>
                              <h1 className="highlighthead">{totalRequests}</h1>
                              <p className="highlightP"> Total number of print requests</p>
                            </div>
                          </div>
                          <div className="col-xl-2 printCustomBox">
                            <div className="printbox2">
                              <div className="imageicon"> <img src={print_pending} /></div>
                              <h1 className="highlighthead">{pendingPrintrequests} </h1>
                              <p className="highlightP"> Pending </p>
                            </div>
                          </div>
                          <div className="col-xl-2 printCustomBox">
                            <div className="printbox3">
                              <div className="imageicon"> <img src={complete_print} /></div>
                              <h1 className="highlighthead">{completedPrintrequests} </h1>
                              <p className="highlightP"> Completed </p>


                            </div>
                          </div><div className="col-xl-2 printCustomBox">
                            <div className="printbox4">
                              <div className="imageicon"> <img src={print_progress} /></div>
                              <h1 className="highlighthead">{progressPrintrequests} </h1>
                              <p className="highlightP"> In Progress </p>
                            </div>
                          </div>

                        </div> */}

                        <div className="row mt-4">
                          <div className="col-lg-4 col-xl-3">
                            <div className="printbox1">
                              <div className="imageicon"><img src={printer} alt="Printer" /></div>
                              <h1 className="highlighthead">{totalRequests}</h1>
                              <p className="highlightP">Total number of print requests</p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-2 printCustomBox">
                            <div className="printbox2">
                              <div className="imageicon"><img src={print_pending} alt="Print Progress" /></div>
                              <h1 className="highlighthead">{pendingPrintrequests}</h1>
                              <p className="highlightP">In Progress</p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-2 printCustomBox">
                            <div className="printbox3">
                              <div className="imageicon"><img src={complete_print} alt="Complete Print" /></div>
                              <h1 className="highlighthead">{completedPrintrequests}</h1>
                              <p className="highlightP">Completed</p>
                            </div>
                          </div>
                          {/* <div className="col-lg-4 col-xl-3 printCustomBox">
                            <div className="printbox4">
                              <div className="imageicon"><img src={print_progress} alt="Print Progress" /></div>
                              <h1 className="highlighthead">{progressPrintrequests}</h1>
                              <p className="highlightP">In Progress</p>
                            </div>
                          </div> */}
                        </div>



                        <div className="row">
                          <div className="col-xl-12 mb-4 filterBox">
                            <div className="row">
                              <div className="col-xl-5">
                                <div className="form-group position-relative">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search")}
                                    searchText={searchText}
                                  />
                                  {/* <input type="text" className='form-control pe-5' placeholder='Search.....' />
                      <button className='btn position-absolute end-0 top-0 bottom-0 p-0 me-3'><i className="fa-light fa-magnifying-glass"></i></button> */}
                                </div>
                              </div>
                              {/* <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/filterIcon.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Filter
                                  </h3>
                                  <div className="form-group position-relative w-100">

                                    <select
                                      name=""
                                      className="form-control form-select"
                                      id=""

                                      onChange={selectRole}
                                    >
                                      <option value="" selected disabled>Select Status</option>
                                      <option value="Pending">Pending</option>
                                      <option value="Progress">In Progress</option>
                                      <option value="Completed">Completed</option>
                                    </select>
                                  </div>
                                </div>
                              </div> */}
                              <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="form-group position-relative w-100">
                                    <div className="range-date" style={{position:'relative'}}>
                                      <RangePicker className="custom-date-picker"
                                        onClear={handleClear}
                                        disabledDate={disabledDate}
                                        onChange={(values) => {
                                          if (values) {
                                            var dateStr1 = values[0].$d;
                                            var dateStr2 = values[1].$d;

                                            // Parse the date strings using Moment.js
                                            var date1 = moment(dateStr1);
                                            var date2 = moment(dateStr2);

                                            // Calculate the difference between the two dates
                                            var dateDifference = date2.diff(date1);
                                            var daysDifference = moment.duration(dateDifference).asDays();
                                            // if (daysDifference > 15) {
                                            //   return toastr.error("Date Range should be less than 15 days")
                                            // }
                                            // Display the difference in days
                                            var daysDifference = moment.duration(dateDifference).asDays();
                                            setDates(values.map(item => {
                                              return moment(item.$d).format('YYYY-DD-MM')
                                            }))
                                          }
                                          else {
                                            setFilter((prevState) => ({ ...prevState, starting_day: '1970-01-01', day: todaysDate }))
                                          }
                                        }} />
                    <img src="/images/svg/Vector.svg" width="auto" height="auto" classname="position-absolute" style={{right:"0.875rem", top:"0.7rem",position:"absolute"}}/>

                                    </div>

                                  </div>
                                </div>
                              </div>
                              {(!!lastFields.fieldValue ||
                                !!filter.search || !!filter.order_status || clearAllBtnForSortBy === true) && (
                                  <div className='col-xl-2'>
                                    <button onClick={(e) => {
                                      setClearAllBtnForSortBy(false)
                                      e.preventDefault();
                                      setFilter((prevState) => ({
                                        ...prevState,
                                        search: "",
                                        order_status: "",
                                        orderBy: ""
                                      }));
                                      setSearchText("");
                                      setLastFields({
                                        fieldName: "status",
                                        fieldValue: "",
                                      });
                                    }} className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}</button>
                                  </div>)}
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tableBox usertable">
                              <BootstrapTable
                                responsive
                                remote
                                bordered={false}
                                striped={false}
                                noDataIndication={"No data available"}
                                classes={"table  table-centered table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                defaultSorted={[
                                  {
                                    dataField: "created_at",
                                    order: "desc",
                                  },
                                ]}
                                sort={{
                                  sortCaret: (order) =>
                                    order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) : <span className="caret">
                                      {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                    </span>,
                                }}
                              />

                              {loading && (
                                <div className="spinnerBoxNew ">
                                  <Spinner color="primary" />
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

print.propTypes = {
  t: PropTypes.any,
  totalRequests: PropTypes.number,
  onGetPrintingRequest: PropTypes.func,
  pendingPrintrequests: PropTypes.number,
  progressPrintrequests: PropTypes.number,
  completedPrintrequests: PropTypes.number,
};

const mapStateToProps = ({ Partners }) => ({
  loading: Partners.loading,
  requests: Partners.printingRequests,
  totalRequests: Partners.totalPrintrequests,
  pendingPrintrequests: Partners.pendingPrintrequests,
  progressPrintrequests: Partners.progressPrintrequests,
  completedPrintrequests: Partners.completedPrintrequests
});

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPrintingRequest: (data, callback) => dispatch(lsitPrintingRequest(data, callback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(print))
);
