export const ITEM_API_FAIL = "ITEM_API_FAIL";

// ITEMs
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";

// Add ITEM
export const ADD_ITEM = "ADD_ITEM";
export const ADD_ITEM_FAIL = "ADD_ITEM_FAIL";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";

// Get ITEM
export const GET_ITEM = "GET_ITEM";
export const GET_ITEM_FAIL = "GET_ITEM_FAIL";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";

// update ITEM
export const PUT_ITEM = "PUT_ITEM";
export const PUT_ITEM_FAIL = "PUT_ITEM_FAIL";
export const PUT_ITEM_SUCCESS = "PUT_ITEM_SUCCESS";

// Delete ITEM
export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

// Update Multi ITEM Status
export const PUT_ITEMS_STATUS = "PUT_ITEMS_STATUS";
export const PUT_ITEMS_STATUS_FAIL = "PUT_ITEMS_STATUS_FAIL";
export const PUT_ITEMS_STATUS_SUCCESS = "PUT_ITEMS_STATUS_SUCCESS";

// Get Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";


// OTHER NOTIFICATION 
export const GET_OTHER_NOTIFICATIONS = "GET_OTHER_NOTIFICATIONS";
export const GET_OTHER_NOTIFICATIONS_FAIL = "GET_OTHER_NOTIFICATIONS_FAIL";
export const GET_OTHER_NOTIFICATIONS_SUCCESS = "GET_OTHER_NOTIFICATIONS_SUCCESS";


// MARK OTHER NOTIIFCATION


export const MARK_GET_OTHER_NOTIFICATIONS = "MARK_GET_OTHER_NOTIFICATIONS";
export const MARK_GET_OTHER_NOTIFICATIONS_FAIL = "MARK_GET_OTHER_NOTIFICATIONS_FAIL";
export const MARK_GET_OTHER_NOTIFICATIONS_SUCCESS = "MARK_GET_OTHER_NOTIFICATIONS_SUCCESS";


// GET PARTICULAR SELLITEM


export const GET_PARTICULAR_SELLITEM = "GET_PARTICULAR_SELLITEM";
export const GET_PARTICULAR_SELLITEM_FAIL = "GET_PARTICULAR_SELLITEM_FAIL";
export const GET_PARTICULAR_SELLITEM_SUCCESS = "GET_PARTICULAR_SELLITEM_SUCCESS";