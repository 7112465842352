import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import Instructions from "./Instructions";
import { isObject } from "lodash";
import toastr from "toastr";
import { uploadFile as onUploadFile } from "store/actions";
import logo_placeholder from "assets/images/logo-placeholder.png";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const HomePageSettings = (props) => {
  const { fields, handleChange } = props;
  const dispatch = useDispatch();
  const [logoselectedFiles, setlogoselectedFiles] = useState([]);

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;
    handleChange(name)({ target: { value: _id } });
  };

  const handleAcceptedFiles = (name) => (_files) => {
    const files = _files?.filter((file) => file.size < 50242880);
    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }
    dispatch(onUploadFile({ image: files[0] }, uploadFileSuccess(name)));
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    if (name === "image") {
      setlogoselectedFiles(files);
    }
  };

  useEffect(() => {
    if (isObject(fields?.image) && !!fields?.image?.link) {
      setlogoselectedFiles([{ preview: fields?.image?.link }]);
    }
  }, [JSON.stringify(fields?.image)]);

  return (
    <React.Fragment>
      <Row>
      <Col md={12}>
      <div className="categories_div">
          <FormGroup>
            <Label> Home Page Image </Label>
            <div className="uploadImageMaine mb-0">
                <div className="uploadImgNew full widthimg w-100 position-relative start-0 top-0">
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles("image")(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => {
                const imageFile = logoselectedFiles[0];

                return (
                  <div
                    className="dropzone-single-image h-100 avatar-xl border categoryUploadImage  video-setting"
                    {...getRootProps()}
                  >
                    <input
                      {...getInputProps()}
                      id="formrow-profile-image-Input"
                      multiple={false}
                    />

                    {(imageFile?.path &&
                      imageFile?.path?.search(".mp4") !== -1) ||
                    imageFile?.preview?.search(".mp4") !== -1 ? (
                      <video
                        className="rounded avatar-xl uploadlogogbox video-inp"
                        controls
                        autoplay="autoplay"
                        muted
                        src={
                          imageFile?.preview || !!imageFile
                            ? imageFile.preview
                            : logo_placeholder
                        }
                      >
                        <source
                          src={
                            imageFile?.preview || !!imageFile
                              ? imageFile.preview
                              : logo_placeholder
                          }
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <img
                        className="rounded avatar-xl video-inp uploadlogogbox img-fluid"
                        alt={
                          !!imageFile && imageFile.name ? imageFile.name : "hlc"
                        }
                        src={
                          fields?.image?.link || !!imageFile
                            ? imageFile.preview
                            : logo_placeholder
                        }
                      />
                    )}

                    <div className="edit" hidden={imageFile?.path ? true : false}>
                            <i className="bx bx-pencil"></i>
                          </div>
                  </div>
                );
              }}
            </Dropzone>
            <small class="fw-bold  form-text text-muted">Max. upload file size: 5MB</small>
            </div>
            </div>

          
          </FormGroup>
          </div>
        </Col>
        <Col md={12}>
          <FormGroup>
            <Label>Top Heading</Label>

            <Input
              type="text"
              value={fields.subHeading}
              onChange={handleChange("subHeading")}
            />
          </FormGroup>
        </Col>

        <Col md={12}>
          <FormGroup>
            <Label>Description</Label>

            <Input
              type="textarea"
              value={fields.description}
              onChange={handleChange("description")}
              rows={4}
            />
          </FormGroup>
        </Col>

       
      </Row>
    </React.Fragment>
  );
};

HomePageSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePageSettings)
);
