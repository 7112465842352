import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleviewCollection, accesses, t) => [
  {
    dataField: "name",
    text: t("Name"),
    sort: true,
  },

  // {
  //   dataField: "createdby",
  //   text: t("Created by"),
  // },
  {
    dataField: "email.name",
    text: t("Created by"),
    sort: true,
    // formatter: (_, row) => (
    //   <Link to={`/users/profile/${row?.author?._id}`}>
    //     {row?.author?.username}
    //   </Link>
    // ),
  },
  // {
  //   dataField: "category",
  //   text: t("Category"),
  // },
  {
    dataField: "totalNfts",
    text: t("No. of NFT's"),
    sort: true,
  },

 

  // {
  //   dataField: "name",
  //   text: t("Banner"),
  // },
  // {
  //   dataField: "name",
  //   text: t("Royalties"),
  // },
  {
    text: t("created_at"),
    dataField: "createdAt",
    sort: true,
    formatter: (_, row) => moment(row.created_at).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row,i) => (
      <>
       <button onClick={() => toggleviewCollection(row._id)} className='btn border-0 p-0'>
       <i className="fa-light fa-eye py-2"  id={`view-${i}-tooltip`}></i>
       <UncontrolledTooltip
            placement="top"
            target={`view-${i}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
       </button>
        {/* <Link
          to={`/collections/${row._id}/edit`}
          className="mr-3 text-secondary"
        >
          <i className="far fa-edit mr-3" id={`edit-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`edit-${row._id}-tooltip`}
          >
            {t("edit")}
          </UncontrolledTooltip>
        </Link>
        {accesses?.canDelete && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault()
              toggleConfirmModal(row._id)
            }}
            className="text-secondary"
          >
            <i
              className="fas fa-trash-alt mr-3"
              id={`delete-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link>
        )} */}
        {/* <Link
          to={`/collections/view/${row._id}`}
          className="mr-3 text-secondary"
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            View
          </UncontrolledTooltip>
        </Link> */}
      </>
    ),
  },
];

export default ListColumns;