import {
  GET_SENDEMAIL,
  GET_SENDEMAIL_FAIL,
  GET_SENDEMAIL_SUCCESS,
  ADD_EMAIL,
  ADD_EMAIL_FAIL,
  ADD_EMAIL_SUCCESS,
  GET_EMAIL_BY_ID,
  GET_EMAIL_BY_ID_SUCCESS,
  GET_EMAIL_BY_ID_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  emails: [],
  totalEmails: 0,
  error: "",
  loading: false,
  email: {},
};

const SendEmailReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SENDEMAIL:
    case GET_EMAIL_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_SENDEMAIL_SUCCESS:
      return {
        ...state,
        emails: action.payload.data || [],
        totalEmails: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

      case GET_EMAIL_BY_ID_SUCCESS:
        return {
          ...state,
          email: action.payload.data || {},
          loading: false,
          error: "",
        };
    case GET_SENDEMAIL_FAIL:
    case GET_EMAIL_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_EMAIL:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case ADD_EMAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_EMAIL_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    default:
      return state;
  }
};

export default SendEmailReducer;
