import { sendOtherNotificationApi } from "helpers/backend_helper";
import { OTHER_NOTIFICATION_REQUEST } from "./actionTypes";
import { otherNotificationFail } from "./actions";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
function* sendOtherNotification({ payload: { data, callback } }) {
    try {
      console.log("sendOtherNotificationbody", data)
      const response = yield call(sendOtherNotificationApi, data);
      console.log("sendOtherNotificationres", response)
      if (response.status == "failure") {
        return yield put(otherNotificationFail(response.message));
      }
      callback && callback(response);
  
      yield put(otherNotificationSuccess());
    } catch (error) {
      yield put(otherNotificationFail("Internal Error!"));
    }
  }
  
  function* SendOtherNotificationSaga() {
    yield takeEvery(OTHER_NOTIFICATION_REQUEST, sendOtherNotification)
  }
  
  export default SendOtherNotificationSaga;