import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (history, toggleConfirmModal, toggleViewrequest, t, accesses, filter) => [

  {
    dataField: "name",
    text: t("Name"),
    sort: true,
  },
  {
    dataField: "email",
    text: t("Email"),
    sort: true,
    formatter: (cellContent, row) => <>{row?.email || "---"}</>,
  },
  // {
  //   dataField: "status",
  //   text: t("Status"),
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         "text-capitalize font-size-13 badge-soft-" +
  //         (row?.status == "active"
  //           ? "success"
  //           : row?.status == "inactive"
  //           ? "danger"
  //           : "warning")
  //       }
  //     >
  //       {t(row?.status)}
  //     </Badge>
  //   ),
  // },
  {
    dataField: "created_at",
    text: t("Registered At "),
    sort: true,
    formatter: (_, row) => ( row?.created_at ? moment(row.created_at).format("DD MMM YYYY hh:mm A "):"---"),
  },
  {
    text: t("User"),
    dataField: "role",
    sort: true,
    formatter: (_, row) => (
      <div className={row.role === 'MUSEUM' ? 'museums' : row.role === 'ARTIST' ? 'artist' : ''}>{row.role}</div>
    )
  },
  {
    text: t("Status"),
    dataField: "verificationProfileStatus",
    sort: true,
    formatter: (_, row) => (
      <><div  className={row.verificationProfileStatus === 'requested' ? 'pending' : row.verificationProfileStatus === 'accept' ? 'verify' : row.verificationProfileStatus === 'reject' ? 'reject' : ''} >
      {row.verificationProfileStatus === 'requested' ? 'Pending' : row.verificationProfileStatus === "accept" ? 'Verified' : row.verificationProfileStatus === "reject" ? "Rejected" : "---"} </div></>
    ),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <button onClick={(e) => toggleViewrequest(row._id)} className='btn p-0'><i className="fa-light fa-eye"></i></button>
        {/* <Link to={`/request/view/${row._id}`} className="mr-3 text-secondary">
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link> */}

        {/* <Link
          to={`/verification-request/view?type=${filter?.type}&id=${row._id}`}
          className="mr-3 text-secondary"
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link> */}
      </>
    ),
  },
];

export default CustomersColumns;
