import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),

  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "subject",
    text: t("Subject"),
    sort:true
  },
  {
    dataField: "title",
    text: t("Title"),
    sort:true,
    formatter: (cellContent, row) => <>{(row && row?.title?.length>15)? row?.title?.substring(0, 30) + "..." :  row?.title  }</>
  },
  {
    dataField: "description",
    text: t("Description"),
    sort:true,
    formatter: (cellContent, row) => <>{( row && row?.description?.length>15)? row?.description?.substring(0, 30) + "..." :  row?.description  }</>
   
  },


  // {
  //   dataField: "status",
  //   text: t("Status"),
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         "text-capitalize font-size-13 badge-soft-" +
  //         (row.status == "active"
  //           ? "success"
  //           : row.status == "inactive"
  //           ? "warning"
  //           : "danger")
  //       }
  //       color={row.badgeClass}
  //     >
  //       {t(row.status)}
  //     </Badge>
  //   ),
  // },
  {
    text: t("Created At"),
    dataField: "date_created",
    sort: true,
    formatter: (_, row) => moment(row.date_created).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        {accesses?.canEdit ? (
          <Link
   
            to={`/email/add/${row._id}/view`}
            className="mr-3 text-secondary"
          >
            <i className="far fa-eye mr-3" id={`edit-${row._id}-tooltip`} />
            <UncontrolledTooltip
              placement="top"
              target={`edit-${row._id}-tooltip`}
            >
              {t("edit")}
            </UncontrolledTooltip>
          </Link>
        ) : (
          ""
        )}

        {/* {accesses?.canDelete && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal(row._id);
            }}
            className="text-secondary"
          >
            <i
              className="fas fa-trash-alt mr-3"
              id={`delete-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link>
        )} */}
      </>
    ),
  },
];

export default ListColumns;
