import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import viewuser from "../../assets/css/viewuser.module.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
} from "reactstrap";

// Actions
import { getSettings, putSettings } from "store/actions";



import toastr from "toastr";

const PrintingPartner = (props) => {
  const location = useLocation();
  const { from } = location;
  const [pageName, setPageName] = useState();
  useEffect(() => {
    setPageName(location.state);
    // console.log(location);
  }, [location.state]);
  const { onGetSettings, onPutSettings, loading, error } = props;
  const [submitInfo, setSubmitInfo] = useState(null);

  const fileLoading = useSelector((state) => state?.File);



  const [fields, setFields] = useState({
    name: props.settings.name,
    logo: props.settings.logo,
    adminCommission: props.settings.adminCommission,
    isCommingSoon: false,
    homePageBannerSetting: {
      topHeading: "",
      subHeading: "",
      description: "",
      item: "",
      image: "",
    },

    homeNftDescription: {
      topHeading: "",
      description: "",
      image: "",
    },

    faqData: {
      video: "",
      image: "",

      artistvideo: "",
      artistImage: "",

      buyerTittle: "",
      artistTittle: "",
    },

    registerImages: {
      loginRegisterImag: "",
      museumRegisImage: "",
      artistRegisImage: "",
      userRegisImage: "",
    },

    AboutSetting: {
      topHeading: "",
      image: "",
    },

    footerSetting: {
      description: "",
    },
    socialMediaSetting: {
      facebook: "",
      twitter: "",
      youtube: "",
      vimeo: "",
      telegram: "",
      instagram: "",
    },
    supportSetting: {
      email: "",
      mobileNumber: "",
      address: "",
    },
    museumsSetting: {
      image: props?.settings?.image,
      topHeading: "",
      description: "",
    },

    googleCaptchaKey: {
      captchaKey: "",
    },
  });

  useEffect(() => {
    onGetSettings();
  }, []);
  useEffect(()=>{
    // console.log("fields?.faqData", fields?.faqData)
  },[fields?.faqData])
  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        name: props.settings.name,
        logo: props.settings.logo,
        adminCommission: props.settings.adminCommission,
        isCommingSoon: props.settings.isCommingSoon,
        homePageBannerSetting: {
          topHeading: "",
          subHeading: "",
          image: "",
          description: "",
          ...(props.settings.homePageBannerSetting || {}),
          item: props.settings.homePageBannerSetting?.item
            ? {
                label: props.settings.homePageBannerSetting.item.name,
                value: props.settings.homePageBannerSetting.item._id,
              }
            : null,
        },

        homeNftDescription: props.settings.homeNftDescription || {
          topHeading: "",
          description: "",
          image: "",
        },

        faqData: props.settings.faqData || {
          video: "",
          image: "",

          artistvideo: "",
          artistImage: "",

          buyerTittle: "",
          artistTittle: "",
        },

        AboutSetting: props.settings.AboutSetting || {
          topHeading: "",
          image: "",
        },

        registerImages: props.settings.registerImages || {
          museumRegisImage: "",
          artistRegisImage: "",
          userRegisImage: "",
          loginRegisterImag: "",
        },

        footerSetting: props.settings.footerSetting || {
          description: "",
        },
        socialMediaSetting: props.settings.socialMediaSetting || {
          facebook: "",
          twitter: "",
          instagram: "",
          linkedin: "",
          discord: "",
        },
        supportSetting: props.settings.supportSetting || {
          email: "",
          mobileNumber: "",
          address: "",
        },
        museumsSetting: props.settings.museumsSetting || {
          image: props.settings.image,
          topHeading: "",
          description: "",
        },

        googleCaptchaKey: props.settings.googleCaptchaKey || {
          captchaKey: "",
        },
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handleInnerChange = (name) => (name2) => (event) => {
    const { value } = event.target;

    setFields((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], [name2]: value },
    }));
  };

  function submit() {
    let data = {
      _id: props.settings?._id,
      adminCommission: props.settings.adminCommission,
      name: fields?.name,
      logo: fields?.logo._id || fields?.logo,
      isCommingSoon: fields?.isCommingSoon,
      homePageBannerSetting: {
        ...fields?.homePageBannerSetting,
        image:
          fields?.homePageBannerSetting?.image._id ||
          fields?.homePageBannerSetting?.image,
      },

      homeNftDescription: {
        ...fields?.homeNftDescription,
        image:
          fields?.homeNftDescription?.image?._id ||
          fields?.homeNftDescription?.image,
      },

      faqData: {
        ...fields?.faqData,
        video: fields?.faqData?.video?._id || fields?.faqData?.video,
        image: fields?.faqData?.image?._id || fields?.faqData?.image,

        artistvideo:
          fields?.faqData?.artistvideo?._id || fields?.faqData?.artistvideo,
        artistImage:
          fields?.faqData?.artistImage?._id || fields?.faqData?.artistImage,
      },

      registerImages: {
        ...fields?.registerImages,
        museumRegisImage:
          fields?.registerImages?.museumRegisImage?._id ||
          fields?.registerImages?.museumRegisImage,

        artistRegisImage:
          fields?.registerImages?.artistRegisImage?._id ||
          fields?.registerImages?.artistRegisImage,

        userRegisImage:
          fields?.registerImages?.userRegisImage?._id ||
          fields?.registerImages?.userRegisImage,

        loginRegisterImag:
          fields?.registerImages?.loginRegisterImag?._id ||
          fields?.registerImages?.loginRegisterImag,
      },

      AboutSetting: {
        ...fields?.AboutSetting,
        image: fields?.AboutSetting?.image?._id || fields?.AboutSetting?.image,
      },

      footerSetting: {
        ...fields?.footerSetting,
      },

      googleCaptchaKey: {
        ...fields?.googleCaptchaKey,
      },

      socialMediaSetting: {
        ...fields?.socialMediaSetting,
      },

      supportSetting: {
        ...fields?.supportSetting,
      },

      museumsSetting: {
        ...fields?.museumsSetting,
        image:
          fields?.museumsSetting?.image?._id || fields?.museumsSetting?.image,
      },
    };
    onPutSettings(data);
    toastr.success("Updated");
  }

  return (
    <div className="page-content">
      <Container fluid>
        {/* <Breadcrumbs
          title="General Settings"
          breadcrumbItem="General Settings"
        /> */}
        <Row>

          <div className="col-xl-10 my-auto">
          <ul class="breadcrumbnew">
          <li class={"breadcrumb-item " + (pageName == "General" ? 'active':'')}><Link to={{pathname:"/general", state: "General" }}>General Settings</Link></li>
          {pageName !== "General" &&   <li class="active breadcrumb-item" aria-current="page">
          {pageName == "pageSetting" ? 'Home Page Settings':pageName == "Description" ? 'Nft Description Settings':pageName == "Faqvideos" ? 'Faq Video Settings':pageName == "Media" ? 'Social Media Settings':pageName == "Support" ? 'Support Settings': pageName == "Museums" ? 'Museums Settings':pageName == "About" ? 'About Settings':pageName == "Footer"?'Footer Settings':pageName == "Registerimages" ? 'Register Images':pageName == "Captcha" ? 'Captcha Key Settings':''} 
          </li>}
        
          </ul>
     
          </div>
        </Row>
        <Row>
          <Col lg={12}>
            <div
              className={
                "addbox position-relative mt-4 " + viewuser.viewProfile
              }
            >
              {/* <Card>
              <CardBody className="spinner-content"> */}
              {error && typeof error === "string" ? (
                <Alert color="danger">{error}</Alert>
              ) : null}
              {loading && 
                <div className="spinnerBoxNew">
                    <Spinner color="primary" />
                  </div>}
              <HomePageHideShow fields={fields} handleChange={handleChange} />
              <hr className="my-3" />
              {pageName == "General" && (
                <GeneralSettings fields={fields} handleChange={handleChange} />
              )}

              {pageName == "pageSetting" && (
                <HomePageSettings
                  fields={fields.homePageBannerSetting}
                  handleChange={handleInnerChange("homePageBannerSetting")}
                />
              )}
              {pageName == "Description" && (
                <HomePageNftDescription
                  fields={fields.homeNftDescription}
                  handleChange={handleInnerChange("homeNftDescription")}
                />
              )}
             
              {pageName == "Faqvideos" && (
               
            
                <FaqVideoSetting
                  fields={fields?.faqData}
                  handleChange={handleInnerChange("faqData")}
                />
                
              )}
              {pageName == "Media" && (
                <SocialMediaSettings
                  fields={fields.socialMediaSetting}
                  handleChange={handleInnerChange("socialMediaSetting")}
                />
              )}
              {pageName == "Support" && (
                <SupportSettings
                  fields={fields.supportSetting}
                  handleChange={handleInnerChange("supportSetting")}
                />
              )}
              {pageName == "Museums" && (
                <MuseumSettings
                  fields={fields.museumsSetting}
                  handleChange={handleInnerChange("museumsSetting")}
                />
              )}
              {pageName == "About" && (
                <AboutSetting
                  fields={fields.AboutSetting}
                  handleChange={handleInnerChange("AboutSetting")}
                />
              )}
              {pageName == "Footer" && (
                <FooterSettings
                  fields={fields.footerSetting}
                  handleChange={handleInnerChange("footerSetting")}
                />
              )}
              {pageName == "Registerimages" && (
                <RegisterImagesSetting
                  fields={fields.registerImages}
                  handleChange={handleInnerChange("registerImages")}
                />
              )}
              {pageName == "Captcha" && (
                <ClientCaptchKey
                  fields={fields.googleCaptchaKey}
                  handleChange={handleInnerChange("googleCaptchaKey")}
                />
              )}

              <Row>
                <Col lg={12} className="d-flex justify-content-start">
                  {loading && <Spinner color="primary" className="mr-2" />}

                  {fileLoading?.loading ? (
                    <Button className="buttoncolor">
                      <Spinner /> laoding...
                    </Button>
                  ) : (
                    <button
                      disabled={fileLoading?.loading ? true : false}
                      className="btn verifybtn px-5 text-white"
                      onClick={submit}
                    >
                      Save Changes
                    </button>
                  )}
                </Col>
              </Row>
            </div>
            <div className="mt-2">
              {submitInfo && <Alert>{submitInfo}</Alert>}
            </div>
            {/* </CardBody>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

PrintingPartner.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onGetSettings: PropTypes.func,
  onPutSettings: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings?.settings,
  loading: Settings?.loading,
  error: Settings?.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrintingPartner)
);
