import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "reactstrap";
import toastr from "toastr";
import authStyle from "../../assets/css/auth.module.css";
import loginImg from "../../assets/images/svg/loginImg.svg";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import logodark from "../../assets/images/logoopen.png";
import logodark2x from "../../assets/images/logoopen.png";
import logoheader from "../../assets/images/logo.png";
import logoheader2x from "../../assets/images/logoopen.png";

// actions
import { loginUser, apiError } from "store/actions";

// import images
// import logo from "assets/images/logo.png";

const Login = (props) => {
  const [passwordtype, setPasswordType] = useState('password')
  const { loading } = props;

  const [fields, setfields] = useState({ email: "", password: "" });
  let didInit = false;


  useEffect(() => {
    if (!didInit) {
      didInit = true;
      localStorage.clear();
    }
  }, []);
  const showpassword =()=>{
    if(passwordtype==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  useEffect(() => {
    props.apiError();
  }, []);

  const handleChange = (name) => (e) => {
    const { value } = e.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    props.loginUser(fields, props.history);
    // console.log("admin login",props);
  //    if((props.error && typeof props.error === "string") || props.error == undefined){
  
  //    return toastr.error(props.error)
  // }
  };
 

  return (
    <>
    <div className="container-fluid" >
      <div className="row">
        <div className="col-xl-8 px-0">
          <img
            src={loginImg}
            className={authStyle.loginImgStyle}
            alt="Login" />
        </div>
        <div className={"col-xl-4 " + (authStyle.authRight)}>
          <div className={authStyle.loginInner}>
            <div className={authStyle.logobx}>
              <Link href="/" className="navbar-brand mx-auto py-0">
                <div className="d-flex align-items-center justify-content-start position-relative gap-3">
                  <>
                    <img
                      alt="logo"
                      className="img-fluid logodesktop"
                      src={"/images/logoNew.png"} width={250}
                   />
                  </>

                  {/* <div className={authStyle.logohead}>
                    Terpnash
                  </div> */}
                </div>
              </Link>
            </div>
            <form  id="loginform"
            onSubmit={() => handleSubmit()}>
           

            {/* {props.error && typeof props.error === "string" ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null} */}

              <div className={"text-center mt-4 " + (authStyle.loginpanal)}>
                <h1>Welcome Back</h1>
                <p>Please enter your details.</p>
                <div className="row mt-4">
                  <div className="col-xl-12 mb-3">
                    <div className="form-group position-relative ">
                      {/* <Label htmlFor="exampleEmail">Email address</Label> */}
                      <input name="email" type="text"  value={fields?.email}
                    onChange={handleChange("email")}
                        className={"form-control " + (authStyle.loginimp)} autoComplete={'false'}
                        placeholder="Enter Your Email" />
                      <div className="btn position-absolute top-0 start-0  border-0 bottom-0 h-min-content my-auto ">
                        <i className="fa-sharp fa-regular fa-envelope-open"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="password-divmain form-group position-relative">
                      {/* <div className="password-div">
      <Label htmlFor="examplePassword">Password</Label>
    </div> */}
                      <input name="password"
                        type={passwordtype}  value={fields?.password}
                    onChange={handleChange("password")}
                        className={"form-control pe-5 " + (authStyle.loginimp)} autoComplete={'false'}
                        placeholder="Enter your Password" />
                      <div className="btn position-absolute h-min-content top-0 start-0 bottom-0 border-0 my-auto ">
                        <i className="fa-regular fa-lock-keyhole"></i>
                      </div>
                      <button
                        type="button"
                        onClick={showpassword}
                        className="btn position-absolute top-0 end-0 bottom-0 my-auto border-0 h-min-content"
                      >
                        {passwordtype === "password" ? (
                          <i className="fa-light fa-eye"></i>
                        ) : (
                          <i className="fa-light fa-eye-slash"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {/* <p className={" text-end w-auto mt-1 " + (authStyle.login_forgot)}>
      <Link  href="/forgot-password">
        Forgot Password?
      </Link>
    </p> */}
                <div className="text-center Done-wrap mt-5">

                  <button  id="nextBtn"
                  onClick={handleSubmit} type="submit" className=" btn btn-primary-v text-white w-100">
                    Login   
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div><section id="login" hidden >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sign-in-top">
                <img src={logodark} alt="" className="img-fluid registerlogo" />

                {/* <div id="site-logo" className="clearfix">
      <div id="site-logo-inner">
        <Link to="/" rel="home" className="main-logo">
          <img
            className="logo-dark"
            id="logo_header"
            src={logodark}
            srcSet={`${logodark2x}`}
            alt="nft-gaming"
          />
          <img
            className="logo-light"
            id="logo_header"
            src={logoheader}
            srcSet={`${logoheader2x}`}
            alt="nft-gaming"
          />
        </Link>
      </div>
    </div>  */}
              </div>
            </div>
          </div>

          <form
            id="loginform"
            onSubmit={() => handleSubmit()}
            className="spinner-content position-relative"
          >
            {loading && (
              <div className="spinner">
                <Spinner color="primary" />
              </div>
            )}

            {props.error && typeof props.error === "string" ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}

            <div className="row">
              <div className="col-lg-12">
                <h1>Sign in</h1>
              </div>

              <div className="col-lg-12">
                <div className="input-group">
                  <label>Your email</label>
                  <input
                    type="text"
                    placeholder="Your email"
                    value={fields?.email}
                    onChange={handleChange("email")} />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="input-group">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    value={fields?.password}
                    onChange={handleChange("password")} />
                </div>

                {/* <div className="forgor-password">
      <Link to="/forgot-password" className="colortextlink">
        Forgot Password?
      </Link>
    </div> */}
              </div>

              <div className="col-lg-12">
                <button
                  type="submit"
                  className="themebtn fullwidthbtn"
                  id="nextBtn"
                  onClick={handleSubmit}
                >
                  Sign in
                </button>
              </div>
            </div>
          </form>

          {/* <div className="col-lg-12">
      <div className="reglink">
        <Link to="/signup" className="colortextlink">
          Don't have an account?
        </Link>
      </div>
    </div> */}
        </div>
      </section>
      </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
