import { SEND_VERIFICATION_EMAIL_FAIL, SEND_VERIFICATION_EMAIL_REQUEST, SEND_VERIFICATION_EMAIL_SUCCESS } from "./actionTypes";

export const sendVerificationMailRequest = (data, callback) => ({
    type: SEND_VERIFICATION_EMAIL_REQUEST,
    payload: { data, callback },
});

export const sendVerificationMailFail = (error) => ({
    type: SEND_VERIFICATION_EMAIL_FAIL,
    payload: error,
});

export const sendVerificationMailSuccess = (data) => ({
    type: SEND_VERIFICATION_EMAIL_SUCCESS,
    payload: data,
});