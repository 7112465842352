const { ADD_PRINTING_PARTTNER, ADD_PRINTING_PARTTNER_FAIL, ADD_PRINTING_PARTTNER_SUCCESS, LIST_PRINTING_PARTTNER, LIST_PRINTING_PARTTNER_FAIL, LIST_PRINTING_PARTTNER_SUCCESS, EDIT_PRINTING_PARTTNER, EDIT_PRINTING_PARTTNER_FAIL, EDIT_PRINTING_PARTTNER_SUCCESS, GET_PRINTING_PARTTNER, GET_PRINTING_PARTTNER_FAIL, GET_PRINTING_PARTTNER_SUCCESS, DELETE_PRINTING_PARTTNER, DELETE_PRINTING_PARTTNER_FAIL, DELETE_PRINTING_PARTTNER_SUCCESS, LIST_PRINTING_REQUEST_FAIL, LIST_PRINTING_REQUEST_SUCCESS, LIST_PRINTING_REQUEST, GET_PRINTING_REQUEST, GET_PRINTING_REQUEST_FAIL, GET_PRINTING_REQUEST_SUCCESS, GET_PRINTING_PAYMENT_REQUEST, GET_PRINTING_PAYMENT_REQUEST_FAIL, GET_PRINTING_PAYMENT_REQUEST_SUCCESS, GET_PRINTING_WITHDRAWL_REQUEST, GET_PRINTING_WITHDRAWL_REQUEST_FAIL, GET_PRINTING_WITHDRAWL_REQUEST_SUCCESS, VIEW_PRINTING_WITHDRAWL_REQUEST, VIEW_PRINTING_WITHDRAWL_REQUEST_FAIL, VIEW_PRINTING_WITHDRAWL_REQUEST_SUCCESS, UPDATE_PRINTING_WITHDRAWL_REQUEST, UPDATE_PRINTING_WITHDRAWL_REQUEST_FAIL, UPDATE_PRINTING_WITHDRAWL_REQUEST_SUCCESS, GET_PRINTING_CSV_DATA, GET_PRINTING_CSV_DATA_FAIL, GET_PRINTING_CSV_DATA_SUCCESS } = require("./actionTypes")

const INIT_STATE = {
  partners: [],
  partner: {},
  printingRequests: [],
  error: "",
  loading: false,
  totalPartners: 0,
  recentOnboardingCount: 0,
  totalPrintrequests: 0,
  pendingPrintrequests: 0,
  progressPrintrequests: 0,
  completedPrintrequests: 0,
  printingRequest: {},
  printingPaymnetRequests: [],
  withdrawlRequestList: [],
  totalWithdrawlCount: 0,
  totalPrintingPayment: 0,
  totalAdminEarning: 0,
  quarterlyEarnings: 0,
  monthlyEarnings: 0,
  yearlyEarnings: 0,
  viewWithdrawlRequest: {},
  prinitngCsvData: [],
}



const PrintingPartners = (state = INIT_STATE, action) => {

  switch (action.type) {
    /* Add Partner SK*/

    case ADD_PRINTING_PARTTNER:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case ADD_PRINTING_PARTTNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_PRINTING_PARTTNER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Add Partner end  SK*/


    /* list Partners SK*/

    case LIST_PRINTING_PARTTNER:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case LIST_PRINTING_PARTTNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case LIST_PRINTING_PARTTNER_SUCCESS:
      console.log("actionfasdfdf", action)
      return {
        ...state,
        error: "",
        loading: false,
        partners: action.payload.data || [],
        totalPartners: action.payload.totalCount || 0,
        recentOnboardingCount: action.payload.onboarding || 0
      }
    /* list Partners end  SK*/

    /* edit Partners SK*/
    case EDIT_PRINTING_PARTTNER:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case EDIT_PRINTING_PARTTNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case EDIT_PRINTING_PARTTNER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false
      }
    /* edit Partners end  SK*/
    /* get Partners SK*/
    case GET_PRINTING_PARTTNER:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case GET_PRINTING_PARTTNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PRINTING_PARTTNER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        partner: action.payload.data
      }
    /* get Partners end  SK*/

    /* delete Partners SK*/
    case DELETE_PRINTING_PARTTNER:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_PRINTING_PARTTNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PRINTING_PARTTNER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* delete Partners end  SK*/
    /* listing printer  */
    case LIST_PRINTING_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case LIST_PRINTING_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case LIST_PRINTING_REQUEST_SUCCESS:
      console.log("fdadsfasdfgfhvcetertrwer", action)
      return {
        ...state,
        error: "",
        loading: false,
        printingRequests: action.payload.data || [],
        totalPrintrequests: action.payload.totalCount || 0,
        pendingPrintrequests: action.payload.pendingCount || 0,
        progressPrintrequests: action.payload.progressCount || 0,
        completedPrintrequests: action.payload.completedCount || 0
      }
    /* list printing requests end  SK*/

    /* get printer  */
    case GET_PRINTING_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case GET_PRINTING_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PRINTING_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        printingRequest: action.payload.data || {},
      }

    case GET_PRINTING_PAYMENT_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case GET_PRINTING_PAYMENT_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PRINTING_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        printingPaymnetRequest: action.payload.data || {},
        totalPrintingPayment: action.payload.totalCount || 0,
        totalAdminEarning: action.payload.totalAdminEarning,
        quarterlyEarnings: action.payload.quarterlyEarnings,
        monthlyEarnings: action.payload.monthlyEarnings,
        yearlyEarnings: action.payload.yearlyEarnings
      }
    /* get printing requests end  SK*/


    case GET_PRINTING_WITHDRAWL_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case GET_PRINTING_WITHDRAWL_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PRINTING_WITHDRAWL_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        withdrawlRequestList: action.payload.data || [],
        totalWithdrawlCount: action.payload.totalCount || 0,
      }
    case VIEW_PRINTING_WITHDRAWL_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case VIEW_PRINTING_WITHDRAWL_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case VIEW_PRINTING_WITHDRAWL_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        viewWithdrawlRequest: action.payload.data || {}
      }


    case UPDATE_PRINTING_WITHDRAWL_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case UPDATE_PRINTING_WITHDRAWL_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_PRINTING_WITHDRAWL_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false
      }


      case GET_PRINTING_CSV_DATA:
        return {
          ...state,
          error: "",
          loading: true,
        }
  
      case GET_PRINTING_CSV_DATA_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
  
      case GET_PRINTING_CSV_DATA_SUCCESS:
        return {
          ...state,
          error: "",
          loading: false,
          prinitngCsvData:action.payload.data || []
        }
    default:
      return state
  }
}
export default PrintingPartners