import {
  CATEGORY_API_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_CATEGORY,
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
  PUT_CATEGORY,
  PUT_CATEGORY_FAIL,
  PUT_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  PUT_CATEGORIES_STATUS,
  PUT_CATEGORIES_STATUS_FAIL,
  PUT_CATEGORIES_STATUS_SUCCESS,
  GET_BLOG,
  GET_BLOG_FAIL,
  GET_BLOG_SUCCESS,
  ADD_BLOG,
  ADD_BLOG_FAIL,
  ADD_BLOG_SUCCESS,
  UPDATE_BLOG,
  UPDATE_BLOG_FAIL,
  UPDATE_BLOG_SUCCESS,
  VIEW_BLOG,
  VIEW_BLOG_FAIL,
  VIEW_BLOG_SUCCESS,
  DELETE_BLOG,
  DELETE_BLOG_FAIL,
  DELETE_BLOG_SUCCESS,
  GET_SUBCATEGORIES,
  GET_SUBCATEGORIES_SUCCESS,
  GET_SUBCATEGORIES_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  items: [],
  totalCategories: 0,
  error: "",
  loading: false,
  item: {},
  blogview: {},
  subCategories: [],
  totalSubCategories: 0,
};

const Category = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Categories */
    case GET_CATEGORIES:
    case GET_BLOG:
    case GET_SUBCATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case GET_BLOG_SUCCESS:
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalCategories: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

      case GET_SUBCATEGORIES_SUCCESS:
        return {
          ...state,
          subCategories: action.payload.data || [],
          totalSubCategories: action.payload.totalCount || 0,
          loading: false,
          error: "",
        };
    case GET_CATEGORIES_FAIL:
    case GET_BLOG_FAIL:
    case GET_SUBCATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Categories END */

    /* Add Category */
    case ADD_CATEGORY:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Category END */

    /* Get Category */
    case GET_CATEGORY:
      return {
        ...state,
        error: "",
        loading: true,
        item: {},
      };

    case GET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        item: {},
      };

    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        item: action.payload,
      };
    /* Add Category END */

    /* Update Category */
    case PUT_CATEGORY:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case PUT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case PUT_CATEGORY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Category END */

    /* Delete Category */
    case DELETE_CATEGORY:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Category END */

    /* Update Categories Status */
    case PUT_CATEGORIES_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case PUT_CATEGORIES_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case PUT_CATEGORIES_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Categories Status END */

    case CATEGORY_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* Delete Category */
    case DELETE_BLOG:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case DELETE_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Category END */

    /* Add bLOG */
    case ADD_BLOG:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case ADD_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_BLOG_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Blog END */

    /* Update blogs  */
    case UPDATE_BLOG:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case UPDATE_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update blogs  END */

    /* Get Category */
    case VIEW_BLOG:
      return {
        ...state,
        error: "",
        loading: true,
        blogview: {},
      };

    case VIEW_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        blogview: {},
      };

    case VIEW_BLOG_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        blogview: action.payload,
      };
    /* Add Category END */

    default:
      return state;
  }
};

export default Category;
