import { SEND_VERIFICATION_EMAIL_REQUEST, SEND_VERIFICATION_EMAIL_FAIL, SEND_VERIFICATION_EMAIL_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    error: "",
    loading: false,
}

const SendEmail = (state = INIT_STATE, action) => {
    switch (action.type) {
        /* Get Items */
        case SEND_VERIFICATION_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case SEND_VERIFICATION_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
            }

        case SEND_VERIFICATION_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: action.payload.data,
                totalNotifications: action.payload.totalCount,
            }

        default:
            return state
    }
}
export default SendEmail;