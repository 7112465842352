import { call, put, takeEvery } from "redux-saga/effects"

// Faq Redux States
import {
  GET_CONTACTUS,
} from "./actionTypes"
import {
  getContactusFail,
  getContactusSuccess,
} from "./actions"

import {
  getContactUs,
} from "helpers/backend_helper"

function* fetchContactUs({ payload }) {
  try {
    const response = yield call(getContactUs, payload)
    if (response.status == "failure") {
      return yield put(getContactusFail(response.message))
    }
    yield put(getContactusSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getContactusFail(error))
  }
}

function* ContactUsSaga() {
  yield takeEvery(GET_CONTACTUS, fetchContactUs)
}

export default ContactUsSaga
