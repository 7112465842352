import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select, { components } from "react-select";
import { getSettings, putSettings } from "store/actions";
import { useLocation } from "react-router-dom";
import viewuser from "../../../assets/css/viewuser.module.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input,
  UncontrolledTooltip,
  Card,
  CardTitle,
  Alert,
  CardBody,
  Container,
  Button,
  Spinner,
} from "reactstrap";
import Instructions from "./Instructions";
import NetworkUrl from "./NetworkUrl";

const DEFAULT_BLOCKCHAIN = {
  status: "",
  label: "",
  type: "",
  networkUrl: [
    {
      url: "",
      type: "testnet",
      apiKey: "",
      chainId: "",
      chainName: "",
      blockExplorerUrls: [],
      nativeCurrency: {
        name: "",
        symbol: "",
        decimals: 18,
      },
    },
    {
      url: "",
      type: "mainnet",
      apiKey: "",
      chainId: "",
      chainName: "",
      blockExplorerUrls: [],
      nativeCurrency: {
        name: "",
        symbol: "",
        decimals: 18,
      },
    },
  ],
};

const BlockchainNetwork = (props) => {
  // const { fields, handleChange } = props;
  const location = useLocation();
  const { from } = location;

  useEffect(() => {
    console.log("deepak", location.state);
  }, [location.state]);
  const [submitInfo, setSubmitInfo] = useState(null);
  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({
    blockchainNetworkMode: "testnet",
    blockchain: [{ ...DEFAULT_BLOCKCHAIN }],
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (!fields.blockchain) {
      let newBlockchain = [...fields.blockchain, { ...DEFAULT_BLOCKCHAIN }];
      handleChange("blockchain")({ target: { value: newBlockchain } });
    }
  }, [fields.blockchain]);

  const addBlockchain = (event) => {
    event.preventDefault();

    let newBlockchain = [...fields.blockchain, { ...DEFAULT_BLOCKCHAIN }];
    handleChange("blockchain")({ target: { value: newBlockchain } });
  };

  const removeBlockchain = (index) => (event) => {
    event.preventDefault();

    if (fields.blockchain.index <= 1) return;

    let newBlockchain = [
      ...fields.blockchain.slice(0, index),
      ...fields.blockchain.slice(index + 1),
    ];
    handleChange("blockchain")({ target: { value: newBlockchain } });
  };

  const handleBlockchainChange = (index, name) => (event) => {
    let newBlockchain = [
      ...fields.blockchain.slice(0, index),
      {
        ...fields.blockchain[index],
        [name]: event.target.value,
      },
      ...fields.blockchain.slice(index + 1),
    ];
    handleChange("blockchain")({ target: { value: newBlockchain } });
  };

  const handleNetworkUrlChange = (index) => (name) =>
    handleBlockchainChange(index, name);

  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        name: props.settings.name,
        logo: props.settings.logo,
        adminCommission: props.settings.adminCommission,
        blockchainNetworkMode:
          props.settings.blockchainNetworkMode || "testnet",
        blockchain: props.settings.blockchain || [{ ...DEFAULT_BLOCKCHAIN }],
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  function submit() {
    try {
      onPutSettings({ ...fields, _id: props.settings._id });
      setSubmitInfo("Information updated successfully!!");
    } catch {
      setSubmitInfo("Some Error Occurred!!");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Blockchain Network 2"
            breadcrumbItem="Blockchain Network 2"
          />
          <Row>
            {error && typeof error === "string" ? (
              <Alert color="danger">{error}</Alert>
            ) : null}

            {/* {loading && <div className="spinner"></div>} */}
            {loading && (
                  <div className="spinnerBoxNew">
                    <Spinner color="primary" />
                  </div>
                )}
            <Col className="mt-4" lg={12}>
              <Form>
                <Row>
                  

                  <Col lg={12}>
                    <div
                      className={
                        "addbox position-relative mt-4 " + viewuser.viewProfile
                      }
                    >
                      <Row>
                      <Col className="my-auto" md={6}>
                    <div className="mb-0 form-group">
                      <div className="blockchainmainebOx">
                        <h5>Blockchain Network Mode</h5>
                        <div className="customeslideBox">
                          <input
                            className="switcher"
                            type="checkbox"
                            id="blockchain-network-mode"
                            switch="none"
                            checked={fields.blockchainNetworkMode === "mainnet"}
                            onChange={() => {
                              handleChange("blockchainNetworkMode")({
                                target: {
                                  value:
                                    fields.blockchainNetworkMode === "mainnet"
                                      ? "testnet"
                                      : "mainnet",
                                },
                              });
                            }}
                          />
                          <label
                            htmlFor="blockchain-network-mode"
                            data-on-label="Mainnet"
                            data-off-label="Testnet"
                          />
                          <p>Testnet</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                        <Col className="text-end" md={6}>
                         
                            <Link
                              to={`#`}
                              onClick={addBlockchain}
                              className="btn verifybtn px-5 py-3 text-white btn btn-success my-1 h-auto"
                            >
                              Add Network Mode
                            </Link>
                         
                        </Col>
                      </Row>
                    </div>
                    
               
                  
                  </Col>
                  <Col md={12} className="mt-3">
                    {fields.blockchain?.map((item, index) => (
                      <div
                      className={
                        "addbox position-relative mt-4 " + viewuser.viewProfile
                      }
                    >
                      <React.Fragment key={"blockchain-" + index}>
                        <Row className="mb-3 mb-sm-2">
                          <Col xs={12} className="">
                           <div className="d-flex align-items-center justify-content-between">
                           <h5 className="">Network Mode</h5>
                           <div className="d-flex align-items-center">
                           <div className="customeslideBox">
                            <input
                                  className="switcher"
                                  type="checkbox"
                                  id={`blockchain-${index}-status`}
                                  switch="none"
                                  checked={item.status}
                                  onChange={() => {
                                    handleBlockchainChange(
                                      index,
                                      "status"
                                    )({
                                      target: {
                                        value: !item.status,
                                      },
                                    });
                                  }}
                                />
                          <label
                            htmlFor={`blockchain-${index}-status`}
                            data-on-label="Mainnet"
                            data-off-label="Testnet"
                          />
                          <p>{item.status === true ? 'True':'False'}</p>
                        </div>
                        <button
                                disabled={fields.blockchain?.length === 1}
                                onClick={removeBlockchain(index)}
                                className={'btn border-0 deletebtn ' + (fields.blockchain?.length === 1 ? "text-secondary" : "text-danger" )}
                              >
                                <i
                                  className="bx bx-trash"
                                  id="removeoptiontooltip"
                                />
                              </button>
                           </div>
                           </div>
                            <hr className="my-3" />
                          </Col>

                          <Col xs={6} sm={6} className="pb-3">
                            <Label className="">Label</Label>

                            <Input
                              type="text"
                              className="form-control"
                              value={item.label || ""}
                              onChange={handleBlockchainChange(index, "label")}
                              required
                            />
                          </Col>

                          <Col xs={6} sm={6} className="pb-3">
                            <Label className="">Type</Label>

                            <Input
                              type="text"
                              className="form-control"
                              value={item.type || ""}
                              onChange={handleBlockchainChange(index, "type")}
                              required
                            />
                          </Col>
                        </Row>

                        <NetworkUrl
                          fields={item}
                          handleChange={handleNetworkUrlChange(index)}
                        />
                      </React.Fragment>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Form>

              <button className="btn verifybtn px-5 py-3 text-white btn btn-success my-1 h-auto mt-3" onClick={submit}>
                Save Changes
              </button>
              <div className="mt-2">
                {submitInfo && <Alert>{submitInfo}</Alert>}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

BlockchainNetwork.propTypes = {
  fields: PropTypes.object,
  storeTypes: PropTypes.array,
  storeVendors: PropTypes.object,
  handleChange: PropTypes.func,
  handleStoreTypes: PropTypes.func,
  handleStoreVendors: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BlockchainNetwork)
);
