import React from "react";
import { Redirect } from "react-router-dom";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Signup from "../pages/Authentication/Signup";
import ProfileEdit from "../pages/Authentication/EditProfile";
import ProfileChangePassword from "../pages/Authentication/ChangePassword";
import Customers_List from "../pages/Customers/CustomersList";
import manage_art from "../pages/ManageArt/ArtList";
import manage_earning from '../pages/earning'
import VerifyRequest from "../pages/Verification/CustomersList/index";
import CollectionsList from "../pages/Collections/List";
import ItemsList from "../pages/Items/List";
import Categories from "../pages/Categories/List";
// import EditCategory from "../pages/Categories/Edit";
import MailChimpSettings from "../pages/Settings/MailChimp/index";
import Blog from "../pages/Blog/List";
import Report from "../pages/Report/List";
import AddSendEmail from "../pages/SendEmail/NewAdd";
import ActivitiesList from "../pages/Activities/List";
import NotificationsList from "../pages/Notifications/List";
import TransactionsList from "../pages/Transactions/List";
import OffersList from "../pages/Offers/List";
import ViewCustomer from "../pages/Customers/ViewCustomer";
import ViewReport from "pages/Report/ViewReport";
import Settings from "../pages/Settings";
import PrintingPartner from "../pages/PrintingPartner";
// import PrintingPartner from "../pages/PrintingPartner";
// import PrintingPartner from "../pages/PrintingPartner";
// import PrintingPartner from "../pages/PrintingPartner";

import BlockchainNetwork from "../pages/Settings/BlockchainNetwork";
import ContractSettings from "../pages/Settings/Contract";
// import AddCategory from "../pages/Categories/Add";
import SendEmail from "../pages/SendEmail/List/index";
import ViewCollection from "../pages/Collections/ViewCollection";
import Wallet from "../pages/Settings/Wallet";
import ViewItem from "../pages/Items/ViewItem";
import ContactUs from "../pages/ContactUs/List/index";
import Faqs from "../pages/Faq/List/index";
import FaqAddEdit from "../pages/Faq/AddEdit";
import PinataIpfs from "pages/Settings/PinataIpfs";
import AddCategory from "../pages/Categories/NewAdd/index";
import AddnewBlog from "../pages/Blog/NewAdd/index";
// Content Page
import ContentPage from "../pages/ContentPage/List";
import ContentPageAddEdit from "../pages/ContentPage/AddEdit";
// Add Images
import AddHomeImages from "../pages/AddImages/NewAdd";
import PrintingPartnerList from "pages/PrintingPartner/PrintingPartnerList";
import PrintList from "pages/PrintingPartner/PrintList";
import PrintWithdrawalList from "pages/PrintingPartner/PrintWithdrawalList";
import PaymentList from "pages/PrintingPartner/PaymentList";

const userRoutes = (role, accessLevel) => [
  { path: "/logout", exact: true, component: Logout },
  { path: "/edit-profile", exact: true, component: ProfileEdit },
  {
    path: "/change-password",
    exact: true,
    component: ProfileChangePassword,
  },
  { path: "/earning", component: manage_earning},
  { path: "/users", component: Customers_List},
  { path: "/manageart", component: manage_art },

  { path: "/users/profile/:id", component: ViewCustomer },

  { path: "/verify_request", component: VerifyRequest },
  { path: "/request/view/:id", component: ViewCustomer },

  { path: "/collections", component: CollectionsList },
  { path: "/collections/view/:id", component: ViewCollection },

  { path: "/items", component: ItemsList },
  { path: "/items/view/:id", component: ViewItem },

  { path: "/categories", component: Categories },
  { path: "/categories/add", component: AddCategory },
  { path: "/categories/edit/:id", component: AddCategory },
  { path: "/categories/view/:id", component: AddCategory },

  { path: "/blog", component: Blog },
  { path: "/blog/add", component: AddnewBlog },
  { path: "/blog/edit/:id", component: AddnewBlog },
  { path: "/image-setting", component: AddHomeImages },

  { path: "/report", component: Report },
  { path: "/report/view/:id", component: ViewReport },

  { path: "/activities", component: ActivitiesList },
  { path: "/notifications", component: NotificationsList },
  { path: "/transactions", component: TransactionsList },
  { path: "/offers", component: OffersList },

  { path: "/content-pages", component: ContentPage },
  { path: "/content-pages/add", component: ContentPageAddEdit },
  { path: "/content-pages/:id/edit", component: ContentPageAddEdit },
  { path: "/content-pages/:id/view", component: ContentPageAddEdit },

  { path: "/email", component: SendEmail },
  { path: "/email/add", component: AddSendEmail },
  { path: "/email/add/:id/view", component: AddSendEmail },

  { path: "/general", component: Settings },
  { path: "/printingpartner", component: PrintingPartnerList },
  { path: "/print", component: PrintList },
  { path: "/printpayment", component:PaymentList },
  { path: "/withdawprintpayment", component:PrintWithdrawalList  },

  { path: "/mailchimp", component: MailChimpSettings },

  { path: "/blockchain", component: BlockchainNetwork },
  { path: "/contract", component: ContractSettings },
  { path: "/ipfs-settings", component: PinataIpfs },
  { path: "/wallet", component: Wallet },
  { path: "/contact-us", component: ContactUs },
  { path: "/faqs", component: Faqs },
  { path: "/add/faqs", component: FaqAddEdit },
  { path: "/faqs/:id/edit", component: FaqAddEdit },
  { path: "/faqs/:id/view", component: FaqAddEdit },

  { path: "/", exact: true, component: () => <Redirect to="/users" /> },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/signup", component: Signup },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { userRoutes, authRoutes };
