import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import CKEditor from "react-ckeditor-component";
import FormButton from "components/Common/FormButtons";
import viewuser from "../../../assets/css/viewuser.module.css";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import {
  addContentPage,
  getContentPage,
  putContentPage,
} from "store/contentpage/actions";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AddCustomer = ({
  accessLevel,
  history,
  error,
  onAddContentPage,
  onGetContentPage,
  onPutContentPage,
  contentPage,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);
  const location = useLocation()
  const [isView, setIsView] = useState(false)
  useEffect(() => {
    console.log("location", location);
    const view = location.pathname.split("/")[3]
    console.log("view", view);
    setIsView(view === "view" ? true : false)
  }, [location])

  useEffect(() => {
    console.log("isView", isView);
  }, [isView])
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    title: "",
    content: "",
    status: "active",
  });

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };
      return setaccesses(data);
    }
    const data = {
      canAdd: false,
      canEdit: false,
    };
    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;
        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetContentPage(id);
    }
  }, [id]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!!contentPage && !!contentPage.content) {
        try {
          setFields({
            title: contentPage.title || "",
            content: contentPage.content || "",
            status: contentPage.status || "active",
          });
        } catch (err) {
          console.log("parsing error ", err);
        }
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(contentPage)]);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (loading) return;
    if (id) {
      if (!accesses.canEdit) return;
      onPutContentPage({ _id: contentPage._id, ...fields }, history);
    } else {
      if (!accesses.canAdd) return;
      onAddContentPage({ ...fields }, history);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="ContentPages"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) +
              " " +
              props.t("Content Page")
            }
            breadcrumbItems={[
              { title: props.t("Content Pages"), link: "/content-pages" },
              { title: (id && !isView) ? props.t("edit") : (id && isView) ? props.t("view") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <div className={"addbox position-relative mt-4 " + viewuser.viewProfile}>
                <Form onSubmit={onSubmit} className="spinner-content">
                  {error && typeof error === "string" ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-firstname-Input">
                          {props.t("Title")}
                        </Label>
                        <Input
                          type="text"
                          id="formrow-firstname-Input"
                          value={fields.title}
                          onChange={handleChange("title")}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <Label>{props.t("Content")}</Label>

                        <CKEditor
                          content={fields.content}
                          events={{
                            change: (evt) => {
                              const data = evt?.editor?.getData();

                              handleChange("content")({
                                target: { value: data },
                              });
                            },
                          }}
                          config={{
                            height: 250,
                            toolbar: [
                              [
                                "Undo",
                                "Redo",
                                "Font",
                                "FontSize",
                                "Styles",
                                "Format",
                                "-",
                                "Maximize",
                                "-",
                                "Source",
                              ],
                              [
                                "Bold",
                                "Italic",
                                "Underline",
                                "Strike",
                                "-",
                                "RemoveFormat",
                                "-",
                                "NumberedList",
                                "BulletedList",
                              ],
                              [
                                "Link",
                                "Unlink",
                                "-",
                                "JustifyLeft",
                                "JustifyCenter",
                                "JustifyRight",
                                "JustifyBlock",
                                "-",
                                "Outdent",
                                "Indent",
                                "-",
                                "TextColor",
                                "BGColor",
                              ],
                              [
                                "Image",
                                "Table",
                                "HorizontalRule",
                                "SpecialChar",
                                "-",
                                "Blockquote",
                              ],
                            ],
                          }}
                          scriptUrl="https://cdn.ckeditor.com/4.16.0/full/ckeditor.js"
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label>{props.t("status")}</Label>
                        <Input type="select" value={fields?.status}  onChange={(e) => { setFields((prevState) => ({ ...prevState, ["status"]: event.target.value })) }}  >

                          <option value={"active"}>Active</option>
                          <option value={"inactive"}>Inactive</option>

                        </Input>
                        {/* <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div> */}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormButton
                    needSubmit={id ? accesses.canEdit : accesses.canAdd}
                    goBack={() => history.goBack()}
                  />

                  {loading && (
                    <div className="spinner">
                      <Spinner color="primary" />
                    </div>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  contentPage: PropTypes.object,
  onAddContentPage: PropTypes.func,
  onGetContentPage: PropTypes.func,
  onPutContentPage: PropTypes.func,
};

const mapStateToProps = ({ content_page }) => ({
  error: content_page.error,
  loading: content_page.loading,
  contentPage: content_page.contentPage,
});

const mapDispatchToProps = (dispatch) => ({
  onAddContentPage: (data, history) => dispatch(addContentPage(data, history)),
  onGetContentPage: (id) => dispatch(getContentPage(id)),
  onPutContentPage: (data, history) => dispatch(putContentPage(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
);
