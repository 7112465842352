import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import FormButton from "components/Common/FormButtons";
import Dropzone from "react-dropzone";
import viewuser from "../../../assets/css/viewuser.module.css";
import toastr from "toastr";
import CKEditor from "react-ckeditor-component";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import { uploadFile, addBlogs, putBlogs, viewBlog } from "store/actions";

import Breadcrumbs from "components/Common/Breadcrumb2";
import { withTranslation } from "react-i18next";
import avatar4 from "assets/images/logo-placeholder.png";
import uploadIcon from "assets/images/svg/imgUploadIcon.svg"
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import { useLocale } from "antd/es/locale";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AddnewBlog = ({
  accessLevel,
  history,
  error,
  fileLoading,
  onAddBlogs,
  onGetCategories,
  onputBlogs,
  items,
  onUploadFile,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);
  const [selectedFiles, setselectedFiles] = useState({});
  const location = useLocation()
  const [edit, setEdit] = useState(false)
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    title: "",
    subtitle: "",
    meta_title: "",
    meta_subtitle: "",
    image: "",
    status: "active",
    video_link: "",
    isVideoBlog: false,
    description: "",
    is_landing_page_blog: null,

  });
  useEffect(() => { console.log("fields", fields) }, [fields])
  useEffect(() => {
    const isEdit = location.pathname.split("/")[2]
    if (isEdit === "add") {
      setEdit(false)
    } else {
      setEdit(true)
    }
  }, [])
  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };
      return setaccesses(data);
    }
    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetCategories(id);
    }
  }, [id]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!!items) {
        try {

          setFields({
            title: items?.title,
            subtitle: items?.subtitle,
            description: items?.description,
            image: items?.image?._id,
            status: items?.status || "inactive",
            video_link: items?.video_link,
            isVideoBlog: items?.is_video_blog,
            meta_title: items?.meta_title,
            meta_subtitle: items?.meta_subtitle,
            is_landing_page_blog: items?.is_landing_page_blog
          });

          let images = {};
          if (items?.image?.link) {
            images.image = { preview: items?.image?.link };
          }
          if (items?.bannerImage?.link) {
            images.bannerImage = { preview: items?.bannerImage?.link };
          }
          setselectedFiles(images);
        } catch (err) {
          console.log("parsing error ", err);
        }
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(items)]);

  const uploadFileSuccess = (name) => (response) => {
    const { _id: image } = response.data;
    setFields((prevState) => ({ ...prevState, [name]: image }));
  };

  function handleAcceptedFiles(name, _files) {
    if (id && !accesses.canEdit) return;
    const files = _files?.filter((file) => file.size < 5e6);
    if (files.length < _files.length) {
      return toastr.error(props.t("Maxc upload file size: 5MB"));
    }

    onUploadFile({ image: files[0] }, uploadFileSuccess(name));
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setselectedFiles((prevState) => ({ ...prevState, [name]: files[0] }));
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (loading) return;
    if (id) {
      if (!accesses.canEdit) return;
      onputBlogs(
        { _id: items?._id, ...fields },
        toastr.success("Updated Successfully"),
        history.push("/blog")
      );
    } else {
      if (fields?.is_landing_page_blog === null) {
        return toastr.error("Select For Blog is Required");
      }
      if (!accesses.canAdd) return;
      if (fields?.image == "") {
        return toastr.error("Image is Required");
      }
      if (fields?.title == "") {
        return toastr.error("Title is Required");
      }
      if (fields?.subtitle === "") {
        return toastr.error("Subtitle is Required");
      }
      if (fields?.description == "") {
        return toastr.error("Description is Required");
      }


      onAddBlogs(
        { type: fields.blockchain, ...fields },
        toastr.success("Added Successfully"),
        history.push("/blog")
      );
    }
  };

  useEffect(() => {
    console.log("selectedFiles", selectedFiles);
  }, [selectedFiles])
  const [blogtype, setBlogType] = useState('image')
  const blogType = (e) => {
    if (e === "video") {
      fields.isVideoBlog = true
    } else {
      fields.isVideoBlog = false
    }
    setBlogType(e)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Blog"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) + " " + props.t("Blog")
            }
            breadcrumbItems={[
              { title: props.t("Blog"), link: "/Blog" },
              { title: id ? props.t("edit") : props.t("add") },
            ]}
          />
          <div className="blogTbs">

            {(fields?.isVideoBlog && edit) ?
              <button onClick={() => blogType('video')} className={blogtype === 'video' ? 'active' : ''}>Video Blog</button>
              : ((!fields?.isVideoBlog) && edit) ? <button onClick={() => blogType('image')} className={blogtype === 'image' ? 'active' : ''}>Image Blog</button> : <>  <button onClick={() => blogType('video')} className={blogtype === 'video' ? 'active' : ''}>Video Blog</button> <button onClick={() => blogType('image')} className={blogtype === 'image' ? 'active' : ''}>Image Blog</button></>}

          </div>
          <div
            className={
              "addbox addblog mt-3 position-relative " + viewuser.viewProfile
            }
          >
            <Row>
              <Col lg={2}>
                <FormGroup>
                  <Label>{props.t("Select Blog For")}</Label>

                  <div className="status-switch square-switch">
                    <Input
                      type="select"
                      required
                      className="form-control form-select"
                      value={fields?.is_landing_page_blog} onChange={(e) => { setFields((prevState) => ({ ...prevState, ["is_landing_page_blog"]: e.target.value === "true" ? true : false })) }}  >
                      <option value={null} disabled selected>Select For Blog</option>
                      <option value={false}>How To Article</option>
                      <option value={true}>Blog</option>

                    </Input>
                  </div>
                </FormGroup>
              </Col>
              <Col lg={12}>
                <Form onSubmit={onSubmit} className="spinner-content">
                  {error && typeof error === "string" ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  <Row>
                    <Col className="mb-4 mt-2" md={fields?.isVideoBlog === true ? '6' : '12'}>
                      <div className="categories_div h-100">
                        <FormGroup className="h-100 mb-0">
                          {/* <Label for="formrow-profile-image-Input">
                                {props.t("Image")}
                              </Label> */}

                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("image", acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles.image;
                              console.log("selectedFiles", selectedFiles)
                              return (
                                <div
                                  className="dropzone-single-image avatar-xl position-relative h-100"
                                  {...getRootProps()}
                                >
                                  <input
                                    className="category-inp"
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  {!!imageFile ?
                                    <img
                                      className="rounded avatar-xl categories-image-uploader w-100"
                                      alt={
                                        !!imageFile
                                          ? imageFile.name
                                          : "hlc"
                                      }
                                      src={!!imageFile ? imageFile.preview : ''}
                                    /> : <label className="blogUpload" htmlFor="formrow-profile-image-Input">
                                      <img src={uploadIcon} />
                                      <h3>Max. upload file size: 5MB</h3>
                                    </label>}

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>

                          <FormText>Max. upload file size: 5MB</FormText>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col className="mb-4 mt-2" md={fields?.isVideoBlog === true ? '6' : '12'}>
                      <Row>
                        {fields?.isVideoBlog === true &&
                          <Col md={fields?.isVideoBlog === true ? '12' : '6'}>
                            <FormGroup>
                              <Label for="formrow-name-Input">
                                {props.t("Video Link")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-name-Input"
                                value={fields?.video_link}
                                onChange={handleChange("video_link")}
                                required
                              />
                            </FormGroup>
                          </Col>
                        }
                        <Col md={fields?.isVideoBlog === true ? '12' : '6'}>
                          <FormGroup>
                            <Label for="formrow-name-Input">
                              {props.t("Title")}
                            </Label>
                            <Input
                              type="text"
                              id="formrow-name-Input"
                              value={fields?.title}
                              onChange={handleChange("title")}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={fields?.isVideoBlog === true ? '12' : '6'}>
                          <div className="form-group mb-0">
                            <Label for="formrow-name-Input">
                              {props.t("Subtitle")}
                            </Label>
                            <Input
                              type="text"
                              id="formrow-name-Input"
                              value={fields?.subtitle}
                              onChange={handleChange("subtitle")}

                            />
                          </div>
                        </Col>
                        <Col md={fields?.isVideoBlog === true ? '12' : '6'}>
                          <FormGroup>
                            <Label for="formrow-name-Input">
                              {props.t("Meta Title")}
                            </Label>
                            <Input
                              type="text"
                              id="formrow-name-Input"
                              value={fields?.meta_title}
                              onChange={handleChange("meta_title")}

                            />
                          </FormGroup>
                        </Col>
                        <Col md={fields?.isVideoBlog === true ? '12' : '6'}>
                          <FormGroup>
                            <Label for="formrow-name-Input">
                              {props.t("Meta Description")}
                            </Label>
                            <Input
                              type="text"
                              id="formrow-name-Input"
                              value={fields?.meta_subtitle}
                              onChange={handleChange("meta_subtitle")}

                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                  </Row>

                  <Row className="mt-2" >
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-name-Input">
                          {props.t("Description")}
                        </Label>
                        {/* <Input
                          type="textarea"
                          id="formrow-name-Input"
                          value={fields.description}
                          onChange={handleChange("description")}
                          required
                        /> */}
                        <Ckeditor
                          content={fields.description}
                          events={{
                            change: (evt) => {
                              const data = evt?.editor?.getData();

                              handleChange("description")({
                                target: { value: data },
                              });
                            },
                          }}
                          config={{
                            height: 250,
                            toolbar: [
                              [
                                "Undo",
                                "Redo",
                                "Font",
                                "FontSize",
                                "Styles",
                                "Format",
                                "-",
                                "Maximize",
                                "-",
                                "Source",
                              ],
                              [
                                "Bold",
                                "Italic",
                                "Underline",
                                "Strike",
                                "-",
                                "RemoveFormat",
                                "-",
                                "NumberedList",
                                "BulletedList",
                              ],
                              [
                                "Link",
                                "Unlink",
                                "-",
                                "JustifyLeft",
                                "JustifyCenter",
                                "JustifyRight",
                                "JustifyBlock",
                                "-",
                                "Outdent",
                                "Indent",
                                "-",
                                "TextColor",
                                "BGColor",
                              ],
                              [
                                "Image",
                                "Table",
                                "HorizontalRule",
                                "SpecialChar",
                                "-",
                                "Blockquote",
                              ],
                            ],
                          }}
                          scriptUrl="https://cdn.ckeditor.com/4.16.0/full/ckeditor.js"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <Label>{props.t("Status")}</Label>

                        <div className="status-switch square-switch">
                          <Input
                            type="select"
                            className="form-control form-select"
                            value={fields?.status} onChange={(e) => { setFields((prevState) => ({ ...prevState, ["status"]: event.target.value })) }}  >

                            <option value={"active"}>Active</option>
                            <option value={"inactive"}>Inactive</option>

                          </Input>
                          {/* <label
                            htmlFor="square-switch1"
                            data-on-label={props.t("active")}
                            data-off-label={props.t("inactive")}
                          /> */}
                        </div>
                      </FormGroup>

                    </Col>
                  </Row>

                  {fileLoading ? (
                    <Spinner />
                  ) : (
                    <div className="text-end">
                      <FormButton
                        needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      /></div>
                  )}

                  {loading && (
                    <div className="spinner">
                      <Spinner color="primary" />
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddnewBlog.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  items: PropTypes.object,
  onAddBlogs: PropTypes.func,
  onGetCategories: PropTypes.func,
  onputBlogs: PropTypes.func,
};

const mapStateToProps = ({ File, Category }) => ({
  loading: Category.loading,
  items: Category.blogview,
  totalCategories: Category.totalCategories,
  fileLoading: File?.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onAddBlogs: (data, history) => dispatch(addBlogs(data, history)),

  onGetCategories: (id) => dispatch(viewBlog(id)),

  onputBlogs: (data, history) => dispatch(putBlogs(data, history)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddnewBlog))
);
