import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

//i18n
import { withTranslation } from "react-i18next";


import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import {
  getVerificationRequests,
  getVerificationRequestsSuccess,
} from "store/actions";
import UsersColumns, { selectRow } from "./Columns";
import UsersConfirmModal from "./ConfirmModal";

import Select from "react-select";
import ViewRequest from "./ViewRequest";
import sortSvg from "../../../assets/images/svg/sort.svg";
const statusFilter = { fieldName: "status", fieldValue: { $ne: "temp" } };

const options = [
  { value: "user", label: "User" },
  { value: "collection", label: "Collection" },
];

const VerifyRequest = (props) => {
  const {
    accessLevel,
    history,
    users,
    totalUsers,
    getCustomersSuccess,
    onGetUsers,
    loading,
  } = props;
  console.log(users);
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });

  const [filter, setFilter] = useState({
    orderBy: "created_date",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    role: "",
    type: options[0]?.value,
    fields: [
      /* {
        fieldName: "status",
        fieldValue: "",
      }, */
    ],
  });

  const [lastFields, setLastFields] = useState({
    fieldName: "status",
    fieldValue: "",
  });
  const [searchText, setSearchText] = useState("");
  const [userList, setUsersList] = useState([]);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalUsers,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    page:filter?.page
  };

  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    // getCustomersSuccess({ data: [], totalcount: 0 });
    onGetUsers({ ...filter, fields: [...filter.fields, lastFields] });
    setUsersList(users);
  }, [onGetUsers, JSON.stringify(filter), lastFields.fieldValue]);

  useEffect(() => {
    console.log(users);
    setUsersList(users || []);
  }, [users]);
  useEffect(() => {
    console.log("userList", userList);
   
  }, [userList]);
  

  useEffect(() => {
    console.log(5645454, filter);
    onGetUsers(filter);
  }, []);
  const selectRole = (e) => {
    console.log("e select role", e.target.value);
    setFilter((prevState) => ({ ...prevState, role: e.target.value }));

  }

  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
  
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
   
  };

  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;
    console.log("remove confirm called")
    const onComplete = () => {
      onGetUsers(filter);
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    onDeleteUser({ _id: userId }, onComplete);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText, page:1 }));
  };
  const [viewUser, setviewUser] = useState({
    isOpen: false,
    userId: null,
  });
  function toggleViewrequest(userId) {
    console.log("toggleViewrequest called")
    setviewUser((prevState) => ({ isOpen: !prevState.isOpen, userId }));
    console.log(viewUser);
  };
  const sortDateWise = (e) => {
    if (e.target.value === "Newest_first") {
      setFilter((prevState) => ({
        ...prevState,
        orderBy: "created_at",
        order: -1,
      }));
    }
    else {
      setFilter((prevState) => ({
        ...prevState,
        orderBy: "created_at",
        order: 1,
      }));
    }

  }
  return (

    <>
      <ViewRequest data={viewUser} toggle={toggleViewrequest} />
      <React.Fragment>
        <UsersConfirmModal
          isOpen={confirmModal.isOpen}
          toggle={toggleConfirmModal}
          onConfirm={removeConfirm} />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Verify Museum Request"
              breadcrumbItem={props.t("Verify Museum Request")}
              breadcrumbItems={[{ title: props.t("Verify Museum Request") }]} />
            <Row>
              <Col xs="12">

                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="_id"
                      data={userList || []}
                      columns={UsersColumns(
                        history,
                        toggleConfirmModal,
                        toggleViewrequest,
                        props.t,
                        accesses,
                        filter
                      )}
                      bootstrap4
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <div className="row">
                            <div className="col-xl-12 mb-4 filterBox">
                              <div className="row">
                                <div className="col-xl-5">
                                  <div className="form-group position-relative">
                                    <SearchInput
                                      {...toolkitProps.searchProps}
                                      triggerSearch={onSearch}
                                      placeholder={props.t("Search")}
                                      searchText={searchText} />
                                    {/* <input type="text" className='form-control pe-5' placeholder='Search.....' />
        <button className='btn position-absolute end-0 top-0 bottom-0 p-0 me-3'><i className="fa-light fa-magnifying-glass"></i></button> */}
                                  </div>
                                </div>
                                {(!!lastFields.fieldValue ||
                                  !!filter.search) && (
                                    <div className='col-xl-2'>
                                      <button onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          search: ""
                                        }));
                                        setSearchText("");
                                        setLastFields({
                                          fieldName: "status",
                                          fieldValue: ""
                                        });
                                      }} className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}</button>
                                    </div>)}
                              </div>
                            </div>
                          </div>
                          <Row hidden className="mb-2">
                            <Col md="6" lg="10">
                              <div className="form-group row mb-2 mr-2">
                                <Col md="4" lg="3">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search")}
                                    searchText={searchText} />
                                </Col>

                                {/* <Col md="4" lg="3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={options[0]}
                        name="color"
                        onChange={(e) =>
                          setFilter((prev) => ({
                            ...prev,
                            type: e.value,
                          }))
                        }
                        options={options}
                      />
                    </Col> */}
                              </div>
                            </Col>

                            <Col sm="2">
                              {(!!lastFields.fieldValue ||
                                !!filter.search) && (
                                  <div
                                    className="mr-4 mb-2"
                                    style={{
                                      height: "calc( 1.5em + 0.94rem + 2px)",
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          search: ""
                                        }));
                                        setSearchText("");
                                        setLastFields({
                                          fieldName: "status",
                                          fieldValue: ""
                                        });
                                      }}
                                      className=""
                                    >
                                      {props.t("clear filters")}
                                    </Link>
                                  </div>
                                )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive tableBox verifytable">
                                <BootstrapTable
                                  responsive
                                  remote
                                  noDataIndication={"No data available"}
                                  bordered={false}
                                  striped={false}
                                  classes={"table table-centered table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  {...paginationTableProps}
                                  defaultSorted={[
                                    {
                                      dataField: "created_at",
                                      order: "desc"
                                    },
                                  ]}
                                  sort={{
                                    sortCaret: (order) => order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) :  <span className="caret">
                                    {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                  </span>,
                                  }} />

                                {/* {loading && (
                                <div className="spinnerBoxNew">
                                  <Spinner color="primary" />
                                </div>
                              )} */}
                              </div>
                              {loading && (
                                <div className="spinnerBoxNew ">
                                  <Spinner color="primary" />
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <PaginationListStandalone
                                {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment></>
  );
};

VerifyRequest.propTypes = {
  t: PropTypes.any,
  users: PropTypes.array,
  totalUsers: PropTypes.number,
  getCustomersSuccess: PropTypes.func,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  loading: customers.loading,
  users: customers.pending_customers,
  totalUsers: customers.totalPendingCustomers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomersSuccess: (data) =>
      dispatch(getVerificationRequestsSuccess(data)),
    onGetUsers: (data) => {
      console.log(data);
      dispatch(getVerificationRequests(data));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VerifyRequest))
);
