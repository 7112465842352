// action sahil khan start
import { ADD_PRINTING_PARTTNER, ADD_PRINTING_PARTTNER_FAIL, ADD_PRINTING_PARTTNER_SUCCESS, DELETE_PRINTING_PARTTNER, DELETE_PRINTING_PARTTNER_FAIL, DELETE_PRINTING_PARTTNER_SUCCESS, EDIT_PRINTING_PARTTNER, EDIT_PRINTING_PARTTNER_FAIL, EDIT_PRINTING_PARTTNER_SUCCESS, GET_PRINTING_CSV_DATA, GET_PRINTING_CSV_DATA_FAIL, GET_PRINTING_CSV_DATA_SUCCESS, GET_PRINTING_PARTTNER, GET_PRINTING_PARTTNER_SUCCESS, GET_PRINTING_PAYMENT_REQUEST, GET_PRINTING_PAYMENT_REQUEST_FAIL, GET_PRINTING_PAYMENT_REQUEST_SUCCESS, GET_PRINTING_REQUEST, GET_PRINTING_REQUEST_FAIL, GET_PRINTING_REQUEST_SUCCESS, GET_PRINTING_WITHDRAWL_REQUEST, GET_PRINTING_WITHDRAWL_REQUEST_FAIL, GET_PRINTING_WITHDRAWL_REQUEST_SUCCESS, LIST_PRINTING_PARTTNER, LIST_PRINTING_PARTTNER_FAIL, LIST_PRINTING_PARTTNER_SUCCESS, LIST_PRINTING_REQUEST, LIST_PRINTING_REQUEST_FAIL, LIST_PRINTING_REQUEST_SUCCESS, UPDATE_PRINTING_WITHDRAWL_REQUEST, UPDATE_PRINTING_WITHDRAWL_REQUEST_FAIL, UPDATE_PRINTING_WITHDRAWL_REQUEST_SUCCESS, VIEW_PRINTING_WITHDRAWL_REQUEST, VIEW_PRINTING_WITHDRAWL_REQUEST_FAIL, VIEW_PRINTING_WITHDRAWL_REQUEST_SUCCESS } from "./actionTypes"


export const addPrinter = (data, callback) => ({
  type: ADD_PRINTING_PARTTNER,
  payload: { data, callback },
})

export const addPrinterFail = (error) => ({
  type: ADD_PRINTING_PARTTNER_FAIL,
  payload: error,
})

export const addPrinterSuccess = () => ({
  type: ADD_PRINTING_PARTTNER_SUCCESS,
})





export const listPrinter = (data, callback) => ({
  type: LIST_PRINTING_PARTTNER,
  payload: { data, callback },
})

export const listPrinterFail = (error) => ({
  type: LIST_PRINTING_PARTTNER_FAIL,
  payload: error,
})

export const listPrinterSuccess = (items) => ({
  type: LIST_PRINTING_PARTTNER_SUCCESS,
  payload: items,
})

export const editPrinter = (data, callback) => ({
  type: EDIT_PRINTING_PARTTNER,
  payload: { data, callback },
})

export const editPrinterFail = (error) => ({
  type: EDIT_PRINTING_PARTTNER_FAIL,
  payload: error,
})

export const editPrinterSuccess = (items) => ({
  type: EDIT_PRINTING_PARTTNER_SUCCESS,
  payload: items,
})




export const getPrinter = (data, callback) => ({
  type: GET_PRINTING_PARTTNER,
  payload: { data, callback },
})

export const getPrinterFail = (error) => ({
  type: EDIT_PRINTING_PARTTNER_FAIL,
  payload: error,
})

export const getPrinterSuccess = (items) => ({
  type: GET_PRINTING_PARTTNER_SUCCESS,
  payload: items,
})


export const deletePrinter = (data, callback) => ({
  type: DELETE_PRINTING_PARTTNER,
  payload: { data, callback },
})

export const deletePrinterFail = (error) => ({
  type: DELETE_PRINTING_PARTTNER_FAIL,
  payload: error,
})

export const deletePrinterSuccess = () => ({
  type: DELETE_PRINTING_PARTTNER_SUCCESS
})



export const lsitPrintingRequest = (data, callback) => ({
  type: LIST_PRINTING_REQUEST,
  payload: { data, callback },
})

export const lsitPrintingRequestFail = (error) => ({
  type: LIST_PRINTING_REQUEST_FAIL,
  payload: error,
})

export const lsitPrintingRequestSuccess = (item) => ({
  type: LIST_PRINTING_REQUEST_SUCCESS,
  payload: item
})



export const getPrintingRequest = (data, callback) => ({
  type: GET_PRINTING_REQUEST,
  payload: { data, callback },
})

export const getPrintingRequestFail = (error) => ({
  type: GET_PRINTING_REQUEST_FAIL,
  payload: error,
})

export const getPrintingRequestSuccess = (item) => ({
  type: GET_PRINTING_REQUEST_SUCCESS,
  payload: item
})


export const getPrintingPaymentList = (data, callback) => ({
  type: GET_PRINTING_PAYMENT_REQUEST,
  payload: { data, callback },
})

export const getPrintingPaymentListFail = (error) => ({
  type: GET_PRINTING_PAYMENT_REQUEST_FAIL,
  payload: error,
})

export const getPrintingPaymentListSuccess = (item) => ({
  type: GET_PRINTING_PAYMENT_REQUEST_SUCCESS,
  payload: item
})

export const getPrinterWithdrawlList = (data, callback) => ({
  type: GET_PRINTING_WITHDRAWL_REQUEST,
  payload: { data, callback },
})

export const getPrinterWithdrawlListFail = (error) => ({
  type: GET_PRINTING_WITHDRAWL_REQUEST_FAIL,
  payload: error,
})

export const getPrinterWithdrawlListSuccess = (item) => ({
  type: GET_PRINTING_WITHDRAWL_REQUEST_SUCCESS,
  payload: item
})


export const viewPrinterWithdrawlList = (data, callback) => (console.log("dsdasdasd", data), {
  type: VIEW_PRINTING_WITHDRAWL_REQUEST,
  payload: { data, callback },
})

export const viewPrinterWithdrawlListFail = (error) => ({
  type: VIEW_PRINTING_WITHDRAWL_REQUEST_FAIL,
  payload: error,
})

export const viewPrinterWithdrawlListSuccess = (item) => ({
  type: VIEW_PRINTING_WITHDRAWL_REQUEST_SUCCESS,
  payload: item
})
export const updatePrinterWithdrawlList = (data, callback) => ( {
  type: UPDATE_PRINTING_WITHDRAWL_REQUEST,
  payload: { data, callback },
})

export const updatePrinterWithdrawlListFail = (error) => ({
  type: UPDATE_PRINTING_WITHDRAWL_REQUEST_FAIL,
  payload: error,
})

export const updatePrinterWithdrawlListSuccess = (item) => ({
  type: UPDATE_PRINTING_WITHDRAWL_REQUEST_SUCCESS,
  payload: item
}) 



export const getPrinterCsvData = (data, callback) => ( {
  type: GET_PRINTING_CSV_DATA,
  payload: { data, callback },
})

export const getPrinterCsvDataFail = (error) => ({
  type: GET_PRINTING_CSV_DATA_FAIL,
  payload: error,
})

export const getPrinterCsvDataSuccess = (item) => ({
  type: GET_PRINTING_CSV_DATA_SUCCESS,
  payload: item
}) 