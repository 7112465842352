import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  GET_COLLECTIONS,
  ADD_COLLECTION,
  GET_COLLECTION,
  PUT_COLLECTION,
  DELETE_COLLECTION,
  PUT_COLLECTIONS_STATUS,
  VERIFY_COLLECTION_REQUEST,
  GET_NEW_COLLECTIONS,
  GET_NEW_COLLECTIONS_BY_ID,
} from "./actionTypes";
import {
  getCollectionsFail,
  getCollectionsSuccess,
  addCollectionFail,
  addCollectionSuccess,
  getCollectionFail,
  getCollectionSuccess,
  putCollectionFail,
  putCollectionSuccess,
  deleteCollectionFail,
  deleteCollectionSuccess,
  putCollectionsStatusFail,
  putCollectionsStatusSuccess,
  VerifyCollectionRequestError,
  VerifyCollectionRequestSuccess,
  getNewCollectionsSuccess,
  getNewCollectionsFail,
  getNewCollectionsFailByID,
  getNewCollectionsSuccessByID,
} from "./actions";

import {
  getCollections,
  postCollection,
  getCollection,
  putCollection,
  deleteCollection,
  putCollectionsStatus,
  VerifyCollectionRequestUrl,
  getNewCollectionsApi,
  getNewCollectionByIdApi,
} from "helpers/backend_helper";

function* fetchCollections({ payload }) {
  try {
    const response = yield call(getCollections, payload);

    if (response.status == "failure") {
      return yield put(getCollectionsFail(response.message));
    }

    yield put(getCollectionsSuccess(response));
  } catch (error) {
    yield put(getCollectionsFail(error));
  }
}

function* onAddNewCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(postCollection, collection);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(addCollectionFail(response.message));
    }

    history && history.goBack();

    yield put(addCollectionSuccess());
  } catch (error) {
    yield put(addCollectionFail("Internal Error!"));
  }
}

function* fetchCollection({ payload }) {
  try {
    const response = yield call(getCollection, payload);
    if (response.status == "failure") {
      return yield put(getCollectionFail(response.message));
    }
    yield put(getCollectionSuccess(response.data));
  } catch (error) {
    yield put(getCollectionFail(error));
  }
}

function* onPutCollection({ payload: { data, history } }) {
  try {
    const response = yield call(putCollection, data);

    if (response.status == "failure") {
      return yield put(putCollectionFail(response.message));
    }

    history && history.goBack();

    yield put(putCollectionSuccess());
  } catch (error) {
    yield put(putCollectionFail("Internal Error!"));
  }
}

function* onDeleteCollection({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCollection, data);

    if (response.status == "failure") {
      return yield put(deleteCollectionFail(response.message));
    }

    yield put(deleteCollectionSuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteCollectionFail("Internal Error!"));
  }
}

function* onPutCollectionsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putCollectionsStatus, data);

    if (response.status == "failure") {
      return yield put(putCollectionsStatusFail(response.message));
    }

    yield put(putCollectionsStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putCollectionsStatusFail("Internal Error!"));
  }
}

function* VerifyCollectionRequest({ payload: { data, callback } }) {
  try {
    const response = yield call(VerifyCollectionRequestUrl, data);
    callback && callback(response);

    if (response.status === "failure") {
      return yield put(VerifyCollectionRequestError(response.message));
    }

    yield put(VerifyCollectionRequestSuccess(response));
  } catch (error) {
    yield put(VerifyCollectionRequestError("Internal Server Error"));
  }
}
function* getNewCollectionsRequest({ payload: {data, callback } }) {
  try {
    const response = yield call(getNewCollectionsApi, data);
    console.log("response", response);
    callback && callback(response);
    if (response.status === "failure") {
      return yield put(getNewCollectionsFail(response.message));
    }

    yield put(getNewCollectionsSuccess(response));
  } catch (error) {
    yield put(getNewCollectionsFail("Internal Server Error"));
  }
}
function* getNewCollectionByIdRequest({ payload: {id,  callback } }) {
  try {
    const response = yield call(getNewCollectionByIdApi, id);
    console.log("response", response);
    callback && callback(response);
    if (response.status === "failure") {
      return yield put(getNewCollectionsFailByID(response.message));
    }

    yield put(getNewCollectionsSuccessByID(response));
  } catch (error) {
    yield put(getNewCollectionsFailByID("Internal Server Error"));
  }
}

function* CollectionsSaga() {
  yield takeEvery(GET_COLLECTIONS, fetchCollections);
  yield takeEvery(ADD_COLLECTION, onAddNewCollection);
  yield takeEvery(GET_COLLECTION, fetchCollection);
  yield takeEvery(PUT_COLLECTION, onPutCollection);
  yield takeEvery(DELETE_COLLECTION, onDeleteCollection);
  yield takeEvery(PUT_COLLECTIONS_STATUS, onPutCollectionsStatus);
  yield takeEvery(VERIFY_COLLECTION_REQUEST, VerifyCollectionRequest);
  yield takeEvery(GET_NEW_COLLECTIONS, getNewCollectionsRequest);
  yield takeEvery(GET_NEW_COLLECTIONS_BY_ID, getNewCollectionByIdRequest);
}

export default CollectionsSaga;
