import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";
import { Row, Col, FormGroup, FormText, Label, Input } from "reactstrap";
import Instructions from "./Instructions";
import { uploadFile } from "store/actions";
import image_placeholder from "assets/images/logo-placeholder.png";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const MuseumSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;
  const [imageselectedFiles, setimageselectedFiles] = useState([]);

  useEffect(() => {
    if (isObject(fields?.image) && !!fields?.image?.link) {
      setimageselectedFiles([{ preview: fields?.image?.link }]);
    }
  }, [JSON.stringify(fields?.image)]);

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;
    handleChange(name)({ target: { value: _id } });
  };

  const handleAcceptedFiles = (name) => (_files) => {
    const files = _files?.filter((file) => file.size < 5242880);
    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }
    onUploadFile({ image: files[0] }, uploadFileSuccess(name));
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    if (name === "image") {
      setimageselectedFiles(files);
    }
  };

  return (
    <React.Fragment>
      <Row>
    
          <Col md={12}>
          <div className="categories_div">
              <FormGroup>
                <Label>Background Image </Label>
                <div className="uploadImageMaine mb-0">
                  <div className="uploadImgNew h-100 full widthimg w-100 position-relative start-0 top-0">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles("image")(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => {
                      const imageFile = imageselectedFiles[0];

                      return (
                        <div
                          className="dropzone-single-image categoryUploadImage avatar-xl border"
                          {...getRootProps()}
                        >
                          <input
                            {...getInputProps()}
                            id="formrow-profile-image-Input"
                            multiple={false}
                          />
                          <img
                            className="rounded avatar-xl uploadlogogbox img-fluid"
                            alt={
                              !!imageFile && imageFile.name
                                ? imageFile.name
                                : "hlc"
                            }
                            src={
                              !!imageFile ? imageFile.preview : image_placeholder
                            }
                          />
                          <div className="edit">
                            <i className="bx bx-pencil"></i>
                          </div>
                        </div>
                      );
                    }}
                  </Dropzone>

                  <small class="fw-bold  form-text text-muted">Max. upload file size: 5MB</small>
                  </div>
                </div>
              </FormGroup>
            </div>
            </Col>

            <Col md={12}>
              <FormGroup>
                <Label>Top Heading</Label>
                <Input
                  type="text"
                  value={fields.topHeading}
                  onChange={(e) => {
                    handleChange("topHeading")(e);
                  }}
                />
              </FormGroup>
            </Col>

          
            <Col md={12}>
              <FormGroup>
                <Label> Description</Label>
                <Input
                  type="textarea"
                  value={fields.description}
                  onChange={(e) => {
                    handleChange("description")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
 
    </React.Fragment>
  );
};

MuseumSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MuseumSettings)
);
