import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),

  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "name",
    text: t("Title"),
    sort:true
  },
  {
    dataField: "status",
    sort:true,
    text: t("Status"),
    formatter: (cellContent, row) => (
      // <Badge
      //   className={
      //     "text-capitalize font-size-13 badge-soft-" +
      //     (row.status == "active"
      //       ? "success"
      //       : row.status == "inactive"
      //       ? "warning"
      //       : "danger")
      //   }
      //   color={row.badgeClass}
      // >
      //   {t(row.status)}
      // </Badge>
      <div className={"text-capitalize " + (row.status === 'active' ? 'active': 'inactive')}>{row.status}</div>
    ),
  },
  {
    text: t("Created At"),
    dataField: "create_date",
    sort: true,
    formatter: (_, row) => moment(row.create_date).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row,i) => (
      <>

       <Link to={`/categories/view/${row?._id}`} className='btn px-0 py-0 id-btn border-0' id={`view-${i}-tooltip`}><i className="fa-light fa-eye"></i></Link>
        {accesses?.canEdit ? (
          <>
         
          <Link to={`/categories/edit/${row?._id}`} className='btn id-btn px-2 py-0  border-0' id={`edit-${i}-tooltip`}><i class="fa-regular text-success fa-pen-to-square"></i>
          {/* <UncontrolledTooltip
              placement="top"
              target={`edit-${i}-tooltip`}
            >
              {t("edit")}
            </UncontrolledTooltip> */}
            </Link>
          {/* <Link
            to={`/categories/${row._id}/edit`}
            className="mr-3 text-secondary"
          >
            <i className="far fa-edit mr-3" id={`edit-${row._id}-tooltip`} />
            <UncontrolledTooltip
              placement="top"
              target={`edit-${row._id}-tooltip`}
            >
              {t("edit")}
            </UncontrolledTooltip>
          </Link> */}
         </>
        ) : (
          ""
        )}

        {accesses?.canDelete && (
          <button   onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal(row?._id);
            }} className='btn p-0 id-btn border-0'>
            <i class="fa-regular text-danger  fa-trash"></i>
             {/* <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip> */}
            </button>
          /* <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal(row?._id);
            }}
            className="text-secondary"
          >
           <i class="fa-regular fa-trash"   id={`delete-${row._id}-tooltip`}></i>
            
            
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link> */
        )}
      </>
    ),
  },
];

export default ListColumns;
