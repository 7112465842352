import {
  GET_SENDEMAIL,
  GET_SENDEMAIL_FAIL,
  GET_SENDEMAIL_SUCCESS,
  ADD_EMAIL,
  ADD_EMAIL_FAIL,
  ADD_EMAIL_SUCCESS,
  GET_EMAIL_BY_ID,
  GET_EMAIL_BY_ID_FAIL,
  GET_EMAIL_BY_ID_SUCCESS,
} from "./actionTypes";

export const getSendEmail = (data) => ({
  type: GET_SENDEMAIL,
  payload: data,
});

export const getSendEmailSuccess = (emails) => ({
  type: GET_SENDEMAIL_SUCCESS,
  payload: emails,
});

export const getSendEmailFail = (error) => ({
  type: GET_SENDEMAIL_FAIL,
  payload: error,
});

/* Add Send Email */
export const addSendEmail = (email, callback) => ({
  type: ADD_EMAIL,
  payload: { email, callback },
});

export const addSendEmailFail = (error) => ({
  type: ADD_EMAIL_FAIL,
  payload: error,
});

export const addSendEmailSuccess = () => ({
  type: ADD_EMAIL_SUCCESS,
});
/* Add Send Email END */


// get email by id

export const getEmailByID = (id, callback) => ({
  type: GET_EMAIL_BY_ID,
  payload: { id, callback },
});

export const getEmailByIDFail = (error) => ({
  type: GET_EMAIL_BY_ID_FAIL,
  payload: error,
});

export const getEmailByIDSuccess = (data) => ({
  type: GET_EMAIL_BY_ID_SUCCESS,
  payload: data,
});
