import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty, orderBy } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";
import toastr from "toastr"
//i18n
import moment from 'moment';
import { withTranslation } from "react-i18next";
import sortSvg from "../../../assets/images/svg/sort.svg";
import exporticon from "../../../assets/images/svg/exportIcon.svg"
import { DatePicker } from 'antd'
import Papa from 'papaparse';
const { RangePicker } = DatePicker;
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import { getCustomers, getOtherNotifications, getPrinterCsvData, getPrintingPaymentList } from "store/actions";
import UsersColumns, { selectRow } from "./Columns";
import { STORE_FIELD_NAME, STATUS_VALUE, TRIAL_VALUE } from "helpers/contants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { sendVerificationMailRequest, sendVerificationMailSuccess } from "store/emailVerification/action";


const printpayment = (props) => {

  const {
    accessLevel,
    history,
    loading,
    printPayments,
    totalPayments,
    onGetPayments,
    totalAdminEarning,
    quarterlyEarnings,
    monthlyEarnings,
    yearlyEarnings,
    prinitngCsvData,
    onGetCsvData
  } = props;

  const [clearAllBtnForSortBy, setClearAllBtnForSortBy] = useState(false)
  const [dates, setDates] = useState([])
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });

  const [filter, setFilter] = useState({
    orderBy: "created_date",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    role: "",
    timeZone: "",
    fields: [
      {
        fieldName: "status",
        fieldValue: "",
      },
    ],
  });

  const [lastFields, setLastFields] = useState({
    fieldName: "status",
    fieldValue: "",
  });

  const [searchText, setSearchText] = useState("");
  const [userList, setUsersList] = useState([]);
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalPayments,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    page: filter?.page
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };
      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    onGetPayments({ ...filter, fields: [...filter.fields, lastFields] });
    setUsersList(printPayments);
  }, [JSON.stringify(filter), lastFields.fieldValue]);



  useEffect(() => {
    setUsersList(printPayments || []);
  }, [printPayments]);

  useEffect(() => {
    onGetPayments(filter);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {

    if (type === "search") {
      setSearchText(searchText);
    }
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };


  const onFieldNameChange = (value) => {
    setLastFields({ fieldName: value, fieldValue: "" });
    /* setFilter((prevState) => ({
      ...prevState,
      fields: [
        prevState?.fields[0],
        statusFilter,
        { fieldName: value, fieldValue: "" },
      ],
    })) */
  };

  const onFieldValueChange = (value) => {
    let fieldName = lastFields.fieldName;
    let fieldValue = value;

    if (value !== "" && fieldName === "isTrial") {
      fieldName = "billingPlan";
    }

    if (value === "isTrial") {
      fieldName = "isTrial";
      fieldValue = true;
    }

    if (value == "isPaid") {
      fieldName = "isTrial";
      fieldValue = false;
    }

    setLastFields((prevState) => ({ ...prevState, fieldName, fieldValue }));

    /* setFilter((prevState) => ({
      ...prevState,
      fields: [
        prevState?.fields[0],
        statusFilter,
        { ...prevState?.fields[2], fieldValue },
      ],
    })) */
  };

  const onSearch = (event) => {
    event.preventDefault();
    setFilter((prevState) => ({ ...prevState, search: searchText, page: 1 }));
  };

  const [role, setRole] = useState('')
  const selectRole = (e) => {
    setRole(e.target.value);
  }
  const sortDateWise = (e) => {
    setClearAllBtnForSortBy(true)
    if (e.target.value === "Newest_first") {
      setFilter((prevState) => ({
        ...prevState,
        orderBy: "date_created",
        order: -1,
      }));
    }
    else {
      setFilter((prevState) => ({
        ...prevState,
        orderBy: "date_created",
        order: 1,
      }));
    }

  }
  async function arrayToCsv() {

    if (!dates[0]) {
      return toastr.error("Starting Day is required")
    }
    if (!dates[1]) {
      return toastr.error("Ending Day is required")
    }
    let data = {}
    data.starting_day = dates[0]
    data.day = dates[1]
    // data.payment_status = role
    const callback = (res) => {
      if (res.status === "success" && res.data.length > 0) {
        console.log("jfksjadklfjsdkljfkljasdkljfklasd", res.data)
        const data = res.data;
        const customColumnNames = [{ 'Total Order Value': 'trans_hash' }, { 'Admin Commission Percentage': 'admin_earning' }, { 'Seller Earning': 'seller_earning' }, { 'Print Partner Earnings': 'printer_earning' }, { 'Date': 'created_date' }, { 'Payment Status': 'payment_status' }];
        const customData = data.map(item => {
          const customObject = {};
          for (let i = 0; i < customColumnNames.length; i++) {
            const currentObject = customColumnNames[i];
            for (const key in currentObject) {
              if (currentObject.hasOwnProperty(key)) {
                const value = currentObject[key];
                customObject[key] = value !== undefined ? (key === "Date" ? (item[value] ? moment(item[value]).utcOffset(330).format("DD MMM YYYY hh:mm A") : "---") : (key === "Total Order Value" ? item?.admin_earning + item?.seller_earning + item?.printer_earning : item[value])) : "N/A";
              }
            }
          }
          return customObject;
        });
        const csv = Papa.unparse(customData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, `${dates[0]} to ${dates[1]} Earning History`);
        } else {
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.setAttribute('download', `${dates[0]} to ${dates[1]} Earning History`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        return toastr.error("No Data for CSV")
      }

    }

    onGetCsvData(data, callback)

  }
  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }
  const handleClear = () => {
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Manage Payment"
            breadcrumbItem={props.t("Manage Payment")}
            breadcrumbItems={[{ title: props.t("Manage Payment") }]}
          />

          <Row>
            <Col xs="12">
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={userList}
                    columns={UsersColumns(
                      history,
                      props.t,
                      accesses
                    )}
                    bootstrap4
                    search
                  >

                    {(toolkitProps) => (
                      <React.Fragment>
                        <div className="row mt-4">
                          <div className="col-xl-3 ">
                            <div className="paymentbox1">
                              <h1 className="highlighthead">${Number(totalAdminEarning).toFixed(3)} </h1>
                              <p className="highlightP"> Total Earning</p>
                            </div>
                          </div>
                          <div className="col-xl-3 ">
                            <div className="paymentbox2">
                              <h1 className="highlighthead">${Number(yearlyEarnings).toFixed(3)} </h1>
                              <p className="highlightP"> Yearly </p>
                            </div>
                          </div>
                          <div className="col-xl-3 ">
                            <div className="paymentbox3">
                              <h1 className="highlighthead">${Number(quarterlyEarnings).toFixed(3)} </h1>
                              <p className="highlightP"> Quarterly </p>
                            </div>
                          </div><div className="col-xl-3 ">
                            <div className="paymentbox4">
                              <h1 className="highlighthead">${Number(monthlyEarnings).toFixed(3)} </h1>
                              <p className="highlightP"> Monthly </p>
                            </div>
                          </div>


                        </div>
                        <div className="row">
                          <div className="col-xl-12 mb-4 filterBox">

                            <div className="row">
                              <div className="col-xl-7 m-auto">
                                <div className="d-flex gap-3 align-items-center">
                                  <div>
                                    <div className="form-group position-relative">
                                      <SearchInput
                                        {...toolkitProps.searchProps}
                                        triggerSearch={onSearch}
                                        placeholder={props.t("Search")}
                                        searchText={searchText}
                                      />
                                      {/* <input type="text" className='form-control pe-5' placeholder='Search.....' />
                      <button className='btn position-absolute end-0 top-0 bottom-0 p-0 me-3'><i className="fa-light fa-magnifying-glass"></i></button> */}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="form-group position-relative ">

                                      <select
                                        name=""
                                        className="form-control form-select"
                                        id=""

                                        onChange={selectRole}
                                      >
                                        <option value="" selected disabled>Select Status</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Pending">Pending</option>

                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-5 ms-auto">
                                <div className="d-flex align-items-center justify-content-end">
                                  <div >
                                    <div className="text-end">
                                      <div className="d-flex gap-3">
                                        <div className="w-100">
                                          <div style={{ margin: 20, position: "relative" }} className=" position-relative">
                                            {/* <RangePicker className="position-relative" style={{ position: "relative" }}
                                            onClear={handleClear}
                                            disabledDate={disabledDate}
                                            onChange={(values) => {
                                              if (values) {
                                                var dateStr1 = values[0].$d;
                                                var dateStr2 = values[1].$d;

                                                var date1 = moment(dateStr1);
                                                var date2 = moment(dateStr2);

                                                var dateDifference = date2.diff(date1);
                                                var daysDifference = moment.duration(dateDifference).asDays();


                                                var daysDifference = moment.duration(dateDifference).asDays();

                                                setDates(values.map(item => {
                                                  return moment(item.$d).format('YYYY-DD-MM')
                                                }))
                                              }
                                              else {
                                                setFilter((prevState) => ({ ...prevState, starting_day: "", day: "" }))
                                              }
                                            }}
                                          />
                                          <img src="/images/svg/Vector.svg" width="auto" height="auto" classname="position-absolute" style={{ right: "0.875rem", top: "0.5rem", position: "absolute" }} />
                                        </div> */}
                                            <RangePicker className="custom-date-picker"
                                              onClear={handleClear}
                                              disabledDate={disabledDate}
                                              onChange={(values) => {
                                                if (values) {
                                                  var dateStr1 = values[0].$d;
                                                  var dateStr2 = values[1].$d;

                                                  // Parse the date strings using Moment.js
                                                  var date1 = moment(dateStr1);
                                                  var date2 = moment(dateStr2);

                                                  // Calculate the difference between the two dates
                                                  var dateDifference = date2.diff(date1);
                                                  var daysDifference = moment.duration(dateDifference).asDays();
                                                  // if (daysDifference > 15) {
                                                  //   return toastr.error("Date Range should be less than 15 days")
                                                  // }
                                                  // Display the difference in days
                                                  var daysDifference = moment.duration(dateDifference).asDays();
                                                  setDates(values.map(item => {
                                                    return moment(item.$d).format('YYYY-DD-MM')
                                                  }))
                                                }
                                                else {
                                                  setFilter((prevState) => ({ ...prevState, starting_day: '1970-01-01', day: todaysDate }))
                                                }
                                              }} />
                                            <img src="/images/svg/Vector.svg" width="auto" height="auto" classname="position-absolute" style={{ right: "0.875rem", top: "0.7rem", position: "absolute" }} />

                                          </div>

                                        </div>
                                        <div>

                                        </div>
                                      </div>

                                    </div>

                                  </div>
                                  <div>
                                    <button className="btn btn-dark d-flex align-items-center" onClick={() => arrayToCsv(prinitngCsvData)} > <img className="exportimage" src={exporticon} /> Export CSV
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/filterIcon.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Filter
                                  </h3>
                                  <div className="form-group position-relative w-100">
                                    <select
                                      name=""
                                      className="form-control form-select"
                                      id=""

                                      onChange={selectRole}
                                    >

                                      <option value="" selected disabled>Select User Type</option>
                                      <option value="User">User</option>
                                      <option value="Artist">Artist</option>
                                      <option value="Museum">Museum</option>
                                      {/* <option value="Admin">Admin</option> 

                                    {/* </select> *
                                  {/* </div>
                                </div>
                              </div>*
                              <div className="col-xl-3">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/shortby.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Sort by
                                  </h3>
                                  <div className="form-group position-relative w-100">
                                    <select
                                      name=""
                                      className="form-control form-select"
                                      id=""
                                      onChange={sortDateWise}
                                    >
                                      <option value="Newest_first">Newest First</option>
                                      <option value="Oldest_first">Oldest First</option>
                                    </select>
                                  </div>
                                </div>
                              </div> */}
                              {(!!lastFields.fieldValue ||
                                !!filter.search || !!filter.role || clearAllBtnForSortBy === true) && (
                                  <div className='col-xl-2'>
                                    <button onClick={(e) => {
                                      setClearAllBtnForSortBy(false)
                                      e.preventDefault();
                                      setFilter((prevState) => ({
                                        ...prevState,
                                        search: "",
                                        role: "",
                                        orderBy: ""
                                      }));
                                      setSearchText("");
                                      setLastFields({
                                        fieldName: "status",
                                        fieldValue: "",
                                      });
                                    }} className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}</button>
                                  </div>)}
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tableBox usertable">
                              <BootstrapTable
                                responsive
                                remote
                                bordered={false}
                                striped={false}
                                noDataIndication={"No data available"}
                                classes={"table  table-centered table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                defaultSorted={[
                                  {
                                    dataField: "date_created",
                                    order: "desc",
                                  },
                                ]}
                                sort={{
                                  sortCaret: (order) =>
                                    order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) : <span className="caret">
                                      {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                    </span>,
                                }}
                              />

                              {loading && (
                                <div className="spinnerBoxNew ">
                                  <Spinner color="primary" />
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

printpayment.propTypes = {
  t: PropTypes.any,
  totalPayments: PropTypes.number,
  onGetPayments: PropTypes.func,
  onSendEmail: PropTypes.func
};

const mapStateToProps = ({ Partners }) => ({
  loading: Partners.loading,
  printPayments: Partners.printingPaymnetRequest,
  totalPayments: Partners.totalPrintingPayment,
  totalAdminEarning: Partners.totalAdminEarning,
  quarterlyEarnings: Partners.quarterlyEarnings,
  monthlyEarnings: Partners.monthlyEarnings,
  yearlyEarnings: Partners.yearlyEarnings,
  prinitngCsvData: Partners.prinitngCsvData
});

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPayments: (data) => dispatch(getPrintingPaymentList(data)),
    onGetCsvData: (data, callback) => dispatch(getPrinterCsvData(data, callback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(printpayment))
);
