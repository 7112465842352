import { OTHER_NOTIFICATION_FAIL, OTHER_NOTIFICATION_REQUEST, OTHER_NOTIFICATION_SUCCESS } from "./actionTypes";

export const otherNotificationRequest = (data, callback) => ({
    type: OTHER_NOTIFICATION_REQUEST,
    payload: {data,callback},
  });
  
  export const otherNotificationSuccess = (data) => ({
    type: OTHER_NOTIFICATION_SUCCESS,
    payload: data,
  });
  
  export const otherNotificationFail = (error) => ({
    type: OTHER_NOTIFICATION_FAIL,
    payload: error,
  });