import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

const FooterSettings = (props) => {
  const { fields, handleChange } = props;

  return (
    <React.Fragment>
      <Row>
        {/* <Col lg={12}>
          <Instructions t={props.t} />
        </Col> */}
        <Col md={12}>
              <FormGroup>
                <Label>Description</Label>

                <Input
                 type="textarea"
                  value={fields.description}
                  onChange={handleChange("description")}
                />
              </FormGroup>
            </Col>
      </Row>
    </React.Fragment>
  );
};

FooterSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FooterSettings)
);
