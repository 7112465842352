import { call, put, takeEvery } from "redux-saga/effects";
import { getAllSellItemsWithFilterFail, getAllSellItemsWithFilterSuccess } from "./actions";
import { GET_ALL_SELL_ITEMS } from "./actionTypes";
import { getSellItemsWithFilterApi } from "helpers/backend_helper";
function* getSellItemsWithFilter({ payload: { data, callback } }) {
    try {
      console.log("rronMarkOtherSellItemscalled");
      const response = yield call(getSellItemsWithFilterApi, data);
      console.log("ronMarkOtherSellItems", response);
      if (response.status == "failure") {
        return yield put(getAllSellItemsWithFilterFail(response.message));
      }
      yield put(getAllSellItemsWithFilterSuccess(response));
      callback && callback(response);
    } catch (error) {
      yield put(getAllSellItemsWithFilterFail("Internal Error!"));
    }
  }
  
  function* SellItemsSaga() {
    yield takeEvery(GET_ALL_SELL_ITEMS, getSellItemsWithFilter);
  }
  export default SellItemsSaga;
  