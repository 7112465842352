import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Button, Modal, ModalBody, Spinner } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, updatePrinterWithdrawlList, viewPrinterWithdrawlList } from "store/actions";
import CustomersList from ".";
import addprintclose from "assets/images/svg/addprintclose.svg"

import moment from "moment-timezone";

const ViewPrintWithdrawal = props => {

  const { isOpen, toggle, onConfirm, userId, getStatusValue, onGetwithdrawl, withdrawl, onUpdatewithdrawlStatus } = props
 const [loading, setLoading] =useState(false)
  const [viewDialog, setViewDialog] = useState(false)

  const handleStatusChange = (data) => {
    setLoading(true)
    let object = {
      id: userId,
      status: data,
      partner_id: withdrawl?.partner_id
    }
    getStatusValue(object)

    if (withdrawl?.type === "crypto") {

    }
    if (data !== "Failed") {
      const callback = (res) => {
        setLoading(false)
        if (res.status === 'success') {
          toastr.success("Status Updated Successfully")
          toggle()

        } else {
          setLoading(false)
          toastr.error(res.message);
        }
      }
      onUpdatewithdrawlStatus(object, callback)
    }
  }


  useEffect(() => {
    if (userId && isOpen === true) {
      onGetwithdrawl({ id: userId })
    };
  }, [userId, isOpen, viewuser])
  const closeDialog = () => {
    setViewDialog(false)
  }
  return (

    <>
      {(props?.particularCustomerLoading|| loading) ? (
        <div className="spinnerBoxNew">
          <Spinner color="primary" />
        </div>) :
        (
          <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>

            <div className='dialogViewInner'>
              <div className='addprinthead'>
                <h1>View</h1>
                <button className='closebtn' onClick={toggle}>
                  <img src={addprintclose} alt="close" width={40} height={40} />
                </button>
              </div>
              <div className={'dialogbody ' + (viewuser.viewProfile)}>
                {/* <div className='details'>
                  <h5>Request ID</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Request ID</label>
                        <input type="text" className='form-control' placeholder="Request ID" value={withdrawl?.withdraw_track_id} readOnly />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className='details'>
                  <h5>Partner Details</h5>
                  <div className='row'>
                  <div className='col-xl-3 col-md-3 col-sm-3 mb-4'>
                  <div className='form-group'>
                        <label>Request ID</label>
                        <input type="text" className='form-control' placeholder="Request ID" value={withdrawl?.withdraw_track_id} readOnly />
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-3 mb-4'>
                      <div className='form-group'>
                        <label>Partner Name</label>
                        <input type="text" className='form-control' placeholder="Partner Name" value={withdrawl?.partner_id?.shop_name || 'N/A'} readOnly />
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-3 mb-4'>
                      <div className='form-group'>
                        <label>Contact Number</label>
                        <input type="text" className='form-control' placeholder="Contact Number" value={withdrawl?.partner_id?.business_contact_number || 'N/A'} readOnly />
                      </div>
                    </div>{withdrawl?.payment_method!=="bank" &&
                    <div className='col-xl-3 col-md-3 col-sm-3 mb-4'>
                      <div className='form-group'>
                        <label>Wallet Address</label>
                        <input type="text" className='form-control' placeholder="Wallet Address" value={withdrawl?.wallet_address || 'N/A'} readOnly />
                      </div>
                    </div>
}
                    {withdrawl?.payment_method==="bank"  &&<>
                    <div className="col-lg-12">
                      <div className="row">
                      <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Bank Name</label>
                        <input type="text" className='form-control' placeholder="Wallet Address" value={withdrawl?.bank_name || 'N/A'} readOnly />
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Account Number</label>
                        <input type="text" className='form-control' placeholder="Wallet Address" value={withdrawl?.account_number || 'N/A'} readOnly />
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>IFSC Code</label>
                        <input type="text" className='form-control' placeholder="Wallet Address" value={withdrawl?.ifsc_code || 'N/A'} readOnly />
                      </div>
                    </div>
                    <div className='col-xl-12 col-md-12 col-sm-12 mb-4'>
                      <div className='form-group'>
                        <label>Comments/Notes</label>
                        <textarea type="text" className='form-control' placeholder="Wallet Address" value={withdrawl?.reason || 'N/A'} readOnly ></textarea>
                      </div>
                    </div>
                      </div>
                    </div>
                    <div className='col-xl-12 col-md-12 col-sm-12 mb-4'>
                      <div className='form-group'>
                        <label>Address</label>
                        <input type="text" className='form-control' placeholder="Address" value={withdrawl?.partner_id?.business_address || 'N/A'} readOnly />
                      </div>
                    </div></>
}
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Withdraw Amount</label>
                        <input type="text" className='form-control' placeholder="Withdraw Amount" value={withdrawl?.withdrawal_amount?("$ "+ withdrawl?.withdrawal_amount) : 'N/A'} readOnly />
                      </div>
                    </div>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Payment Method</label>
                        {/* <input type="text" className='form-control' placeholder="Payment Method" value={withdrawl?.payment_method || 'N/A'} readOnly /> */}
                        <input type="text" className='form-control' placeholder="Payment Method" value={withdrawl?.payment_method==="crypto" ? "Crypto" : "Bank"} readOnly />
                      </div>
                    </div>
                  </div>
                  {(withdrawl?.admin_status === 'Pending' || withdrawl.admin_status === 'In Progress') &&
                    <div className='row'>
                      <div className="col-xl-12 col-12 col-md-12 col-sm-12 ">
                        <button className="btn success-button px-5 text-white me-3" onClick={() => handleStatusChange(withdrawl?.admin_status === 'Pending' ? "Approve" : "Completed")} type="submit">
                          {withdrawl?.admin_status === 'Pending' ? "Approve" : "Completed"}
                        </button>
                        <button className="btn btn-outline-danger px-5" onClick={() => handleStatusChange(withdrawl?.admin_status === 'Pending' ? "Reject" : "Failed")} >
                          {withdrawl?.admin_status === 'Pending' ? "Reject" : "Failed"}
                        </button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  )

}
ViewPrintWithdrawal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any,
  onGetwithdrawl: PropTypes.func
}
const mapStateToProps = ({ Partners }) => ({
  error: Partners.error,
  loading: Partners.loading,
  withdrawl: Partners.viewWithdrawlRequest
});

const mapDispatchToProps = (dispatch) => ({
  onGetwithdrawl: (data) => dispatch(viewPrinterWithdrawlList(data)),
  onUpdatewithdrawlStatus: (data) => dispatch(updatePrinterWithdrawlList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPrintWithdrawal);