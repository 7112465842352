import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

const HomePageHideShow = (props) => {
  const { fields, handleChange, t } = props;

  return (
    <React.Fragment>
      <Row>
        <Col className="my-auto" lg={4}>
          <Instructions className="mb-0" t={""} />
        </Col>

        <Col lg={8}>
          <Row>
            <Col md={6}>
              <div className="form-group mb-0">
                <div className="customeslideBox w-fitcontent">
                  <input
                    type="checkbox"
                    id="square-switch1"
                    switch="none"
                    checked={fields.isCommingSoon == true}
                    onChange={() => {
                      const value = fields.isCommingSoon == true ? false : true;

                      handleChange("isCommingSoon")({ target: { value } });
                    }}
                  />
                  <label
                    htmlFor="square-switch1"
                    data-on-label="Mainnet"
                    data-off-label="Testnet"
                  />
                  <p>{fields.isCommingSoon == true ? 'True': 'False'}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

HomePageHideShow.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePageHideShow)
);
