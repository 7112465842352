import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
  getContentPages,
  deleteContentPage,
  putContentPagesStatus,
} from "store/contentpage/actions";

import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import SearchInput from "components/Common/SearchInput";
import sortSvg from "../../../assets/images/svg/sort.svg";

const ContentPages = ({
  accessLevel,
  history,
  contentPages,
  totalContentPages,
  onGetContentPages,
  onDeleteContentPage,
  onPutContentPagesStatus,
  loading,
  ...props
}) => {
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    contentId: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 10,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [searchText, setSearchText] = useState("");
  const [contentPagesList, setContentPagesList] = useState([]);
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalContentPages,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    onGetContentPages(filter);
    setContentPagesList(contentPages);
  }, [onGetContentPages, JSON.stringify(filter)]);

  useEffect(() => {
    setContentPagesList(contentPages || []);
  }, [contentPages]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (contentId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      contentId,
    }));
  };

  const removeConfirm = () => {
    const { contentId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetContentPages(filter);
    };

    setConfirmModal({ isOpen: !isOpen, contentId: null });
    onDeleteContentPage({ _id: contentId }, onComplete);
  };

  const onStatusChange = (value) => {
    const fieldName = !!value ? "status" : "";
    const fieldValue = value;

    setFilter((prevState) => ({
      ...prevState,
      fields: { fieldName, fieldValue },
    }));
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText, page: 1 }));
  };

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const handleContentPageStatusChange = (status) => {
    const callback = () => {
      setSelected([]);
      onGetContentPages(filter);
    };

    onPutContentPagesStatus({ _id: selected, status }, callback);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="ContentPages"
            breadcrumbItem={props.t("Content Pages")}
            breadcrumbItems={[{ title: props.t("Content Pages") }]}
          />

          <Row>
            <Col xs="12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={contentPagesList || []}
                    columns={ListColumns(
                      history,
                      toggleConfirmModal,
                      accesses,
                      props.t
                    )}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-xl-12 mb-4 filterBox">
                            <div className="row">
                              <div className="col-xl-5">
                                <div className="form-group position-relative">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search")}
                                    searchText={searchText}
                                  />

                                </div>
                              </div>
                              {/* <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/filterIcon.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Filter
                                  </h3>
                                  <div className="form-group position-relative w-100">
                                    <select
                                      className=" form-control form-select"
                                      onChange={(e) =>
                                        onStatusChange(e.target.value)
                                      }
                                      value={filter.fields.fieldValue}
                                    >
                                      <option value="">
                                        {props.t("status")}
                                      </option>
                                      <option value="active">
                                        {props.t("active")}
                                      </option>
                                      <option value="inactive">
                                        {props.t("inactive")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div> */}
                              { /*<div className="col-xl-3">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/shortby.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Sort by
                                  </h3>
                                  <div className="form-group position-relative w-100">
                                    <select
                                      name=""
                                      className="form-control form-select"
                                      id=""
                                      onChange={sortDateWise}
                                    >
                                      <option value="Newest_first">Newest First</option>
                                      <option value="Oldest_first">Oldest First</option>
                                    </select>
                                  </div>
                                </div>
                              </div> */}
                              {!!filter.fields.fieldName ||
                                (!!filter.search && (
                                  <div className='col-xl-2'>
                                    <button

                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          fields: {
                                            fieldName: "",
                                            fieldValue: "",
                                          },
                                          search: "",
                                        }));
                                        setSearchText("");
                                      }}

                                      className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}
                                    </button>
                                  </div>
                                ))}

                              <div className="col-xl-3 ms-auto text-end">
                                <Link
                                  className="btn btn-dark py-2 px-4 rounded-pill"
                                  to={'/content-pages/add'}
                                >
                                  Add Content Page
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Row>
                          <Col
                            className="mb-3"
                            xs={12}
                            md={7}
                            lg={8}
                            xl={7}
                          >
                            {/* <div className="custom-flex-sm d-flex align-items-center">
                                  <div className="filter">
                                    <div className="form-group d-flex align-items-center mb-0">
                                      <label
                                        className="mb-0"
                                        style={{ minWidth: "54px" }}
                                      >
                                        {props.t("filter_by")}:
                                      </label>

                                      <div className="w-auto pl-3">
                                        <select
                                          className="custom-select w-100"
                                          onChange={(e) =>
                                            onStatusChange(e.target.value)
                                          }
                                          value={filter.fields.fieldValue}
                                          style={{ minWidth: "106px" }}
                                        >
                                          <option value="">
                                            {props.t("status")}
                                          </option>
                                          <option value="active">
                                            {props.t("active")}
                                          </option>
                                          <option value="inactive">
                                            {props.t("inactive")}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="search d-flex align-items-center">
                                    <div className="pl-3">
                                      <SearchInput
                                        {...toolkitProps.searchProps}
                                        triggerSearch={onSearch}
                                        placeholder={props.t("search")}
                                        searchText={searchText}
                                      />
                                    </div>

                                    {!!filter.fieldName ||
                                      (!!filter.search && (
                                        <div
                                          className="mr-4"
                                          style={{
                                            minWidth: "73px",
                                          }}
                                        >
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              e.preventDefault()
                                              setFilter((prevState) => ({
                                                ...prevState,
                                                fields: {
                                                  fieldName: "",
                                                  fieldValue: "",
                                                },
                                                search: "",
                                              }))
                                              setSearchText("")
                                            }}
                                            className=""
                                          >
                                            {props.t("clear_filters")}
                                          </Link>
                                        </div>
                                      ))}
                                  </div>
                                </div> */}
                          </Col>
                          <Col
                            className="mb-3"
                            xs={12}
                            md={5}
                            lg={4}
                            xl={5}
                          >
                            {/* <div className="text-sm-left text-md-right">
                                  {accesses.canAdd && (
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                      onClick={() =>
                                        history.push("/content-pages/add")
                                      }
                                    >
                                      <i className="mdi mdi-plus mr-1" />
                                      {props.t("Add Content Page")}
                                    </Button>
                                  )}
                                </div> */}
                          </Col>
                        </Row>
                        {(accesses.canEdit || accesses.canDelete) && (
                          <AnimateHeight
                            duration={500}
                            height={selected.length > 0 ? "auto" : 0}
                          >
                            <div className="custom-flex-xs d-flex align-items-center mb-3">
                              {accesses.canEdit && (
                                <div className="filter form-group d-flex align-items-center mb-0 mr-3">
                                  <label
                                    className="mb-0"
                                    style={{ minWidth: "48px" }}
                                  >
                                    {props.t("action")}:
                                  </label>

                                  <div className="w-auto pl-3">
                                    <select
                                      className="custom-select w-100"
                                      onChange={(e) =>
                                        handleContentPageStatusChange(
                                          e.target.value
                                        )
                                      }
                                      style={{ minWidth: "106px" }}
                                    >
                                      <option value="">
                                        {props.t("status")}
                                      </option>
                                      <option value="active">
                                        {props.t("active")}
                                      </option>
                                      <option value="inactive">
                                        {props.t("inactive")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              )}

                              {accesses.canDelete && (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    handleContentPageStatusChange(
                                      "archived"
                                    )
                                  }
                                >
                                  {props.t("delete")} {props.t("selected")}
                                </button>
                              )}
                            </div>
                          </AnimateHeight>
                        )}

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tableBox categorytable">
                              <BootstrapTable
                                responsive
                                remote
                                bordered={false}
                                striped={false}
                                noDataIndication={"No data available"}
                                classes={
                                  "table table-centered table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                defaultSorted={[
                                  {
                                    dataField: "createdAt",
                                    order: "desc",
                                  },
                                ]}
                                sort={{
                                  sortCaret: (order) =>
                                    order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) : <span className="caret">
                                      {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                    </span>,
                                }}
                              />

                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>

            </Col>
          </Row>
        </Container>
      </div>

      {loading && (
        <div className="spinnerBoxNew">
          <Spinner color="primary" />
        </div>
      )}
    </React.Fragment>
  );
};

ContentPages.propTypes = {
  contentPages: PropTypes.array,
  totalContentPages: PropTypes.number,
  onGetContentPages: PropTypes.func,
  onDeleteContentPage: PropTypes.func,
  onPutContentPagesStatus: PropTypes.func,
};

const mapStateToProps = ({ content_page }) => ({
  loading: content_page.loading,
  contentPages: content_page.contentPages,
  totalContentPages: content_page.totalContentPages,
});

const mapDispatchToProps = (dispatch) => ({
  onGetContentPages: (data) => dispatch(getContentPages(data)),
  onDeleteContentPage: (data, callback) =>
    dispatch(deleteContentPage(data, callback)),
  onPutContentPagesStatus: (data, callback) =>
    dispatch(putContentPagesStatus(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContentPages))
);
