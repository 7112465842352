import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import toastr from "toastr";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getBlogs, putItemsStatus, deleteBlogs, viewBlog } from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import ViewBlog from "./ViewBlog";
import sortSvg from "../../../assets/images/svg/sort.svg";
import CategoryForm from "pages/Report/AddEdit";
const Items = (props) => {
  const {
    accessLevel,
    history,
    items,
    totalCategories,
    onGetBlogs,
    onDeleteItem,
    onPutItemsStatus,
    onViewBlog,
    loading,
  } = props;
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    itemId: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "date_created",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    // status: "active",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [searchText, setSearchText] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalCategories,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    page:filter?.page
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    onGetBlogs(filter);
    setItemsList(items);
  }, [onGetBlogs, JSON.stringify(filter)]);

  useEffect(() => {
    setItemsList(items || []);
  }, [items]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "create_date";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (itemId) => {
    const itemIds = itemId ? itemId : selected;
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      itemIds,
    }));
  };

  const removeConfirm = () => {
    const { itemIds, isOpen } = confirmModal;

    const onComplete = () => {
      onGetBlogs(filter);
      setConfirmModal({ isOpen: !isOpen, itemIds: null });
      toastr.success("Deleted Successfully");
    };
    onDeleteItem({ _id: itemIds }, onComplete);
  };

  const onStatusChange = (value) => {
    const fieldName = !!value ? "status" : "";
    const fieldValue = value;
    setFilter((prevState) => ({
      ...prevState,
      fields: { fieldName, fieldValue },
    }));
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText, page:1 }));
  };

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const handleItemStatusChange = (status) => {
    const callback = () => {
      setSelected([]);
      onGetBlogs(filter);
    };
    onPutItemsStatus({ _id: selected, status }, callback);
  };

  const [categoryFormOpen, setCategoryFormOpen] = useState(false);

  const categoryFormHandler = () => {
    setCategoryFormOpen(true);
  };
  const closeFormHandler = () => {
    setCategoryFormOpen(false);
  };
  const [viewUser, setviewUser] = useState({
    isOpen: false,
    userId: null,
  });
  const toggleViewUser = (userId) => {
    console.log('dee');
    setviewUser((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };
  console.log(confirmModal, "confirmModal");
  return (


    <React.Fragment>
      <ViewBlog
        isOpen={viewUser.isOpen}
        userId={viewUser.userId}
        toggle={toggleViewUser}
      />
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="How to Articles / Videos"
            breadcrumbItem={props.t("How to Articles / Videos")}
            breadcrumbItems={[{ title: props.t("How to Articles / Videos") }]}
          />
          {categoryFormOpen && <CategoryForm closeForm={closeFormHandler} />}
          <Row>
            <Col xs="12">

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={itemsList || []}
                    columns={ListColumns(
                      history,
                      toggleViewUser,
                      toggleConfirmModal,
                      accesses,
                      props.t
                    )}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-xl-12 mb-4 filterBox">

                            <div className="row">
                              <div className="col-xl-5">
                                <div className="form-group position-relative">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search")}
                                    searchText={searchText}
                                  />

                                </div>
                              </div>
                              <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/filterIcon.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Filter
                                  </h3>
                                  <div className="form-group position-relative w-100">
                                    <select
                                      className=" form-control form-select"
                                      onChange={(e) =>
                                        onStatusChange(e.target.value)
                                      }
                                      value={filter.fields.fieldValue}
                                    >
                                      <option value="">
                                        {props.t("status")}
                                      </option>
                                      <option value="active">
                                        {props.t("active")}
                                      </option>
                                      <option value="inactive">
                                        {props.t("inactive")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              { /*<div className="col-xl-3">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/shortby.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Sort by
                                  </h3>
                                  <div className="form-group position-relative w-100">
                                    <select
                                      name=""
                                      className="form-control form-select"
                                      id=""
                                      onChange={sortDateWise}
                                    >
                                      <option value="Newest_first">Newest First</option>
                                      <option value="Oldest_first">Oldest First</option>
                                    </select>
                                  </div>
                                </div>
                              </div> */}
                              {
                                (((filter.search) || (filter.fields.fieldName)) && (
                                  <div className='col-xl-2'>
                                    <button

                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          fields: {
                                            fieldName: "",
                                            fieldValue: "",
                                          },
                                          search: "",
                                        }));
                                        setSearchText("");
                                      }}

                                      className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}
                                    </button>
                                  </div>
                                ))}

                              <div className="col-xl-3 ms-auto text-end">
                                <Link
                                  className="btn btn-dark py-2 px-4 rounded-pill"
                                  to={'/blog/add'}
                                >
                                  Add Blog
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>


                        {/* {(accesses.canEdit || accesses.canDelete) && (
                           
                                <Row className="pb-2">
                                  {accesses.canEdit && (
                                    <Col sm="2">
                                      <div className="form-group row">
                                        <label className="col-md-5 pr-0 col-form-label">
                                          {props.t("action")}:
                                        </label>

                                        <div className="col-md-7">
                                          <select
                                            className="custom-select"
                                            onChange={(e) =>
                                              handleItemStatusChange(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              {props.t("status")}
                                            </option>
                                            <option value="active">
                                              {props.t("active")}
                                            </option>
                                            <option value="inactive">
                                              {props.t("inactive")}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  {accesses.canDelete && (
                                    <Col sm="2">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => toggleConfirmModal()}
                                      >
                                        {props.t("delete")}{" "}
                                        {props.t("selected")}
                                      </button>
                                    </Col>
                                  )}

                                  <Col></Col>
                                </Row>
                              
                            )} */}

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tableBox">
                              <BootstrapTable
                                responsive
                                noDataIndication={"No data available"}
                                remote
                                bordered={false}
                                striped={false}
                                classes={
                                  "table table-centered table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                defaultSorted={[
                                  {
                                    dataField: "createdAt",
                                    order: "desc",
                                  },
                                ]}
                                sort={{
                                  sortCaret: (order) =>
                                    order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) : <span className="caret">
                                      {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                    </span>,
                                }}
                              // selectRow={selectRow({
                              //   selected,
                              //   onSelect,
                              //   onSelectAll,
                              // })}
                              />


                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                        {loading && (
                          <div className="spinnerBoxNew">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Items.propTypes = {
  items: PropTypes.array,
  totalCategories: PropTypes.number,
  onGetBlogs: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onPutItemsStatus: PropTypes.func,
  onViewBlog: PropTypes.func,
};

const mapStateToProps = ({ Category }) => ({
  loading: Category.loading,
  items: Category.items,
  totalCategories: Category.totalCategories,
});

const mapDispatchToProps = (dispatch) => ({
  onGetBlogs: (data) => dispatch(getBlogs(data)),
  onDeleteItem: (data, callback) => dispatch(deleteBlogs(data, callback)),
  onPutItemsStatus: (data, callback) =>
    dispatch(putItemsStatus(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Items))
);
