import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, Spinner } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { connect } from "react-redux";
import toastr from "toastr";
//Import actions
import { getCustomer, putVerifyProfileRequest } from "store/actions";



const ViewRequest = props => {
  const {

    onGetCustomer,
    onPutVerifyProfileRequest,
    customer,
    error,
    loading,
  } = props;
  console.log(customer?.role);
  const { data, toggle, onConfirm } = props
  const [userRole, setuserRole] = useState()
  useEffect(() => {
    setuserRole(customer?.role)
  }, [customer?.role])
  console.log(data.userId, data);
  const [viewDialog, setViewDialog] = useState(false)
  // const closeDialog = () => {
  //   console.log('Deepak');
  //   setViewDialog(false)

  // }


  console.log(customer, 66565657489);

  useEffect(() => {
    if (data && data.userId) {
      onGetCustomer(data.userId);
    }
  }, [data]);

  const changeVerifyStatus = (name) => {
    var parameter = {
      user_id: data.userId,
      verificationProfileStatus: name,
    };
    const callback = (response) => {
      console.log("reject accept response", response)
      if (response.status === "success") {
        console.log("inside, success erposnse");
        toastr.success("Profile verify successfully.");
        onGetCustomer(data.userId);
        // closeDialog()
        toggle
      } else {
        toastr.error("Profile not verify.");
        onGetCustomer(data.userId);
      }
    };
    onPutVerifyProfileRequest(parameter, callback);
  };

  console.log(customer, 549847845);
  return (
    <>
      {/* <Modal
     
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle} className="dialogInner"
    >
      <div className="modal-content ">
        <ModalBody>
          <div className="infoimg">
           Deepak
          </div>

          <h4>Are you sure?</h4>

          <div className="delete-btns">
            <Button type="button" outline color="secondary" onClick={toggle}>
              No, cancel!
            </Button>

            <Button type="button" color="primary" onClick={onConfirm}>
              Yes, delete it!
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal> */}
      <div className={'dialogMain ' + (data.isOpen === true ? 'show' : '')}>
        {/* <div className={'dialogMain show'}> */}
        <div className='dialogInner'>
          <div className='dialogHead'>
            <h1>View Request</h1>
            <button className='closebtn' onClick={toggle}>
              <img src={'/images/svg/icons/closeIcon.svg'} alt="close" width={40} height={40} />
            </button>
          </div>
          <div className={'dialogbody ' + (viewuser.viewProfile)}>
            <div className='row'>
              <div className='col-xl-5'>
                <div className={viewuser.profilephoto}>
                  <img src={customer?.profileImage?.link} alt="close" width={40} height={40} />
                </div>
              </div>
              <div className='col-xl-7'>
                <div className='row'>
                  {userRole === 'MUSEUM' &&
                    <>
                      <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                        <div className='form-group'>
                          <label >Museum Name</label>
                          <input type="text" className='form-control' readOnly value={customer?.museumName} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                        <div className='form-group'>
                          <label >Wallet Address</label>
                          <input type="text" className='form-control' readOnly value={customer?.walletAddress} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Contact Person Name</label>
                          <input type="text" className='form-control' readOnly value={customer?.name} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Contact Number</label>
                          <input type="text" className='form-control' readOnly value={customer?.mobileNumber} name="" id="" />
                        </div>
                      </div>
                    </>
                  }
                  {(userRole === 'ARTIST') &&
                    <>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Name</label>
                          <input type="text" className='form-control' readOnly value={customer?.name} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Last Name</label>
                          <input type="text" className='form-control' readOnly value={customer?.lastname} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Gender</label>
                          <input type="text" className='form-control' readOnly value={customer?.gender} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Age</label>
                          <input type="text" className='form-control' readOnly value={customer?.age} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Phone Number</label>
                          <input type="text" className='form-control' readOnly value={customer?.mobileNumber} name="" id="" />
                        </div>
                      </div>
                      <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                        <div className='form-group'>
                          <label >Email</label>
                          <input type="text" className='form-control' readOnly value={customer?.email} name="" id="" />
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className='row'>

              {userRole === 'MUSEUM' &&
                <>
                  <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                    <div className='form-group'>
                      <label >Email</label>
                      <input type="text" className='form-control' readOnly value={customer?.email} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                    <div className='form-group'>
                      <label >Document Number </label>
                      <div className={'form-group position-relative ' + viewuser.documentdetails}>
                        <input type="text" className='form-control' readOnly value={customer?.docs_number} name="" id="" />
                        <a className='position-absolute end-0 top-0 bottom-0' href={customer?.docs_link?.link}>View Document</a>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                    <div className='form-group'>
                      <label >Description</label>
                      <textarea className='form-control' readOnly value={customer?.description} name="" id="" ></textarea>
                    </div>
                  </div>
                  <div className='col-xl-12 col-12 col-md-6 col-sm-6 mb-4'>
                    <div className='form-group'>
                      <label >Museum Address</label>
                      <input type="text" className='form-control' readOnly value={customer?.address} name="" id="" />
                    </div>
                  </div>
                </>
              }
              {(userRole === 'ARTIST') &&
                <>
                  <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                    <div className='form-group'>
                      <label >Address</label>
                      <input type="text" className='form-control' readOnly value={customer?.address + ' ' + customer?.city + ' ' + customer?.state + ' ' + customer?.country + ' ' + customer?.zipcode} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                    <div className='form-group'>
                      <label >Company Name</label>
                      <input type="text" className='form-control' readOnly value={customer?.companyName} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                    <div className='form-group'>
                      <label >Website</label>
                      <input type="text" className='form-control' readOnly value={customer?.website} name="" id="" />
                    </div>
                  </div>
                  <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                    <div className='form-group'>
                      <label >Social  Media</label>
                      <input type="text" className='form-control' readOnly value={customer?.socialMedia} name="" id="" />
                    </div>
                  </div>
                </>
              }
              {customer?.verificationProfileStatus=="requested"  &&
                <div className='col-xl-12 col-12 col-md-12 col-sm-12 '>
                  <div className='row'>
                    <div className='col-xl-6'>
                      <button className='btn verifybtn w-100 text-white' onClick={() => changeVerifyStatus("accept")}>Accept</button>
                    </div>
                    <div className='col-xl-6'>
                      <button className='btn btn-outline-danger w-100' onClick={() => changeVerifyStatus("reject")}>Reject</button>
                    </div>
                  </div>
                </div>
              }

            </div>
          </div>

          {loading && (
            <div className="spinnerBoxNew">
              <Spinner color="primary" />
            </div>
          )}
        </div>
      </div>
    </>
  )

}


ViewRequest.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  customer: PropTypes.any,
  onGetCustomer: PropTypes.func,
  toggle: PropTypes.func,
  data: PropTypes.object,
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getCustomer(id)),
  onPutVerifyProfileRequest: (data, callback) =>
    dispatch(putVerifyProfileRequest(data, callback)),
});
// export default ViewRequest
export default connect(mapStateToProps, mapDispatchToProps)(ViewRequest);