import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import logoLightPng from "../../assets/images/logo.png";
import logoDark from "../../assets/images/logo.png";
import mini_logoDark from "../../assets/images/mini-logo.png";
import logodark from "../../assets/images/logoopen.png";
import logodark2x from "../../assets/images/logoopen.png";
import logoheader from "../../assets/images/logo.png";
import logoheader2x from "../../assets/images/logoopen.png";
//Simple bar
import SimpleBar from "simplebar-react";
import adminStyle from "../../assets/css/admin.module.css";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Sidebar = (props) => {
 const menutoggle =() => {
  const maineBox =  document.getElementById('layout-wrapper')
  // console.log('menu',maineBox);
  maineBox.classList.toggle('active')
 }
  return (
    <React.Fragment>
      <div className="vertical-menu position-relative">
      {/* <button onClick={() => menutoggle()} className="togglebtn btn btn-primary p-2"><i class='bx bx-menu-alt-left'></i></button> */}
        <div data-simplebar className="h-100">

        <div className={'logobx'}>
        <Link href="/" className="navbar-brand py-0">
          <div className="d-flex align-items-center justify-content-start position-relative logobox gap-3">
            <>
              <img
                alt="logo"
                className="logodesktop"
                src={"/images/logoNew.png"}
               
              />
            </>

            {/* <div className={'logohead'}>Terpnash</div> */}
          </div>
        </Link>
        
      </div>

          {props.type !== "condensed" ? (
            <SimpleBar style={{ maxHeight: "100%" }}>
              <SidebarContent date={'deepak'} />
            </SimpleBar>
          ) : (
            <SidebarContent date={'deepak'} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
