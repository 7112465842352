import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  VERIFY_PROFILE_REQUEST,
  VERIFY_PROFILE_REQUEST_FAIL,
  VERIFY_PROFILE_REQUEST_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state, loading: true };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case CHANGE_PASSWORD:
      state = { ...state, loading: true };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case CHANGE_PASSWORD_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;

    case VERIFY_PROFILE_REQUEST:
      state = { ...state, loading: true };
      break;
    case VERIFY_PROFILE_REQUEST_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case VERIFY_PROFILE_REQUEST_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
