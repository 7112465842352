import { GET_ADMIN_EARNING, GET_ADMIN_EARNING_FAIL, GET_ADMIN_EARNING_SUCCESS, GET_ALL_TRANSACTIONS, GET_ALL_TRANSACTIONS_FAIL, GET_ALL_TRANSACTIONS_SUCCESS, GET_CSV, GET_CSV_FAIL, GET_CSV_SUCCESS, GET_MANAGE_EARNING, GET_MANAGE_EARNING_FAIL, GET_MANAGE_EARNING_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    error: "",
    loading: false,
    transactions: [],
    Earnings: [],
    CsvData: [],
    totalTransactions: 0,
    adminEarning: 0,
    totalEarnings: 0
}

const Transactions = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_TRANSACTIONS:
        case GET_MANAGE_EARNING:
        case GET_CSV:
        case GET_ADMIN_EARNING:
            return {
                ...state,
                loading: true,
            }

        case GET_ALL_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: action.payload.data || [],
                totalTransactions: action.payload.totalCount || 0,
                loading: false,
                error: "",
            }
        case GET_MANAGE_EARNING_SUCCESS:

            return {
                ...state,
                Earnings: action.payload.data || [],
                totalEarnings: action.payload.totalCount || 0,
                loading: false,
                error: "",
            }
        case GET_CSV_SUCCESS:
            return {
                ...state,
                CsvData: action.payload.data || [],
                loading: false,
                error: "",
            }
        case GET_ADMIN_EARNING_SUCCESS:
            return {
                ...state,
                adminEarning: action.payload.data || [],
                loading: false,
                error: "",
            }


        case GET_ALL_TRANSACTIONS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case GET_MANAGE_EARNING_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case GET_ADMIN_EARNING_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case GET_CSV_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state
    }
}

export default Transactions