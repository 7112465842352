import React, { useState } from "react";
import {
  Alert,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { post } from "../../../helpers/api_helper";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { ADD_CATEGORY, POST_FILE } from "../../../helpers/url_helper";
import styles from "./AddEditForm.module.css";

function CategoryForm(props) {
  const closeHandler = () => {
    props.closeForm();
  };
  const [name, setName] = useState("");
  const [status, setStatus] = useState(false);
  const [statusData, setStatusData] = useState("inactive");
  const [file, setFile] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [data, setData] = useState({ msg: "", color: "" });
  const [fileTouched, setFileTouched] = useState(false);
  const [categoryAdded, setCategoryAdded] = useState(false);
  const [loading, setLoading] = useState(false);

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const switchToggleHandler = (e) => {
    setStatus((oldValue) => !oldValue);
    setStatusData(e.target.checked ? "active" : "inactive");
  };

  const fileHandler = (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    setLoading(true);
    post(POST_FILE, formData)
      .then((res) => {
        setFile(res.data._id);
        setLoading(false);
      })
      .catch((err) => {
        setFormValid(false);
        setData({ msg: "Cannot post image to the server", color: "danger" });
        setLoading(false);
      });
    setFileTouched(true);
  };

  const formHandler = (e) => {
    e.preventDefault();
    setCategoryAdded(false);
    setData({ msg: "", color: "" });
    setFormSubmitted(true);

    if (fileTouched && file === null) {
      setFormValid(false);
      setData({
        msg: "Processing Image!! Please submit again in few seconds!!",
        color: "danger",
      });
      return;
    }

    if (name.trim() === "" || file === null || statusData.trim() === "") {
      setFormValid(false);
      setData({ msg: "All fields are mandatory!!", color: "danger" });
      return;
    }
    setFormValid(true);

    let formData = {
      name,
      image: file,
      status: true,
    };

    const headerToken = {
      Authorization: accessToken,
    };

    setLoading(true);
    post(ADD_CATEGORY, formData, JSON.stringify(headerToken))
      .then((res) => {
        res.status === "success" &&
          setData({
            msg: "Successfully created new category",
            color: "success",
          });
        setCategoryAdded(true);
        // Reset other fields
        setName("");
        setStatus(false);
        setStatusData("inactive");
        setFile(null);
        setFormValid(false);
        setFormSubmitted(false);
        setFileTouched(false);
        setLoading(false);
      })
      .catch((err) => {
        setData({
          msg: "Some error occured! Please try again later!!",
          color: "danger",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div className={styles.backdrop} onClick={closeHandler} />
      <div className={styles.modal}>
        <header className={styles.header}>
          <p>Add Category</p>
          <button className="btn btn-primary" onClick={closeHandler}>
            &times;
          </button>
        </header>
        <div className={styles.content}>
          <Form encType="multipart/form-data" onSubmit={formHandler}>
            <Row>
              {formSubmitted && !formValid && (
                <Col md={12}>
                  <Alert color={data.color}>{data.msg}</Alert>
                </Col>
              )}

              {categoryAdded && (
                <Col md={12}>
                  <Alert color={data.color}>{data.msg}</Alert>
                </Col>
              )}

              <Col md={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    value={name}
                    onChange={nameHandler}
                    placeholder="Enter Name Here"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleFile">Image</Label>
                  <Input type="file" onChange={fileHandler} />
                  <FormText color="muted">
                    Please upload the image of your category.
                  </FormText>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Status</Label>
                  <div className="status-switch square-switch">
                    <input
                      type="checkbox"
                      id="square-switch1"
                      switch="none"
                      checked={status}
                      value={statusData}
                      onChange={switchToggleHandler}
                    />
                    <label
                      htmlFor="square-switch1"
                      data-on-label={"active"}
                      data-off-label={"inactive"}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="2">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <Spinner color="light" /> : "Add"}
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default CategoryForm;
