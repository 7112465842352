import axios from "axios";
import { post, del, get, put } from "./api_helper";
import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";

const urlHelper = (...props) => props?.join("/");

// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);

// Register
export const postRegisterEmail = (data) => post(url.POST_REGISTER_EMAIL, data);
export const postStoreName = (data) => post(url.POST_STORE_NAME, data);

export const postRegister = (data) => post(url.POST_REGISTER, data);

// Forgot Password
export const postForgetPwd = (data) => post(url.POST_FORGOT_PASSWORD, data);
export const postResetPwd = (data) => post(url.POST_RESET_PASSWORD, data);

// Profile
export const getCurrentUser = () => get(url.GET_CURRENT_USER);
export const putProfile = (data) => post(url.PUT_PROFILE, data);
export const putChangePassword = (data) => post(url.CHANGE_PASSWORD, data);
export const verifyProfileRequest = (data) =>
  post(url.VERIFY_PROFILE_REQUEST, data);
export const getLanguages = () => get(url.GET_LANGUAGES);
export const deleteStore = (data) => post(url.DELETE_STORE, data);

// Collection Request

export const VerifyCollectionRequestUrl = (data) =>
  post(url.VERIFY_COLLECTION_REQUEST, data);

// Logs
export const getLogs = (data) => post(url.GET_LOGS, data);
export const postRebuildScript = () => get(url.POST_REBUILD_SCRIPT);
export const postStoreRebuildScript = (data) =>
  post(url.POST_STORE_REBUILD_SCRIPT, data);
export const postStoreSettingsScript = () =>
  get(url.POST_STORE_SETTINGS_SCRIPT);
export const postClearLogs = (query) => get(url.POST_CLEAR_LOGS + query);

// File
export const postFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_FILE, formData, config);
};
export const postRemoveFile = (data) => post(url.POST_REMOVE_FILE, data);

//verifcation request
export const getVerificationRequests = (data) =>
  post(url.GET_VERIFICATIONS, data);

export const getVerificationRequest = (id) =>
  get(url.GET_VERIFICATION + "/" + id);

// Customers
export const getCustomers = (data) => post(url.GET_USERS, data);
export const getCustomer = (id) => get(url.VIEW_USER + "/" + id);
export const updateStoreStatus = (data) => post(url.UPDATE_STORE_STATUS, data);
export const putStoreKeys = (data) => post(url.PUT_STORE_KEYS, data);
export const postCheckMail = (query) => get(url.POST_CHECK_MAIL + query);
export const postHideThings = (data) => post(url.POST_HIDE_THINGS, data);

// Email Template
export const getEmailTemplates = (data) => post(url.GET_EMAIL_TEMPLATES, data);
export const postEmailTemplate = (data) => post(url.POST_EMAIL_TEMPLATE, data);
export const putEmailTemplate = (data) => post(url.PUT_EMAIL_TEMPLATE, data);
export const deleteEmailTemplate = (data) =>
  post(url.DELETE_EMAIL_TEMPLATE, data);
export const getEmailTemplate = (id) => get(url.GET_EMAIL_TEMPLATE + "/" + id);

// Plans
export const getCollections = (data) => post(url.GET_COLLECTIONS, data);
export const postCollection = (data) => post(url.POST_COLLECTION, data);
export const putCollection = (data) => post(url.PUT_COLLECTION, data);
export const deleteCollection = (data) => post(url.DELETE_COLLECTION, data);
export const getCollection = (id) => get(url.GET_COLLECTION + "/" + id);
export const putCollectionsStatus = (data) =>
  post(url.PUT_COLLECTION_STATUS, data);
export const updateStoreCollection = (data) =>
  post(url.UPDATE_STORE_COLLECTION, data);
export const putStoreCollectionDiscount = (data) =>
  post(url.PUT_COLLECTION_DISCOUNT, data);

// Transactions
export const getTransactions = (data) => post(url.GET_TRANSACTIONS, data);
export const chargeStore = (data) => post(url.POST_STORE_CHARGE, data);
export const refundStore = (data) => post(url.POST_STORE_REFUND, data);

// get Domain
export const updateDns = (data) =>
  axios.get(`${url.UPDATE_DNS}webname=${data.old}&newdomain=${data.domain}`);
export const addDomain = (data) => post(url.ADD_DOMAIN, data);
export const searchDomain = (data) => post(url.SEARCH_DOMAIN, data);
export const buyDomain = (data) => post(url.BUY_DOMAIN, data);

// Sub Admins
export const getSubAdmins = (data) => post(url.GET_SUB_ADMINS, data);
export const postSubAdmin = (data) => post(url.ADD_SUB_ADMIN, data);
export const getSubAdmin = (data) => post(url.GET_SUB_ADMIN, data);
export const putSubAdmin = (data) => post(url.PUT_SUB_ADMIN, data);
export const deleteSubAdmin = (data) => post(url.DELETE_SUB_ADMIN, data);

// Store Login
export const storeLogin = (data) => post(url.STORE_LOGIN_BY_ADMIN, data);

// Settings
export const getSettings = () => get(url.GET_SETTINGS);
export const putSettings = (data) => post(url.PUT_SETTINGS, data);
export const getStoreSettings = (storeType, id) =>
  get([url.API_SETTINGS, storeType, url.GET_STORE_SETTINGS, id].join("/"));
export const putStoreSettings = (storeType, data) =>
  post([url.API_SETTINGS, storeType, url.PUT_STORE_SETTINGS].join("/"), data);
export const getAccessList = () => get(url.GET_ACCESS_LIST);
export const getStoreTypes = () => get(url.GET_STORE_TYPES);
export const getStarted = () => get(url.GET_STARTED);
export const postDeployContract = (data) =>
  post(url.POST_CONTRACT_DEPLOY, data);

// Categories
export const getCategories = (data) => post(url.VIEW_CATEGORIES, data);
export const postCategory = (data) => post(url.ADD_CATEGORY, data);
export const getCategory = (id) => get(url.VIEW_CATEGORY + "/" + id);
export const deleteCategory = (data) => post(url.DELETE_CATEGORY, data);
export const updateCategories = (data) => post(url.UPDATE_CATEGORY, data);
export const getSubCategoriesApi = (data) => post(url.GET_SUBCATEGORIES, data);


// Activities
export const getActivities = (data) => post(url.GET_ACTIVITIES, data);

export const postEmails = (data) => post(url.GET_EMAILS, data);
export const postAddEmails = (data) => post(url.ADD_EMAILS_URL, data);
export const fetchEmailByIdApi = (id) => get(url.GET_EMAIL_BY_ID_API+"/"+id);

// Items
export const getItems = (data) => post(url.GET_ITEMS, data);
export const postItem = (data) => post(url.POST_ITEM, data);
export const putItem = (data) => post(url.PUT_ITEM, data);

export const postblog = (data) => post(url.ADD_BLOG, data);
export const deleteItem = (data) => post(url.DELETE_ITEM, data);
export const deleteBlog = (data) => post(url.DELETE_BLOG, data);

export const getItem = (id) => get(url.GET_ITEM + "/" + id);
export const putItemsStatus = (data) => post(url.PUT_ITEM_STATUS, data);
export const getNotifications = (data) => post(url.GET_NOTIFICATIONS, data);
export const getOtherNotificationsApi = (data) => post(url.GET_OTHER_NOTIFICATIONS, data);
export const onMarkOtherNotificationsApi = (data) => post(url.MARK_OTHER_NOTIFICATIONS, data);
export const onGetParticularSellItemApi = (id) => get(url.GET_PARTICULAR_SELLITEM_URL  + "/" + id);

// Faqs
export const getFaqs = (data) => post(url.GET_FAQS, data);
export const postFaq = (data) => post(url.ADD_FAQ, data);
export const getFaq = (id) => get(url.GET_FAQ + "/" + id);
export const putFaq = (data) => post(url.PUT_FAQ, data);
export const deleteFaq = (data) => post(url.DELETE_FAQ, data);
export const putFaqsStatus = (data) => post(url.PUT_FAQS_STATUS, data);

// Contact Us
export const getContactUs = (data) => post(url.GET_CONTACTUS, data);

//blog
export const getBlogs = (data) => post(url.VIEW_BLOG_LIST, data);
export const delBlogs = (data) => post(url.DELETE_BLOG, data);
export const putBlog = (data) => post(url.UPDATE_BLOG, data);

export const getBlogView = (id) => get(url.VIEW_BLOG + "/" + id);

export const getReport = (data) => post(url.GET_REPROT_USER_LIST, data);
export const getReportById = (data) => get(url.GET_REPORT_BY_ID + data);

// Content Page
export const getContentPages = (data) => post(url.GET_CONTENT_PAGES, data);
export const postContentPage = (data) => post(url.ADD_CONTENT_PAGE, data);
export const getContentPage = (id) => get(url.GET_CONTENT_PAGE + "/" + id);
export const putContentPage = (data) => post(url.PUT_CONTENT_PAGE, data);
export const deleteContentPage = (data) => post(url.DELETE_CONTENT_PAGE, data);
export const putContentPagesStatus = (data) =>
  post(url.PUT_CONTENT_PAGES_STATUS, data);

// add Images
export const postImage = (data) => post(url.POST_IMAGE, data);
export const getImages = (data) => post(url.GET_IMAGES, data);
export const getImage = (id) => get(url.GET_IMAGE + id);
export const putImage = (data) => post(url.PUT_IMAGE, data);
export const deleteImage = (data) => post(url.DELETE_IMAGE, data);

//arts

export const getArts = (data) => post(url.GET_ARTS, data);
export const approveOrRejectArtApi = (data) => post(url.APPROVE_OR_REJECT_ART, data);
export const fetchArtByIdApi = (userId) => get(url.ArtById+"/"+userId);

// send user verification email
export const sendEmailVerificationApi =(data) => post(url.VERIFICATION_EMAIL, data)


// send other notfication 


export const sendOtherNotificationApi =(data) => post(url.SEND_OTHER_NOTIFICATION, data)

// newCOllections 
export const getNewCollectionsApi =(data) => post(url.GET_NEW_COLLECTION_URL, data)
export const getNewCollectionByIdApi =(id) => get(url.GET_NEW_COLLECTION_BY_ID_URL+id)


// transactions 

export const getTransactionsWithFilterApi =(data) => post(url.GET_TRANSACTIONS_WITH_FILTER_URL, data)
export const getManageEarningApi =(data) => post(url.GET_MANAGE_EARNING_URL, data)
export const getCsvApi =(data) => post(url.GET_CSV_URL, data)
export const getAdminEarningApi =(data) => post(url.GET_ADMIN_EARNING_URL, data)

// sell items
export const getSellItemsWithFilterApi =(data) => post(url.GET_SELL_ITEMS_WITH_FILTER_URL, data)

//printing partners
export const addPrintingPartnerApi =(data) => post(url.ADD_PRINTING_PARTNER_URL, data)
export const listPrintingPartnerApi =(data) => post(url.LIST_PRINTING_PARTNER_URL, data)
export const editPrintingPartnerApi =(data) => post(url.EDIT_PRINTING_PARTNER_URL, data)
export const getPrintingPartnerApi =(data) => post(url.GET_PRINTING_PARTNER_URL, data)
export const deletePrintingPartnerApi =(data) => post(url.DELETE_PRINTING_PARTNER_URL, data)
export const listPrinterApi =(data) => post(url.LIST_PRINTING_REQUEST_URL, data)
export const getPrinterApi =(data) => post(url.GET_PRINTING_REQUEST_URL, data)
export const getPrintingPaymentListApi =(data) => post(url.GET_PRINTING_PAYMENT_REQUEST_URL, data)
export const getPrintingWithdrawlListApi =(data) => post(url.GET_PRINTER_WITHDRAWL_REQUEST_URL, data)
export const viewPrintingWithdrawlListApi =(data) => post(url.VIEW_PRINTER_WITHDRAWL_REQUEST_URL, data)
export const updatePrintingWithdrawlListApi =(data) => post(url.UPDATE_PRINTER_WITHDRAWL_REQUEST_URL, data)
export const getPrinterCsvDataApi =(data) => post(url.GET_PRINTER_CSV_DATA_URL, data)