// list
export const GET_IMAGES = "GET_IMAGES";
export const GET_IMAGES_FAIL = "GET_IMAGES_FAIL";
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS";

// Add
export const POST_IMAGE = "POST_IMAGE";
export const POST_IMAGE_FAIL = "POST_IMAGE_FAIL";
export const POST_IMAGE_SUCCESS = "POST_IMAGE_SUCCESS";

// Get
export const GET_IMAGE = "GET_IMAGE";
export const GET_IMAGE_FAIL = "GET_IMAGE_FAIL";
export const GET_IMAGE_SUCCESS = "GET_IMAGE_SUCCESS";

// update
export const PUT_IMAGE = "PUT_IMAGE";
export const PUT_IMAGE_FAIL = "PUT_IMAGE_FAIL";
export const PUT_IMAGE_SUCCESS = "PUT_IMAGE_SUCCESS";

// Delete
export const DELETE_IMAGE = "DELETE_IMAGE";
export const DELETE_IMAGE_FAIL = "DELETE_IMAGE_FAIL";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
