import * as TYPES from "./actionTypes";

const INIT_STATE = {
  imagesData: [],
  total: 0,
  error: "",
  loading: false,
  imageView: {},
};

const ImageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // list
    case TYPES.GET_IMAGES:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_IMAGES_SUCCESS:
      return {
        ...state,
        imagesData: action.payload.data || [],
        total: action.payload.totalcount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_IMAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // add
    case TYPES.POST_IMAGE:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.POST_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.POST_IMAGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    // get
    case TYPES.GET_IMAGE:
      return {
        ...state,
        error: "",
        loading: true,
        imageView: {},
      };

    case TYPES.GET_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        imageView: {},
      };

    case TYPES.GET_IMAGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        imageView: action.payload,
      };

    // update
    case TYPES.PUT_IMAGE:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.PUT_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.PUT_IMAGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    // delete
    case TYPES.DELETE_IMAGE:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.DELETE_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    default:
      return state;
  }
};

export default ImageReducer;
