import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import images
import user1 from "assets/images/testimonial-user1.jpg";
import user2 from "assets/images/testimonial-user2.jpg";
import user3 from "assets/images/testimonial-user3.jpg";
import wavesSvg from "assets/images/Waves.svg";
import userimg from "assets/images/userimg.png";
import qomas2 from "assets/images/qomas2.png";

const settings = {
  dots: true,
  arrows: false,
  autoplay: true,
  lazyLoad: true,
  loop: true,
  margin: 20,
  responsiveClass: true,
  autoHeight: true,
  autoplayTimeout: 7000,
  smartSpeed: 800,
  responsive: [
    { breakpoint: 0, settings: { slidesToShow: 1 } },
    { breakpoint: 600, settings: { slidesToShow: 1 } },
    { breakpoint: 1024, settings: { slidesToShow: 1 } },
    { breakpoint: 1366, settings: { slidesToShow: 1 } },
  ],
};

const testimonials = [
  {
    profile: user1,
    name: "JACK",
    quote:
      "You guys rocked on this SAAS Based system, I am enjoying my Food Devilvery App, Thank you !.",
  },
  {
    profile: user2,
    name: "RALF WIPER",
    quote:
      "Its Really cool & affordable, I have got my online store ready with my brand witin 2 weeeks.. Thanks To Team Helloone !.",
  },
  {
    profile: user3,
    name: "KATE",
    quote:
      "Helloone is one of the best platform for the early stage startups to kick start their delivery business. It has very flexible & faster Go Live process.",
  },
  {
    profile: user1,
    name: "ROBIN WOLF",
    quote:
      "I was looking for a reliable & Affordable Whitelabel service provider and I came across Hyperlocal Cloud. I looked into it and invested. Easily one of the best decisions I ever made! Highy Recommended.",
  },
  {
    profile: user2,
    name: "MARK DAMINGO",
    quote:
      "I would recommend Hyperlocal Cloud to any organization that wants to start thier online store for service delivery and increase transparency.",
  },
];

const Aside = () => {
  return (
    <aside>
      <div className="top-sidebar">
        <div className="centerdiv">
          <Slider {...settings} className="owl-carousel owl-theme">
            {testimonials?.map((item, key) => (
              <div key={`_testimonial_${key}`} className="item">
                <div className="top-flex">
                  <div className="sideimg">
                    <img src={item.profile} className="img-fluid" />
                  </div>
                  <div className="sidetext">
                    <h3>{item.name}</h3>
                    {/* <p>SEO Expert</p> */}
                  </div>
                  <img src={qomas2} className="img-fluid qomas2" />
                </div>
                <h5>{item.quote}</h5>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="bottom-sidebar">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shape-rendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="#00b38885" />
            <use href="#gentle-wave" x="48" y="3" fill="#00b388" />
          </g>
        </svg>

        <div className="bottom-padd">
          <ul className="social-media">
            <li>
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
          <p>
            Copyright <b>@2021</b> All rights reserved to Helloone.
          </p>
        </div>
      </div>
    </aside>
  );
};

export default Aside;
