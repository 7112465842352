// art api fail
export const ARTS_API_FAIL = "CATEGORY_API_FAIL";

//get art
export const GET_ARTS = "GET_ARTS";
export const GET_ARTS_FAIL = "GET_ARTS_FAIL";
export const GET_ARTS_SUCCESS = "GET_ARTS_SUCCESS";

//approve or reject art

export const APPROVE_OR_REJECT = "APPROVE_OR_REJECT";
export const APPROVE_OR_REJECT_FAIL = "APPROVE_OR_REJECT_FAIL";
export const APPROVE_OR_REJECT_SUCCESS = "APPROVE_OR_REJECT_SUCCESS";

export const GET_ART_BY_ID = "GET_ART_BY_ID";
export const GET_ART_BY_ID_FAIL = "GET_ART_BY_ID_FAIL";
export const GET_ART_BY_ID_SUCCESS = "GET_ART_BY_ID_SUCCESS";