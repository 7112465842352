import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { isEmpty } from "lodash";
import toastr from "toastr";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getReportById, putVerifyProfileRequest } from "store/actions";

const ViewCustomer = (props) => {
  const {
    match: { params },
    onGetCustomer,
    onPutVerifyProfileRequest,
    customer,
    error,
    loading,
  } = props;

  useEffect(() => {
    if (params && params.id) {
      onGetCustomer(params.id);
    }
  }, [params]);

  const changeVerifyStatus = (name) => {
    var parameter = {
      user_id: params.id,
      verificationProfileStatus: name,
    };
    const callback = (response) => {
      if (response.status === "success") {
        toastr.success("Profile verify successfully.");
        onGetCustomer(params.id);
      } else {
        toastr.success("Profile not verify.");
        onGetCustomer(params.id);
      }
    };
    onPutVerifyProfileRequest(parameter, callback);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="page-content">
          <Spinner color="primary" />
        </div>
      )}
      {error && <Redirect to="/dashboard" />}
      {!error && !isEmpty(customer) && !loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Users" breadcrumbItem="User Details" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <p className="text-primary">{customer?.role}</p>
                          <h6 className="mt-1 mb-3">{customer?.name}</h6>
                          <p className="mt-1 mb-3">
                            Report Type Description - {customer?.reportType}
                          </p>
                          <p className="mt-1 mb-3 text-muted">
                            Description- {customer?.description}
                          </p>

                          <div className="text-muted float-left mr-3 mb-3">
                            {/* <p>Average Rating - {customer?.avgRating}</p> */}
                            {/* <StarRatings
                              rating={customer.avgRating}
                              starRatedColor="#F1B44C"
                              starEmptyColor="#2D363F"
                              numberOfStars={5}
                              name="rating"
                              starDimension="14px"
                              starSpacing="3px"
                            /> */}
                          </div>
                          {/* <h6 className="text-success text-uppercase">
                            {customer?.reviewCount} Total Reviews
                          </h6> */}

                          {customer?.verificationProfileStatus ===
                            "requested" && (
                            <div className="mt-5">
                              <h5 className="mb-4">Verify Profile Request:</h5>
                              <button
                                className="btn btn-success mr-2"
                                onClick={() => changeVerifyStatus("accept")}
                              >
                                Accept
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => changeVerifyStatus("reject")}
                              >
                                Reject
                              </button>
                            </div>
                          )}

                          {customer?.verificationProfileStatus === "accept" && (
                            <div className="mt-5">
                              <b>Profile Verified: </b>
                              <p>Yes</p>
                            </div>
                          )}
                          {customer?.verificationProfileStatus === "reject" && (
                            <div className="mt-5">
                              <b>Profile Verified: </b>
                              <p>No</p>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {customer.reviewCount > 0 && (
                      <div className="mt-5">
                        <h5 className="mb-4">Reviews :</h5>
                        {customer.reviews.map((comments) => (
                          <p className="text-muted">{comments}</p>
                        ))}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ViewCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  customer: PropTypes.any,
  onGetCustomer: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getReportById(id)),
  onPutVerifyProfileRequest: (data, callback) =>
    dispatch(putVerifyProfileRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
