import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import Instructions from "./Instructions";
import toastr from "toastr";
import CKEditor from "react-ckeditor-component";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";
import { uploadFile as onUploadFile } from "store/actions";
import logo_placeholder from "assets/images/logo-placeholder.png";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const RegisterImagesSetting = (props) => {
  const { fields, handleChange } = props;
  const dispatch = useDispatch();
  const [logoselectedFiles, setlogoselectedFiles] = useState([]);

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;
    handleChange(name)({ target: { value: _id } });
  };

  const handleAcceptedFiles = (name) => (_files) => {
    const files = _files?.filter((file) => file.size < 5242880);

    const ext = files[0]?.path.split(".").pop();
    if (ext !== "png" && ext !== "jpg" && ext !== "jpeg" && ext !== "JPEG") {
      toastr.error("Only .png,.jpg,.jpeg, JPEG extension allow!!");
      return;
    }

    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }

    dispatch(onUploadFile({ image: files[0] }, uploadFileSuccess(name)));

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    if (name === "loginRegisterImag") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 0),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(0 + 1),
      ]);
    } else if (name === "museumRegisImage") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 1),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(1 + 1),
      ]);
    } else if (name === "artistRegisImage") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 2),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(2 + 1),
      ]);
    } else if (name === "userRegisImage") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 3),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(3 + 1),
      ]);
    }
  };

  useEffect(() => {
    if (
      isObject(fields?.loginRegisterImag) &&
      !!fields?.loginRegisterImag?.link
    ) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 0),
        {
          preview: fields?.loginRegisterImag?.link,
        },
        ...prev.slice(0 + 1),
      ]);
    }

    if (
      isObject(fields?.museumRegisImage) &&
      !!fields?.museumRegisImage?.link
    ) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 1),
        {
          preview: fields?.museumRegisImage?.link,
        },
        ...prev.slice(1 + 1),
      ]);
    }

    if (
      isObject(fields?.artistRegisImage) &&
      !!fields?.artistRegisImage?.link
    ) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 2),
        {
          preview: fields?.artistRegisImage?.link,
        },
        ...prev.slice(2 + 1),
      ]);
    }

    if (isObject(fields?.userRegisImage) && !!fields?.userRegisImage?.link) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 3),
        {
          preview: fields?.userRegisImage?.link,
        },
        ...prev.slice(3 + 1),
      ]);
    }
  }, [
    fields?.loginRegisterImag,
    fields?.museumRegisImage,
    fields?.artistRegisImage,
    fields?.userRegisImage,
  ]);

  return (
    <React.Fragment>
      <Row>
       
            <Col md={6}>
            <div className="categories_div">
              <FormGroup>
                <Label> Login Image </Label>
                <div className="uploadImageMaine mb-0">
                  <div className="uploadImgNew h-100 full faqimage w-100 position-relative start-0 top-0">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles("loginRegisterImag")(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    const imageFile = logoselectedFiles[0];

                    return (
                      <div
                        className="dropzone-single-image categoryUploadImage avatar-xl border video-setting"
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps()}
                          id="formrow-profile-image-Input"
                          multiple={false}
                        />

                        {(imageFile?.path &&
                          imageFile?.path?.search(".mp4") !== -1) ||
                        imageFile?.preview?.search(".mp4") !== -1 ? (
                          <video
                            className="rounded avatar-xl uploadlogogbox video-inp"
                            controls
                            autoplay="autoplay"
                            muted
                            src={
                              imageFile?.preview || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          >
                            <source
                              src={
                                imageFile?.preview || !!imageFile
                                  ? imageFile.preview
                                  : logo_placeholder
                              }
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            className="rounded avatar-xl uploadlogogbox video-inp img-fluid"
                            alt={
                              !!imageFile && imageFile.name
                                ? imageFile.name
                                : "hlc"
                            }
                            src={
                              fields?.image?.link || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          />
                        )}

                        <div className="edit">
                          <i className="bx bx-pencil"></i>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>

                <small class="fw-bold  form-text text-muted">Max. upload file size: 5MB</small>
                </div>
                </div>
              </FormGroup>
              </div>
            </Col>

            <Col md={6}>
            <div className="categories_div">
              <FormGroup>
                <Label> Museum SignUp Background Image </Label>
                <div className="uploadImageMaine mb-0">
                  <div className="uploadImgNew h-100 full faqimage w-100 position-relative start-0 top-0">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles("museumRegisImage")(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    const imageFile = logoselectedFiles[1];

                    return (
                      <div
                        className="dropzone-single-image avatar-xl categoryUploadImage border video-setting"
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps()}
                          id="formrow-profile-image-Input"
                          multiple={false}
                        />

                        {(imageFile?.path &&
                          imageFile?.path?.search(".mp4") !== -1) ||
                        imageFile?.preview?.search(".mp4") !== -1 ? (
                          <video
                            className="rounded avatar-xl uploadlogogbox video-inp"
                            controls
                            autoplay="autoplay"
                            muted
                            src={
                              imageFile?.preview || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          >
                            <source
                              src={
                                imageFile?.preview || !!imageFile
                                  ? imageFile.preview
                                  : logo_placeholder
                              }
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            className="rounded avatar-xl uploadlogogbox video-inp img-fluid"
                            alt={
                              !!imageFile && imageFile.name
                                ? imageFile.name
                                : "hlc"
                            }
                            src={
                              fields?.image?.link || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          />
                        )}

                        <div className="edit">
                          <i className="bx bx-pencil"></i>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
                <small class="fw-bold  form-text text-muted">Max. upload file size: 5MB</small>
                </div>
                </div>
              </FormGroup>
              </div>
            </Col>

            <Col md={6}>
            <div className="categories_div">
              <FormGroup>
                <Label> Artist SignUp Background Image </Label>
                <div className="uploadImageMaine mb-0">
                  <div className="uploadImgNew h-100 full faqimage w-100 position-relative start-0 top-0">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles("artistRegisImage")(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    const imageFile = logoselectedFiles[2];

                    return (
                      <div
                        className="dropzone-single-image avatar-xl categoryUploadImage border video-setting"
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps()}
                          id="formrow-profile-image-Input"
                          multiple={false}
                        />

                        {(imageFile?.path &&
                          imageFile?.path?.search(".mp4") !== -1) ||
                        imageFile?.preview?.search(".mp4") !== -1 ? (
                          <video
                            className="rounded avatar-xl uploadlogogbox video-inp"
                            controls
                            autoplay="autoplay"
                            muted
                            src={
                              imageFile?.preview || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          >
                            <source
                              src={
                                imageFile?.preview || !!imageFile
                                  ? imageFile.preview
                                  : logo_placeholder
                              }
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            className="rounded avatar-xl uploadlogogbox video-inp img-fluid"
                            alt={
                              !!imageFile && imageFile.name
                                ? imageFile.name
                                : "hlc"
                            }
                            src={
                              fields?.image?.link || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          />
                        )}

                        <div className="edit">
                          <i className="bx bx-pencil"></i>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
                <small class="fw-bold  form-text text-muted">Max. upload file size: 5MB</small>
                </div>
                </div>
              </FormGroup>
              </div>
            </Col>

            <Col md={6}>
            <div className="categories_div">
              <FormGroup>
                <Label> User SignUp Background Image </Label>
                <div className="uploadImageMaine mb-0">
                  <div className="uploadImgNew h-100 full faqimage w-100 position-relative start-0 top-0">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles("userRegisImage")(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    const imageFile = logoselectedFiles[3];

                    return (
                      <div
                        className="dropzone-single-image avatar-xl categoryUploadImage border video-setting"
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps()}
                          id="formrow-profile-image-Input"
                          multiple={false}
                        />

                        {(imageFile?.path &&
                          imageFile?.path?.search(".mp4") !== -1) ||
                        imageFile?.preview?.search(".mp4") !== -1 ? (
                          <video
                            className="rounded avatar-xl uploadlogogbox video-inp"
                            controls
                            autoplay="autoplay"
                            muted
                            src={
                              imageFile?.preview || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          >
                            <source
                              src={
                                imageFile?.preview || !!imageFile
                                  ? imageFile.preview
                                  : logo_placeholder
                              }
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            className="rounded avatar-xl video-inp uploadlogogbox img-fluid"
                            alt={
                              !!imageFile && imageFile.name
                                ? imageFile.name
                                : "hlc"
                            }
                            src={
                              fields?.image?.link || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          />
                        )}

                        <div className="edit">
                          <i className="bx bx-pencil"></i>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
                <small class="fw-bold  form-text text-muted">Max. upload file size: 5MB</small>
                </div>
                </div>
              </FormGroup>
              </div>
            </Col>
       
      </Row>
    </React.Fragment>
  );
};

RegisterImagesSetting.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterImagesSetting)
);
