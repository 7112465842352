import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleViewUser, toggleConfirmModal, accesses, t) => [
  {
    dataField: "title",
    text: t("Title"),
    sort: true
  },
  {
    dataField: "is_video_blog",
    text: t("Blog For"),
    sort: true,
    formatter: (cellContent, row) => (
      <div >{row?.is_landing_page_blog === true ? "Blog" : "How To Articles"}</div>
    ),
  },
  {
    dataField: "status",
    text: t("Status"),
    sort: true,
    formatter: (cellContent, row) => (
      <div className={"text-capitalize " + (row.status === 'active' ? 'active' : 'inactive')}>{row.status}</div>
    ),
  },
  {
    text: t("Created At"),
    dataField: "date_created",
    sort: true,
    formatter: (_, row) => moment(row.date_created).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row, i) => (
      <>
    <div className="d-flex align-items-center">
      <div>
      <button onClick={() => toggleViewUser(row?._id)}  className='btn px-0 py-0  border-0 id-btn' id={`view-${i}-tooltip`}><i className="fa-light fa-eye"></i></button>
     

        {/* <Link
          to={`/items/${row._id}/plans`}
          className="mr-3 text-secondary"
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            View
          </UncontrolledTooltip>
        </Link> */}

      
      </div>
      <div>
      <Link
          to={`/blog/edit/${row._id}`}
          // to={{ pathname: "/edit/blog", category_id: row._id }}
          className="btn px-2 id-btn py-0  border-0"
        >
          <i className="fa-regular text-success fa-pen-to-square" id={`edit-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`edit-${row._id}-tooltip`}
          >
            {t("edit")}
          </UncontrolledTooltip>
        </Link>
      </div>
      <div>
      {accesses?.canDelete && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal(row._id);
            }}
            className="btn id-btn px-0 py-0  border-0"
          >
            <i
              className="fa-regular text-danger  fa-trash"
              id={`delete-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link>
        )}
      </div>
    </div>
      </>
    ),
  },
];

export default ListColumns;
