import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (history, toggleConfirmModal, toggleViewUser, sendVerificationMail, t, accesses) => [
  {
    dataField: "name",
    text: t("Name"),
    sort: true,
  },
  {
    dataField: "email",
    text: t("Email"),
    sort: true,
    formatter: (cellContent, row) => <>{row?.email || "---"}</>,
  },
  // {
  //   dataField: "status",
  //   text: t("Status"),
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         "text-capitalize font-size-13 badge-soft-" +
  //         (row?.status == "active"
  //           ? "success"
  //           : row?.status == "inactive"
  //           ? "danger"
  //           : "warning")
  //       }
  //     >
  //       {t(row?.status)}
  //     </Badge>
  //   ),
  // },

  {
    text: t("Registered At"),
    dataField: "created_at",
    sort: true,
    formatter: (_, row) =>

    <>{ row?.created_at? moment(row?.created_at).utcOffset(330).format("DD MMM YYYY hh:mm A") : "---"} </>,
      // formatter: (cellContent, row) => <>{row?.user?.email || "---"}</>,
  },
  {
    dataField: "role",
    text: t("User Role"),
    sort: true,
    formatter: (_, row) => (
      <div className={row.role === 'MUSEUM' ? 'museums' : row.role === 'ARTIST' ? 'artist' : ''}>{row.role}</div>
    )
  },
  {
    dataField: "is_email_verified",
    text: t("Status"),
    sort:true,
    formatter: (_, row) => (
      row.is_email_verified === true ? <div className='verify'>{"Verified"}</div> : <div className='reject d-flex align-items-center gap-1'>{"Not Verified"}  <button onClick={() => sendVerificationMail(row.email)} className='btn p-0 border-0'>
        <i class="fa-regular fa-envelopes py-2" id={`view-${row._id}-tooltip`} ></i>
        <UncontrolledTooltip
          placement="top"
          target={`view-${row._id}-tooltip`}
        >
          {t("Send")} {t("Mail")}
        </UncontrolledTooltip>
      </button></div>
    )
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row, i) => (
      <>
        {/* onClick={viewUser}  upload art */}
        {/* onClick={toggleConfirmModal} */}
        <button onClick={() => toggleViewUser(row._id)} className='btn p-0'><i className="fa-light fa-eye"></i>
        </button>
        {/* <Link to={`/users/profile/${row._id}`} className="mr-3 text-secondary">
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link> */}
      </>
    ),
  },
];

export default CustomersColumns;
