import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Button, Modal, ModalBody, Spinner } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, getPrinter } from "store/actions";
import addprintclose from "assets/images/svg/addprintclose.svg"

const ViewPrintPartner = props => {
  const { isOpen, toggle, onConfirm, userId, onGetPartner, partner, loading } = props
  useEffect(() => {
    if (userId) {

      const callback = (res) => {
        // console.log("res", res)

      }
      onGetPartner({ _id: userId }, callback)
    }

  }, [userId, viewuser])


  useEffect(() => {
    console.log("dasdasdasdas", partner)
  }, [partner])

  return (
    <>
      {loading ? (
        <div className="spinnerBoxNew">
          <Spinner color="primary" />
        </div>) :
        (
          <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>

            <div className='dialogViewInner'>
              <div className='addprinthead'>
                <h1>View</h1>
                <button className='closebtn' onClick={toggle}>
                  <img src={addprintclose} alt="close" width={40} height={40} />
                </button>
              </div>
              <div className={'dialogbody ' + (viewuser.viewProfile)}>
                <div className='details'>
                  <h5>Business Information</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Shop Name</label>
                        <input type="text" className='form-control' placeholder="shop name" value={partner?.shop_name || "N/A"} disabled />
                      </div>
                    </div>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Contact Number</label>
                        <input type="text" className='form-control' placeholder="contact number" value={partner?.contact_number || "N/A"} disabled />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xl-12 mb-4'>
                      <div className='form-group'>
                        <label>Business Address</label>
                        <textarea className='form-control' placeholder="business address" value={partner?.business_address || "N/A"} disabled />
                      </div>
                    </div>
                    {/* <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Email Address</label>
                        <input type="text" className='form-control' placeholder="email address" value={partner?.email || "N/A"} disabled />
                      </div>
                    </div> */}
                    {/* <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Website</label>
                        <input type="text" className='form-control' placeholder="website URL" value={partner?.website || "N/A"} disabled />
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-6 mb-4">
                      <div className="form-group">
                        <label>Country</label>
                        <input type="text" className='form-control' placeholder="contact number" value={partner?.country?.name || "N/A"} disabled />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 mb-4">
                      <div className="form-group">
                        <label>State</label>
                        <input type="text" className='form-control' placeholder="contact number" value={partner?.state?.name || "N/A"} disabled />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 mb-4">
                      <div className="form-group">
                        <label>City</label>
                        <input type="text" className='form-control' placeholder="contact number" value={partner?.city || "N/A"} disabled />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 mb-4">
                      <div className="form-group">
                        <label>ZipCode</label>
                        <input type="text" className='form-control' placeholder="contact number" value={partner?.zipCode || "N/A"} disabled />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Website</label>
                        <input type="text" className='form-control' placeholder="website URL" value={partner?.website || "N/A"} disabled />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='details'>
                  <h5>Owner/Manager Details</h5>
                  <div className='row'>
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Name</label>
                        <input type="text" className='form-control' placeholder="full name" value={partner?.fullName || "N/A"} disabled />
                      </div>
                    </div>
                    {/* <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Contact Number</label>
                        <input type="text" className='form-control' placeholder="contact number" value={partner?.contact_number || "N/A"} disabled />
                      </div>
                    </div> */}
                    <div className='col-xl-4 col-md-4 col-sm-4 mb-4'>
                      <div className='form-group'>
                        <label>Email Address</label>
                        <input type="text" className='form-control' placeholder="Email Address" value={partner?.email || "N/A"} disabled />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='details'>
                  <h5>Business Registration Details</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Business Registration Number</label>
                        <input type="text" className='form-control' placeholder="Business Registration Number" value={partner?.business_registration_number || "N/A"} disabled />
                      </div>
                    </div>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Tax Identification Number</label>
                        <input type="text" className='form-control' placeholder="Tax Identification Number" disabled value={partner?.tax_identification_number || "N/A"} />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 mb-4">
                      <div className="form-group">
                        <label>Business Document</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          placeholder="Business Registration Number"
                          value={partner?.doc_one?.name || "N/A"}
                        />
                        {partner?.doc_one?.link && (
                          <a
                            href={partner?.doc_one?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-link mt-2"
                          >
                            View Document
                          </a>
                        )}
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-sm-6 mb-4">
                      <div className="form-group">
                        <label>Tax Document</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          placeholder="Tax Identification Number"
                          value={partner?.doc_two?.name || "N/A"}
                        />
                        {partner?.doc_two?.link && (
                          <a
                            href={partner?.doc_two?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-link mt-2"
                          >
                            View Document
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='details'>
                  <h5>Service Information</h5>
                  <div className='row'>
                    <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                      <div className='form-group'>
                        <label>Canvas Sizes</label>


                        <div className="CanvasSize">
                          <label>Paper</label>
                          <ul className="mt-2">
                            {partner?.papers?.length > 0 ? partner?.papers?.map((item, index) =>
                              <li>{item?.name}</li>) : "No Paper Found."
                            }

                          </ul>
                        </div>
                        <div className="CanvasSize mt-3">
                          <label>Canvas</label>
                          <ul className="mt-2">
                            {partner?.canvases?.length > 0 ? partner?.canvases?.map((item, index) =>
                              <li>{item?.name}</li>) : "No Canvas Found."
                            }

                          </ul>
                        </div>
                        {/* <input type="text" className='form-control' placeholder="Overview of Services Offered" value={partner?.services_offered  || "N/A"} /> */}
                      </div>
                    </div>

                    {/* <div className='col-xl-12 col-md-12 col-sm-12 mb-4'>
                      <div className='form-group'>
                        <label>List of Products</label>
                        <ul className="product-list">
                        {partner?.products?.length > 0
                          ? partner.products.map((product, index) => (
                            <li key={index}>
                              {product.name} - ${product.price}
                            </li>
                          ))
                          : "N/A"}
                      </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  )

}
ViewPrintPartner.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any
}
const mapStateToProps = ({ Partners }) => ({
  error: Partners.error,
  loading: Partners.loading,
  partner: Partners.partner,
  // particularCustomerLoading: Partners.particularCustomerLoading
});

const mapDispatchToProps = (dispatch) => ({
  onGetPartner: (id, callback) => dispatch(getPrinter(id, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPrintPartner);