import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import toastr from "toastr"
//i18n
import { withTranslation } from "react-i18next";
import sortSvg from "../../../assets/images/svg/sort.svg";
import onboard from "assets/images/svg/onboard.svg"
import printer from "assets/images/svg/printer.svg"

import addprint from "assets/images/svg/addprinter.svg"

import {
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import { listPrinter } from "store/actions";
import UsersColumns, { selectRow } from "./Columns";
import DeletePrintPartner from "./DeletePrintPartner";
import ViewPrintPartner from "./ViewPrintPartner";
import AddPrintPartner from "./AddPrintPartner";
import EditPrintPartner from "./EditPrintPartner";

const statusFilter = { fieldName: "status", fieldValue: { $ne: "temp" } };

const printingpartner = (props) => {

  const {
    accessLevel,
    history,
    totalPartners,
    getPartnersSuccess,
    partners,
    loading,
    onGetPartners,
    recentOnboardingCount
  } = props;

  const [clearAllBtnForSortBy, setClearAllBtnForSortBy] = useState(false)
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [deletePrintPartner, setDeletePrintPartner] = useState({
    isOpen: false,
    userId: null
  });

  const [viewPrintPartner, setViewPrintPartner] = useState({ isOpen: false, userId: null });

  const [addPrintPartner, setAddPrintPartner] = useState({ isOpen: false, userId: null });
  const [editPrintPartner, setEditPrintPartner] = useState({ isOpen: false, userId: null });


  const [filter, setFilter] = useState({
    orderBy: "created_date",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    role: "",
    timeZone: "",
    fields: [
      {
        fieldName: "status",
        fieldValue: "",
      },
    ],
  });

  const [lastFields, setLastFields] = useState({
    fieldName: "status",
    fieldValue: "",
  });

  const [searchText, setSearchText] = useState("");
  const [userList, setUsersList] = useState([]);
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalPartners,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    page: filter?.page
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  // useEffect(() => {
  //   onGetPartners({ ...filter, fields: [...filter.fields, lastFields] });
  //   setUsersList(partners);
  // }, [JSON.stringify(filter), lastFields.fieldValue]);


  useEffect(() => {
    setUsersList(partners || []);
  }, [partners]);

  useEffect(() => {
    onGetPartners(filter);
  }, [filter]);

  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {

    if (type === "search") {
      setSearchText(searchText);
    }
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };
  const toggleDeletePrintPartner = (userId) => {
    if (deletePrintPartner?.isOpen === true) {
      onGetPartners(filter);
    }
    setDeletePrintPartner((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const toggleViewPrintPartner = (userId) => {
    setViewPrintPartner((prevState) => ({ isOpen: !viewPrintPartner.isOpen, userId }));
  };
  const toggleAddPrintPartner = (userId) => {
    if (addPrintPartner?.isOpen === true) {
      onGetPartners(filter);
    }

    setAddPrintPartner((prevState) => ({ isOpen: !addPrintPartner.isOpen, userId }));
  };

  const toggleEditPrintPartner = (userId) => {
    console.log({userId})
    if (editPrintPartner?.isOpen === true) {
      onGetPartners(filter);
    }
    setEditPrintPartner((prevState) => ({ isOpen: !editPrintPartner.isOpen, userId }));
  };
  // const sendVerificationMail = (email) => {
  //   const callback = (response) => {
  //     if (response.status === "success") {
  //       toastr.success("An account verification email has been sent to successfully.")
  //     }
  //     else {
  //       toastr.error(response.message)
  //     }
  //   }
  //   onSendEmail({ email: email }, callback);

  // };
  const removeConfirm = () => {
    const { userId, isOpen } = deletePrintPartner;

    const onComplete = () => {
      onGetUsers(filter);
    };

    setDeletePrintPartner({ isOpen: !isOpen, userId: null });
    onDeleteUser({ _id: userId }, onComplete);
  };
  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText, page: 1 }));
  };

  return (

    <React.Fragment>
      {loading && (
        <div className="spinnerBoxNew">
          <Spinner color="primary" />
        </div>
      )}
      {viewPrintPartner.isOpen === true && <ViewPrintPartner isOpen={viewPrintPartner.isOpen} userId={viewPrintPartner.userId} toggle={toggleViewPrintPartner} onConfirm={removeConfirm} />}
      {addPrintPartner.isOpen === true && <AddPrintPartner isOpen={addPrintPartner.isOpen} userId={addPrintPartner.userId} toggle={toggleAddPrintPartner} onConfirm={removeConfirm} />}
      {editPrintPartner.isOpen === true && <EditPrintPartner isOpen={editPrintPartner.isOpen} userId={editPrintPartner.userId} toggle={toggleEditPrintPartner} onConfirm={removeConfirm} />}
      {deletePrintPartner.isOpen === true && <DeletePrintPartner isOpen={deletePrintPartner.isOpen} userId={deletePrintPartner.userId} toggle={toggleDeletePrintPartner} onConfirm={removeConfirm} />}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Print Shop List"
            breadcrumbItem={props.t("Print Shop List")}
            breadcrumbItems={[{ title: props.t("Print Shop List") }]}
          />

          <Row>
            <Col xs="12">
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={userList || []}
                    columns={UsersColumns(
                      history,
                      toggleDeletePrintPartner,
                      toggleViewPrintPartner,
                      toggleEditPrintPartner,
                      props.t,
                      accesses
                    )}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div className="row mt-4">

                          <div className="col-xl-3 ">
                            <div className="box1">
                              <div className="imageicon"><img src={printer} /></div>
                              <h1 className="highlighthead"> {totalPartners} </h1>
                              <p className="highlightP"> Total Number of Print Shops</p>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="box2">
                              <div className="imageicon"> <img src={onboard} /></div>
                              <h1 className="highlighthead">{recentOnboardingCount} </h1>
                              <p className="highlightP"> Recent Onboardings </p>
                            </div>
                          </div>
                          <div className="col-xl-2 ms-auto">
                            <div className="text-end">
                              <button className="btn btn-primary" onClick={() => toggleAddPrintPartner()} > <img src={addprint} /> Add
                              </button>
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-xl-12 mb-4 filterBox">

                            <div className="row">
                              <div className="col-xl-5">
                                <div className="form-group position-relative">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search")}
                                    searchText={searchText}
                                  />
                                </div>
                              </div>
                              {(!!lastFields.fieldValue ||
                                !!filter.search || !!filter.role || clearAllBtnForSortBy === true) && (
                                  <div className='col-xl-2'>
                                    <button onClick={(e) => {
                                      setClearAllBtnForSortBy(false)
                                      e.preventDefault();
                                      setFilter((prevState) => ({
                                        ...prevState,
                                        search: "",
                                        role: "",
                                        orderBy: ""
                                      }));
                                      setSearchText("");
                                      setLastFields({
                                        fieldName: "status",
                                        fieldValue: "",
                                      });
                                    }} className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}</button>
                                  </div>)}
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tableBox usertable">
                              <BootstrapTable
                                responsive
                                remote
                                bordered={false}
                                striped={false}
                                noDataIndication={"No data available"}
                                classes={"table  table-centered table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                defaultSorted={[
                                  {
                                    dataField: "created_at",
                                    order: "desc",
                                  },
                                ]}
                                sort={{
                                  sortCaret: (order) =>
                                    order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) : <span className="caret">
                                      {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                    </span>,
                                }}
                              />

                              {loading && (
                                <div className="spinnerBoxNew ">
                                  <Spinner color="primary" />
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                        

                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </Container>
      </div>


    </React.Fragment>
  );
};

printingpartner.propTypes = {
  t: PropTypes.any,
  partners: PropTypes.array,
  totalPartners: PropTypes.number,
  getPartnersSuccess: PropTypes.func,
  onGetPartners: PropTypes.func,
};

const mapStateToProps = ({ Partners }) => ({
  loading: Partners.loading,
  partners: Partners.partners,
  totalPartners: Partners.totalPartners,
  recentOnboardingCount: Partners.recentOnboardingCount,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPartnersSuccess: (data) => dispatch(listPrinter(data)),
    onGetPartners: (data, callback) => {
      dispatch(listPrinter(data, callback));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(printingpartner))
);
