import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const paymentColumns = (history,t, accesses) => [
  {
    dataField: "total_order_value",
    text: t("Total Order Value"),
    // sort: true,
    formatter: (_, row) =>
      <>{  `$${Number(row?.partner_earning +2*(row?.admin_commission))?.toFixed(6)}`} </>,
  },
  {
    dataField: "admin_commission",
    text: t("Admin Commission Percentage"),
    sort: true,
    formatter: (_, row) => <>{  `$${Number(row?.admin_commission)?.toFixed(6)}`} </>,
  },
  {
    dataField: "seller_earning",
    text: t("Seller Earning"),
    sort: true,
    formatter: (_, row) => <>{  `$${Number(row?.seller_earning)?.toFixed(6)}`} </>,
  },
  {
    dataField: "partner_earning",
    text: t("Print Partner Earnings"),
    sort: true,
    formatter: (_, row) => <>{  `$${Number(row?.partner_earning)?.toFixed(3)}`} </>,
  },
  {
    text: t("Date"),
    dataField: "created_date",
    sort: true,
    formatter: (_, row) =>
    <>{ row?.created_date ? moment(row?.created_date).utcOffset(330).format("MMMM D, YYYY") : "---" } </>,
  },
  {
    dataField: "status",
    text: t("Payment Status"),
    sort: true,
    formatter: (_, row) => 
      <div className={row?.status === 'Completed' ? 'verify' : row?.status === 'Pending' || 'In Progress' ? 'pending' : row?.status === 'Failed' ? 'reject' : ''}>{row?.status}</div>,
    
  },
];

export default paymentColumns;
