import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useLocation, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { NavLink } from "reactstrap";
const SidebarContent = (props) => {

  const location = useLocation();
  const { from } = location;
  const [pageName, setPageName] = useState();
  useEffect(() => {
    setPageName(location.state);
    console.log('admin',location.state);
  }, [location.state]);

  return (
    <React.Fragment>
      {/* <li>
        <Link to={"/dashboard"}>
        <i class='bx bxs-dashboard' ></i>
        <span>{props.t("Dashboard")}</span>
        </Link>
      </li> */}
      <li>
        <Link to={"/users"} state='data_you_need_to_pass' >
          <i className="bx bx-user-circle" />
          <span>{props.t("Manage Users")}</span>
        </Link>
      </li>
      <li>
        <Link to={"/manageart"}>
          <i className="bx bx-image" />
          <span>{props.t("Manage Arts")}</span>
        </Link>
      </li>


      {/* <li>
        <Link to={"/users"}>
          <i className="bx bx-user-circle" />
          <span>{props.t("Deepak")}</span>
        </Link>
      </li> */}

      <li>
        <Link to={"/verify_request"}>
          <i className="bx bx-check" />
          <span>{props.t("Verify Museum Request")}</span>
        </Link>
      </li>

      <li>
        <Link to={"/collections"}>
          <i className="bx bx-collection" />
          <span>{props.t("Collections")}</span>
        </Link>
      </li>
      <li>
        <Link to={"/earning"}>
        <i class='bx bxs-dashboard' ></i>
        <span>{props.t("Manage Earning")}</span>
        </Link>
      </li>
      <li>
        <Link to={"/email"}>
          <i class="bx bx-envelope"></i>
          <span>{props.t("Send Email")}</span>
        </Link>
      </li>

      <li>
        <Link to="/items" className="has-arrow waves-effect">
          <i className="bx bx-message-rounded-x" />
          <span>{props.t("NFTs")}</span>
        </Link>
        {/* <ul hidden className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/items">{props.t("Created")}</Link>
          </li>
          {/* <li>
            <Link to="/items">{props.t("On Sale")}</Link>
          </li>
          <li>
            <Link to="/items">{props.t("Inactive")}</Link>
          </li> *
        </ul> */}
      </li>

      <li>
        <Link to={"/categories"} className="has-arrow waves-effect">
          <i className="bx bx-chevron-down-square" />
          <span>{props.t("Categories")}</span>
        </Link>
        {/* <ul hidden className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/categories">{props.t("List Categories")}</Link>
          </li>
          <li>
            <Link to="/add_category">{props.t("Add Category")}</Link>
          </li>
        </ul> */}
      </li>

      <li>
        <Link to={"/blog"}>
          <i className="bx bx-text" />
          <span>{props.t("How to Articles / Videos")}</span>
        </Link>
      </li>

      {/* <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-text" />
          <span>{props.t("Blog")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/blog">{props.t("List Blog")}</Link>
          </li>
          <li>
            <Link to="/add_blog">{props.t("Add Blog")}</Link>
          </li>
        </ul>
      </li> */}

      <li>
        <Link to={"/activities"}>
          <i className="bx bx-bar-chart" />
          <span>{props.t("Activities")}</span>
        </Link>
      </li>

      <li>
        <Link to={"/transactions"}>
          <i className="bx bx-transfer" />
          <span>{props.t("Transactions")}</span>
        </Link>
      </li>

      <li>
        <Link to={"/content-pages"}>
          <i className="bx bxs-book-content" />
          <span>{props.t("Content Pages")}</span>
        </Link>
      </li>

      <li>
        <Link to={"/faqs"}>
          <i className="bx bx-bar-chart" />
          <span>{props.t("FAQs")}</span>
        </Link>
      </li>
      {/* <li>
        <Link to={"/contact-us"}>
          <i class="bx bxs-user"></i>
          <span>{props.t("Contact Us")}</span>
        </Link>
      </li> */}


      <li>
      <Link to={{pathname:"/printingpartner", state: "Print Shop" }}>
      <i class='bx bx-printer'></i>
          <span>{props.t("Manage Print Shop")}</span>
      </Link>
        <ul className="submenu">
          <li><Link  className={pageName == 'Print Shop' ? 'active':''} to={{pathname:"/printingpartner", state: "Print Shop" }}><i class="fa-light fa-angle-right"></i> Print Shop List</Link></li>
          <li><Link  className={pageName == 'print' ? 'active':''} to={{pathname:"/print", state: "Faqvideos" }}><i class="fa-light fa-angle-right"></i> Print Request </Link></li>
          <li><Link  className={pageName == 'Media' ? 'active':''} to={{pathname:"/printpayment", state: "Media" }}><i class="fa-light fa-angle-right"></i> Manage Payment </Link></li>
          <li><Link  className={pageName == 'Support' ? 'active':''} to={{pathname:"/withdawprintpayment", state: "Support" }}><i class="fa-light fa-angle-right"></i> Printer Withdrawal Request </Link></li>
        </ul>

      </li>

      <li>
      <Link to={{pathname:"/general", state: "General" }}>
          <i className="bx bx-cog" />
          <span>{props.t("Settings")}</span>
      </Link>
        <ul className="submenu">
          {/* <li><Link  className={pageName == 'General' ? 'active':''} >General Settings</Link>
            <ul className="childmenu">
              <li><Link  className={pageName == 'pageSetting' ? 'active':''} to={{pathname:"/general", state: "pageSetting" }}><i class="fa-light fa-angle-right"></i> Home Page </Link></li>
              <li><Link  className={pageName == 'Description' ? 'active':''} to={{pathname:"/general", state: "Description" }}><i class="fa-light fa-angle-right"></i> Home Nft Description </Link></li>
              <li><Link  className={pageName == 'Museums' ? 'active':''} to={{pathname:"/general", state: "Museums" }}><i class="fa-light fa-angle-right"></i> Museums </Link></li>
              <li><Link  className={pageName == 'About' ? 'active':''} to={{pathname:"/general", state: "About" }}><i class="fa-light fa-angle-right"></i>  About </Link></li>
              <li><Link  className={pageName == 'Footer' ? 'active':''} to={{pathname:"/general", state: "Footer" }}><i class="fa-light fa-angle-right"></i>  Footer </Link></li>
              <li><Link  className={pageName == 'Registerimages' ? 'active':''} to={{pathname:"/general", state: "Registerimages" }}><i class="fa-light fa-angle-right"></i> Register Images</Link></li>
              <li><Link  className={pageName == 'Captcha' ? 'active':''} to={{pathname:"/general", state: "Captcha" }}><i class="fa-light fa-angle-right"></i> Captcha Key Settings</Link></li>
            </ul>
          </li> */}
          {/* <li><Link to={"/image-setting"}> <span>{props.t("Image Settings")}</span></Link> </li> */}
          <li><Link  className={pageName == 'General' ? 'active':''} to={{pathname:"/general", state: "General" }}><i class="fa-light fa-angle-right"></i> General</Link></li>
          <li><Link  className={pageName == 'Faqvideos' ? 'active':''} to={{pathname:"/general", state: "Faqvideos" }}><i class="fa-light fa-angle-right"></i> Faq Video </Link></li>
          <li><Link  className={pageName == 'Media' ? 'active':''} to={{pathname:"/general", state: "Media" }}><i class="fa-light fa-angle-right"></i> Social Media </Link></li>
          <li><Link  className={pageName == 'Support' ? 'active':''} to={{pathname:"/general", state: "Support" }}><i class="fa-light fa-angle-right"></i> Support </Link></li>
         


        </ul>

      </li>

      {/* <li>
        <Link to="/ipfs-settings">
          <i className="bx bx-donate-blood" />
          <span>{props.t("IPFS Settings")}</span>
        </Link>
      </li> */}

      {/* <li>
        <Link to={{ pathname: '/blockchain'}} >
          <i className="bx bx-bitcoin" />
          <span>{props.t("Blockchain Network")}</span>
        </Link>
      </li> */}

      {/* <li>
        <Link to="/mailchimp">
          <i className="bx bx-cog" />
          <span>{props.t("MailChimp Settings")}</span>
        </Link>
      </li> */}

      {/* <li>
        <Link to="/contract" >
          <i className="bx bx-cog" />
          <span>{props.t("Contract Settings")}</span>
        </Link>
      </li> */}

      {/* <li>
        <Link to="/wallet">
          <i className="bx bx-wallet-alt" />
          <span>{props.t("Wallet Settings")}</span>           
        </Link>
      </li> */}

      {/* <hr /> */}

      {/* <li>
        <Link to="/change-password">
          <i className="mdi mdi-key" />
          <span>{props.t("Change Password")}</span>
        </Link>
      </li> */}

      {/* <li>
       <div>
       <Link to="/logout">
          <i className="bx bx-power-off" />
          <span>{props.t("Logout")}</span>
        </Link>
       </div>
      </li> */}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
