import { call, put, takeEvery } from "redux-saga/effects";

import { GET_SENDEMAIL, ADD_EMAIL, GET_EMAIL_BY_ID } from "./actionTypes";
import {
  getSendEmailFail,
  getSendEmailSuccess,
  addSendEmailFail,
  addSendEmailSuccess,
  getEmailByIDFail,
  getEmailByIDSuccess,
} from "./actions";

import { postEmails, postAddEmails, fetchEmailByIdApi } from "helpers/backend_helper";
import SendEmailReducer from "./reducer";

function* fetchCategories({ payload }) {
  try {
    const response = yield call(postEmails, payload);
    if (response.status == "failure") {
      return yield put(getSendEmailFail(response.message));
    }

    yield put(getSendEmailSuccess(response));
  } catch (error) {
    yield put(getSendEmailFail(error));
  }
}

function* onAddSendEmails({ payload: { email, callback } }) {
  try {
    const response = yield call(postAddEmails, email);

    if (response.status == "failure") {
      return yield put(addSendEmailFail(response.message));
    }
    callback && callback(response);

    yield put(addSendEmailSuccess(response));
  } catch (error) {
    yield put(addSendEmailFail("Internal Error!"));
  }
}

function* fetchEmailById({ payload: { id, callback } }) {
  try {
    const response = yield call(fetchEmailByIdApi, id);
    if (response.status == "failure") {
      return yield put(getEmailByIDFail(response.message));
    }
    callback && callback(response);

    yield put(getEmailByIDSuccess(response));
  } catch (error) {
    yield put(getEmailByIDFail("Internal Error!"));
  }
}

function* SendEmailSaga() {
  yield takeEvery(GET_SENDEMAIL, fetchCategories);
  yield takeEvery(ADD_EMAIL, onAddSendEmails);
  yield takeEvery(GET_EMAIL_BY_ID, fetchEmailById);
}

export default SendEmailSaga;
