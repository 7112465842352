import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";
import { Spinner } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { addPrinter } from "store/actions";
import addprintclose from "assets/images/svg/addprintclose.svg"
import { withTranslation } from "react-i18next";
import toastr from "toastr";
const AddPrintPartner = props => {
  const { isOpen, toggle, onAddPertner, loading } = props
  const validEmailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w- ;,./?%&=]*)?$/i;
  const [viewDialog, setViewDialog] = useState(false)
  const [addLength, setAddLength] = useState(0)
  const [fields, setFields] = useState({
    shop_name: "",
    email: "",
    business_address: "",
    website: "",
    contact_number: "",
  })
  const closeDialog = () => {
    toggle()
    setViewDialog(false)
  }
  useEffect(() => {
    setAddLength(fields?.business_address.length)
  }, [fields?.business_address])


  const onChangeHandler = (e) => {
    e.persist();
    var { value, name } = e.target;
    if (value == '' || value === ' ') {
      value = value.replace(/\s+/g, '');
    }
    if (name === 'contact_number') {
      value = value.replace(/[^0-9]/g, '');
    }
    setFields((prev) =>
      value !== "" || undefined || null
        ? { ...prev, [name + "Error"]: false, [name]: value }
        : { ...prev, [name + "Error"]: true, [name]: value }
    );
  }
  const onAddHandler = () => {
    try {
      if (!fields?.shop_name) {
        return toastr.error("Shop name is required.");
      }
      if (fields?.shop_name.length<2) {
        return toastr.error("Minimum two character are required for shop name.");
      }
      if (!fields?.contact_number) {
        return toastr.error("Contact number is required.");
      }
      if (!fields?.business_address) {
        return toastr.error("Business address is required.");
      }
      if (!fields?.email) {
        return toastr.error("Email is required.");
      }
      if (!validEmailRegex.test(fields?.email)) {
        return toastr.error("Please enter a valid email address.");
      }
  
      const callback = (response) => {
        if (response.status !== "success") {
          return toastr.error(response.message);
        }
        setFields((p) => ({
          ...p,
          shop_name: "",
          email: "",
          business_address: "",
          website: "",
          contact_number: "",
        }))
        closeDialog()
        return toastr.success("Print Shop added successfully.");
      }
      onAddPertner(fields, callback)

    } catch (error) {
      console.log("error", error)
    }
  }
  return (
    <>
      {loading && (
        <div className="spinnerBoxNew">
          <Spinner color="primary" />
        </div>
      )}
      <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>
        <div className='dialogInner'>
          <div className='addprinthead'>
            <h1>Add Print Shop</h1>
            <button className='closebtn' onClick={toggle}>
              <img src={addprintclose} alt="close" width={40} height={40} />
            </button>
          </div>
          <div className={'dialogbody ' + (viewuser.viewProfile)}>
            <div className='row'>
              <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                <div className='form-group'>
                  <label>Shop Name</label>
                  <input type="text" className='form-control' placeholder="Enter shop name" name="shop_name" value={fields?.shop_name} onChange={onChangeHandler} maxLength={30} />
                </div>
              </div>
              <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                <div className='form-group'>
                  <label>Contact Number</label>
                  <input type="text" className='form-control' placeholder="Enter contact number" name="contact_number" value={fields?.contact_number} onChange={onChangeHandler} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-12 mb-4'>
                <div className='form-group'>
                  <label>Business Address</label>
                  <textarea className='form-control' placeholder="Enter business address" name="business_address" value={fields?.business_address} onChange={onChangeHandler} maxLength={200} />{`${addLength}/200`}
                </div>
              </div>
              <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                <div className='form-group'>
                  <label>Email Address</label>
                  <input type="text" className='form-control' placeholder="Enter email address" name="email" value={fields?.email} onChange={onChangeHandler} />
                </div>
              </div>
              <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                <div className='form-group'>
                  <label>Website</label>
                  <input type="text" className='form-control' placeholder="Enter website URL" name="website" value={fields?.website} onChange={onChangeHandler} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-xl-12 col-12 col-md-12 col-sm-12 ">
                <button className="btn verifybtn px-5 text-white me-3" type="submit" onClick={onAddHandler}>
                  Add
                </button>
                <button className="btn btn-outline-danger px-5" onClick={toggle}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>)
}
AddPrintPartner.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
}
const mapStateToProps = ({ Partners }) => ({
  error: Partners.error,
  loading: Partners.loading,
  partner: Partners.partner,
});

const mapDispatchToProps = (dispatch) => ({
  onAddPertner: (data, callback) => dispatch(addPrinter(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddPrintPartner));