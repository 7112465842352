import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { connect, useDispatch, useSelector } from "react-redux";
// import { Row, Col } from "reactstrap"

import { Link } from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoLightPng from "../../assets/images/logo.png";
import logoDark from "../../assets/images/logo.png";
import mini_logoDark from "../../assets/images/mini-logo.png";
import logodark from "../../assets/images/logoopen.png";
import logodark2x from "../../assets/images/logoopen.png";
import logoheader from "../../assets/images/logo.png";
import logoheader2x from "../../assets/images/logoopen.png";
import { useNavigate } from "react";
//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  getOtherNotifications,
  markOtherNotifications,
} from "../../store/actions";

import { ROLES } from "helpers/contants";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Notification from "./notification";
import moment from "moment";

const Header = (props) => {
  // console.log('header', props);
  const navigate = useLocation();
  // console.log(navigate.state);
  const dispatch = useDispatch()
  const history = useHistory()
  const { otherNotifications, otherNotificationCount } = useSelector((s) => s?.items)
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [isOpenNotification, setOpenNotification] = useState(false)
  const [data, setData] = useState([])
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }

  const notification = () => {
    setOpenNotification(true)
  }
  const notificationClose = () => {
    setOpenNotification(false)
    let data = {}
    data.receiver = process.env.REACT_APP_ADMIN_ID
    const callback = (response) => {
      // console.log("bcjshdbcjhsdb", response)
    }
    if (data.receiver) {
      dispatch(getOtherNotifications(data, callback))
      dispatch(markOtherNotifications(data))
    }
  }

  useEffect(() => {
    // const callback = (res) => {
    //   if (res.status === "success") {
    //     let count = 0;
    //     for (let i = 0; i < data.length; i++) {
    //       if (data[i].is_read === false) {
    //         count++;
    //       }
    //     }
    //     setUnread(count)
    //   } else { }
    // }
    let data = {}
    data.receiver = process.env.REACT_APP_ADMIN_ID
    dispatch(getOtherNotifications(data))
  }, [])

  useEffect(() => {
    // console.log("data", data)
  }, [data])

  useEffect(() => {

    setData(otherNotifications)
  }, [otherNotifications, otherNotificationCount])

  const markRead = (item) => {
    notificationClose()
    let data = {}
    data.notificationId = item?._id
    dispatch(markOtherNotifications(data))
    if (item?.type === "User Registration") {
      history.push('/users');
    }
    else if (item?.type === "artApproval" || item?.type === "Art Upload") {
      history.push('/manageart');
    }

  }
  const menucollaps = () => {
    const getmenu = document.getElementById('menuBoxLeft')
    getmenu.classList.toggle('active')
    // console.log(getmenu)
  }
  return (
    <React.Fragment>
      <header id="page-topbar">

        <div className="navbar-header container-fluid mb-3">
          <div className="row justify-content-between">
            <div className="col-xl-2 my-auto" >
              <button className="btn border-0 p-0 menubutton" onClick={() => menucollaps()} ><i class='bx bx-menu-alt-left'></i></button>
            </div>
            {/* <div className="col-xl-10">
        <Breadcrumbs
            title="Send Email"
            breadcrumbItem={props.t("Send Email")}
            breadcrumbItems={[{ title: props.t("Send Email") }]}
          />
        </div> */}
            <div className='col-xl-2'>
              <div className='topprofile'>
                <ul>
                  <li><button className='fullview' type='button' onClick={() => {
                    toggleFullscreen();
                  }}
                    data-toggle="fullscreen">
                    <img src="/images/svg/icons/fullview.svg" width={30} height={30} alt="" />
                  </button></li>
                  <li>
                    <button onClick={() => notification()} className='bell position-relative' type='button'>
                      <img src="/images/svg/icons/bell.svg" width={30} height={30} alt="" />
                      <div className="notiificationCount">{otherNotificationCount}</div>
                    </button>
                  </li>
                  <li>
                    <button className='key' type='button'>
                      <img src="/images/svg/icons/key.svg" width={30} height={30} alt="" />
                    </button>
                  </li>
                  <li>
                    <ProfileMenu />
                  </li>


                </ul>
              </div>
            </div>
          </div>

        </div>
      </header>

      {/* -------------------Notification Box ---------------------- */}
      <div
        className={"notificationBox " + (isOpenNotification === true ? "active" : "")}
      >
        <div className="dialogHead">
          <h1>Notification</h1>
          <button className="closebtn" onClick={() => notificationClose()}>
            <img
              src={"/images/svg/icons/closeIcon.svg"}
              alt="close"
              width={40}
              height={40}
            />
          </button>
        </div>
        <div className="notificationBody">
          <ul>


            {data.map((item) => (
              (item?.type === "User Registration" || item?.type === "Registration") &&
              // <Link to={'/users'}>
              <li key={item._id} style={{ animationDuration: '.5s' }} >
                <div className="d-flex align-items-center justify-content-between">
                  <h2>{item?.is_read === false && <span className="unreadbox">*</span>}{item?.type} </h2>

                  <h2><span>{moment.unix(item?.created_date).local().fromNow()}</span></h2>
                </div>
                <p>{item?.content} </p>
              </li>

            ))}
            {data.map((item) => (
              (item?.type === "artApproval" || item?.type === "Art Upload" || item?.type === "Pending Art for Approval") &&
              // <Link to={'/manageart'}>
              <li key={item._id} style={{ animationDuration: '1.0s' }} >
                <div className="d-flex align-items-center justify-content-between">
                  <h2>  {item?.is_read === false && <span className="unreadbox">*</span>}{item?.type}</h2>

                  <h2><span>{moment.unix(item?.created_date).local().fromNow()}</span></h2>
                </div>

                <p>{item?.content}</p>
              </li>
              // </Link>
            ))}
            {/* <li style={{ animationDuration: '1s' }}>
              <div className="d-flex align-items-center justify-content-between">
                <h2>Art</h2>
                <h2><span>10/10/2023</span></h2>
              </div>
              <p> Discover 3500+ Notifications designs on Dribbble. Your resource to discover and connect with designers worldwide. </p>
            </li> */}
            {/* <li style={{ animationDuration: '1.5s' }}>
              <div className="d-flex align-items-center justify-content-between">
                <h2>Verify Museum Request”</h2>
                <h2><span>1 hour ago</span></h2>
              </div>
              <p> Discover 3500+ Notifications designs on Dribbble. Your resource to discover and connect with designers worldwide. </p>
            </li>
            <li style={{ animationDuration: '2.0s' }}>
              <div className="d-flex align-items-center justify-content-between">
                <h2>Verify Museum Request”</h2>
                <h2><span>5 hour ago</span></h2>
              </div>
              <p> Discover 3500+ Notifications designs on Dribbble. Your resource to discover and connect with designers worldwide. </p>
            </li>
            <li style={{ animationDuration: '3s' }}>
              <div className="d-flex align-items-center justify-content-between">
                <h2>Collection</h2>
                <h2><span>11 hour ago</span></h2>
              </div>
              <p> Discover 3500+ Notifications designs on Dribbble. Your resource to discover and connect with designers worldwide. </p>
            </li>
            <li style={{ animationDuration: '3.5s' }}>
              <div className="d-flex align-items-center justify-content-between">
                <h2>Items</h2>
                <h2><span>11/10/animationDuration</span></h2>
              </div>
              <p> Discover 3500+ Notifications designs on Dribbble. Your resource to discover and connect with designers worldwide. </p>
            </li> */}


          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  userId: PropTypes.string
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    role: state?.Login?.user?.role || "",
    storeID: state?.Settings?.settings?.slug || "#",
    userId: state?.Login?.user?._id
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//   //   getCustomersSuccess: (data) => dispatch(getCustomersSuccess(data)),
//     onGetOtherNotifications: (data) => {
//       console.log(data);
//       dispatch(getOtherNotifications(data));
//     },
//   //   onSendEmail: (data, callback) => {
//   //     dispatch(sendVerificationMailRequest(data, callback))
//   //   }
//   };
// };

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,

})(withTranslation()(Header));
