import { call, put, takeEvery } from "redux-saga/effects";
import { approveOrRejectArtApi, fetchArtByIdApi, getArts } from "helpers/backend_helper";
import { approveOrRejectArtSuccess, approveOrRejectFail, getArtByIdFail, getArtByIdSuccess, getArtsFail, getArtsSuccess } from "./actions";
import * as CONST from "./actionTypes";

function* fetchArts({ payload }) {
    try {
        const response = yield call(getArts, payload);
        console.log("response", response);
        if (response.status == "failure") {
            return yield put(getArtsFail(response.message));
        }

        yield put(getArtsSuccess(response));
    } catch (error) {
        yield put(getArtsFail(error));
    }
}

function* approveOrRejectArt({ payload: { data, callback } }) {
    try {
        console.log("payload", data)
        const response = yield call(approveOrRejectArtApi, data);
        console.log("response", response);
        if (response.status == "failure") {
            return yield put(approveOrRejectFail(response.message));
        }


        yield put(approveOrRejectArtSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(approveOrRejectFail(error));
    }
}
function* fetchArtById({ payload: { userId, callback } }) {
    try {
        const response = yield call(fetchArtByIdApi, userId);
        console.log("response fetchArtByIdApi", response);
        if (response.status == "failure") {
            return yield put(getArtByIdFail(response.message));
        }
        callback && callback(response)
        yield put(getArtByIdSuccess(response));
    } catch (error) {
        yield put(getArtByIdFail(error));
    }
}


function* ArtsSaga() {
    yield takeEvery(CONST.GET_ARTS, fetchArts);
    yield takeEvery(CONST.GET_ART_BY_ID, fetchArtById);
    yield takeEvery(CONST.APPROVE_OR_REJECT, approveOrRejectArt);

}

export default ArtsSaga;