import { sendEmailVerificationApi } from "helpers/backend_helper";
import { SEND_VERIFICATION_EMAIL_REQUEST } from "./actionTypes";
import { sendVerificationMailFail, sendVerificationMailSuccess } from "./action";

import { call, takeEvery, put, takeLatest } from "redux-saga/effects"

function* onsendVerificationEmail({ payload: { data, callback } }) {
    try {
      const response = yield call(sendEmailVerificationApi, data);
      console.log("reposne" ,response);
  
      if (response.status == "failure") {
        return yield put(sendVerificationMailFail(response.message));
      }
      callback && callback(response);
      yield put(sendVerificationMailSuccess());
      
    } catch (error) {
      yield put(sendVerificationMailFail("Internal Error!"));
    }
  }



  function* sendverificationEmailSaga() {
    yield takeLatest(SEND_VERIFICATION_EMAIL_REQUEST, onsendVerificationEmail);
  }
  export default sendverificationEmailSaga;