import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty, orderBy } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";
import toastr from "toastr"
//i18n
import { withTranslation } from "react-i18next";
import sortSvg from "../../../assets/images/svg/sort.svg";
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker;
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import { getCustomers, getCustomersSuccess, getOtherNotifications, getPrinterWithdrawlList } from "store/actions";
import UsersColumns, { selectRow } from "./Columns";
import ConfirmModal from "./ConfirmModal";

import { STORE_FIELD_NAME, STATUS_VALUE, TRIAL_VALUE } from "helpers/contants";
import ViewPrintWithdrawal from "./ViewPrintWithdrawal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { sendVerificationMailRequest, sendVerificationMailSuccess } from "store/emailVerification/action";
import moment from 'moment';
const statusFilter = { fieldName: "status", fieldValue: { $ne: "temp" } };

// const data = viewDialog
const withdawprintpayment = (props) => {

  const {
    accessLevel,
    history,
    withdrawls,
    totalWithdrawlsCount,
    getWithdrawlSuccess,
    loading,
    onSendEmail
  } = props;

  const [clearAllBtnForSortBy, setClearAllBtnForSortBy] = useState(false)
  const [todaysDate, setTodaysDate] = useState('')
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });

  const [viewUser, setviewUser] = useState({
    isOpen: false,
    userId: null,
  });

  const [viewStatusValue, setStatusValue] = useState({
    isOpen: false,
    userId: null,
    status: ''
  });
  const [dates, setDates] = useState([])
  const [filter, setFilter] = useState({
    orderBy: "created_date",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    role: "",
    timeZone: "",
    starting_day: "1970-01-01",
    day: todaysDate,
    fields: [
      {
        fieldName: "status",
        fieldValue: "",
      },
    ],
  });

  const [lastFields, setLastFields] = useState({
    fieldName: "status",
    fieldValue: "",
  });

  const [searchText, setSearchText] = useState("");
  const [userList, setUsersList] = useState([]);
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalWithdrawlsCount,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    page: filter?.page
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);
  useEffect(() => {
    const getFormattedDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so +1
      const day = String(today.getDate()).padStart(2, '0');

      return `${year}-${day}-${month}`;
    };
    setTodaysDate(getFormattedDate())
  }, [])


  useEffect(() => {
    getWithdrawlSuccess(filter);
    setUsersList(withdrawls);
  }, [JSON.stringify(filter), lastFields.fieldValue]);




  useEffect(() => {
    setUsersList(withdrawls || []);
  }, [withdrawls]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {

    if (type === "search") {
      setSearchText(searchText);
    }
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };
  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const toggleViewUser = (userId) => {
    setviewUser((prevState) => ({ isOpen: !prevState.isOpen, userId }));
    getWithdrawlSuccess(filter)
  };

  const onStatusChange = (data) => {
    setStatusValue((prevState) => ({ isOpen: !prevState?.isOpen, id: data?.id, status: data?.status }));
    // setStatusValue(data);
    
  };
  const sendVerificationMail = (email) => {
    const callback = (response) => {
      if (response.status === "success") {
        toastr.success("An account verification email has been sent successfully.")
      }
      else {
        toastr.error(response.message)
      } 0
    }
    onSendEmail({ email: email }, callback);

  };
  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetUsers(filter);
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    onDeleteUser({ _id: userId }, onComplete);
  };

  const onFieldNameChange = (value) => {
    setLastFields({ fieldName: value, fieldValue: "" });
    /* setFilter((prevState) => ({
      ...prevState,
      fields: [
        prevState?.fields[0],
        statusFilter,
        { fieldName: value, fieldValue: "" },
      ],
    })) */
  };

  const onFieldValueChange = (value) => {
    let fieldName = lastFields.fieldName;
    let fieldValue = value;

    if (value !== "" && fieldName === "isTrial") {
      fieldName = "billingPlan";
    }

    if (value === "isTrial") {
      fieldName = "isTrial";
      fieldValue = true;
    }

    if (value == "isPaid") {
      fieldName = "isTrial";
      fieldValue = false;
    }

    setLastFields((prevState) => ({ ...prevState, fieldName, fieldValue }));
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText, page: 1 }));
  };
  const selectRole = (e) => {
    setFilter((prevState) => ({ ...prevState, role: e.target.value }));
  }
  const sortDateWise = (e) => {
    setClearAllBtnForSortBy(true)
    if (e.target.value === "Newest_first") {
      setFilter((prevState) => ({
        ...prevState,
        orderBy: "date_created",
        order: -1,
      }));
    }
    else {
      setFilter((prevState) => ({
        ...prevState,
        orderBy: "date_created",
        order: 1,
      }));
    }

  }
  const handleClear = () => {
  }
  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }
  useEffect(() => {
    setFilter((prevState) => ({ ...prevState, starting_day: dates[0], day: dates[1], page: 1 }))
  }, [dates])
  return (
    <React.Fragment>
      {viewUser.isOpen === true &&
        <ViewPrintWithdrawal isOpen={viewUser.isOpen} userId={viewUser.userId} toggle={toggleViewUser} onConfirm={removeConfirm} getStatusValue={onStatusChange} />
      }
      {viewStatusValue.isOpen === true &&
        <ConfirmModal
          isOpen={viewStatusValue.isOpen}
          toggle={onStatusChange}
          onConfirm={removeConfirm}
          onStatus={viewStatusValue}
          toggleViewUser={toggleViewUser}
        />
      }

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Printer Withdrawal Request"
            breadcrumbItem={props.t("Printer Withdrawal Request")}
            breadcrumbItems={[{ title: props.t("Printer Withdrawal Request") }]}
          />

          <Row>
            <Col xs="12">
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={userList || []}
                    columns={UsersColumns(
                      history,
                      toggleConfirmModal,
                      toggleViewUser,
                      sendVerificationMail,
                      props.t,
                      accesses
                    )}
                    bootstrap4
                    search
                  >

                    {(toolkitProps) => (
                      <React.Fragment>

                        <div className="row">
                          <div className="col-xl-12 mb-4 filterBox">
                            <div className="row">
                              <div className="col-xl-5">
                                <div className="form-group position-relative">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search")}
                                    searchText={searchText}
                                  />
                                  {/* <input type="text" className='form-control pe-5' placeholder='Search.....' />
                    <button className='btn position-absolute end-0 top-0 bottom-0 p-0 me-3'><i className="fa-light fa-magnifying-glass"></i></button> */}
                                </div>
                              </div>
                              {/* <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                  <h3>
                                    {" "}
                                    <img
                                      src={"/images/svg/icons/filterIcon.svg"}
                                      alt={"Filter"}
                                      width={25}
                                      height={25}
                                    />{" "}
                                    Filter
                                  </h3>
                                  <div className="form-group position-relative w-100">

                                    <select
                                      name=""
                                      className="form-control form-select"
                                      id=""

                                      onChange={selectRole}
                                    >
                                      <option value="" selected disabled>Select Status</option>
                                      <option value="User">User</option>
                                      <option value="Artist">Artist</option>
                                      <option value="Museum">Museum</option>

                                    </select>
                                  </div>
                                </div>
                              </div> */}
                              <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="form-group position-relative w-100">
                                    <div className="range-date" style={{position:"relative"}} >
                                      {/* <RangePicker className="custom-date-picker"
                                        onClear={handleClear}
                                        disabledDate={disabledDate}
                                        onChange={(values) => {
                                          if (values) {
                                            var dateStr1 = values[0].$d;
                                            var dateStr2 = values[1].$d;

                                            var date1 = moment(dateStr1);
                                            var date2 = moment(dateStr2);

                                            var dateDifference = date2.diff(date1);
                                            var daysDifference = moment.duration(dateDifference).asDays();
                                           
                                            var daysDifference = moment.duration(dateDifference).asDays();
                                            setDates(values.map(item => {
                                              return moment(item.$d).format('YYYY-DD-MM')
                                            }))
                                          }
                                          else {
                                            setFilter((prevState) => ({ ...prevState, starting_day: '1970-01-01', day: todaysDate }))
                                          }
                                        }} />
                     <img src="/images/svg/Vector.svg" width="auto" height="auto" classname="position-absolute" style={{right:"0.875rem", top:"0.7rem",position:"absolute"}}/> */}

<RangePicker className="custom-date-picker"
                                              onClear={handleClear}
                                              disabledDate={disabledDate}
                                              onChange={(values) => {
                                                if (values) {
                                                  var dateStr1 = values[0].$d;
                                                  var dateStr2 = values[1].$d;

                                                  // Parse the date strings using Moment.js
                                                  var date1 = moment(dateStr1);
                                                  var date2 = moment(dateStr2);

                                                  // Calculate the difference between the two dates
                                                  var dateDifference = date2.diff(date1);

                                                  var daysDifference = moment.duration(dateDifference).asDays();
                                                  // if (daysDifference > 15) {
                                                  //   return toastr.error("Date Range should be less than 15 days")
                                                  // }
                                                  // Display the difference in days
                                                  var daysDifference = moment.duration(dateDifference).asDays();
                                                  setDates(values.map(item => {
                                                    return moment(item.$d).format('YYYY-DD-MM')
                                                  }))
                                                }
                                                else {
                                                  setFilter((prevState) => ({ ...prevState, starting_day: '1970-01-01', day: todaysDate }))
                                                }
                                              }} />
                                            <img src="/images/svg/Vector.svg" width="auto" height="auto" classname="position-absolute" style={{ right: "0.875rem", top: "0.7rem", position: "absolute" }} />

                                    </div>

                                  </div>
                                </div>
                              </div>
                              {(!!lastFields.fieldValue ||
                                !!filter.search || !!filter.role || clearAllBtnForSortBy === true) && (
                                  <div className='col-xl-2'>
                                    <button onClick={(e) => {
                                      setClearAllBtnForSortBy(false)
                                      e.preventDefault();
                                      setFilter((prevState) => ({
                                        ...prevState,
                                        search: "",
                                        role: "",
                                        orderBy: ""
                                      }));
                                      setSearchText("");
                                      setLastFields({
                                        fieldName: "status",
                                        fieldValue: "",
                                      });
                                    }} className='btn clearAll'><i className="fa-regular fa-filter-circle-xmark"></i>  {props.t("Clear All")}</button>
                                  </div>)}
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tableBox usertable">
                              <BootstrapTable
                                responsive
                                remote
                                bordered={false}
                                striped={false}
                                noDataIndication={"No data available"}
                                classes={"table  table-centered table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                defaultSorted={[
                                  {
                                    dataField: "created_date",
                                    order: "desc",
                                  },
                                ]}
                                sort={{
                                  sortCaret: (order) =>
                                    order === "asc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-up" />
                                      </span>
                                    ) : order === "desc" ? (
                                      <span className="caret">
                                        <i className="bx bx-caret-down" />
                                      </span>
                                    ) : <span className="caret">
                                      {" "}<img src={sortSvg} alt="" width={11.1} height={11.1} />
                                    </span>,
                                }}
                              />

                              {loading && (
                                <div className="spinnerBoxNew ">
                                  <Spinner color="primary" />
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

withdawprintpayment.propTypes = {
  t: PropTypes.any,
  withdrawls: PropTypes.array,
  totalWithdrawlsCount: PropTypes.number,
  getWithdrawlSuccess: PropTypes.func
};

const mapStateToProps = ({ Partners }) => ({
  loading: Partners.loading,
  withdrawls: Partners.withdrawlRequestList,
  totalWithdrawlsCount: Partners.totalWithdrawlCount,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getWithdrawlSuccess: (data) => dispatch(getPrinterWithdrawlList(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withdawprintpayment))
);
