import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";
import { Row, Col, FormGroup, FormText, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

// Images
import logo_placeholder from "assets/images/logo-placeholder.png";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;

  const [logoselectedFiles, setlogoselectedFiles] = useState([]);

  useEffect(() => {
    if (isObject(fields?.logo) && !!fields?.logo?.link) {
      setlogoselectedFiles([{ preview: fields?.logo?.link }]);
    }
  }, [JSON.stringify(fields?.logo)]);

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;

    handleChange(name)({ target: { value: _id } });
  };

  const handleAcceptedFiles = (name) => (_files) => {
    const files = _files?.filter((file) => file.size < 9e6);
    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 7MB");
    }
    const ext = files[0]?.path.split(".").pop();
    console.log(ext, "56654654654");
    if (
      ext !== "png" &&
      ext !== "jpg" &&
      ext !== "gif" &&
      ext !== "jpeg" &&
      ext !== "mp4"
    ) {
      toastr.error("Only .png,.jpg,.jpeg, JPEG extension allow!!");
      return;
    }

    onUploadFile({ image: files[0] }, uploadFileSuccess(name));
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    if (name === "logo") {
      setlogoselectedFiles(files);
    }
  };
  useEffect(() => {
    if (isObject(fields?.logo) && !!fields?.logo?.link) {
      setlogoselectedFiles([{ preview: fields?.logo?.link }]);
    }
  }, [JSON.stringify(fields?.logo)]);

  return (
    <React.Fragment>
      <Row>
        {/* <Col lg={4}>
          <Instructions t={props.t} />
        </Col> */}
        <Col md={3}>
        <div className="categories_div">
              <FormGroup>
                {/* <Label>Logo</Label> */}
                <div className="uploadImageMaine mb-0">
                        <div className="uploadImgNew  position-relative start-0 top-0">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles("logo")(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    const imageFile = logoselectedFiles[0];
                    console.log(imageFile, 88888888888);

                    return (
                      <div
                        className="dropzone-single-image categoryUploadImage  avatar-xl border video-setting"
                        {...getRootProps()}
                      >
                        <input
                          className="category-inp"
                          {...getInputProps()}
                          id="formrow-profile-image-Input"
                          multiple={false}
                        />

                        {(imageFile?.path &&
                          imageFile?.path?.search(".mp4") !== -1) ||
                        imageFile?.preview?.search(".mp4") !== -1 ? (
                          <video
                            className="rounded avatar-xl video-inp uploadlogogbox categories-image-uploader"
                            controls
                            autoplay="autoplay"
                            muted
                            src={
                              imageFile?.preview || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          >
                            <source
                              src={
                                imageFile?.preview || !!imageFile
                                  ? imageFile.preview
                                  : logo_placeholder
                              }
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            className="rounded avatar-xl video-inp uploadlogogbox categories-image-uploader"
                            alt={
                              !!imageFile && imageFile.name
                                ? imageFile.name
                                : "hlc"
                            }
                            src={
                              fields?.bannerImage?.link || !!imageFile
                                ? imageFile.preview
                                : logo_placeholder
                            }
                          />
                        )}

                        <div className="edit" hidden={imageFile?.path ? true : false}>
                            <i className="bx bx-pencil"></i>
                          </div>
                      </div>
                    );
                  }}
                </Dropzone>
              
                <small class="fw-bold  form-text text-muted">Max. upload file size: 5MB</small>
              
                </div>
                      </div>
              </FormGroup>
              </div>
            </Col>
        <Col lg={12}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Name</Label>

                <Input
                  type="text"
                  value={fields.name}
                  onChange={(e) => {
                    handleChange("name")(e);
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <Label>Admin Commission</Label>

                <Input
                  type="number"
                  value={fields.adminCommission}
                  onChange={(e) => {
                    handleChange("adminCommission")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>

           
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
