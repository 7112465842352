import { call, put, takeEvery } from "redux-saga/effects";
import "toastr/build/toastr.min.css";

import toastr from "toastr";

// Food Delivery Drivers Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* onpostImages({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postImage, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      window.scrollTo(0, 0);
      return yield put(ACTIONS.postImageFail(response.message));
    }
    callback && callback(response);
    yield put(ACTIONS.postImageSuccess());
  } catch (error) {
    yield put(ACTIONS.postImageFail("Internal Error!"));
  }
}

function* ongetAllImages({ payload }) {
  try {
    const response = yield call(API.getImages, payload);
    if (response.status == "failure") {
      return yield put(ACTIONS.getImagesFail(response.message));
    }
    yield put(ACTIONS.getImagesSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getImagesFail(error));
  }
}

function* ongetImage({ payload: { id } }) {
  try {
    const response = yield call(API.getImage, id);

    if (response.status == "failure") {
      return yield put(ACTIONS.getImageFail(response.message));
    }

    yield put(ACTIONS.getImageSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getImageFail(error));
  }
}

function* onputImage({ payload: { data, callback } }) {
  try {
    const response = yield call(API.putImage, data);
    if (response.status == "failure") {
      return yield put(ACTIONS.putImageFail(response.message));
    }
    callback && callback(response);

    yield put(ACTIONS.putImageSuccess());
  } catch (error) {
    yield put(ACTIONS.putImageFail("Internal Error!"));
  }
}

function* onDeleteImage({ payload: { data, callback } }) {
  try {
    const response = yield call(API.deleteImage, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.deleteImageFail(response.message));
    }
    yield put(ACTIONS.deleteImageSuccess());
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.deleteImageFail("Internal Error!"));
  }
}

function* ImageSaga() {
  yield takeEvery(TYPES.GET_IMAGES, ongetAllImages);
  yield takeEvery(TYPES.POST_IMAGE, onpostImages);
  yield takeEvery(TYPES.GET_IMAGE, ongetImage);
  yield takeEvery(TYPES.PUT_IMAGE, onputImage);
  yield takeEvery(TYPES.DELETE_IMAGE, onDeleteImage);
}

export default ImageSaga;
