import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Button, Modal, ModalBody } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, getParticularSellItem } from "store/actions";
import CustomersList from ".";
import moment from "moment-timezone";
import avatar4 from "assets/images/svg/logoPlaceholder.svg";

const ViewItems = props => {
  console.log("cjdnsjkcnjkndcjsdjnkn", props);
  const dispatch = useDispatch()
  const { isOpen, toggle, onConfirm, userId, onGetCustomer, customer } = props
  const [nfttype, setnfttype] = useState('nft')
  const [viewDialog, setViewDialog] = useState(false)

  useEffect(() => { console.log("customer", customer) }, [isOpen, customer])
  useEffect(() => {
    if (userId && isOpen === true) {
      console.log("jscjksjkcjsc", userId)
      onGetCustomer(userId)
    }
  }, [userId])
  const closeDialog = () => {
    console.log('Deepak');
    setViewDialog(false)
  }
  return (
    // <Modal

    //   isOpen={isOpen}
    //   autoFocus={true}
    //   centered={true}
    //   toggle={toggle} className="dialogInner"
    // >
    //   <div className="modal-content ">
    //     <ModalBody>
    //       <div className="infoimg">
    //        Deepak
    //       </div>

    //       <h4>Are you sure?</h4>

    //       <div className="delete-btns">
    //         <Button type="button" outline color="secondary" onClick={toggle}>
    //           No, cancel!
    //         </Button>

    //         <Button type="button" color="primary" onClick={onConfirm}>
    //           Yes, delete it!
    //         </Button>
    //       </div>
    //     </ModalBody>
    //   </div>
    // </Modal>
    <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>
      <div className='dialogInner'>
        <div className='dialogHead'>
          <h1>View NFT</h1>
          <button className='closebtn' onClick={toggle}>
            <img src={'/images/svg/icons/closeIcon.svg'} alt="close" width={40} height={40} />
          </button>
        </div>
        <div className={'dialogbody ' + (viewuser.viewProfile)}>
          <div className='row'>
            <div className='col-xl-4'>
              <div className={viewuser.profilephoto}>
                <img src={customer?.nft_id?.image_id?.image?.link ? customer?.nft_id?.image_id?.image?.link : avatar4} alt="Image" width={100} height={100} />
              </div>
            </div>
            <div className='col-xl-8'>
              <div className='row'>
                <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                  <div className='form-group'>
                    <label >Listing Title</label>
                    <input type="text" className='form-control' readOnly value={customer?.nft_id?.name} name="" id="" />
                  </div>
                </div>
                <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                  <div className='form-group'>
                    <label > Listing Sub title</label>
                    <input type="text" className='form-control' readOnly value={customer?.nft_id?.subtitle} name="" id="" />
                  </div>
                </div>

                <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                  {nfttype === 'nft' ? <div className='form-group'>
                    <label >Price</label>
                    <input type="text" className='form-control' readOnly value={customer?.nft_id?.price + " " + "MATIC"} name="" id="" />
                  </div> : <div className='form-group'>
                    <label >Sold Price</label>
                    <input type="text" className='form-control' readOnly value={'$455'} name="" id="" />
                  </div>}

                </div>
                <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                  <div className='form-group'>
                    <label >Royalty Percentage %</label>
                    <input type="text" className='form-control' readOnly value={customer?.loaylty} name="" id="" />
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className='row'>
            {customer?.sellType === 'auction' &&
              <>
                <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                  <div className='form-group'>
                    <label > Starting Bid Price</label>
                    <input type="text" className='form-control' readOnly value={customer?.price + " " + "MATIC"} name="" id="" />
                  </div>
                </div>
                <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                  <div className='form-group'>
                    <label > Minimum Bid Price</label>
                    <input type="text" className='form-control' readOnly value={customer?.current_price + " " + "MATIC"} name="" id="" />
                  </div>
                </div>
                <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                  <div className='form-group'>
                    <label > Auction Length</label>
                    <input type="text" className='form-control' readOnly value={customer?.auction_days + " " + "days"} name="" id="" />
                  </div>
                </div>
              </>
            }

            <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
              <div className='form-group'>
                <label >Owner</label>
                <input type="text" className='form-control' readOnly value={customer?.owner_id?.name + " " + customer?.owner_id?.lastname} name="" id="" />
              </div>
            </div>
            <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
              <div className='form-group'>
                <label >Creation date</label>
                <input type="text" className='form-control' readOnly value={moment.unix(customer?.created_date).format("DD MMM YYYY hh:mm A")} name="" id="" />
              </div>
            </div>
            {customer?.nft_id?.relatedImages &&
              <div className='col-xl-4 col-12 col-md-4 col-sm-4 mb-4'>
                <div className={'form-group ' + viewuser.documentdetails}>
                  <label >Supporting Documents</label>
                  <div className="position-relative ">
                    <input type="text" className='form-control' readOnly value={customer?.nft_id?.relatedImages?.link.split("/")[3]} name="" id="" />
                    {/* <span  >View Document</span> */}
                    {/* <a className='position-absolute end-0 top-0 bottom-0' href={customer?.docs_link?.link}>View</a> */}
                    <a className='position-absolute end-0 top-0 bottom-0'  href={customer?.nft_id?.relatedImages?.link} target={"_blank"}>View Document</a>
                    {/* <a className='position-absolute end-0 top-0 bottom-0'  href="path_to_file" download={customer?.d}>Download</a> */}
                  </div>

                </div>
              </div>
            }
            <div className={' col-12 col-md-6 col-sm-6 mb-4 ' + (nfttype !== 'nft' ? 'col-xl-12' : 'col-xl-6')}>
              <div className='form-group'>
                <label >Type</label>
                <input type="text" className='form-control' readOnly value={customer?.sellType === "fixed" ? "Fixed" : "Auction"} name="" id="" />
              </div>
            </div>
            {nfttype === 'nft' &&
              <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                <div className='form-group'>
                  <label >No. of copies</label>
                  <input type="text" className='form-control' readOnly value={customer?.no_of_copies} name="" id="" />
                </div>
              </div>
            }
            <div className='col-xl-6 col-12 col-md-6 col-sm-6'>
              <div className='form-group'>
                <label >Category</label>
                <input type="text" className='form-control' readOnly value={customer?.nft_id?.category_id?.name} name="" id="" />
              </div>
            </div>
            <div className='col-xl-6 col-12 col-md-6 col-sm-6'>
              <div className='form-group'>
                <label >Subcategory</label>
                <input type="text" className='form-control' readOnly value={customer?.nft_id?.subcategory?.name} name="" id="" />
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )

}
ViewItems.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any
}
const mapStateToProps = ({ items }) => ({
  error: items.error,
  loading: items.loading,
  customer: items.sellItem,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getParticularSellItem(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewItems);