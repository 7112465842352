import React, { useEffect, useState } from "react";

// PropTypes
import PropTypes from "prop-types";

// Componets
import FormButton from "components/Common/FormButtons";

import Dropzone from "react-dropzone";
import toastr from "toastr";
import { Card, CardBody, Col, Container, Row, Form } from "reactstrap";
import { FormGroup, FormText, Label, Alert, Spinner, Input } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import avatar4 from "assets/images/svg/logoPlaceholder.svg";

// Actions
import { uploadFile, postImage, putImage, getSettings } from "store/actions";

// Breadcrumbs
import Breadcrumbs from "components/Common/Breadcrumb2";

// Images
// import avatar4 from "assets/images/logo-placeholder.png";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

let rowCount = {
  row1: "12345678",
  row2: "123456",
  row3: "12345",
  row4: "12345",
};

const AddHomeImages = ({
  accessLevel,
  callback,
  error,
  fileLoading,
  onAddImages,
  onPutImages,
  settings,
  onUploadFile,
  onGetSettings,
  loading,
  ...props
}) => {
  const [selectedFiles, setselectedFiles] = useState({});
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });

  const [images, setImages] = useState([]);
  const imgId = images?.map((_list) => _list?._id);
  const [fields, setFields] = useState({
    type: "row1",
    id: "",
    images: "",
  });

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };
      return setaccesses(data);
    }
    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;
        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });
    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    const FilterImage = settings?.homePageImages?.filter(
      (d) => d?.type == fields?.type
    );
    if (FilterImage?.length > 0) {
      setImages(FilterImage[0]?.image);
    } else {
      setImages([]);
    }
  }, [settings?.homePageImages, fields?.type]);

  useEffect(() => {
    onGetSettings();
  }, []);

  const uploadFileSuccess = (_, indexNo, files) => (response) => {
    const { _id: image } = response.data;
    setImages((prev) => [
      ...prev.slice(0, indexNo),
      {
        ...prev[indexNo],
        _id: image,
        preview: URL.createObjectURL(files[0]),
        formattedSize: formatBytes(files[0].size),
      },
      ...prev.slice(indexNo + 1),
    ]);
  };

  const handleAcceptedFiles = (name, indexNo) => (_files) => {
    const files = _files?.filter((file) => file.size < 5242880);
    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    onUploadFile({ image: files[0] }, uploadFileSuccess(name, indexNo, files));
  };

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setFields((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (loading) return;
    const imageData = {
      type: fields?.type,
      id: settings?._id,
      images: imgId,
    };
    onAddImages(imageData);
  };
const [IsImages, setisImages] =  useState(true)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs
            title="Home Banner Images"
            breadcrumbItem={
              props.t("add") + " " + props.t("Home Banner Images")
            }
            breadcrumbItems={[
              {
                title: props.t("Home Banner Images"),
                link: "",
              },
              props.t("add"),
            ]}
          /> */}
          <Breadcrumbs
            title="Banner Images"
            breadcrumbItem={props.t("Banner Images")}
            breadcrumbItems={[{ title: props.t("Banner Images") }]}
          />

          <Row className="mt-4">
            <Col lg={12}>
             
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      {/* <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            Select Row Type for upload Image!!
                          </Label>
                          <Input
                            type="select"
                            id="formrow-firstname-Input"
                            value={fields.type}
                            onChange={handleChange("type", rowCount)}
                            required
                          >
                            <option value="">Select Image Type</option>
                            <option value="row1">Row (1)</option>
                            <option value="row2">Row (2)</option>
                            <option value="row3">Row (3)</option>
                            <option value="row4">Row (4)</option>
                          </Input>
                        </FormGroup>
                      </Col> */}
                      {IsImages ? (
                        <>
                          <Col md={3}>
                            <div className="imgageBox">
                                <img  src={'/images/jpg/bannerImg.jpg'} />
                                <button><i class='bx bx-x'></i></button>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="uploadbtnImage">
                              <img src={'images/svg/imageIcon.svg'} />
                              <h4>Max. upload file size: 5MB</h4>
                              <Input type="file" id="selectImage" hidden />
                            <label htmlFor="selectImage" className="btn verifybtn px-4 text-white py-2 mt-2 ">Select Image</label>
                            </div>
                          </Col>
                          <Col className="mt-5" md={12}>
                            {loading || fileLoading ? (
                            <button className="btn btn-primary w-md mb-2 mr-3">
                              <Spinner animation="border" size="sm" />
                            </button>
                          ) : (
                            <FormButton needSubmit={accesses.canAdd} />
                          )}
                      </Col>
                        </>
                      ):(
                        <>
                        <Col className="text-center py-5" md={12}>
                          <img src={'/images/svg/noData.svg'}  alt="" /> <br />
                          <Input type="file" id="selectImage" hidden />
                          <label htmlFor="selectImage" className="btn verifybtn px-5 text-white py-2 mt-3 ">Select Image</label>
                      </Col>
                    
                        </>
                      )}
                     

                      {/* {Array.from(rowCount[fields?.type]).map((d, idx) => {
                        return (
                          <Col md={3}>
                            <div className="categories_div">
                              <FormGroup>
                                <Label for="formrow-profile-image-Input">
                                  {props.t("Image")}
                                </Label>
                                <Dropzone
                                  onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(
                                      "image",
                                      idx
                                    )(acceptedFiles);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => {
                                    const imageFile = selectedFiles.image;

                                    return (
                                      <div
                                        className="dropzone-single-image avatar-xl"
                                        {...getRootProps()}
                                      >
                                        <input
                                          className="category-inp"
                                          {...getInputProps()}
                                          id="formrow-profile-image-Input"
                                          multiple={false}
                                        />

                                        <img
                                          className="rounded avatar-xl categories-image-uploader"
                                          alt={
                                            !!imageFile && imageFile.name
                                              ? imageFile.name
                                              : "hlc"
                                          }
                                          src={
                                            images[idx]?.preview
                                              ? images[idx]?.preview
                                              : images[idx]?.link
                                              ? images[idx]?.link
                                              : avatar4
                                          }
                                        />
                                        <div className="edit">
                                          <i className="bx bx-pencil"></i>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Dropzone>
                                <FormText>Max. upload file size: 5MB</FormText>
                              </FormGroup>
                            </div>
                          </Col>
                        );
                      })} */}
                     
                    </Row>

                  

                    {loading && (
                      <div className="spinnerBoxNew">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
               
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddHomeImages.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddImages: PropTypes.func,
  onPutImages: PropTypes.func,
};

const mapStateToProps = ({ File, ImageReducer, Settings }) => ({
  loading: ImageReducer.loading,
  fileLoading: File?.loading,
  settings: Settings?.settings,
});

const mapDispatchToProps = (dispatch) => ({
  onAddImages: (data, callback) => dispatch(postImage(data, callback)),
  onGetSettings: () => dispatch(getSettings()),
  onPutImages: (data, callback) => dispatch(putImage(data, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddHomeImages))
);
