// Add Printing partners (SK) 
export const ADD_PRINTING_PARTTNER = "ADD_PRINTING_PARTTNER"
export const ADD_PRINTING_PARTTNER_FAIL = "ADD_PRINTING_PARTTNER_FAIL"
export const ADD_PRINTING_PARTTNER_SUCCESS = "ADD_PRINTING_PARTTNER_SUCCESS"

// list Printing partners  
export const LIST_PRINTING_PARTTNER = "LIST_PRINTING_PARTTNER"
export const LIST_PRINTING_PARTTNER_FAIL = "LIST_PRINTING_PARTTNER_FAIL"
export const LIST_PRINTING_PARTTNER_SUCCESS = "LIST_PRINTING_PARTTNER_SUCCESS"

// edit Printing partners  
export const EDIT_PRINTING_PARTTNER = "EDIT_PRINTING_PARTTNER"
export const EDIT_PRINTING_PARTTNER_FAIL = "EDIT_PRINTING_PARTTNER_FAIL"
export const EDIT_PRINTING_PARTTNER_SUCCESS = "EDIT_PRINTING_PARTTNER_SUCCESS"

// get Printing partners  
export const GET_PRINTING_PARTTNER = "GET_PRINTING_PARTTNER"
export const GET_PRINTING_PARTTNER_FAIL = "GET_PRINTING_PARTTNER_FAIL"
export const GET_PRINTING_PARTTNER_SUCCESS = "GET_PRINTING_PARTTNER_SUCCEGET"
// delete Printing partners  
export const DELETE_PRINTING_PARTTNER = "DELETE_PRINTING_PARTTNER"
export const DELETE_PRINTING_PARTTNER_FAIL = "DELETE_PRINTING_PARTTNER_FAIL"
export const DELETE_PRINTING_PARTTNER_SUCCESS = "DELETE_PRINTING_PARTTNER_SUCCESS"

// delete Printing partners  
export const LIST_PRINTING_REQUEST = "LIST_PRINTING_REQUEST"
export const LIST_PRINTING_REQUEST_FAIL = "LIST_PRINTING_REQUEST_FAIL"
export const LIST_PRINTING_REQUEST_SUCCESS = "LIST_PRINTING_REQUEST_SUCCESS"

// get Printing requests  
export const GET_PRINTING_REQUEST = "GET_PRINTING_REQUEST"
export const GET_PRINTING_REQUEST_FAIL = "GET_PRINTING_REQUEST_FAIL"
export const GET_PRINTING_REQUEST_SUCCESS = "GET_PRINTING_REQUEST_SUCCESS"

// get Printing Payment requests list
export const GET_PRINTING_PAYMENT_REQUEST = "GET_PRINTING_PAYMENT_REQUEST"
export const GET_PRINTING_PAYMENT_REQUEST_FAIL = "GET_PRINTING_PAYMENT_REQUEST_FAIL"
export const GET_PRINTING_PAYMENT_REQUEST_SUCCESS = "GET_PRINTING_PAYMENT_REQUEST_SUCCESS"

// get Printing Payment requests list
export const GET_PRINTING_WITHDRAWL_REQUEST = "GET_PRINTING_WITHDRAWL_REQUEST"
export const GET_PRINTING_WITHDRAWL_REQUEST_FAIL = "GET_PRINTING_WITHDRAWL_REQUEST_FAIL"
export const GET_PRINTING_WITHDRAWL_REQUEST_SUCCESS = "GET_PRINTING_WITHDRAWL_REQUEST_SUCCESS"


// view Printing Payment requests list
export const VIEW_PRINTING_WITHDRAWL_REQUEST = "VIEW_PRINTING_WITHDRAWL_REQUEST"
export const VIEW_PRINTING_WITHDRAWL_REQUEST_FAIL = "VIEW_PRINTING_WITHDRAWL_REQUEST_FAIL"
export const VIEW_PRINTING_WITHDRAWL_REQUEST_SUCCESS = "VIEW_PRINTING_WITHDRAWL_REQUEST_SUCCESS"


// update Printing Payment requests list
export const UPDATE_PRINTING_WITHDRAWL_REQUEST = "UPDATE_PRINTING_WITHDRAWL_REQUEST"
export const UPDATE_PRINTING_WITHDRAWL_REQUEST_FAIL = "UPDATE_PRINTING_WITHDRAWL_REQUEST_FAIL"
export const UPDATE_PRINTING_WITHDRAWL_REQUEST_SUCCESS = "UPDATE_PRINTING_WITHDRAWL_REQUEST_SUCCESS"


// get Printing Payment csv data
export const GET_PRINTING_CSV_DATA = "GET_PRINTING_CSV_DATA"
export const GET_PRINTING_CSV_DATA_FAIL = "GET_PRINTING_CSV_DATA_FAIL"
export const GET_PRINTING_CSV_DATA_SUCCESS = "GET_PRINTING_CSV_DATA_SUCCESS"