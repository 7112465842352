import {
  ITEM_API_FAIL,
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  ADD_ITEM,
  ADD_ITEM_FAIL,
  ADD_ITEM_SUCCESS,
  GET_ITEM,
  GET_ITEM_FAIL,
  GET_ITEM_SUCCESS,
  PUT_ITEM,
  PUT_ITEM_FAIL,
  PUT_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  PUT_ITEMS_STATUS,
  PUT_ITEMS_STATUS_FAIL,
  PUT_ITEMS_STATUS_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  GET_OTHER_NOTIFICATIONS_SUCCESS,
  GET_OTHER_NOTIFICATIONS_FAIL,
  GET_OTHER_NOTIFICATIONS,
  MARK_GET_OTHER_NOTIFICATIONS,
  MARK_GET_OTHER_NOTIFICATIONS_FAIL,
  MARK_GET_OTHER_NOTIFICATIONS_SUCCESS,
  GET_PARTICULAR_SELLITEM_FAIL,
  GET_PARTICULAR_SELLITEM,
  GET_PARTICULAR_SELLITEM_SUCCESS,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: ITEM_API_FAIL,
  payload: error,
});

/* Get items */
export const getItems = (data) => ({
  type: GET_ITEMS,
  payload: data,
});

export const getItemsSuccess = (items) => ({
  type: GET_ITEMS_SUCCESS,
  payload: items,
});

export const getItemsFail = (error) => ({
  type: GET_ITEMS_FAIL,
  payload: error,
});
/* Get Items END */

/* Add Item */
export const addItem = (item, history) => ({
  type: ADD_ITEM,
  payload: { item, history },
});

export const addItemFail = (error) => ({
  type: ADD_ITEM_FAIL,
  payload: error,
});

export const addItemSuccess = () => ({
  type: ADD_ITEM_SUCCESS,
});
/* Add Item END */

/* Get Item */
export const getItem = (id) => ({
  type: GET_ITEM,
  payload: id,
});

export const getItemFail = (error) => ({
  type: GET_ITEM_FAIL,
  payload: error,
});

export const getItemSuccess = (item) => ({
  type: GET_ITEM_SUCCESS,
  payload: item,
});
/* Get Item END */

/* Update Item */
export const putItem = (data, history) => ({
  type: PUT_ITEM,
  payload: { data, history },
});

export const putItemFail = (error) => ({
  type: PUT_ITEM_FAIL,
  payload: error,
});

export const putItemSuccess = () => ({
  type: PUT_ITEM_SUCCESS,
});
/* Update Item END */

/* Delete Item */
export const deleteItem = (data, callback, type) => ({
  type: DELETE_ITEM,
  payload: { data, callback, type },
});

export const deleteItemFail = (error) => ({
  type: DELETE_ITEM_FAIL,
  payload: error,
});

export const deleteItemSuccess = () => ({
  type: DELETE_ITEM_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Items Status */
export const putItemsStatus = (data, callback) => ({
  type: PUT_ITEMS_STATUS,
  payload: { data, callback },
});

export const putItemsStatusFail = (error) => ({
  type: PUT_ITEMS_STATUS_FAIL,
  payload: error,
});

export const putItemsStatusSuccess = () => ({
  type: PUT_ITEMS_STATUS_SUCCESS,
});
/* Update Multi Items Status END */

/* Get Notifications */
export const getNotifications = (data, callback) => ({
  type: GET_NOTIFICATIONS,
  payload: { data, callback },
});

export const getNotificationsFail = (error) => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
});

export const getNotificationsSuccess = (data) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: data,
});
/* Get Notifications END */



// other notifiction 

export const getOtherNotifications = (data, callback) => ({
  type: GET_OTHER_NOTIFICATIONS,
  payload: { data, callback },
});

export const getOtherNotificationsFail = (error) => ({
  type: GET_OTHER_NOTIFICATIONS_FAIL,
  payload: error,
});

export const getOtherNotificationsSuccess = (data) => ({
  type: GET_OTHER_NOTIFICATIONS_SUCCESS,
  payload: data,
});



export const markOtherNotifications = (data, callback) => ({
  type: MARK_GET_OTHER_NOTIFICATIONS,
  payload: { data, callback },
});

export const markOtherNotificationsFail = (error) => ({
  type: MARK_GET_OTHER_NOTIFICATIONS_FAIL,
  payload: error,
});

export const markOtherNotificationsSuccess = (data) => ({
  type: MARK_GET_OTHER_NOTIFICATIONS_SUCCESS,
  payload: data,
});



/* Get Item */
export const getParticularSellItem = (id, callback) => ({
  type: GET_PARTICULAR_SELLITEM,
  payload: {id, callback},
});

export const getParticularSellItemFail = (error) => ({
  type: GET_PARTICULAR_SELLITEM_FAIL,
  payload: error,
});

export const getParticularSellItemSuccess = (item) => ({
  type: GET_PARTICULAR_SELLITEM_SUCCESS,
  payload: item,
});
/* Get Item END */