import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Alert
import Alert from "./Alert/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Register from "./auth/register/reducer";
import Settings from "./Settings/reducer";
import customers from "./customers/reducer";
import domain from "./domain/reducer";
import collections from "./collection/reducer";
import items from "./items/reducer";
import Category from "./category/reducer";
import Activities from "./activity/reducer";

// SUbadmins
import SubAdmins from "./subAdmins/reducer";

// Content Page
import content_page from "./contentpage/reducer";

// Files
import File from "./File/reducer";

// Logs
import Logs from "./logs/reducer";

// Email Templates
import emailtemplates from "./emailtemplates/reducer";
import SendEmailReducer from "./SendEmail/reducer";

// Faqs
import faqs from "./faq/reducer";
import ContactUs from "./contactus/reducer";

import ImageReducer from "./AddImages/reducer";
import Arts from "./art/reducer";
import SendEmail from "./emailVerification/reducer"
import sendOtherNotification from "./otherNotification/reducers"

import Transactions from "./transaction/reducers";
import SellItems from "./sellItems/reducer";
import Partners from "./printingPartner/reducer";
const rootReducer = combineReducers({
  Layout,
  Alert,
  Login,
  Activities,
  collections,
  Category,
  items,
  ForgetPassword,
  Profile,
  Register,
  Settings,
  domain,
  customers,
  SubAdmins,
  File,
  Logs,
  emailtemplates,
  faqs,
  ContactUs,
  content_page,
  SendEmailReducer,
  ImageReducer,
  Arts,
  SendEmail,
  sendOtherNotification,
  Transactions,
  SellItems,
  Partners
});

export default rootReducer;
