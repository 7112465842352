import React from "react"
import { Link } from "react-router-dom"
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap"
import moment from "moment-timezone"

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: false,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
})

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "title",
    text: t("Title"),
    sort: true
  },
  {
    dataField: "status",
    text: t("status"),
    sort: true,
    formatter: (cellContent, row) => (
      <div
        className={
          (row.status == "active"
            ? "verify"
            : "reject")
        }
      >
        {t(row.status)}
      </div>
    ),
  },
  {
    text: t("created_at"),
    dataField: "createdAt",
    sort: true,
    formatter: (_, row) => moment(row.date_created_utc).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link
          to={`/content-pages/${row._id}/view`}
          // to={{ pathname: "/edit/blog", category_id: row._id }}
          className="btn px-0 py-0  border-0"
        >
          <i className="fa-light fa-eye"></i>
          <UncontrolledTooltip
            placement="top"
            target={`edit-${row._id}-tooltip`}
          >
            {t("edit")}
          </UncontrolledTooltip>
        </Link>


        {accesses?.canEdit ? (

          <Link
            to={`/content-pages/${row._id}/edit`}
            className="btn px-2 py-0  border-0"
          >
            <i className="fa-regular text-success fa-pen-to-square" id={`edit-${row._id}-tooltip`} />
            <UncontrolledTooltip
              placement="top"
              target={`edit-${row._id}-tooltip`}
            >
              {t("edit")}
            </UncontrolledTooltip>
          </Link>
        ) : (
          <Link
            to={`/content-pages/${row._id}/edit`}
            className="mr-3 text-secondary"
          >
            <i className="far fa-eye mr-3" id={`edit-${row._id}-tooltip`} />
            <UncontrolledTooltip
              placement="top"
              target={`edit-${row._id}-tooltip`}
            >
              {t("view_details")}
            </UncontrolledTooltip>
          </Link>
        )}

        {accesses?.canDelete && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault()
              toggleConfirmModal(row._id)
            }}
            className="btn px-0 py-0  border-0"
          >
            <i
              className="fa-regular text-danger  fa-trash"
              id={`delete-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link>
        )}
      </>
    ),
  },
]

export default ListColumns
