import React, { useEffect, useRef, useState } from "react";
import PropTypes, { array } from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import FormButton from "components/Common/FormButtons";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import viewuser from "../../../assets/css/viewuser.module.css";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";
import {
  uploadFile,
  addCategory,
  getCategory,
  putCategory,
  getSubCategories,
} from "store/actions";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { withTranslation } from "react-i18next";
import avatar4 from "assets/images/logo-placeholder.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AddCategory = ({
  accessLevel,
  history,
  error,
  fileLoading,
  onAddCategories,
  onGetCategories,
  onPutCategories,
  onGetSubCategories,
  subCategories,
  items,
  onUploadFile,

  loading,
  ...props
}) => {
  const { id } = useParams();
  const location = useLocation()
  const didMountRef = useRef(null);
  const [selectedFiles, setselectedFiles] = useState({});
  const [loading2, setLoading2] = useState(false);
  const [path, setPath] = useState();
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    name: "",
    categoryType: "",
    image: "",
    status: "active",
    subCategories: []
  });

  useEffect(() => {
    console.log("params", location)
    const x = location.pathname.split("/")[2]
    console.log("path", x)
    setPath(x)

  }, [location])


  useEffect(() => {
    if(path ==="add"){
      setsubcategoryArray([])
    }

  }, [path])

  console.log(fields, 5845464865759);
  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };
      return setaccesses(data);
    }
    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id && path !== "add") {
      onGetCategories(id);
    }
  }, [id, path]);

  useEffect(() => {
    if (path !== "add") {
      console.log(" subCategories====>>>>", subCategories);

      const array2 = subCategories.filter((item) => item?.category?._id === id)
      let requiredArray = array2.map((item) => {
        return item?.name
      })
      setsubcategoryArray(requiredArray)
      setFields((p) => ({ ...p, subCategories: requiredArray }))
    }
  }, [subCategories, path]);

  useEffect(() => {
    console.log("path", path)
    if (id && path !== "add") {
      onGetSubCategories(id)
    }
  }, [id, path])

  useEffect(() => {
    if (didMountRef.current ) {
      if (!!items) {
        try {
          setFields({
            name: items?.name,
            categoryType: items?.categoryType,
            image: items?.image?._id,
            status: items?.status || "inactive",
          });
          let images = {};
          if (items?.image?.link) {
            images.image = { preview: items?.image?.link };
          }
          if (items?.bannerImage?.link) {
            images.bannerImage = { preview: items?.bannerImage?.link };
          }
          setselectedFiles(images);
        } catch (err) {
          console.log("parsing error ", err);
        }
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(items)]);

  const uploadFileSuccess = (name) => (response) => {
    if (response) setLoading2(false)
    const { _id: image } = response.data;

    setFields((prevState) => ({ ...prevState, [name]: image }));
  };

  function handleAcceptedFiles(name, _files) {
    if (id && !accesses.canEdit) return;
    const files = _files?.filter((file) => file.size < 5e6);
    if (files.length < _files.length) {
      return toastr.error(props.t("Max. upload file size: 5MB"));
    }
    setLoading2(true)
    onUploadFile({ image: files[0] }, uploadFileSuccess(name));
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setselectedFiles((prevState) => ({ ...prevState, [name]: files[0] }));
  }

  const handleChange = (name) => (event) => {
    console.log("sahi", event.target.value);
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    console.log("hey submit callled");
    event.preventDefault();
    if (loading) return;
    if (id) {
      if (!accesses.canEdit) return;
      onPutCategories(
        { category_id: items?._id, ...fields },
        toastr.success("Updated Successfully"),
        history.push("/categories")
      );
    } else {
      if (!accesses.canAdd) return;

      if (fields?.image == "") {
        return toastr.error("Image is Required");
      }
      if (fields?.subCategories == []) {
        return toastr.error("subCategories Required");
      }

      onAddCategories(
        { type: fields.blockchain, ...fields },
        toastr.success("Added Successfully"),
        history.push("/categories")
      );
    }
  };
  const [subcategory, setsubcategory] = useState('')

  const [subcategoryArray, setsubcategoryArray] = useState([])
  const subCategoryChangeHandler = (e) => {
    let data = e.target.value
    console.log("e.target.value", e.target.value);
    setsubcategory(data)
  }

  const onSubCategorySubmit = () => {
    let array = subcategoryArray
    if (subcategory === '') {
      toastr.error("Subcategory can not be empty")
      return
    }
    array.push(subcategory)
    setsubcategoryArray(array)
    // setsubcategoryArray(oldArray => [...oldArray, subcategory]);
    setFields((prevState) => ({ ...prevState, subCategories: subcategoryArray }));
    setsubcategory('')
  }

  const removeSucategory = (data) => {
    const filtereddata = subcategoryArray.filter((item) => item !== data)
    setFields((prevState) => ({ ...prevState, subCategories: filtereddata }));
    setsubcategoryArray(filtereddata)

  }
  useEffect(() => {
    console.log("subcategoryArray", subcategoryArray);

  }, [subcategoryArray])
  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          {path === "edit" ? (
            <Breadcrumbs
              title="Categories"
              breadcrumbItem={
                (id ? props.t("edit") : props.t("add")) +
                " " +
                props.t("Categories")
              }
              breadcrumbItems={[
                { title: props.t("Categories"), link: "/Categories" },
                { title: id ? props.t("edit") : props.t("add") },
              ]}
            />
          ) : (
            <Breadcrumbs
              title="Categories"
              breadcrumbItem={
                (props.t("view")) +
                " " +
                props.t("Categories")
              }
              breadcrumbItems={[
                { title: props.t("Categories"), link: "/Categories" },
                { title: id ? props.t("View") : props.t("add") },
              ]}
            />

          )}
          {(loading || loading2) ? (
            <div className="spinnerBoxNew">
              <Spinner color="primary" />
            </div>
          ) :
            <Row>
              <Col lg={12}>
                <div className={"addbox position-relative " + viewuser.viewProfile}>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    <Row>
                      <Col className="position-relative mb-5" lg={2}>
                        <div className="uploadImgNew">
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("image", acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles.image;

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl"
                                  {...getRootProps()}
                                >
                                  <input

                                    className="category-inp"
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />
                                  <img

                                    className="rounded avatar-xl categories-image-uploader w-100"
                                    alt={
                                      !!imageFile && imageFile.name
                                        ? imageFile.name
                                        : "hlc"
                                    }
                                    src={
                                      !!imageFile
                                        ? imageFile.preview
                                        : avatar4
                                    }
                                  />

                                  {/* <div className="edit">
                                      <i className="bx bx-pencil"></i>
                                    </div> */}
                                </div>
                              );
                            }}
                          </Dropzone>

                          <FormText className="fw-bold mb-4">Max. upload file size: 5MB</FormText>
                        </div>
                      </Col>
                      <Col className="mt-5" lg={12}>
                        <FormGroup>
                          <Label className="mb-1" for="formrow-name-Input">
                            {props.t("Category Name")}
                          </Label>
                          <Input
                            readOnly={path === "view"}
                            type="text"
                            id="formrow-name-Input"
                            value={fields.name}
                            onChange={handleChange("name")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12}>
                        <Row>
                          <Col lg={12}>
                            <div className="form-group mb-0">
                              <Label className="mb-3" for="formrow-name-Input">
                                {path === "edit" ? props.t("Add Subcategories") : props.t("Subcategories")}
                              </Label>
                            </div>

                            <div className="subcategoryList ">
                              <ul className="d-flex" >
                                {subcategoryArray && subcategoryArray.map((item, index) => (
                                  <li key={index}>{item}
                                    <button hidden={path === "view"} onClick={() => removeSucategory(item)}><i class="fa-regular fa-minus fa-xs"></i></button>
                                  </li>
                                ))}
                              </ul>
                            </div>

                          </Col>
                          <Col lg={4}>
                            <FormGroup className="d-flex align-items-center gap-3">
                              <Input type="text"
                                hidden={path === "view"}
                                id="formrow-name-Input"
                                value={subcategory}
                                onChange={subCategoryChangeHandler}
                              />
                              <button hidden={path === "view"} className="btn btn-dark text-nowrap px-4 rounded-pill py-2" type="button" onClick={onSubCategorySubmit}><i class="fa-regular fa-plus fa-sm"></i> Add</button>
                            </FormGroup>
                          </Col>

                        </Row>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label className="mb-1" for="formrow-name-Input">
                            {props.t("Status")}
                          </Label>
                          {path === "view" ?
                            (<Input type="text" value={fields.status}></Input>)
                            : (
                              <Input type="select" value={fields?.status} onChange={(e) => { setFields((prevState) => ({ ...prevState, ["status"]: event.target.value })) }}  >

                                <option value={"active"}>Active</option>
                                <option value={"inactive"}>Inactive</option>
                              </Input>
                            )
                          }
                        </FormGroup>
                      </Col>
                      <div className="col-xl-12 col-12 col-md-12 col-sm-12 ">
                        <button hidden={path === "view"} className="btn verifybtn px-5 text-white me-3" type="submit">
                          Submit
                        </button>
                        <button hidden={path === "view"} className="btn btn-outline-danger px-5">
                          Cancel
                        </button>

                      </div>
                    </Row>
                  </Form>
                </div>
                {/* <Card >
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-name-Input">
                            {props.t("Name")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-name-Input"
                            value={fields.name}
                            onChange={handleChange("name")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      {/* Abstract Select type */}
                {/* <Col md={6}>
                        <FormGroup>
                          <Label>
                            {props.t("Select Abstract/Modern Categories")}
                          </Label>
                          <Input
                            type="select"
                            value={fields.categoryType}
                            onChange={handleChange("categoryType")}
                            required
                          >
                            <option value="">Select categories</option>
                            <option value="ModernPop">Abstract/Modern</option>
                            <option value="Photography">Photography</option>
                            <option value="Representational">
                              Representational
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> */}

                {/* <Row>
                      <Col md={12}>
                        <div className="categories_div">
                          <FormGroup>
                            <Label for="formrow-profile-image-Input">
                              {props.t("Image")}
                            </Label>

                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                handleAcceptedFiles("image", acceptedFiles);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => {
                                const imageFile = selectedFiles.image;

                                return (
                                  <div
                                    className="dropzone-single-image avatar-xl"
                                    {...getRootProps()}
                                  >
                                    <input
                                      className="category-inp"
                                      {...getInputProps()}
                                      id="formrow-profile-image-Input"
                                      multiple={false}
                                    />
                                    <img
                                      className="rounded avatar-xl categories-image-uploader"
                                      alt={
                                        !!imageFile && imageFile.name
                                          ? imageFile.name
                                          : "hlc"
                                      }
                                      src={
                                        !!imageFile
                                          ? imageFile.preview
                                          : avatar4
                                      }
                                    />

                                    <div className="edit">
                                      <i className="bx bx-pencil"></i>
                                    </div>
                                  </div>
                                );
                              }}
                            </Dropzone>

                            <FormText>Max. upload file size: 5MB</FormText>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row> */}

                {/* <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("Status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row> */}

                {/* {fileLoading ? (
                      <Spinner />
                    ) : (
                      <FormButton
                        needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      />
                    )} */}


                {/* </Form> */}
                {/* </CardBody> */}
                {/* </Card>  */}
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCategory.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  items: PropTypes.object,
  onAddCategories: PropTypes.func,
  onGetCategories: PropTypes.func,
  onPutCategories: PropTypes.func,
  subCategories: array

};

const mapStateToProps = ({ File, Category }) => ({
  loading: Category.loading,
  items: Category.item,
  totalCategories: Category.totalCategories,
  subCategories: Category.subCategories,
  fileLoading: File?.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onAddCategories: (data, history) => dispatch(addCategory(data, history)),
  onGetSubCategories: (data, history) => dispatch(getSubCategories(data, history)),
  onGetCategories: (id) => dispatch(getCategory(id)),
  onPutCategories: (data, history) => dispatch(putCategory(data, history)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCategory))
);