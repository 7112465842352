import { GET_ADMIN_EARNING, GET_ADMIN_EARNING_FAIL, GET_ADMIN_EARNING_SUCCESS, GET_ALL_TRANSACTIONS, GET_ALL_TRANSACTIONS_FAIL, GET_ALL_TRANSACTIONS_SUCCESS, GET_CSV, GET_CSV_FAIL, GET_CSV_SUCCESS, GET_MANAGE_EARNING, GET_MANAGE_EARNING_FAIL, GET_MANAGE_EARNING_SUCCESS } from "./actionTypes";

export const getTransactionsWithFilter = (data, callback) => ({
    type: GET_ALL_TRANSACTIONS,
    payload: { data, callback },
});

export const getTransactionsWithFilterFail = (error) => ({
    type: GET_ALL_TRANSACTIONS_FAIL,
    payload: error,
});

export const getTransactionsWithFilterSuccess = (data) => ({
    type: GET_ALL_TRANSACTIONS_SUCCESS,
    payload: data,
});


export const manageEarning = (data, callback) => ({
    type: GET_MANAGE_EARNING,
    payload: { data, callback },
});

export const manageEarningFail = (error) => ({
    type: GET_MANAGE_EARNING_FAIL,
    payload: error,
});

export const manageEarningSuccess = (data) => ({
    type: GET_MANAGE_EARNING_SUCCESS,
    payload: data,
});


export const getCsv = (data, callback) => ({
    type: GET_CSV,
    payload: { data, callback },
});

export const getCsvFail = (error) => ({
    type: GET_CSV_FAIL,
    payload: error,
});

export const getCsvSuccess = (data) => ({
    type: GET_CSV_SUCCESS,
    payload: data,
});

export const getAdminEarning = (data, callback) => ({
    type: GET_ADMIN_EARNING,
    payload: { data, callback },
});

export const getAdminEarningFail = (error) => ({
    type: GET_ADMIN_EARNING_FAIL,
    payload: error,
});

export const getAdminEarningSuccess = (data) => ({
    type: GET_ADMIN_EARNING_SUCCESS,
    payload: data,
});