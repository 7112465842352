import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Spinner } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { editPrinter, getPrinter } from "store/actions";
import addprintclose from "assets/images/svg/addprintclose.svg"
import toastr from "toastr";
const EditPrintPartner = props => {
  const { isOpen, toggle, onGetPartner, userId, partner, onEditPartner, loading } = props
  const [viewDialog, setViewDialog] = useState(false)
  const [addLength, setAddLength] = useState(0)
  const [fields, setFields] = useState({
    _id: userId,
    shop_name: "",
    email: "",
    business_address: "",
    website: "",
    business_contact_number: "",
  })
  const [ovalLoading, setOvalLaoding]= useState(false)
  const callback = (res)=>{
    setOvalLaoding(false)
  }

  useEffect(() => {
    console.log({partner})
    setFields((p) => ({ ...p, shop_name: partner?.shop_name, email: partner?.email, business_address: partner?.business_address, website: partner?.website, business_contact_number: partner?.business_contact_number }))
  }, [partner])

  useEffect(() => {
    if (userId && isOpen === true) {
      onGetPartner({ _id: userId }, callback)
    }
  }, [isOpen, viewuser])
  useEffect(()=>{
    setAddLength(fields?.business_address?.length)
  }, [fields])
  const closeDialog = () => {
    toggle()
    setViewDialog(false)
  }
  const handleSaveButton = () => {
    const callbackeidt = (res) => {
      if (res.status !== "success") {
        return toastr.error(response.message);
      }
      toastr.success("Print Shop has been successfully updated.");
      closeDialog()
    }
    onEditPartner(fields, callbackeidt)
  }
  const handleChange = (name, e) => {
    const value = e.target.value
    setFields((p) => ({ ...p, [name]: value }))
  }
  return (
    <>
      {ovalLoading ? (
        <div className="spinnerBoxNew">
          <Spinner color="primary" />
        </div>) :
        (
          <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>
            <div className='dialogInner'>
              <div className='addprinthead'>
                <h1>Edit Print Shop</h1>
                <button className='closebtn' onClick={toggle}>
                  <img src={addprintclose} alt="close" width={40} height={40} />
                </button>
              </div>
              <div className={'dialogbody ' + (viewuser.viewProfile)}>
                <div className='row'>
                  <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                    <div className='form-group'>
                      <label>Shop Name</label>
                      <input type="text" className='form-control' placeholder="Enter shop name" value={fields?.shop_name} onChange={(e) => handleChange("shop_name", e)} maxLength={30} />
                    </div>
                  </div>
                  <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                    <div className='form-group'>
                      <label>Contact Number</label>
                      <input type="text" className='form-control' placeholder="Enter contact number" value={fields?.business_contact_number} onChange={(e) => handleChange("business_contact_number", e)} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xl-12 mb-4'>
                    <div className='form-group'>
                      <label>Business Address</label>
                      <textarea className='form-control' placeholder="Enter business address" value={fields?.business_address} onChange={(e) => handleChange("business_address", e)} maxLength={200} />{`${addLength}/200`}
                    </div>
                  </div>
                  <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                    <div className='form-group'>
                      <label>Email Address</label>
                      <input type="text" className='form-control' placeholder="Enter email address" value={fields?.email} disabled onChange={(e) => handleChange("email", e)} />
                    </div>
                  </div>
                  <div className='col-xl-6 col-md-6 col-sm-6 mb-4'>
                    <div className='form-group'>
                      <label>Website</label>
                      <input type="text" className='form-control' placeholder="Enter website URL" value={fields?.website} onChange={(e) => handleChange("website", e)} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-xl-12 col-12 col-md-12 col-sm-12 ">
                    <button className="btn verifybtn px-5 text-white me-3" type="submit" onClick={handleSaveButton}>
                      Save
                    </button>
                    <button className="btn btn-outline-danger px-5" onClick={toggle}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  )

}
EditPrintPartner.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any
}
const mapStateToProps = ({ Partners }) => ({
  error: Partners.error,
  loading: Partners.loading,
  partner: Partners.partner,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPartner: (id) => dispatch(getPrinter(id)),
  onEditPartner: (data, callback) => dispatch(editPrinter(data, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPrintPartner);