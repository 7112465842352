import { OTHER_NOTIFICATION_FAIL, OTHER_NOTIFICATION_REQUEST, OTHER_NOTIFICATION_SUCCESS } from "./actionTypes";

const INIT_STATE = {
    error: "",
    loading: false,

};

const sendOtherNotification = (state = INIT_STATE, action) => {
    switch (action.type) {
        case OTHER_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case OTHER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                emails: action.payload.data || [],
                totalEmails: action.payload.totalCount || 0,
                loading: false,
                error: "",
            };
        case OTHER_NOTIFICATION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}

export default sendOtherNotification;
