import { GET_ALL_SELL_ITEMS, GET_ALL_SELL_ITEMS_FAIL, GET_ALL_SELL_ITEMS_SUCCESS } from "./actionTypes";

export const getAllSellItemsWithFilter = (data, callback) => ({
    type: GET_ALL_SELL_ITEMS,
    payload: { data, callback },
});

export const getAllSellItemsWithFilterFail = (error) => ({
    type: GET_ALL_SELL_ITEMS_FAIL,
    payload: error,
});

export const getAllSellItemsWithFilterSuccess = (data) => ({
    type: GET_ALL_SELL_ITEMS_SUCCESS,
    payload: data,
});