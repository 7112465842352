import { getAdminEarningApi, getCsvApi, getManageEarningApi, getTransactionsWithFilterApi } from "helpers/backend_helper";
import { getAdminEarningFail, getAdminEarningSuccess, getCsvFail, getCsvSuccess, getTransactionsWithFilterFail, getTransactionsWithFilterSuccess, manageEarningFail, manageEarningSuccess } from "./action";
import { GET_ADMIN_EARNING, GET_ALL_TRANSACTIONS, GET_CSV, GET_CSV_SUCCESS, GET_MANAGE_EARNING } from "./actionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
function* getTransactionsWithFilter({ payload: { data, callback } }) {
    try {
      const response = yield call(getTransactionsWithFilterApi, data);
      console.log("ronMarkOtherNotifications", response);
      if (response.status == "failure") {
  
        return yield put(getTransactionsWithFilterFail(response.message));
      }
      yield put(getTransactionsWithFilterSuccess(response));
      callback && callback(response);
    } catch (error) {
      yield put(getTransactionsWithFilterFail("Internal Error!"));
    }
  }


  function* getManageEarning({ payload: { data, callback } }) {
    try {
      const response = yield call(getManageEarningApi, data);
      console.log("ronMarkOtherNotifications", response);
      if (response.status == "failure") {
  
        return yield put(manageEarningFail(response.message));
      }
      yield put(manageEarningSuccess(response));
      callback && callback(response);
    } catch (error) {
      yield put(manageEarningFail("Internal Error!"));
    }
  }


  function* getCsv({ payload: { data, callback } }) {
    try {
      const response = yield call(getCsvApi, data);
      console.log("ronMarkOtherNotifications", response);
      if (response.status == "failure") {
  
        return yield put(getCsvFail(response.message));
      }
      yield put(getCsvSuccess(response));
      callback && callback(response);
    } catch (error) {
      yield put(getCsvFail("Internal Error!"));
    }
  }


  function* getAdminEarning({ payload: { data, callback } }) {
    try {
      const response = yield call(getAdminEarningApi, data);
      console.log("ronMarkOtherNotifications", response);
      if (response.status == "failure") {
  
        return yield put(getAdminEarningFail(response.message));
      }
      yield put(getAdminEarningSuccess(response));
      callback && callback(response);
    } catch (error) {
      yield put(getAdminEarningFail("Internal Error!"));
    }
  }
  
  function* TransactionsSaga() {
    yield takeEvery(GET_ALL_TRANSACTIONS, getTransactionsWithFilter);
    yield takeEvery(GET_MANAGE_EARNING, getManageEarning);
    yield takeEvery(GET_CSV, getCsv);
    yield takeEvery(GET_ADMIN_EARNING, getAdminEarning);
  }
  export default TransactionsSaga;
  