import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import loaderLogo from "assets/images/loaderlogo.png";
import { ROLES } from "helpers/contants";

const Loader = (props) => {
  const {
    meLoading,
    isMeFailed,
    storeLoading,
    starterLoading,
    isStoreFailed,
    getStarted,
  } = props;

  useEffect(() => {
    document.querySelector("body").classList.add("auth-body");

    return () => {
      document.querySelector("body").classList.remove("auth-body");
    };
  }, []);

  useEffect(() => {
    props?.history?.replace("/pages-500");
  }, [meLoading, isMeFailed, storeLoading, isStoreFailed, starterLoading]);

  return (
    <div className="icon cloudy">
      <img src={loaderLogo} className="loader-img" />

      <div className="cloud"></div>

      <h4 className="loadingtext">Loading please wait...</h4>

      <label className="loading">
        <input type="checkbox" />

        <div>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </label>
    </div>
  );
};

const mapStateToProps = ({ Login, Settings }) => ({
  user: Login?.user,
  meLoading: Login?.meLoading,
  isMeFailed: Login?.isMeFailed,
  settings: Settings?.settings,
  storeLoading: Settings?.storeLoading,
  starterLoading: Settings?.starterLoading,
  isStoreFailed: Settings?.isStoreFailed,
  getStarted: Settings?.getStarted,
});

export default connect(mapStateToProps)(withRouter(Loader));
