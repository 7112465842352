export const COLLECTION_API_FAIL = "COLLECTION_API_FAIL";

// COLLECTIONs
export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_COLLECTIONS_FAIL = "GET_COLLECTIONS_FAIL";
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";

// Add COLLECTION
export const ADD_COLLECTION = "ADD_COLLECTION";
export const ADD_COLLECTION_FAIL = "ADD_COLLECTION_FAIL";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";

// Get COLLECTION
export const GET_COLLECTION = "GET_COLLECTION";
export const GET_COLLECTION_FAIL = "GET_COLLECTION_FAIL";
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";

// update COLLECTION
export const PUT_COLLECTION = "PUT_COLLECTION";
export const PUT_COLLECTION_FAIL = "PUT_COLLECTION_FAIL";
export const PUT_COLLECTION_SUCCESS = "PUT_COLLECTION_SUCCESS";

// Delete COLLECTION
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const DELETE_COLLECTION_FAIL = "DELETE_COLLECTION_FAIL";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";

// Update Multi COLLECTION Status
export const PUT_COLLECTIONS_STATUS = "PUT_COLLECTIONS_STATUS";
export const PUT_COLLECTIONS_STATUS_FAIL = "PUT_COLLECTIONS_STATUS_FAIL";
export const PUT_COLLECTIONS_STATUS_SUCCESS = "PUT_COLLECTIONS_STATUS_SUCCESS";

// Verified Collections
export const VERIFY_COLLECTION_REQUEST = "VERIFY_COLLECTION_REQUEST";
export const VERIFY_COLLECTION_REQUEST_FAIL = "VERIFY_COLLECTION_REQUEST_FAIL ";
export const VERIFY_COLLECTION_REQUEST_SUCCESS =
  "VERIFY_COLLECTION_REQUEST_SUCCESS";


  // NEW COLLECTIONs
export const GET_NEW_COLLECTIONS = "GET_NEW_COLLECTIONS";
export const GET_NEW_COLLECTIONS_FAIL = "GET_NEW_COLLECTIONS_FAIL";
export const GET_NEW_COLLECTIONS_SUCCESS = "GET_NEW_COLLECTIONS_SUCCESS";
// new collection by id 
export const GET_NEW_COLLECTIONS_BY_ID = "GET_NEW_COLLGET_NEW_COLLECTIONS_BY_ID";
export const GET_NEW_COLLECTIONS_FAIL_BY_ID = "GET_NEW_COLLECTIONS_FAIL_BY_ID";
export const GET_NEW_COLLECTIONS_SUCCESS_BY_ID = "GET_NEW_COLLECTIONS_SUCCESS_BY_ID";