export const CATEGORY_API_FAIL = "CATEGORY_API_FAIL";

// CATEGORYs
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

// BLOG
export const GET_BLOG = "GET_BLOG";
export const GET_BLOG_FAIL = "GET_BLOG_FAIL";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";

export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";

export const VIEW_BLOG = "VIEW_BLOG";
export const VIEW_BLOG_FAIL = "VIEW_BLOG_FAIL";
export const VIEW_BLOG_SUCCESS = "VIEW_BLOG_SUCCESS";

export const UPDATE_BLOG = "UPDATE_BLOG";
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";

// Add CATEGORY
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";

// Get CATEGORY
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";

// update CATEGORY
export const PUT_CATEGORY = "PUT_CATEGORY";
export const PUT_CATEGORY_FAIL = "PUT_CATEGORY_FAIL";
export const PUT_CATEGORY_SUCCESS = "PUT_CATEGORY_SUCCESS";

// Delete CATEGORY
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

// Update Multi CATEGORY Status
export const PUT_CATEGORIES_STATUS = "PUT_CATEGORIES_STATUS";
export const PUT_CATEGORIES_STATUS_FAIL = "PUT_CATEGORIES_STATUS_FAIL";
export const PUT_CATEGORIES_STATUS_SUCCESS = "PUT_CATEGORIES_STATUS_SUCCESS";

// BLOG
export const ADD_BLOG = "ADD_BLOG";
export const ADD_BLOG_FAIL = "ADD_BLOG_FAIL";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";

export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";

export const GET_REPORT_VIEW = "GET_REPORT_VIEW";
export const GET_REPORT_VIEW_FAIL = "GET_REPORT_VIEW_FAIL";
export const GET_REPORT_VIEW_SUCCESS = "GET_REPORT_VIEW_SUCCESS";

//sub CATEGORIES 
export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES";
export const GET_SUBCATEGORIES_FAIL = "GET_SUBCATEGORIES_FAIL";
export const GET_SUBCATEGORIES_SUCCESS = "GET_SUBCATEGORIES_SUCCESS";