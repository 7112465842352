import { APPROVE_OR_REJECT, APPROVE_OR_REJECT_FAIL, APPROVE_OR_REJECT_SUCCESS, GET_ARTS, GET_ARTS_FAIL, GET_ARTS_SUCCESS, GET_ART_BY_ID, GET_ART_BY_ID_FAIL, GET_ART_BY_ID_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    arts: [],
    totalArts: 0,
    error: "",
    loading: false,
    artById: {}
    // item: {},
    // blogview: {},
};

const Arts = (state = INIT_STATE, action) => {
    switch (action.type) {
        /* Get Categories */
        case GET_ARTS:
        case GET_ART_BY_ID:
            return {
                ...state,
                loading: true,
            };

        case GET_ARTS_SUCCESS:
            return {
                ...state,
                arts: action.payload.data || [],
                totalArts: action.payload.totalCount || 0,
                loading: false,
                error: "",
            };
        case GET_ART_BY_ID_SUCCESS:
            return {
                ...state,
                artById: action.payload.data || {},
                loading: false,
                error: "",
            };
        case GET_ARTS_FAIL:
        case GET_ART_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case APPROVE_OR_REJECT:
            return {
                ...state,
                loading: true,
            };

        case APPROVE_OR_REJECT_SUCCESS:
            return {
                ...state,
                error: "",
                loading: false,
            };
        case APPROVE_OR_REJECT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}

export default Arts;