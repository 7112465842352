// CATEGORYs
export const GET_SENDEMAIL = "GET_SENDEMAIL";
export const GET_SENDEMAIL_FAIL = "GET_SENDEMAIL_FAIL";
export const GET_SENDEMAIL_SUCCESS = "GET_SENDEMAIL_SUCCESS";

// Add CATEGORY
export const ADD_EMAIL = "ADD_EMAIL";
export const ADD_EMAIL_FAIL = "ADD_EMAIL_FAIL";
export const ADD_EMAIL_SUCCESS = "ADD_EMAIL_SUCCESS";


// fetch email by id
export const GET_EMAIL_BY_ID = "GET_EMAIL_BY_ID";
export const GET_EMAIL_BY_ID_FAIL = "GET_EMAIL_BY_ID_FAIL";
export const GET_EMAIL_BY_ID_SUCCESS = "GET_EMAIL_BY_ID_SUCCESS";