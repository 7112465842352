import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";

import { Button, Modal, ModalBody } from "reactstrap"
import viewuser from "../../../assets/css/viewuser.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, getNewCollectionByID } from "store/actions";
import CustomersList from ".";
import moment from "moment-timezone";

const ViewCollectionDialog = props => {
  console.log("props", props);

  const dispatch = useDispatch()
  const { customer } = useSelector((s) => s?.customers)
  const { newCollection, isOpen, toggle, onConfirm, userId, onGetCollectionById } = props
  const [userRole, setuserRole] = useState('MUSEUM')
  console.log(isOpen);
  console.log(userId);

  const [viewDialog, setViewDialog] = useState(false)

  useEffect(() => {
    if (userId && isOpen === true) {
      onGetCollectionById(userId)
    }
  }, [userId])
  const closeDialog = () => {
    console.log('Deepak');
    setViewDialog(false)
  }
  return (
    // <Modal

    //   isOpen={isOpen}
    //   autoFocus={true}
    //   centered={true}
    //   toggle={toggle} className="dialogInner"
    // >
    //   <div className="modal-content ">
    //     <ModalBody>
    //       <div className="infoimg">
    //        Deepak
    //       </div>

    //       <h4>Are you sure?</h4>

    //       <div className="delete-btns">
    //         <Button type="button" outline color="secondary" onClick={toggle}>
    //           No, cancel!
    //         </Button>

    //         <Button type="button" color="primary" onClick={onConfirm}>
    //           Yes, delete it!
    //         </Button>
    //       </div>
    //     </ModalBody>
    //   </div>
    // </Modal>
    <div className={'dialogMain ' + (isOpen === true ? 'show' : '')}>
      <div className='dialogInner'>
        <div className='dialogHead'>
          <h1>View Collection</h1>
          <button className='closebtn' onClick={toggle}>
            <img src={'/images/svg/icons/closeIcon.svg'} alt="close" width={40} height={40} />
          </button>
        </div>
        <div className={'dialogbody pb-0 ' + (viewuser.viewProfile)}>
          <div className='row'>
            <div className='col-xl-5'>
              <div className={viewuser.profilephoto}>
                {/* <img src={customer?.profileImage?.link} alt="Image" width={100} height={100} /> */}
                <img src={newCollection?.image?.link} alt="Image" width={100} height={100} />

              </div>
            </div>
            <div className='col-xl-7'>
              <div className='row'>

                <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                  <div className='form-group'>
                    <label >Creator Name</label>
                    <input type="text" className='form-control' readOnly value={newCollection?.email?.name + " " + newCollection?.email?.lastname} name="" id="" />
                  </div>
                </div>
                <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                  <div className='form-group'>
                    <label >Role</label>
                    <input type="text" className='form-control' readOnly value={newCollection?.email?.role} name="" id="" />
                  </div>
                </div>


                <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                  <div className='form-group'>
                    <label >Name</label>
                    <input type="text" className='form-control' readOnly value={newCollection?.name} name="" id="" />
                  </div>
                </div>
                {/* <div className='col-xl-6 col-12 col-md-6 col-sm-6 mb-4'>
                  <div className='form-group'>
                    <label >Category</label>
                    <input type="text" className='form-control' readOnly value={} name="" id="" />
                  </div>
                </div> */}
                <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
                  <div className='form-group'>
                    <label >Total NFT</label>
                    <input type="text" className='form-control' readOnly value={newCollection?.totalNfts} name="" id="" />
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div className='row'>
            <div className='col-xl-12 col-12 col-md-12 col-sm-12 mb-4'>
              <div className='form-group'>
                <label >Description</label>
                <textarea className='form-control' readOnly value={newCollection?.description} name="" id="" ></textarea>
              </div>
            </div>
          </div>
          {/* <div className='row'>
            <div className="col-xl-4">
              <div className="CollectionnftBox">
                <figure>
                  <img src={'/images/jpg/viewart.jpg'} alt="Image" />
                  <figcaption>Catsmoke #39</figcaption>
                </figure>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )

}
ViewCollectionDialog.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.bool,
  userId: PropTypes.any
}
const mapStateToProps = ({ collections }) => ({
  error: collections.error,
  loading: collections.loading,
  newCollection: collections.newCollection,
});

const mapDispatchToProps = (dispatch) => ({
  // onGetCustomer: (id) => dispatch(getCustomer(id)),
  onGetCollections: (data) => dispatch(getNewCollections(data)),
  onGetCollectionById: (id) => dispatch(getNewCollectionByID(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCollectionDialog);