import React from "react"
import { Button } from "reactstrap"

const SearchInput = (props) => {
  const handleClick = (event) => {
    // console.log("event handleClick", event);
    props?.triggerSearch(event)
  }

  return (
   
      <div className="position-relative">
     
          <input
            id="search-bar-0"
            aria-labelledby="search-bar-0-label"
            className="form-control"
            type="text"
            placeholder={props?.placeholder}
            onKeyPress={(e) =>
              ["Enter", "NumpadEnter"].includes(e.code) ? handleClick(e) : null
            }
            onChange={(e) => props.onSearch(e.target.value)}
            value={props?.searchText}
          />
      
        <button  onClick={handleClick} className='btn position-absolute border-0 end-0 top-0 bottom-0 p-0 me-3'><i className="fa-light fa-magnifying-glass"></i></button>
       
     
    </div>
  )
}

export default SearchInput
