import * as TYPES from "./actionTypes";

// list
export const getImages = (data) => ({
  type: TYPES.GET_IMAGES,
  payload: data,
});

export const getImagesSuccess = (data) => ({
  type: TYPES.GET_IMAGES_SUCCESS,
  payload: data,
});

export const getImagesFail = (error) => ({
  type: TYPES.GET_IMAGES_FAIL,
  payload: error,
});

// Add
export const postImage = (data, callback) => ({
  type: TYPES.POST_IMAGE,
  payload: { data, callback },
});

export const postImageFail = (error) => ({
  type: TYPES.POST_IMAGE_FAIL,
  payload: error,
});

export const postImageSuccess = () => ({
  type: TYPES.POST_IMAGE_SUCCESS,
});

// Get
export const getImage = (id) => ({
  type: TYPES.GET_IMAGE,
  payload: { id },
});

export const getImageFail = (error) => ({
  type: TYPES.GET_IMAGE_FAIL,
  payload: error,
});

export const getImageSuccess = (data) => ({
  type: TYPES.GET_IMAGE_SUCCESS,
  payload: data,
});

// Update
export const putImage = (data, callback) => ({
  type: TYPES.PUT_IMAGE,
  payload: { data, callback },
});

export const putImageFail = (error) => ({
  type: TYPES.PUT_IMAGE_FAIL,
  payload: error,
});

export const putImageSuccess = () => ({
  type: TYPES.PUT_IMAGE_SUCCESS,
});

// Delete
export const deleteImage = (data, callback) => ({
  type: TYPES.DELETE_IMAGE,
  payload: { data, callback },
});

export const deleteImageFail = (error) => ({
  type: TYPES.DELETE_IMAGE_FAIL,
  payload: error,
});

export const deleteImageSuccess = () => ({
  type: TYPES.DELETE_IMAGE_SUCCESS,
});
