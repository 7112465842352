import {
  COLLECTION_API_FAIL,
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  ADD_COLLECTION,
  ADD_COLLECTION_FAIL,
  ADD_COLLECTION_SUCCESS,
  GET_COLLECTION,
  GET_COLLECTION_FAIL,
  GET_COLLECTION_SUCCESS,
  PUT_COLLECTION,
  PUT_COLLECTION_FAIL,
  PUT_COLLECTION_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_FAIL,
  DELETE_COLLECTION_SUCCESS,
  PUT_COLLECTIONS_STATUS,
  PUT_COLLECTIONS_STATUS_FAIL,
  PUT_COLLECTIONS_STATUS_SUCCESS,
  VERIFY_COLLECTION_REQUEST,
  VERIFY_COLLECTION_REQUEST_SUCCESS,
  VERIFY_COLLECTION_REQUEST_FAIL,
  GET_NEW_COLLECTIONS_FAIL,
  GET_NEW_COLLECTIONS_SUCCESS,
  GET_NEW_COLLECTIONS,
  GET_NEW_COLLECTIONS_BY_ID,
  GET_NEW_COLLECTIONS_SUCCESS_BY_ID,
  GET_NEW_COLLECTIONS_FAIL_BY_ID,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: COLLECTION_API_FAIL,
  payload: error,
});

/* Get collections */
export const getCollections = (data) => ({
  type: GET_COLLECTIONS,
  payload: data,
});

export const getCollectionsSuccess = (collections) => ({
  type: GET_COLLECTIONS_SUCCESS,
  payload: collections,
});

export const getCollectionsFail = (error) => ({
  type: GET_COLLECTIONS_FAIL,
  payload: error,
});
/* Get Collections END */

/* Add Collection */
export const addCollection = (collection, history) => ({
  type: ADD_COLLECTION,
  payload: { collection, history },
});

export const addCollectionFail = (error) => ({
  type: ADD_COLLECTION_FAIL,
  payload: error,
});

export const addCollectionSuccess = () => ({
  type: ADD_COLLECTION_SUCCESS,
});
/* Add Collection END */

/* Get Collection */
export const getCollection = (id) => ({
  type: GET_COLLECTION,
  payload: id,
});

export const getCollectionFail = (error) => ({
  type: GET_COLLECTION_FAIL,
  payload: error,
});

export const getCollectionSuccess = (collection) => ({
  type: GET_COLLECTION_SUCCESS,
  payload: collection,
});
/* Get Collection END */

/* Update Collection */
export const putCollection = (data, history) => ({
  type: PUT_COLLECTION,
  payload: { data, history },
});

export const putCollectionFail = (error) => ({
  type: PUT_COLLECTION_FAIL,
  payload: error,
});

export const putCollectionSuccess = () => ({
  type: PUT_COLLECTION_SUCCESS,
});
/* Update Collection END */

/* Delete Collection */
export const deleteCollection = (data, callback) => ({
  type: DELETE_COLLECTION,
  payload: { data, callback },
});

export const deleteCollectionFail = (error) => ({
  type: DELETE_COLLECTION_FAIL,
  payload: error,
});

export const deleteCollectionSuccess = () => ({
  type: DELETE_COLLECTION_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Collections Status */
export const putCollectionsStatus = (data, callback) => ({
  type: PUT_COLLECTIONS_STATUS,
  payload: { data, callback },
});

export const putCollectionsStatusFail = (error) => ({
  type: PUT_COLLECTIONS_STATUS_FAIL,
  payload: error,
});

export const putCollectionsStatusSuccess = () => ({
  type: PUT_COLLECTIONS_STATUS_SUCCESS,
});
/* Update Multi Collections Status END */

// Verify Collection Request

export const VerifyCollectionRequest = (data, callback) => {
  return {
    type: VERIFY_COLLECTION_REQUEST,
    payload: { data, callback },
  };
};

export const VerifyCollectionRequestSuccess = (msg) => {
  return {
    type: VERIFY_COLLECTION_REQUEST_SUCCESS,
    payload: msg,
  };
};

export const VerifyCollectionRequestError = (error) => {
  return {
    type: VERIFY_COLLECTION_REQUEST_FAIL,
    payload: error,
  };
};

// new collections 
export const getNewCollections = (data, callback) => ({
  type: GET_NEW_COLLECTIONS,
  payload:{data, callback},
});

export const getNewCollectionsSuccess = (collections) => ({
  type: GET_NEW_COLLECTIONS_SUCCESS,
  payload: collections,
});

export const getNewCollectionsFail = (error) => ({
  type: GET_NEW_COLLECTIONS_FAIL,
  payload: error,
});

export const getNewCollectionByID = (id, callback) => ({
  type: GET_NEW_COLLECTIONS_BY_ID,
  payload: {id, callback},
});

export const getNewCollectionsSuccessByID  = (collections) => ({
  type: GET_NEW_COLLECTIONS_SUCCESS_BY_ID,
  payload: collections,
});

export const getNewCollectionsFailByID  = (error) => ({
  type: GET_NEW_COLLECTIONS_FAIL_BY_ID,
  payload: error,
});
